import React from 'react';

const FaBuildingColumnsLight: React.FC<React.SVGProps<SVGSVGElement>> = (props) => (
    <svg viewBox="0 0 511 512" xmlns="http://www.w3.org/2000/svg" {...props}>
        <path
            d="M134.236 66.7429C162.362 50.8343 200.167 29.2571 218.248 18.8343C236.329 8.59429 253.131 0 255.688 0C258.245 0 273.038 7.31429 288.562 16.2743C304.086 25.2343 338.239 44.6171 364.356 59.4286C390.472 74.24 426.999 95.0857 445.628 105.691C464.257 116.114 483.616 127.086 488.547 129.829C493.661 132.754 499.505 136.96 501.697 139.337C503.888 141.714 506.993 146.834 508.637 150.857C510.707 155.733 511.315 160.305 510.463 164.571C509.733 168.046 507.541 174.08 505.349 177.92C502.792 182.126 498.592 186.331 494.026 188.343C486.903 191.817 480.328 192 255.688 192C29.0389 192 24.473 191.817 16.985 188.343C10.7754 185.417 8.21855 182.674 4.56586 175.177C2.00898 169.874 0 163.474 0 160.914C0 158.354 1.09581 153.417 2.55688 149.943C4.01796 146.469 7.48801 141.349 10.4102 138.789C13.1497 136.046 30.6826 125.257 49.3113 114.834C67.94 104.411 106.111 82.6514 134.236 66.7429Z"
            fill="inherit"
        />
        <path
            d="M63.922 351.086V224.914H94.9699V286.72C94.7873 334.811 95.3351 348.891 97.1615 350.537C98.9878 352.183 109.398 352.731 174.416 352L176.242 225.829H207.29L209.116 352H302.26L304.086 225.829H335.134L336.96 352H413.667L415.493 225.829L447.454 224.914V288C447.454 346.331 447.637 351.086 450.742 352.183C452.385 352.914 455.673 355.109 458.047 357.303C460.969 360.046 462.248 363.154 462.248 368.457C462.248 373.577 460.969 376.869 458.23 379.429C454.403 383.077 451.485 383.086 259.917 383.631L258.61 383.634C104.102 384 61.9131 383.634 57.7125 381.806C54.7903 380.526 51.3203 377.234 50.0418 374.491C48.5807 371.566 48.0328 367.543 48.5807 365.349C49.3113 362.971 51.1376 359.68 52.7813 357.669C54.425 355.84 57.5298 353.646 63.922 351.086Z"
            fill="inherit"
        />
        <path
            d="M24.6556 432.457C24.6556 427.154 25.9341 424.594 29.7694 421.303L34.8832 416.914H476.676L482.155 422.4C486.721 426.971 487.451 428.983 486.721 433.92C486.173 437.029 483.981 441.417 481.972 443.429C478.508 447.08 475.59 447.086 37.8024 447.995L35.6137 448L30.1347 443.611C25.7514 440.137 24.6556 437.76 24.6556 432.457Z"
            fill="inherit"
        />
        <path
            d="M1.82634 488.229C2.92215 486.217 6.3922 483.474 9.67962 482.011C14.7934 479.634 49.1286 479.269 255.688 479.269C480.694 479.086 496.218 479.269 502.245 482.377C506.263 484.389 509.185 487.497 510.28 490.971C511.376 494.629 511.194 498.469 509.915 501.943C508.637 505.051 505.715 508.526 503.158 509.806C499.505 511.634 446.358 512 255.688 512C65.0178 512 11.8712 511.634 8.21855 509.806C5.66167 508.526 2.92215 505.6 1.82634 503.406C0.913172 501.029 0 497.554 0 495.543C0 493.531 0.913172 490.24 1.82634 488.229Z"
            fill="inherit"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M256.236 34.7429C256.966 34.7429 271.76 42.7886 288.928 52.8457C306.278 62.72 347.553 86.3086 380.793 105.143C413.849 123.977 448.55 143.543 457.499 148.663C469.188 155.246 473.023 158.171 470.831 158.903C469.005 159.451 431.565 159.817 306.826 159.086L308.652 154.514C309.748 151.954 310.478 143.543 310.478 135.68C310.478 125.44 309.383 119.406 306.643 113.371C304.451 108.8 300.616 102.4 298.059 99.1086C295.32 95.6343 287.832 90.3314 281.257 87.2229C271.029 82.1029 267.559 81.3714 254.775 81.3714C241.443 81.3714 239.068 81.92 228.293 87.9543C219.526 92.7086 214.778 96.7314 210.395 103.497C207.107 108.434 203.455 115.749 202.359 119.771C201.263 123.794 200.35 130.377 200.167 134.4C200.167 138.423 200.532 145.554 202.724 159.086H120.173C72.871 159.086 38.3531 158.354 39.449 157.44C40.3621 156.526 66.1136 141.714 96.7962 124.16C127.479 106.789 175.512 79.5429 203.637 63.6343C231.763 47.7257 255.323 34.7429 256.053 34.7429H256.236ZM255.801 112.201C257.262 112.201 262.233 112.694 266.433 114.705C273 118.462 275.532 121.28 277.604 127.228C280.344 135.091 279.978 136.96 277.604 143.543C275.96 148.114 272.673 152.32 268.655 154.88C265.002 157.44 259.523 159.269 255.871 159.269C252.218 159.269 246.739 157.44 243.086 154.88C239.068 152.32 235.781 148.114 234.137 143.543C231.763 137.143 232.694 132.405 233.911 127.854C235.607 123.326 238.776 117.814 245.169 114.705C249.552 112.328 254.34 112.201 255.801 112.201Z"
            fill="inherit"
        />
    </svg>
);

export default FaBuildingColumnsLight;
