import { create } from 'zustand';
import { ObjectKeys } from '../types/objectKeys';

interface StatisticsState {
    statisticData: ObjectKeys;
}

const useStatisticStore = create<StatisticsState>(() => ({
    statisticData: {},
}));

export default useStatisticStore;
