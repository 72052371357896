import useLanguageStore from '../../../../store/language';
import useTranslations from '../../../../hooks/useTranslation';
import { z } from 'zod';
import useAllBanksQuery from '../../../../react-query/useAllBanksQuery';

const serbianIbanRegex = /^RS[0-9]{2}[0-9]{3}[0-9]{13}[0-9]{2}$/;

const useForeignBankAccountSchema = () => {
    const { currentLang } = useLanguageStore();
    const t = useTranslations(currentLang);
    const { data: allBanks } = useAllBanksQuery();

    const ForeignBankAccountSchema = z.array(
        z
            .object({
                bank_id: z
                    .string({ message: t('pages.agency.bankAccounts.foreign.bank_required').text })
                    .min(1, { message: t('pages.agency.bankAccounts.foreign.bank_required').text }),
                currency: z
                    .string({ message: t('pages.agency.bankAccounts.foreign.currency_required').text })
                    .min(3, { message: t('pages.agency.bankAccounts.foreign.currency_required').text }),
                iban: z
                    .string({ message: t('pages.agency.bankAccounts.foreign.iban_required').text })
                    .min(1, { message: t('pages.agency.bankAccounts.foreign.iban_required').text }),
                swift: z
                    .string({ message: t('pages.agency.bankAccounts.foreign.swift_required').text })
                    .min(1, { message: t('pages.agency.bankAccounts.foreign.swift_required').text }),
            })
            .refine(
                (data) => {
                    const bankCode = allBanks?.find((bank) => bank.id === data.bank_id)?.code;
                    return !(data.iban && !serbianIbanRegex.test(data.iban) && bankCode !== '000');
                },
                {
                    message: t('pages.agency.bankAccounts.foreign.iban_length').text,
                    path: ['iban'],
                },
            )
            .refine(
                (data) => {
                    const bankCode = allBanks?.find((bank) => bank.id === data.bank_id)?.code;
                    const bankIbanCode = data.iban.slice(4, 7);
                    const ibanFirst4Digits = data.iban.slice(0, 4);
                    if (bankCode === '000') {
                        return true;
                    }
                    if (
                        (data.iban && bankCode && bankIbanCode && bankCode !== bankIbanCode) ||
                        ibanFirst4Digits !== 'RS35'
                    ) {
                        return false;
                    }

                    return true;
                },
                {
                    message: t('pages.agency.bankAccounts.foreign.iban_length').text, // Adjust the message accordingly
                    path: ['iban'],
                },
            ),
    );

    return { ForeignBankAccountSchema };
};

export default useForeignBankAccountSchema;
