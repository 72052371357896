import React, { useState } from 'react';
import styled from 'styled-components';
import useAllUsersStore from '../../../../store/admin/allUsers';
import { ObjectKeys } from '@/types/objectKeys';
import { formatTwoDecimals } from '../../../../functions/format';
import Checkbox from '../../../../components/checkbox';
import communication from '../../../../communication';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileInvoice } from '@fortawesome/free-solid-svg-icons';
import Loader from '../../../../components/loader';
import Modal from '../../../../components/modal';
import ConfirmModal from '../../../../components/confirmModal';
// import useServicesStore from '../../../../store/services';

interface CartProps {
    cart: {
        active_until: string;
        created_at: string;
        id: string;
        item: { data: ObjectKeys };
        status: string;
        total_price: number;
        updated_at: string;
        user_id: string;
        service: { data: ObjectKeys };
        read: boolean;
        quantity: number;
        invoice_pdf_url: string;
        packet_service_discount: number;
        current_package_name: string | null;
    };
    fetchCarts: Function;
}

const ServiceCard: React.FC<CartProps> = ({ cart, fetchCarts }) => {
    const { allUsers } = useAllUsersStore();
    const [expanded, setExpanded] = useState(false);

    const [loader, setLoader] = useState<boolean>(false);

    const [resendModal, setResendModal] = useState(false);

    const [clickedCartId, setClickedCartId] = useState('');

    const toggleExpanded = (): void => {
        setExpanded(!expanded);
    };

    const today = new Date();

    const returnUserData = (id: string, current_package: string | null): string => {
        const user = allUsers.find((useR: any) => useR.id === id);
        if (current_package) {
            return `${user ? user.email : 'N/A'} - ${current_package}`;
        } else {
            return `${user ? user.email : 'N/A'} - unknown package`;
        }
    };

    const handleDownloadClick = (url: string): void => {
        window.open(url, '_blank');
    };

    const handleResendFiscalBill = (id: string): void => {
        setClickedCartId(id);
        setResendModal(true);
    };

    const transformDateFormat = (dateString: string): string => {
        const date = new Date(dateString);
        const day = date.getDate();
        const month = date.getMonth() + 1; // Months are zero-based, so we add 1
        const year = date.getFullYear();

        // Ensure leading zeros for single-digit day and month
        const formattedDay = day < 10 ? '0' + day : day;
        const formattedMonth = month < 10 ? '0' + month : month;

        // Return the transformed date in the desired format
        return `${formattedDay}.${formattedMonth}.${year}.`;
    };
    const transformDateTimeFormat = (dateString: string): string => {
        const date = new Date(dateString);

        // Extract date components
        const day = date.getDate();
        const month = date.getMonth() + 1; // Months are zero-based, so we add 1
        const year = date.getFullYear();

        // Extract time components
        const hours = date.getHours();
        const minutes = date.getMinutes();
        const seconds = date.getSeconds();

        // Format date components with leading zeros where needed
        const formattedDay = day < 10 ? `0${day}` : day;
        const formattedMonth = month < 10 ? `0${month}` : month;

        // Format time components with leading zeros
        const formattedHours = hours < 10 ? `0${hours}` : hours;
        const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes;
        const formattedSeconds = seconds < 10 ? `0${seconds}` : seconds;

        // Combine formatted date and time
        return `${formattedDay}.${formattedMonth}.${year}. ${formattedHours}:${formattedMinutes}:${formattedSeconds}`;
    };

    const updateClickUp = (id: string): void => {
        communication.updateClickUp(id).then(() => {
            fetchCarts();
        });
    };

    const generateFiscalBill = (): void => {
        setLoader(true);
        communication
            .resendFiscalBill(clickedCartId)
            .then(() => {
                fetchCarts();
                setLoader(false);
            })
            .catch((error: any) => {
                console.error(error);
                fetchCarts();
                setLoader(false);
            });
    };

    const compareDates = (dateStr1: string, dateStr2: string): boolean => {
        // Parse the date strings into Date objects
        const date1 = new Date(dateStr1);
        const date2 = new Date(dateStr2);

        // Convert dates to ISO strings (UTC timezone) for accurate comparison
        const isoDate1 = date1.toISOString();
        const isoDate2 = date2.toISOString();

        // Compare the ISO date strings
        return isoDate1 === isoDate2;
    };

    return (
        <>
            {loader && <Loader />}
            {resendModal && (
                <Modal
                    width="500px"
                    className="package-modal"
                    modalVisible={true}
                    closeModal={() => {
                        setResendModal(false);
                    }}
                >
                    <ConfirmModal
                        action={() => {
                            setResendModal(false);
                            generateFiscalBill();
                        }}
                        close={() => {
                            setResendModal(false);
                        }}
                        active={resendModal}
                        message={'Da li ste sigurni da zelite da izdate fiskalni racun?'}
                    />
                </Modal>
            )}
            {cart.service && cart.service.data && (
                <TableRow onClick={toggleExpanded}>
                    <TableCell>{cart.service.data.name_rs}</TableCell>
                    <TableCell>
                        {returnUserData(cart.user_id, cart.current_package_name ? cart.current_package_name : null)}
                    </TableCell>
                    <TableCell>
                        {(() => {
                            const originalPrice = formatTwoDecimals(cart.service.data.price.toString());
                            const discountedPrice = formatTwoDecimals(
                                (
                                    cart.service.data.price -
                                    (cart.service.data.price * cart.packet_service_discount) / 100
                                ).toString(),
                            );

                            return (
                                <>
                                    {originalPrice !== discountedPrice && <p className="crossed">{originalPrice}</p>}
                                    <p>{discountedPrice}</p>
                                </>
                            );
                        })()}
                    </TableCell>
                    <TableCell>{cart.quantity}</TableCell>
                    <TableCell>{transformDateFormat(cart.created_at)}</TableCell>
                    {/* <TableCell>{cart.status}</TableCell> */}
                    <TableCell>
                        {cart.status === 'pending' ? (
                            <p>-</p>
                        ) : (
                            <>
                                {!cart.read ? (
                                    <CheckBox>
                                        <Checkbox
                                            defaultChecked={cart.read}
                                            onChange={() => {
                                                updateClickUp(cart.id);
                                            }}
                                            className="clickup-checkbox"
                                        />
                                    </CheckBox>
                                ) : (
                                    <p>{transformDateTimeFormat(cart.updated_at)}</p>
                                )}
                            </>
                        )}
                    </TableCell>
                    {cart.invoice_pdf_url ? (
                        <TableCell
                            onClick={(event) => {
                                event.stopPropagation();
                                handleDownloadClick(cart.invoice_pdf_url);
                            }}
                        >
                            <FontAwesomeIcon icon={faFileInvoice} className="billDownload" />
                        </TableCell>
                    ) : (
                        <>
                            {compareDates(today.toISOString(), cart.created_at) ? (
                                <TableCell
                                    onClick={(event) => {
                                        event.stopPropagation();
                                        handleResendFiscalBill(cart.id);
                                    }}
                                >
                                    <p className="billDownload">Ponovi slanje</p>
                                </TableCell>
                            ) : (
                                <TableCell>-</TableCell>
                            )}
                        </>
                    )}
                </TableRow>
            )}
        </>
    );
};

const TableRow = styled.tr`
    &:nth-child(even) {
        background-color: #f9f9f9;
    }
`;

const TableCell = styled.td`
    padding: 10px;
    border: 1px solid #ddd;
    text-align: center;
    .billDownload {
        cursor: pointer;
        color: var(--purple);
    }
    .crossed {
        text-decoration: line-through;
    }
`;
const CheckBox = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-around;
`;
// const ExpandedCell = styled.td`
//     padding: 10px;
//     border: 1px solid #ddd;
// `;

// const ItemTable = styled.table`
//     width: 100%;
//     border-collapse: collapse;
// `;

// const TableHeader = styled.th`
//     padding: 10px;
//     border: 1px solid #ddd;
//     color: white;
// `;

// const ExpandedRow = styled.tr`
//     background-color: var(--purple);
//     color: white;
// `;

export default ServiceCard;
