import { create } from 'zustand';
// import { ObjectKeys } from '../types/objectKeys';

interface UnitsState {
    units: Array<string>;
}

const useUnitsStore = create<UnitsState>(() => ({
    units: [
        'per_piece',
        'per_hour',
        'meter',
        'square_meter',
        'cubic_meter',
        'kilogram',
        'liter',
        'pack',
        'word',
        'per_day',
    ],
}));

export default useUnitsStore;
