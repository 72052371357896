import { queryClient } from '../../App';
import communication from '../../communication';
import { GET_NotificationsResponse } from '../../communication/notifications/types';
import { useMutation } from '@tanstack/react-query';
import { ObjectKeys } from '../../types/objectKeys';

const markAsRead = (alerts_ids: string[]) => {
    return communication.markAsRead({ alerts_ids });
};

const markAllAsRead = () => {
    return communication.markAllAsRead({ read_all: true });
};

type OldType = GET_NotificationsResponse['data'] & { unreadNotificationsCount: number };

export const useMarkAsRead = (params: ObjectKeys) => {
    const mutation = useMutation({
        mutationFn: (alerts_ids: string[]) => markAsRead(alerts_ids),
        onMutate: async (alerts_ids) => {
            // Cancel any outgoing refetches
            // (so they don't overwrite our optimistic update)
            await queryClient.cancelQueries({ queryKey: ['notifications'] });

            // Snapshot the previous value
            const previousNotificationData = queryClient.getQueryData(['notifications']);

            // Optimistically update to the new value
            queryClient.setQueryData(['notifications', params], (old: OldType | undefined) => {
                return {
                    ...old,
                    unreadNotificationsCount: old?.unreadNotificationsCount
                        ? old.unreadNotificationsCount - alerts_ids.length
                        : 0,
                    data: old?.data.map((notification) => {
                        if (alerts_ids.includes(notification.id)) {
                            return {
                                ...notification,
                                alert_users: {
                                    ...notification.alert_users,
                                    data: notification.alert_users.data.map((dataItem, index) =>
                                        index === 0 ? { ...dataItem, is_read: 1 } : dataItem,
                                    ),
                                },
                            };
                        } else {
                            return notification;
                        }
                    }),
                };
            });
            //Optimistically update unread notifications count on page 1 (to be correctly shown in the Notifications Controller)
            if (params.page !== 1) {
                queryClient.setQueryData(['notifications', { ...params, page: 1 }], (old: OldType | undefined) => {
                    return {
                        ...old,
                        unreadNotificationsCount: old?.unreadNotificationsCount
                            ? old.unreadNotificationsCount - alerts_ids.length
                            : 0,
                    };
                });
            }

            // Return a context object with the snapshotted value
            return { previousNotificationData };
        },
        // If the mutation fails,
        // use the context returned from onMutate to roll back
        onError: (err, _alert_ids, context) => {
            queryClient.setQueryData(['notifications', params], context?.previousNotificationData);
            console.error(err);
        },
        // Always refetch after error or success:
        onSettled: () => {
            queryClient.invalidateQueries({ queryKey: ['notifications', params] });
            if (params.page !== 1) {
                queryClient.invalidateQueries({ queryKey: ['notifications', { ...params, page: 1 }] });
            }
        },
    });

    const markAllMutation = useMutation({
        mutationFn: () => markAllAsRead(),
        onMutate: async () => {
            // Cancel any outgoing refetches
            // (so they don't overwrite our optimistic update)
            await queryClient.cancelQueries({ queryKey: ['notifications'] });

            const previousNotificationData = queryClient.getQueryData(['notifications']);

            queryClient
                .getQueryCache()
                .findAll({ queryKey: ['notifications'] })
                .forEach(({ queryKey }) => {
                    queryClient.setQueryData(queryKey, (old: OldType | undefined) => ({
                        ...old,
                        unreadNotificationsCount: 0,
                        data: old?.data.map((notification) => ({
                            ...notification,
                            alert_users: {
                                ...notification.alert_users,
                                data: notification.alert_users.data.map((dataItem, index) =>
                                    index === 0 ? { ...dataItem, is_read: 1 } : dataItem,
                                ),
                            },
                        })),
                    }));
                });

            return { previousNotificationData };
        },
        // If the mutation fails,
        // use the context returned from onMutate to roll back
        onError: (err, _variables, context) => {
            queryClient
                .getQueryCache()
                .findAll({ queryKey: ['notifications'] })
                .forEach(({ queryKey }) => {
                    queryClient.setQueryData(queryKey, context?.previousNotificationData);
                });
            console.error(err);
        },
        // Always refetch after error or success:
        onSettled: () => {
            queryClient.invalidateQueries({ queryKey: ['notifications'] });
        },
    });

    return {
        markAsRead: mutation,
        markAllAsRead: markAllMutation,
    };
};
