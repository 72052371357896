import { FunctionComponent, useEffect, useState } from 'react';
import styled from 'styled-components';
import useTranslations from '../../../hooks/useTranslation';
import useLanguageStore from '../../../store/language';
interface Props {
    className?: string;
    onChange?: Function;
    defaultChecked?: boolean;
    isDisabled?: boolean;
}

const ToggleSwitch: FunctionComponent<Props> = ({ className, onChange, defaultChecked, isDisabled }) => {
    const [isChecked, setChecked] = useState(defaultChecked);

    const { currentLang } = useLanguageStore();
    const t = useTranslations(currentLang);

    useEffect(() => {
        if (defaultChecked) {
            setChecked(defaultChecked);
        }
    }, [defaultChecked]);

    const change = (): void => {
        setChecked(!isChecked);
        onChange ? onChange(!isChecked) : null;
    };

    return (
        <StyledWrapper
            className={`switch-wrapper ${isChecked ? 'checked' : ''} ${className ?? ''}`.trim()}
            onClick={() => onChange}
            isDisabled={isDisabled}
        >
            <label className="switch cursor-pointer">
                <input
                    type="checkbox"
                    checked={isChecked}
                    onChange={() => {
                        change();
                    }}
                    disabled={isDisabled}
                />
                <span className="switch-button"></span>
                <span className="switch-label">{isChecked ? t('input.yes').text : t('input.no').text}</span>
            </label>
        </StyledWrapper>
    );
};
const StyledWrapper = styled.div<Props>`
    display: flex;
    flex-direction: column;

    .switch {
        position: relative;
        width: 58px;
        height: 22px;
        background-color: var(--blue-gray);
        border-radius: 11px;
        input {
            opacity: 0;
            width: 0;
            height: 0;
        }
    }

    .switch-button {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        background-color: var(--white);
        width: 16px;
        height: 16px;
        border-radius: 50%;
        transition: transform 300ms ease 0s;
        transform: translate3d(3px, 3px, 0px);
    }

    .switch-label {
        position: absolute;
        top: 0;
        color: var(--white);
        display: flex;
        align-items: center;
        height: 100%;
        font-size: 15px;
        right: 10px;
    }

    &.checked {
        .switch {
            background-color: var(--purple);
        }
        .switch-button {
            transform: translate3d(39px, 3px, 0px);
        }
        .switch-label {
            left: 10px;
        }
    }

    ${(props) =>
        props.isDisabled &&
        `
        cursor: not-allowed;

        .switch {
            cursor: not-allowed;
        }

        .switch-button {
            cursor: not-allowed;
            background-color: var(--red);
        }
    `}
`;

export default ToggleSwitch;
