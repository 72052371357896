import { FunctionComponent, useEffect, useState } from 'react';
import styled from 'styled-components';
import { breakpoints } from '../../../../../constants/breakpoints';
import moment from 'moment';
import { MQ_BREAKPOINTS } from '../../../../../constants/breakpoints';

interface MemorandumProps {
    created_at?: string;
    heading?: string;
    id?: string;
    object?: string;
    status?: string;
    template: string;
    updated_at?: string;
    user?: any;
    user_id?: string;
}

const PreviewMemoModal: FunctionComponent<{ memorandum: MemorandumProps }> = ({ memorandum }) => {
    const [renderKey, setRenderKey] = useState<number>(0);

    useEffect(() => {
        // Ensures the template refreshes when updated
        setRenderKey(renderKey + 1);
    }, [memorandum.updated_at]);

    const formattedCreatedAt = moment(memorandum.created_at).format('DD.MM.YYYY');
    const formattedUpdatedAt = moment(memorandum.updated_at).format('DD.MM.YYYY');

    return (
        <Container>
            <Header>
                <h1>{memorandum.heading}</h1>
                <Status>{`Status: ${memorandum.status}`}</Status>
                <DateRow>
                    <p>{`Created: ${formattedCreatedAt}`}</p>
                    <p>{`Updated: ${formattedUpdatedAt}`}</p>
                </DateRow>
            </Header>
            <TemplateContent
                dangerouslySetInnerHTML={{ __html: memorandum.template }}
                key={renderKey}
            ></TemplateContent>
            <Footer>
                <p>Generated by User ID: {memorandum.user_id}</p>
                <p>Memo ID: {memorandum.id}</p>
            </Footer>
        </Container>
    );
};

export default PreviewMemoModal;

const Container = styled.div`
    background-color: var(--white);
    padding: 20px;
    color: black;
    font-family: Arial, sans-serif;
    min-height: 90vh;
    max-height: 90vh;
    overflow-y: scroll;
    overflow-x: hidden;
    display: flex;
    flex-direction: column;
    align-items: stretch;

    ::-webkit-scrollbar {
        width: 10px; /* Adjust the width of the scrollbar */
    }

    /* Scrollbar Track - the area the thumb moves in */
    ::-webkit-scrollbar-track {
        background: #f1f1f1; /* Track color */
        border-radius: 10px; /* Rounded corners for the track */
    }
    /* Scrollbar Thumb - the draggable part */
    ::-webkit-scrollbar-thumb {
        background-color: var(--purple); /* Thumb color */
        border-radius: 10px; /* Rounded corners for the thumb */
        border: 2px solid #f1f1f1; /* Add border around the thumb */
    }

    .ql-align-center {
        display: flex;
        justify-content: center;
        font-size: 14px;
    }

    @media only screen and (max-width: ${MQ_BREAKPOINTS.tablet}) {
        padding: 15px;
    }

    @media only screen and (max-width: ${MQ_BREAKPOINTS.mobileL}) {
        padding: 10px;
    }

    @media only screen and (min-width: ${breakpoints.mobileL + 1}px) {
        min-width: 80vw;
        max-width: 90vw;
    }
    @media only screen and (min-width: ${breakpoints.tablet}px) {
        min-width: 70vw;
        max-width: 70vw;
    }
    @media only screen and (min-width: ${breakpoints.laptop}px) {
        min-width: 50vw;
        max-width: 50vw;
    }
    @media only screen and (min-width: ${breakpoints.laptopL}px) {
        min-width: 35vw;
        max-width: 35vw;
        min-height: 95vh;
        max-height: 95vh;
    }
    @media only screen and (min-width: ${breakpoints.desktop}px) {
        min-width: 35vw;
        max-width: 35vw;
    }
    @media only screen and (min-width: ${breakpoints.desktopL}px) {
        min-width: 35vw;
        max-width: 35vw;
    }
    @media only screen and (min-width: ${breakpoints.ultraWide}px) {
        min-width: 30vw;
        max-width: 30vw;
    }
`;

const Header = styled.div`
    text-align: center;
    border-bottom: 1px solid #ddd;
    padding-bottom: 15px;
    margin-bottom: 15px;
    display: none; // no display in preview

    h1 {
        font-size: 20px;
        font-weight: bold;
    }
`;

const Status = styled.div`
    font-size: 14px;
    color: #888;
`;

const DateRow = styled.div`
    display: flex;
    justify-content: space-between;
    font-size: 12px;
`;

const TemplateContent = styled.div`
    font-size: 14px;
    line-height: 1.5;

    p,
    h1,
    h2,
    h3,
    img {
        text-align: center;
    }

    img {
        max-width: 100%;
        height: auto;
    }

    hr {
        margin: 10px 0;
    }
`;

const Footer = styled.div`
    border-top: 1px solid #ddd;
    padding-top: 10px;
    margin-top: auto;
    text-align: center;
    font-size: 12px;
    color: #666;
    display: none; // no display in preview
`;
