import communication from '../communication';
import { isStatusSuccess } from '../communication/handlers/handleRequest';
import { useQuery } from '@tanstack/react-query';

export const fetchAllBanks = async () => {
    try {
        const response = await communication.getAllBanks();
        if (isStatusSuccess(response.status)) {
            return response.data.data;
        } else {
            throw new Error('Failed to fetch banks');
        }
    } catch (error) {
        console.error('Failed to fetch banks:', error);
        return [];
    }
};

const useAllBanksQuery = () => {
    return useQuery({
        queryKey: ['all-banks'],
        queryFn: fetchAllBanks,
        staleTime: 24 * 60 * 60 * 1000, // 24 hours
    });
};

export default useAllBanksQuery;
