import { create } from 'zustand';

interface Store {
    globalModalContent: any;
    globalModalVisible: boolean;
    setGlobalModal: (content: any, type?: string) => void;
    closeGlobalModal: () => void;
    globalModalType?: string;
}

const useStore = create<Store>((set) => ({
    globalModalVisible: false,
    globalModalContent: '',
    globalModalType: undefined,

    setGlobalModal: (content: any, type?: string) =>
        set({
            globalModalContent: content,
            globalModalVisible: true,
            globalModalType: type,
        }),
    closeGlobalModal: () =>
        set({
            globalModalContent: '',
            globalModalVisible: false,
            globalModalType: undefined,
        }),
}));

export default useStore;
