import { FunctionComponent, useEffect, useState } from 'react';
import { MobileRow, MobileTotal, Row, StyledInput } from '../../style';
import SelectSearch from '../../../../../../components/searchCreateNew';
import Input, { InputComponentType } from '../../../../../../components/input';
import { ObjectKeys } from '../../../../../../types/objectKeys';
import colors from '../../../../../../global/colors';
import { formatToSerbianNumber } from '../../../../../../functions/format';
import useServiceNumberValidation from '../../../../../../hooks/useServiceNumberValidation';
import { useLocation } from 'react-router-dom';

type SelectOption = {
    value?: string;
    label?: string;
};

interface Props {
    t: Function;
    index: number;
    service: ObjectKeys;
    services: ObjectKeys[];
    units: ObjectKeys[];
    setFormData: Function;
    handleTotal: Function;
    invoice: ObjectKeys;
    setUnits?: Function;
    handleServiceClick: Function;
}

const MobileService: FunctionComponent<Props> = ({
    t,
    index,
    service,
    services,
    units,
    setFormData,
    handleTotal,
    invoice,
    setUnits,
    handleServiceClick,
}) => {
    const { pathname } = useLocation();
    const isAdvance = pathname.includes('advance');

    const transformedService: ObjectKeys = {
        ...service,
        pivot: {
            ...service.pivot,
            price_per_unit: service?.pivot?.price_per_unit?.replace('.', ','),
            quantity: '1',
        },
    };

    const isQuantityValid = useServiceNumberValidation(transformedService.pivot.quantity);
    const isPriceValid = useServiceNumberValidation(transformedService.pivot.price_per_unit);

    const [totalServicePrice, setTotalServicePrice] = useState<number>(0);

    useEffect(() => {
        setFormData((prev: ObjectKeys) => {
            const copy = { ...prev };
            const quantity = 1;
            const price = transformedService.pivot.price_per_unit.replace(',', '.');
            copy.services[index].pivot.total_price = quantity * price;
            setTotalServicePrice(copy.services[index].pivot.total_price);
            return copy;
        });
    }, [transformedService.pivot.price_per_unit]);

    useEffect(() => {
        handleTotal(
            invoice.services?.reduce((accumulator: number, object: ObjectKeys) => {
                return accumulator + (isNaN(object.pivot.total_price) ? 0 : Number(object.pivot.total_price));
            }, 0),
        );
    }, [totalServicePrice]);

    return (
        <>
            <Row className="service-list" key={service.type}>
                <MobileRow>
                    <div>
                        <span>{t('pages.editInvoice.previewModal.item').text}</span>
                        <p onClick={() => handleServiceClick()} className="type">
                            {transformedService.name
                                ? transformedService.name
                                : services.find((s: ObjectKeys) => s.id === transformedService.id)?.name}
                        </p>
                    </div>
                </MobileRow>

                <MobileRow>
                    <div style={{ width: '100%' }}>
                        <span>{t('pages.editInvoice.previewModal.unit').text}</span>
                        <SelectSearch
                            handleSelectedValue={(v: ObjectKeys) => {
                                setFormData((prev: ObjectKeys) => {
                                    const copy = { ...prev };
                                    copy.services[index].pivot.measurement_unit = v.value;
                                    return copy;
                                });
                            }}
                            handleCreateOption={(c: SelectOption) => {
                                let newUnitAdded = false;
                                setFormData((prev: ObjectKeys) => {
                                    const copy = { ...prev };
                                    copy.services[index].pivot.measurement_unit = c.value;
                                    copy.services[index].pivot.quantity = '1';
                                    return copy;
                                });
                                // Check if the unit already exists, if not, add it
                                if (!units.some((unit) => unit.value === c.value)) {
                                    units.push({ label: c.label, value: c.value });
                                    newUnitAdded = true;
                                }
                                // If a new unit was added, update the state to re-render the component
                                if (newUnitAdded && setUnits) {
                                    setUnits([...units]);
                                }
                            }}
                            optionList={units.map((unit) => ({
                                label: unit.label,
                                value: unit.value,
                            }))}
                            key={units.length}
                            defaultValue={units.find((u) => u.value === transformedService.pivot.measurement_unit)}
                            color={colors.black}
                            noValuePlaceholderColor="red"
                            placeholder={t('pages.invoices.add_measurement_unit').text}
                            searchPlaceholder={t('input.search_and_add').text}
                        />
                    </div>
                </MobileRow>

                <MobileRow>
                    <div>
                        <span>{t('pages.editInvoice.previewModal.amount').text}</span>
                        <MobileRow>
                            <StyledInput className="price">
                                <Input
                                    type={InputComponentType.Amount}
                                    value={
                                        transformedService.pivot.quantity !== ''
                                            ? transformedService.pivot.quantity
                                            : ''
                                    }
                                    readOnly={true}
                                    hideBlurOnChange
                                    handleBlurAction={() => {
                                        setFormData((prev: ObjectKeys) => {
                                            const copy = { ...prev };
                                            while (
                                                Array.from(copy.services[index].pivot.quantity)[0] === '0' &&
                                                Array.from(copy.services[index].pivot.quantity)[1] !== ','
                                            ) {
                                                copy.services[index].pivot.quantity =
                                                    copy.services[index].pivot.quantity.slice(1);
                                            }
                                            return copy;
                                        });
                                    }}
                                    placeholder={t('pages.editInvoice.enterQuantity').text}
                                    validation={isQuantityValid || isAdvance ? '' : 'error'}
                                />
                            </StyledInput>
                            <span>x</span>
                        </MobileRow>
                    </div>
                    <div>
                        <span>{t('pages.editInvoice.previewModal.unitPrice').text}</span>
                        <MobileRow>
                            <StyledInput className="price">
                                <Input
                                    type={InputComponentType.Amount}
                                    value={
                                        transformedService.pivot.price_per_unit !== ''
                                            ? transformedService.pivot.price_per_unit
                                            : ''
                                    }
                                    onChange={(v: any) => {
                                        setFormData((prev: ObjectKeys) => {
                                            const copy = { ...prev };
                                            copy.services[index].pivot.quantity = transformedService.pivot.quantity;
                                            copy.services[index].pivot.price_per_unit = v;
                                            const quantity = 1;
                                            const price = v.replace(',', '.');
                                            copy.services[index].pivot.total_price = quantity * price;
                                            setTotalServicePrice(copy.services[index].pivot.total_price);
                                            return copy;
                                        });
                                        handleTotal(
                                            invoice.services?.reduce((accumulator: number, object: ObjectKeys) => {
                                                return (
                                                    accumulator +
                                                    (isNaN(object.pivot.total_price)
                                                        ? 0
                                                        : Number(object.pivot.total_price))
                                                );
                                            }, 0),
                                        );
                                    }}
                                    placeholder={t('pages.editInvoice.enterUnitPrice').text}
                                    validation={isPriceValid ? '' : 'error'}
                                />
                            </StyledInput>
                            <span>=</span>
                        </MobileRow>
                    </div>
                </MobileRow>

                <MobileTotal>
                    <span>{t('pages.editInvoice.previewModal.total').text}</span>
                    <StyledInput className="total">
                        <Input
                            type={InputComponentType.Text}
                            value={totalServicePrice ? formatToSerbianNumber(totalServicePrice).toString() : ''}
                        />
                        <span
                            style={{ cursor: 'pointer' }}
                            onClick={() =>
                                setFormData((prev: ObjectKeys) => {
                                    const copy = { ...prev };
                                    copy.services.splice(index, 1);
                                    return copy;
                                })
                            }
                        >
                            x
                        </span>
                    </StyledInput>
                </MobileTotal>
            </Row>
        </>
    );
};

export default MobileService;
