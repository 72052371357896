import { create } from 'zustand';
import { ObjectKeys } from '../types/objectKeys';

interface ServicesState {
    servicesData: ObjectKeys;
}

const useServicesStore = create<ServicesState>(() => ({
    servicesData: {},
}));

export default useServicesStore;
