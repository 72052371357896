import fetch from '../fetch';
import routes from '../routes';
import { ObjectKeys } from '../../types/objectKeys';
import getCurrentLanguage from '../../functions/getCurrentLanguage';

export default class Packages {
    public getAllPackages(): ObjectKeys {
        const params = {
            orderBy: 'name',
            sortedBy: 'asc',
        };
        return fetch({
            method: 'GET',
            headers: { 'Content-Type': 'application/json; charset=UTF-8', 'Accept-Language': getCurrentLanguage() },
            url: routes.admin.packages.getAllPackages,
            params,
        });
    }
    public createPackage(data: ObjectKeys): ObjectKeys {
        return fetch({
            method: 'POST',
            headers: { 'Content-Type': 'application/json; charset=UTF-8', 'Accept-Language': getCurrentLanguage() },
            url: routes.admin.packages.createPackage,
            data,
        });
    }
    public updatePackage(id: string, options: ObjectKeys): ObjectKeys {
        return fetch({
            method: 'PUT',
            url: routes.admin.packages.updatePackage(id),
            headers: { 'Content-Type': 'application/json; charset=UTF-8', 'Accept-Language': getCurrentLanguage() },
            data: options,
        });
    }
    public deletePackage(id: string): ObjectKeys {
        return fetch({
            method: 'DELETE',
            url: routes.admin.packages.deletePackage(id),
            headers: { 'Content-Type': 'application/json; charset=UTF-8', 'Accept-Language': getCurrentLanguage() },
        });
    }
    public activatePackage(id: string, activate: boolean): ObjectKeys {
        return fetch({
            method: 'PUT',
            url: routes.admin.packages.updatePackage(id),
            headers: { 'Content-Type': 'application/json; charset=UTF-8', 'Accept-Language': getCurrentLanguage() },
            data: { active: activate },
        });
    }
}
