import { AxiosError } from 'axios';

const download: any = async (url: string, data: any) => {
    try {
        const form = document.createElement('form');
        form.method = 'POST';
        form.type = 'aplication/pdf';
        form.action = url;
        form.target = '_top';
        for (const name in data) {
            // eslint-disable-next-line no-prototype-builtins
            if (data.hasOwnProperty(name)) {
                const input = document.createElement('input');
                input.type = 'hidden';
                input.name = name;
                input.value = data[name];
                form.appendChild(input);
            }
        }
        form.style.visibility = 'hidden';
        document.body.appendChild(form);
        form.submit();
    } catch (error) {
        throw error as AxiosError;
    }
};

export const downloadFile = (blobData: Blob, fileName: string): void => {
    const url = window.URL.createObjectURL(blobData);
    const a = document.createElement('a');
    a.href = url;
    a.download = fileName;
    document.body.appendChild(a);
    a.click();
    a.remove();
    window.URL.revokeObjectURL(url);
};

export default download;
