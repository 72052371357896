import { Fragment, FunctionComponent, useEffect, useState } from 'react';
import styled from 'styled-components';
import { ObjectKeys } from '../../../../types/objectKeys';
import Pagination from '../../../../components/pagination';
import communication from '../../../../communication';
import Button, { ButtonVariant } from '../../../../components/button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSave } from '@fortawesome/free-solid-svg-icons';
import FiltersStatistics from './filters';
import Loader from '../../../../components/loader';
import NoItems from '../../../../components/noItems';
import exportToExcel from '../../../../functions/downloadXlsx';
import moment from 'moment';

interface Props {
    t: Function;
}

const Users: FunctionComponent<Props> = ({ t }) => {
    const [loading, setLoading] = useState(false);
    const [response, setResponse] = useState<ObjectKeys>();
    const [testUsers, setTestUsers] = useState<ObjectKeys>();
    const [allUsers, setAllUsers] = useState<ObjectKeys>();
    const [usersNo, setUsersNo] = useState(0);
    const [page, setPage] = useState(1);
    const [years, setYears] = useState<string[]>([]);
    const [banks, setBanks] = useState<ObjectKeys[]>([]);
    const currentYear = moment().format('YYYY');

    const [params, setParams] = useState({
        limit: '10',
        page,
    });
    const [paramsFilter, setParamsFilter] = useState({
        page: 1,
        limit: '10',
        start_date: '',
        end_date: '',
        bank_id: '',
    });

    useEffect(() => {
        async function getData(): Promise<void> {
            setLoading(true);
            const bankRes = await communication.getBanks();
            setBanks(bankRes.data.data);

            const res = await communication.getUsersByBank(
                paramsFilter.bank_id === '' ? bankRes.data.data[0]?.id : paramsFilter.bank_id,
                {
                    start_date:
                        paramsFilter.start_date !== '' ? paramsFilter.start_date : `${currentYear}-01-01 00:00:00`,
                    end_date: paramsFilter.end_date !== '' ? paramsFilter.end_date : `${currentYear}-01-31 23:59:59`,
                    limit: '10',
                    page,
                    bank_id: paramsFilter.bank_id === '' ? bankRes.data.data[0]?.id : paramsFilter.bank_id,
                },
            );
            const allUsersRes = await communication.getUsersByBank(
                paramsFilter.bank_id === '' ? bankRes.data.data[0]?.id : paramsFilter.bank_id,
                {
                    start_date:
                        paramsFilter.start_date !== '' ? paramsFilter.start_date : `${currentYear}-01-01 00:00:00`,
                    end_date: paramsFilter.end_date !== '' ? paramsFilter.end_date : `${currentYear}-01-31 23:59:59`,
                    page,
                    bank_id: paramsFilter.bank_id === '' ? bankRes.data.data[0]?.id : paramsFilter.bank_id,
                },
            );
            setResponse(res.data);
            setTestUsers(res.data.data);
            setAllUsers(allUsersRes.data.data);

            const resTable = await communication.getRegistrationStatistics({});

            const num = Object.entries(res.data.meta)[0][1] as { total: number };
            setUsersNo(num.total);
            if (resTable.data.data) setYears(Object.keys(resTable.data.data));
            setLoading(false);
        }

        getData();
    }, [params, page, paramsFilter]);

    const handlePageClick = (e: any): void => {
        setPage(e.selected + 1);
        setParams({ ...params, page: e.selected + 1 });
    };

    return (
        <>
            {loading && <Loader />}

            {banks && (
                <>
                    <Container>
                        {banks && (
                            <FiltersStatistics
                                banks={banks}
                                years={years}
                                setParams={setParamsFilter}
                                params={paramsFilter}
                            />
                        )}
                        {banks && (
                            <Table>
                                <tbody>
                                    <Row>
                                        <Heading>{t('pages.admin.statistics.usersBank.bank').text}</Heading>
                                        <Heading>{t('pages.admin.statistics.usersBank.usersNo').text}</Heading>
                                    </Row>
                                    <Row>
                                        <Data>
                                            {paramsFilter.bank_id === ''
                                                ? banks[0]?.name
                                                : banks.find((x) => x.id === paramsFilter.bank_id)?.name}
                                        </Data>
                                        <Data>{usersNo}</Data>
                                    </Row>
                                </tbody>
                            </Table>
                        )}
                        {testUsers && testUsers.length > 0 ? (
                            <>
                                <Table>
                                    <tbody>
                                        <Row>
                                            <Heading>{t('pages.admin.statistics.users.name').text}</Heading>
                                            <Heading>{t('pages.admin.statistics.users.last').text}</Heading>
                                            <Heading>{t('pages.admin.statistics.users.agencyIdNumber').text}</Heading>
                                            <Heading>
                                                {t('pages.admin.statistics.users.bankAccountNumber').text}
                                            </Heading>
                                        </Row>

                                        {testUsers?.map((user: any, i: number) => (
                                            <Row key={i}>
                                                <Data>{user.first_name}</Data>
                                                <Data>{user.last_name}</Data>
                                                <Data>{user.identification_number}</Data>
                                                <Data>{user.account_number}</Data>
                                            </Row>
                                        ))}
                                    </tbody>
                                </Table>
                                <Pagination
                                    pageCount={response?.meta.pagination?.total_pages}
                                    onPageChange={(e: object) => handlePageClick(e)}
                                    nextLabel={`${
                                        response?.meta.pagination?.current_page ===
                                        response?.meta.pagination?.total_pages
                                            ? ''
                                            : '>'
                                    }`}
                                    previousLabel={`${response?.meta.pagination?.current_page === 1 ? '' : '<'}`}
                                    breakLabel="..."
                                    initialPage={response?.meta.pagination?.current_page - 1}
                                />
                            </>
                        ) : (
                            <NoItems text={t('pages.admin.statistics.noUsers').text} />
                        )}
                    </Container>
                    <ButtonContainer>
                        <Button
                            variant={ButtonVariant.solid}
                            color={'var(--purple)'}
                            icon
                            onClick={async () => {
                                exportToExcel('download', 'bank-users', 'bank-users.xlsx');
                            }}
                        >
                            <FontAwesomeIcon icon={faSave}></FontAwesomeIcon>
                            {t('pages.admin.statistics.save').text}
                        </Button>
                    </ButtonContainer>

                    <Table style={{ display: 'none' }} id="download">
                        <tbody>
                            <Row>
                                <Heading>{t('pages.admin.statistics.usersBank.bank').text}</Heading>
                                <Heading>{t('pages.admin.statistics.usersBank.usersNo').text}</Heading>
                            </Row>
                            <Row>
                                <Data>
                                    {paramsFilter.bank_id === ''
                                        ? banks[0]?.name
                                        : banks.find((x) => x.id === paramsFilter.bank_id)?.name}
                                </Data>
                                <Data>{usersNo}</Data>
                            </Row>
                        </tbody>
                        <tbody>
                            <Row>
                                <Heading>{t('pages.admin.statistics.users.name').text}</Heading>
                                <Heading>{t('pages.admin.statistics.users.last').text}</Heading>
                                <Heading>{t('pages.admin.statistics.users.agencyIdNumber').text}</Heading>
                                <Heading>{t('pages.admin.statistics.users.bankAccountNumber').text}</Heading>
                            </Row>

                            {allUsers?.map((user: any, i: number) => (
                                <Row key={i}>
                                    <Data>{user.first_name}</Data>
                                    <Data>{user.last_name}</Data>
                                    <Data>{user.identification_number}</Data>
                                    <Data>{user.account_number}</Data>
                                </Row>
                            ))}
                        </tbody>
                    </Table>
                </>
            )}
        </>
    );
};

const Container = styled.div`
    position: relative;
`;
export const Table = styled.table`
    :nth-of-type(1) {
        margin-top: 20px;
    }
    width: 100%;
    background-color: var(--white);
    border-collapse: collapse;
`;
export const Row = styled.tr`
    font-weight: 700;
`;
export const Heading = styled.th`
    background-color: var(--purple);
    color: var(--white);
    border: 1px solid rgba(0, 0, 0, 0.1);

    &.data {
        border: 1px solid rgba(0, 0, 0, 0.1);
        padding: 10px;
        font-size: 15px;
        text-align: center;
        vertical-align: middle;
    }
`;
export const Data = styled.td`
    border: 1px solid rgba(0, 0, 0, 0.1);

    padding: 10px;
    font-size: 15px;
    text-align: center;
    vertical-align: middle;
    font-weight: 400;
`;
export const ButtonContainer = styled.div`
    button {
        margin-top: 20px;
        float: right;
        font-size: 12px;
        padding: 8px 20px;
        vertical-align: top;
        border-radius: 100px;
    }
`;
export default Users;
