import React from 'react';

const FaFileInvoiceSharpSolid: React.FC<React.SVGProps<SVGSVGElement>> = (props) => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512" fill="none" {...props}>
        <path
            d="M64 0H0V64V448V512H64H320H384V448V160H256H224V128V0H64ZM256 0V128H384L256 0ZM64 64H160C160 64 160 71.2 160 80C160 88.8 160 96 160 96H64C64 96 64 88.8 64 80C64 71.2 64 64 64 64ZM64 128H160C160 128 160 135.2 160 144C160 152.8 160 160 160 160H64C64 160 64 152.8 64 144C64 135.2 64 128 64 128ZM96 224H288H320V256V320V352H288H96H64V320V256V224H96ZM96 256V320H288V256H96ZM224 416H320C320 416 320 423.2 320 432C320 440.8 320 448 320 448H224C224 448 224 440.8 224 432C224 423.2 224 416 224 416Z"
            fill="inherit"
        />
    </svg>
);

export default FaFileInvoiceSharpSolid;
