import { FunctionComponent } from 'react';
import styled from 'styled-components';
import Tooltip from '../tooltip';
import colors from '../../../../../global/colors';
import { faEdit, faTrashCan } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Icon } from '../memorandumCard';
import useTranslations from '../../../../../hooks/useTranslation';
import useLanguageStore from '../../../../../store/language';
import { ObjectKeys } from '../../../../../types/objectKeys';
import { useNavigate } from 'react-router-dom';

interface TemplateCardProps {
    title: string;
    description: string;
    onClick: () => void;
    isCustomCard: boolean;
    memo: ObjectKeys;
    handleDelete: (id: string) => void;
}

const TemplateCard: FunctionComponent<TemplateCardProps> = ({
    title,
    description,
    onClick,
    isCustomCard,
    memo,
    handleDelete,
}) => {
    const { currentLang } = useLanguageStore();
    const t = useTranslations(currentLang);
    const navigate = useNavigate();
    return (
        <Card onClick={onClick}>
            <div className="header">
                <p className="title">{t('pages.memos.memoNameHeader').text}</p>
                <span className="titleText">{title}</span>
            </div>
            <div className="description">
                <p>{description}</p>
            </div>
            {isCustomCard && (
                <div className="iconFooter">
                    <Tooltip text={t('pages.invoiceCard.dropdownItems.change').text}>
                        <Icon>
                            <FontAwesomeIcon
                                onClick={(e) => {
                                    e.stopPropagation();
                                    navigate(`edit/${memo.id}/pattern`);
                                }}
                                className={'icon'}
                                icon={faEdit}
                                color={colors.gray}
                                style={{ color: colors.blue }}
                            />
                        </Icon>
                    </Tooltip>
                    <Tooltip text={t('pages.invoiceCard.dropdownItems.delete').text}>
                        <Icon>
                            <FontAwesomeIcon
                                onClick={(e) => {
                                    e.stopPropagation();
                                    handleDelete(memo?.id);
                                }}
                                className={'icon'}
                                icon={faTrashCan}
                                color={colors.gray}
                                style={{ color: colors.danger }}
                            />
                        </Icon>
                    </Tooltip>
                </div>
            )}
        </Card>
    );
};

const Card = styled.div`
    background: var(--white);
    color: var(--gray);
    width: 300px;
    padding: 20px 17px;
    border: 1px solid var(--purple);
    display: flex;
    gap: 10px;
    flex-direction: column;
    cursor: pointer;
    &:hover {
        box-shadow: 0px 0px 8px 4px rgba(0, 0, 0, 0.1);
    }
    .title {
        font-size: 14px;
        color: var(--gray);
    }
    .header {
        display: flex;
        flex-direction: column;
        gap: 5px;
        font-size: 14px;
        font-weight: bold;
        color: var(--black);
        line-height: 20px;
    }

    .titleText {
        min-height: 40px;
    }
    .description {
        font-size: 14px;
        color: var(--gray);
        margin-bottom: 10px;
    }
    .iconFooter {
        display: flex;
        gap: 20px;
        margin-top: auto;
    }
`;

export default TemplateCard;
