import React from 'react';

const FaFileLinesSharpRegular: React.FC<React.SVGProps<SVGSVGElement>> = (props) => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512" fill="none" {...props}>
        <path
            d="M64 464H48V448V64.0001V48.0001H64H224V128V160H256H336V448V464H320H64ZM64 6.10352e-05H0V64.0001V448V512H64H320H384V448V154.5V130.5L365.3 109.2L275.5 19.5L258.5 0L229.5 6.10352e-05H64ZM96 256C96 256 96 266.7 96 280C96 293.3 96 304 96 304H288C288 304 288 293.3 288 280C288 266.7 288 256 288 256H96ZM96 352C96 352 96 362.7 96 376C96 389.3 96 400 96 400H288C288 400 288 389.3 288 376C288 362.7 288 352 288 352H96Z"
            fill="inherit"
        />
    </svg>
);

export default FaFileLinesSharpRegular;
