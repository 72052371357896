import { useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

export const usePasswordProtect = (protectedPaths: string[]): void => {
    const navigate = useNavigate();
    const location = useLocation();

    const adminInnerPassword = process.env.REACT_APP_NOTF_PASSWORD;

    useEffect(() => {
        const pathIsProtected = protectedPaths.some((path) => location.pathname.startsWith(path));

        if (pathIsProtected) {
            // Check if the user is already authenticated in this session
            const isAuthenticated = sessionStorage.getItem('isNotfAuthenticated') === 'true';

            if (!isAuthenticated) {
                const password = prompt('Unesi šifru za pristup stranici Notifikacije: ');

                // User clicked the cancel button on the prompt
                if (password === null) {
                    // Navigate back or to a default route
                    navigate('/admin/users'); // Change '/' to your preferred route
                    return; // Ensure the rest of the code doesn't execute after cancel
                }

                if (password === adminInnerPassword) {
                    // Correct password, mark the session as authenticated
                    sessionStorage.setItem('isNotfAuthenticated', 'true');
                } else {
                    // Incorrect password, alert and navigate back
                    alert('Pogrešna šifra! Ne pokušavajte više puta, inače ćete biti blokirani.');
                    navigate(-1);
                }
            }
        }
    }, [navigate, location.pathname, protectedPaths]);
};
