export default function useAdvanceValueValidation(value: string, invoiceValue: number, t: Function): string {
    if (value === '') return t('pages.advanceInvoices.closeModal.validation.mandatory').text;

    if (isNaN(Number(value.replaceAll('.', '').replace(',', '.'))))
        return t('pages.advanceInvoices.closeModal.validation.onlyNumbers').text;
    if (value.slice(-1) === ',') return t('pages.advanceInvoices.closeModal.validation.onlyNumbers').text;

    if (Number(invoiceValue) - Number(value.replace('.', '').replace(',', '.')) < 0)
        return t('pages.advanceInvoices.closeModal.validation.higherValue').text;
    else return '';
}
