import fetch from '../fetch';
import routes from '../routes';
import { ObjectKeys } from '../../types/objectKeys';
import getCurrentLanguage from '../../functions/getCurrentLanguage';

export default class ServicesAdmin {
    public getAllAdminServices(params: ObjectKeys): ObjectKeys {
        return fetch({
            method: 'GET',
            headers: { 'Content-Type': 'application/json; charset=UTF-8', 'Accept-Language': getCurrentLanguage() },
            url: routes.admin.services.getAllAdminServices,
            params: params,
        });
    }
    public createAdminService(data: ObjectKeys): ObjectKeys {
        return fetch({
            method: 'POST',
            headers: { 'Content-Type': 'application/json; charset=UTF-8', 'Accept-Language': getCurrentLanguage() },
            url: routes.admin.services.createAdminService,
            data,
        });
    }
    public updateAdminService(id: string, options: ObjectKeys): ObjectKeys {
        return fetch({
            method: 'PUT',
            url: routes.admin.services.updateAdminService(id),
            headers: { 'Content-Type': 'application/json; charset=UTF-8', 'Accept-Language': getCurrentLanguage() },
            data: options,
        });
    }
    public deleteAdminService(id: string): ObjectKeys {
        return fetch({
            method: 'DELETE',
            url: routes.admin.services.deleteAdminService(id),
            headers: { 'Content-Type': 'application/json; charset=UTF-8', 'Accept-Language': getCurrentLanguage() },
        });
    }
}
