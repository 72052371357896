import Checkbox from '../../components/checkbox';
import useTranslations from '../../hooks/useTranslation';
import useLanguageStore from '../../store/language';
import { useEffect, useState } from 'react';
import styled from 'styled-components';
import { NotificationItem } from '../../components/notifications/NotificationItem';
import { NotificationItemSkeleton } from '../../components/notifications/NotificationSkeleton';
import Input, { InputComponentType } from '../../components/input';
import Pagination from '../..//components/pagination';
import { useNotificationsQuery } from '../../react-query/useNotificationsQuery';
import { useMarkAsRead } from './useMarkAsRead';
import { ObjectKeys } from '../../types/objectKeys';
import { GET_NotificationsRequest } from '@/communication/notifications/types';
import { MQ_BREAKPOINTS } from '../../constants/breakpoints';

const Notifications = () => {
    const { currentLang } = useLanguageStore();
    const t = useTranslations(currentLang);

    const [searchDateFrom, setSearchDateFrom] = useState<Date | null>(null);
    const [searchDateTo, setSearchDateTo] = useState<Date | null>(null);
    const [isChecked, setIsChecked] = useState<'select-all' | null>(null);
    const [showOnlyUnread, setShowOnlyUnread] = useState<boolean>(false);

    const [params, setParams] = useState<GET_NotificationsRequest>({
        page: 1,
        limit: 10,
        search: '',
        searchFields: '',
        searchJoin: 'and',
        orderBy: 'sent_at',
        sortedBy: 'desc',
    });

    const { notifications, isFetching, isLoading, remove } = useNotificationsQuery(params);

    const [hasAnyNotReadChecked, setHasAnyNotReadChecked] = useState<boolean>(false);
    const { markAsRead } = useMarkAsRead(params);

    const handleMarkAsRead = async (): Promise<void> => {
        const checkedNotifications = notifications?.data?.filter((notification) => notification.checked);
        const checkedNotificationIds = checkedNotifications?.map((notification) => notification.id) || [];
        markAsRead.mutate(checkedNotificationIds);
    };

    const handleNotificationCheck = (id: string): void => {
        const checkedNotification = notifications?.data.find((notf) => notf.id === id);
        if (checkedNotification) {
            checkedNotification.checked = !checkedNotification.checked;
        }
        setHasAnyNotReadChecked(
            !!notifications?.data?.find((item) => item.checked && !item.alert_users.data[0].is_read),
        );
    };

    const handleCheck = (check: 'select-all' | null): void => {
        setIsChecked(check);
        if (check === 'select-all') {
            notifications?.data.forEach((notif) => {
                notif.checked = true;
            });
        } else {
            notifications?.data.forEach((notif) => {
                notif.checked = false;
            });
        }
        setHasAnyNotReadChecked(
            !!notifications?.data?.find((item) => item.checked && !item.alert_users.data[0].is_read),
        );
    };

    const formatDateFromForQuery = (dateString: Date | null): string => {
        if (!dateString) return '';
        const date = new Date(dateString);
        const year = date.getFullYear(); // Use local year
        const month = String(date.getMonth() + 1).padStart(2, '0'); // Use local month
        const day = String(date.getDate()).padStart(2, '0'); // Use local day
        return `${year}-${month}-${day}`;
    };

    const formatDateToForQuery = (dateString: Date | null): string => {
        if (!dateString) return '';
        const date = new Date(dateString);
        date.setDate(date.getDate() + 1); // Add one day
        const year = date.getFullYear(); // Use local year
        const month = String(date.getMonth() + 1).padStart(2, '0'); // Use local month
        const day = String(date.getDate()).padStart(2, '0'); // Use local day
        return `${year}-${month}-${day}`;
    };

    const updateSearchParams = (fromDate: Date | null, toDate: Date | null): void => {
        if (fromDate && toDate) {
            setParams((prevParams) => ({
                ...prevParams,
                page: 1,
                search: `created_at:${formatDateFromForQuery(fromDate)},${formatDateToForQuery(toDate)}`,
                searchFields: 'created_at:between',
            }));
        } else if (!fromDate && !toDate) {
            setParams((prevParams) => ({
                ...prevParams,
                page: 1,
                search: '',
                searchFields: '',
            }));
        }
    };

    useEffect(() => {
        notifications?.data.forEach((notif) => (notif.checked = false));
        setHasAnyNotReadChecked(
            !!notifications?.data?.find((item) => item.checked && !item.alert_users.data[0].is_read),
        );
        if (notifications && notifications.data.length === 0 && notifications.meta?.pagination?.current_page > 1) {
            setParams({ ...params, page: notifications.meta?.pagination?.current_page - 1 });
        }
    }, [notifications]);

    return (
        <PageWrapper>
            <Header>
                <h1>{t('pages.notifications.title').text}</h1>
            </Header>
            <Container>
                <Filters>
                    <p className="filters-title">{t('pages.notifications.notification_list').text}</p>
                    <div className="date-pickers">
                        <div className="date-picker">
                            <label htmlFor="date-from">
                                <p className="mobile-hidden">{t('pages.notifications.for_period').text}</p>
                                <p>{t('pages.notifications.from').text}</p>
                            </label>
                            <Input
                                type={InputComponentType.Date}
                                id="date-from"
                                date={searchDateFrom}
                                isClearable
                                onChange={(date: Date) => {
                                    setSearchDateFrom(date);
                                    updateSearchParams(date, searchDateTo);
                                }}
                                maxDate={new Date()}
                            />
                        </div>
                        <div className="date-picker">
                            <label htmlFor="date-to">{t('pages.notifications.to').text}</label>
                            <Input
                                type={InputComponentType.Date}
                                id="date-to"
                                isClearable
                                date={searchDateTo}
                                onChange={(date: Date) => {
                                    setSearchDateTo(date);
                                    updateSearchParams(searchDateFrom, date);
                                }}
                                maxDate={new Date()}
                            />
                        </div>
                        <div className="checkbox-wrapper">
                            <Checkbox
                                label={t('pages.notifications.unread').text}
                                defaultChecked={showOnlyUnread}
                                onChange={(value: boolean) => {
                                    setShowOnlyUnread(value);
                                    setParams((prevParams) => ({
                                        ...prevParams,
                                        page: 1,
                                        search: value ? 'alert_users.is_read:0' : '',
                                        searchFields: value ? 'alert_users.is_read:=' : '',
                                    }));
                                    remove();
                                }}
                                isDisabled={isFetching}
                                className="show-only-unread-checkbox"
                            />
                        </div>
                    </div>
                </Filters>
                {Number(
                    notifications?.data
                        .map((notification) => !!notification.alert_users.data[0].is_read)
                        .filter((item) => !item).length,
                ) > 0 && (
                    <CheckBoxes>
                        <div className="checkbox-wrapper">
                            <Checkbox
                                label={t('pages.notifications.choose_all').text}
                                defaultChecked={isChecked === 'select-all'}
                                onChange={(value: boolean) => {
                                    handleCheck(value ? 'select-all' : null);
                                }}
                                className="notf-checkbox"
                            />
                            {hasAnyNotReadChecked && (
                                <button
                                    className="mark-as-read-button"
                                    onClick={(e) => {
                                        e.stopPropagation(); // Prevent checkbox click from triggering
                                        handleMarkAsRead();
                                    }}
                                >
                                    {t('pages.notifications.mark_as_read').text}
                                </button>
                            )}
                        </div>
                    </CheckBoxes>
                )}
                {isLoading ? (
                    Array.from({ length: 10 }).map((_, index) => <NotificationItemSkeleton key={index} />)
                ) : (
                    <>
                        {notifications && notifications.data.length > 0 ? (
                            <>
                                <NotificationsWrapper>
                                    {notifications?.data?.map((notification) => (
                                        <NotificationItem
                                            onCheck={handleNotificationCheck}
                                            {...notification}
                                            key={notification.id}
                                            onClick={markAsRead.mutate}
                                        />
                                    ))}
                                </NotificationsWrapper>
                                <Pagination
                                    pageCount={notifications.meta?.pagination?.total_pages}
                                    onPageChange={(e: ObjectKeys) => {
                                        notifications?.data.forEach((notif) => (notif.checked = false));
                                        handleCheck(null);
                                        setParams({ ...params, page: e.selected + 1 });
                                    }}
                                    nextLabel={`${
                                        notifications.meta?.pagination?.current_page ===
                                        notifications.meta?.pagination?.total_pages
                                            ? ''
                                            : '>'
                                    }`}
                                    previousLabel={`${notifications.meta?.pagination?.current_page === 1 ? '' : '<'}`}
                                    breakLabel="..."
                                    initialPage={notifications.meta?.pagination?.current_page - 1}
                                />
                            </>
                        ) : (
                            <NoUnreadNotifications>{t('pages.notifications.noUnreadNotf').text}</NoUnreadNotifications>
                        )}
                    </>
                )}
            </Container>
        </PageWrapper>
    );
};

export default Notifications;

const PageWrapper = styled.div`
    p {
        font-size: 14px;
    }
`;

const Header = styled.div`
    padding-bottom: 20px;
    margin-bottom: 20px;
    border-bottom: 1px solid var(--border-color);
`;

const Container = styled.div`
    display: flex;
    flex-direction: column;
    padding: 30px;
    padding-top: 50px;
    background: white;
`;

const Filters = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: var(--purple);
    padding: 10px 20px;
    flex-wrap: wrap;
    gap: 2rem;
    @media screen and (min-width: ${MQ_BREAKPOINTS.tablet}) and (max-width: ${MQ_BREAKPOINTS.laptop}) {
        height: auto;
    }
    @media screen and (min-width: ${MQ_BREAKPOINTS.laptopM}) and (max-width: ${MQ_BREAKPOINTS.laptopL}) {
        height: 65px;
    }
    @media screen and (min-width: ${MQ_BREAKPOINTS.desktop}) and (max-width: ${MQ_BREAKPOINTS.desktopL}) {
        height: 65px;
    }
    @media screen and (min-width: ${MQ_BREAKPOINTS.ultraWide}) {
        height: 65px;
    }
    .filters-title {
        color: var(--white);
        font-size: 14px;
        text-transform: uppercase;
    }
    .date-pickers {
        font-size: 14px;
        display: flex;
        flex-wrap: wrap;
        row-gap: 1rem;
        column-gap: 3rem;
        text-transform: uppercase;
        align-items: center;
        .date-picker {
            display: flex;
            align-items: center;
            gap: 1.5rem;
            .datepicker-input-wrapper {
                background: var(--white);
                border: 1px solid var(--border-color);
                svg {
                    margin-right: 5px;
                }
            }
            label {
                color: var(--white);
                display: flex;
                gap: 5px;
            }
            p {
                font-size: 14px;
            }
        }
    }
    .mobile-hidden {
        @media (max-width: ${MQ_BREAKPOINTS.tablet}) {
            display: none;
        }
    }
    .checkbox-wrapper {
        height: 16px;
        .show-only-unread-checkbox {
            margin-bottom: 0;
            flex-direction: row-reverse;
            gap: 1.5rem;
            p {
                color: var(--white);
            }
        }
    }
`;

const CheckBoxes = styled.div`
    width: 100%;
    background-color: var(--white);
    padding: 1.5rem;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    .checkbox-wrapper {
        display: flex;
        justify-content: space-between;
    }
    .mark-as-read-button {
        display: flex;
        font-size: 12px;
        align-items: center;
        gap: 1rem;
        background-color: var(--purple);
        border: none;
        color: var(--white);
        cursor: pointer;
        padding: 9px 16px;
        &:disabled {
            opacity: 0.8;
            cursor: default;
        }
        height: 35px;
        text-transform: uppercase;
    }
`;

const NotificationsWrapper = styled.div`
    border-top: 1px solid var(--purple);
`;

const NoUnreadNotifications = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    padding: 20px;
    font-size: 1.2rem;
    color: #9b7fe9;
    background-color: #f9f9f9;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    text-align: center;
    opacity: 0.8;
`;
