import React from 'react';
import Select from 'react-select';
import styled from 'styled-components';

export type SelectOption = {
    value: string | number;
    label: string;
};

interface SearchCustomComponentProps {
    allUsersOptions: SelectOption[];
    handleSelectedValue?: (data: { value: string; label: string }) => void;
    placeholder?: string;
    className?: string;
    value?: SelectOption | null; // Add this prop
}

const SearchCustomComponent: React.FC<SearchCustomComponentProps> = ({
    allUsersOptions,
    handleSelectedValue,
    placeholder,
    className,
    value,
}) => {
    const selectedUserId = value;
    const handleSelectChange = (selectedOption: SelectOption | null): void => {
        if (selectedOption) {
            handleSelectedValue &&
                handleSelectedValue({
                    value: selectedOption.value.toString(),
                    label: selectedOption.label,
                });
        }
    };
    const customStyles = {
        control: (provided: any) => ({
            ...provided,
            backgroundColor: 'white',
            borderRadius: '5px',
            cursor: 'pointer',
            boxShadow: '0px',
            height: '35px',
            fontSize: '16px',
            border: '1px solid var(--border-color)',
        }),
        option: (provided: any, state: any) => ({
            ...provided,
            height: '35px',
            backgroundColor: state.isSelected ? '#9b7fe9' : provided.backgroundColor,
            color: 'var(--gray)',
            cursor: state.isDisabled ? 'not-allowed' : 'pointer',
            borderWidth: '2px',
            borderRadius: '0',
            borderBottom: '1px solid var(--border-color)',
            ':hover': {
                backgroundColor: '#9b7fe9',
                color: 'var(--white)',
            },
        }),
        singleValue: (provided: any) => ({
            ...provided,
            color: 'var(--gray)',
        }),
        menuList: (provided: any) => ({
            ...provided,
            ':focus': {
                backgroundColor: '#9b7fe9',
                color: 'var(--white)',
            },
        }),
    };

    return (
        <div>
            <StyledSelectContainer>
                <Select
                    className={className}
                    options={allUsersOptions}
                    onChange={handleSelectChange}
                    value={selectedUserId}
                    isSearchable={true}
                    placeholder={placeholder}
                    styles={customStyles} // Apply the custom styles
                    noOptionsMessage={() => 'Nema rezultata pretrage'}
                />
            </StyledSelectContainer>
        </div>
    );
};
const StyledSelectContainer = styled.div`
    .user-selector {
        width: 450px;
        margin-left: 10px;
    }
    .user-select-transaction {
        width: 100%;
    }
    text-align-last: left;
    display: flex;
    font-family: inherit;
    border-radius: 5px;
    border-style: hidden;
    font-size: 16px;
    cursor: pointer;
    flex-direction: column;
`;

export default SearchCustomComponent;
