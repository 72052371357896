type ReplacementType = {
    [key: string]: string;
};
export const replaceWordsWithLinks = (text: string, replacements: ReplacementType): (string | JSX.Element)[] => {
    const parts = text.split(new RegExp(`(${Object.keys(replacements).join('|')})`, 'g'));
    return parts.map((part) => {
        if (replacements[part]) {
            return (
                <a key={part} href={replacements[part]}>
                    {part}
                </a>
            );
        }
        return part;
    });
};
