import { create } from 'zustand';

interface ParamsState {
    page: number;
    a_page: number;
    p_page: number;
}

const useParamsStore = create<ParamsState>(() => ({
    page: 1,
    a_page: 1,
    p_page: 1,
}));

export default useParamsStore;
