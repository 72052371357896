import { FunctionComponent, useState } from 'react';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDownload, faEnvelope, faPenToSquare, faXmark } from '@fortawesome/free-solid-svg-icons';
import { faTrashCan, faFloppyDisk } from '@fortawesome/free-regular-svg-icons';
import Input, { InputComponentType } from '../../../../components/input';
import communication from '../../../../communication';
import Loader from '../../../../components/loader';
import Sidebar from '../../../../components/sideModal';
import SendDocumentModal from '../sendModal';
import { SendDocumentFormData } from '../../../../types/communication/document';
import Modal from '../../../../components/modal';
import ChangeModal from '../../../../components/changeModal';
import Success from '../../../../components/successModal';
import { MQ_BREAKPOINTS } from '../../../../constants/breakpoints';

interface Props {
    t: Function;
    name: string;
    id: string;
    save: Function;
    token: string;
    onSuccess: Function;
    onDelete: Function;
    onLoad: Function;
}

const Document: FunctionComponent<Props> = ({ t, name, id, save, token, onSuccess, onLoad, onDelete }) => {
    const [edit, setEdit] = useState(false);
    const [fileName, setFileName] = useState(name);
    const [loader, setLoader] = useState(false);
    const [sendModal, setSendModal] = useState(false);
    const [deleteModal, setDeleteModal] = useState(false);
    const [successModal, setSuccessModal] = useState(false);

    const [sendData, setSendData] = useState<SendDocumentFormData>({
        recipient: '',
        subject: '',
        message: '',
    });

    const handleSave = async (): Promise<void> => {
        setLoader(true);
        await communication.updateDocument(fileName, id);
        setEdit(false);
        setLoader(false);
        save();
    };

    const handleDownload = async (): Promise<void> => {
        await communication.downloadDocument(id, token);
    };

    const handleDelete = async (): Promise<void> => {
        setDeleteModal(false);
        const res = await communication.deleteDocument(id);
        if (res.status === 202) {
            setSuccessModal(true);
            onDelete();
        }
    };

    return (
        <>
            {sendModal && (
                <Sidebar close={() => setSendModal(false)}>
                    <SendDocumentModal
                        close={() => setSendModal(false)}
                        setFormData={setSendData}
                        formData={sendData}
                        t={t}
                        id={id}
                        success={() => onSuccess()}
                        loading={() => onLoad()}
                        modalVisible={sendModal}
                    />
                </Sidebar>
            )}
            {deleteModal && (
                <Modal modalVisible={true} closeModal={() => setDeleteModal(false)}>
                    <ChangeModal
                        close={() => setDeleteModal(false)}
                        message={t('pages.agency.documents.doDeleteDocument').text}
                        saveChanges={() => handleDelete()}
                    />
                </Modal>
            )}
            {successModal && (
                <Modal modalVisible={true} closeModal={() => setSuccessModal(false)}>
                    <Success
                        close={() => setSuccessModal(false)}
                        message={t('pages.agency.documents.successfullyDeleted').text}
                    />
                </Modal>
            )}
            {loader && <Loader />}
            <Container className={edit ? 'edit' : ''}>
                {!edit ? (
                    <div className="text">{name}</div>
                ) : (
                    <Input
                        type={InputComponentType.Text}
                        value={fileName}
                        onChange={(value: string) => {
                            setFileName(value);
                        }}
                    />
                )}
                <div className={!edit ? 'margin-top' : ''}>
                    <div className="options">
                        {!edit ? (
                            <Icon>
                                <FontAwesomeIcon
                                    onClick={() => setEdit(true)}
                                    icon={faPenToSquare}
                                    style={{ color: 'var(--blue)' }}
                                />
                                <div className="label">{t('pages.agency.documents.labels.edit').text}</div>
                            </Icon>
                        ) : (
                            <>
                                <Icon>
                                    <FontAwesomeIcon
                                        className="save"
                                        icon={faFloppyDisk}
                                        style={{ color: 'var(--blue)' }}
                                        onClick={handleSave}
                                    />
                                    <div className="label">{t('pages.agency.documents.labels.save').text}</div>
                                </Icon>
                                <Icon>
                                    <FontAwesomeIcon
                                        onClick={() => setEdit(false)}
                                        className="close"
                                        icon={faXmark}
                                        style={{ color: 'var(--purple)' }}
                                    />
                                    <div className="label">{t('pages.agency.documents.labels.cancel').text}</div>
                                </Icon>
                            </>
                        )}
                        <Icon>
                            <FontAwesomeIcon
                                onClick={() => setSendModal(true)}
                                className="send"
                                icon={faEnvelope}
                                style={{ color: 'var(--blue)', marginBottom: '-2px' }}
                            />
                            <div className="label">{t('pages.agency.documents.labels.send').text}</div>
                        </Icon>
                        <Icon>
                            <FontAwesomeIcon
                                onClick={() => handleDownload()}
                                icon={faDownload}
                                style={{ color: 'var(--purple)' }}
                            />
                            <div className="label">{t('pages.agency.documents.labels.download').text}</div>
                        </Icon>
                        <Icon>
                            <FontAwesomeIcon
                                onClick={() => setDeleteModal(true)}
                                icon={faTrashCan}
                                style={{ color: 'var(--danger)' }}
                            />
                            <div className="label">{t('pages.agency.documents.labels.delete').text}</div>
                        </Icon>
                    </div>
                </div>
            </Container>
        </>
    );
};

export default Document;

const Container = styled.div`
    display: grid;
    grid-template-columns: 60% 40%;

    width: 100%;
    background: var(--light-gray);
    border-radius: 10px;
    padding: 20px;
    border-top: 1px solid var(--border-color);
    margin-bottom: 10px;
    cursor: pointer;

    &.edit {
        grid-template-columns: 52% 48%;
        @media only screen and (max-width: 1300px) {
            grid-template-columns: 50% 50%;
        }
        @media only screen and (max-width: 1200px) {
            grid-template-columns: 40% 60%;
        }
        @media only screen and (max-width: ${MQ_BREAKPOINTS.laptop}) {
            grid-template-columns: 52% 48%;
        }
    }

    .margin-top {
        margin-top: -5px;
    }

    .options {
        font-size: 20px;
        float: right;
        display: flex;
        align-items: center;
        margin-bottom: -10px;
    }
    .text {
        display: table-cell;
        vertical-align: middle;
        color: var(--gray);
    }

    .input-container {
        width: -webkit-fill-available;
        margin-bottom: 0px;
        .validation-message {
            display: none;
        }
    }

    input {
        border: 1px solid var(--border-color);
        background: var(--white);
        margin-bottom: 0px;
        &:focus {
            border-bottom: 1px solid var(--border-color);
        }
    }

    @media only screen and (max-width: ${MQ_BREAKPOINTS.mobileL}) {
        font-size: 12px;
        input {
            font-size: 12px;
        }
    }
`;

const Icon = styled.div`
    position: relative;
    .close {
        font-size: 26px;
        margin-top: 2px;
    }
    .send,
    .save {
        font-size: 23px;
    }

    .label {
        opacity: 0;
        position: absolute;
        left: 50%;
        top: -20px;
        transform: translate(-50%, -50%);
        padding: 10px;
        border-radius: 4px;
        z-index: 9;
        font-size: 15px;
    }
    svg {
        margin-left: 15px;
    }
    &:hover {
        .label {
            opacity: 1;
            transition: opacity 0.5s;
            background: var(--gray);
            color: var(--white);
            z-index: 1;
        }
    }
    @media only screen and (max-width: ${MQ_BREAKPOINTS.mobileL}) {
        svg {
            margin-left: 10px;
        }
    }
`;
