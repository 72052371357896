import fetch from './fetch';
import routes from './routes';
import { ObjectKeys } from '../types/objectKeys';
import getCurrentLanguage from '../functions/getCurrentLanguage';

export default class GuestEndpoints {
    public getAllGuestServices(params: ObjectKeys): ObjectKeys {
        return fetch(
            {
                method: 'GET',
                headers: { 'Content-Type': 'application/json; charset=UTF-8', 'Accept-Language': getCurrentLanguage() },
                url: routes.guest.getServices,
                params: params,
            },
            false,
        );
    }
    public createGuestCart(cart: ObjectKeys): ObjectKeys {
        return fetch(
            {
                method: 'POST',
                headers: { 'Content-Type': 'application/json; charset=UTF-8', 'Accept-Language': getCurrentLanguage() },
                url: routes.guest.createCart,
                data: cart,
            },
            false,
        );
    }
    public getGuestSignature(amount: string, cart_id: string): ObjectKeys {
        return fetch(
            {
                method: 'POST',
                headers: { 'Content-Type': 'application/json; charset=UTF-8', 'Accept-Language': getCurrentLanguage() },
                url: routes.guest.getSignature,
                data: { amount: amount, cart_id: cart_id },
            },
            false,
        );
    }
    public getGuestCartById(id: string): ObjectKeys {
        return fetch(
            {
                method: 'GET',
                headers: { 'Content-Type': 'application/json; charset=UTF-8', 'Accept-Language': getCurrentLanguage() },
                url: routes.guest.getCartById(id),
            },
            false,
        );
    }
    public sendGuestPaymentResponse(data: ObjectKeys): ObjectKeys {
        return fetch(
            {
                method: 'POST',
                headers: { 'Content-Type': 'application/json; charset=UTF-8', 'Accept-Language': getCurrentLanguage() },
                url: routes.guest.paymentResponse,
                data: data,
            },
            false,
        );
    }
}
