export const breakpoints = {
    mobileXS: 280, // Extra small mobile screens
    mobileS: 320, // Small mobile screens
    mobileM: 375, // Medium mobile screens
    mobileL: 435, // Large mobile screens
    tablet: 768, // Tablets
    laptop: 1024, // Small laptops
    laptopM: 1440, // Medium laptops
    laptopL: 1600, // Large laptops
    desktop: 1920, // Standard desktop
    desktopL: 2560, // Large desktop monitors
    ultraWide: 3440, // Ultra-wide monitors
};

export const MQ_BREAKPOINTS = {
    mobileXS: `${breakpoints.mobileXS}px`,
    mobileS: `${breakpoints.mobileS}px`,
    mobileM: `${breakpoints.mobileM}px`,
    mobileL: `${breakpoints.mobileL}px`,
    tablet: `${breakpoints.tablet}px`,
    laptop: `${breakpoints.laptop}px`,
    laptopM: `${breakpoints.laptopM}px`,
    laptopL: `${breakpoints.laptopL}px`,
    desktop: `${breakpoints.desktop}px`,
    desktopL: `${breakpoints.desktopL}px`,
    ultraWide: `${breakpoints.ultraWide}px`,
};
