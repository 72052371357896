import fetch from './fetch';
import routes from './routes';
import { ObjectKeys } from '../types/objectKeys';
import getCurrentLanguage from '../functions/getCurrentLanguage';
import downloadInvoice from './downloadInvoice';

export default class Proforma {
    public getProforma(params: ObjectKeys): ObjectKeys {
        return fetch({
            method: 'GET',
            headers: { 'Content-Type': 'application/json; charset=UTF-8', 'Accept-Language': getCurrentLanguage() },
            url: routes.proforma.myProforma,
            params: params,
        });
    }
    public getProformaById(id: string): ObjectKeys {
        return fetch({
            method: 'GET',
            headers: { 'Content-Type': 'application/json; charset=UTF-8', 'Accept-Language': getCurrentLanguage() },
            url: routes.proforma.getById(id),
        });
    }
    public deleteProforma(id: string): ObjectKeys {
        return fetch({
            method: 'DELETE',
            url: routes.proforma.byId(id),
            headers: { 'Content-Type': 'application/json; charset=UTF-8', 'Accept-Language': getCurrentLanguage() },
        });
    }
    public copyProforma(id: string): ObjectKeys {
        return fetch({
            method: 'POST',
            url: routes.proforma.copy(id),
            headers: { 'Content-Type': 'application/json; charset=UTF-8', 'Accept-Language': getCurrentLanguage() },
        });
    }
    public newProforma(options: ObjectKeys, type: string): ObjectKeys {
        return fetch({
            method: 'POST',
            url: routes.proforma.newProforma(type),
            headers: { 'Content-Type': 'application/json; charset=UTF-8', 'Accept-Language': getCurrentLanguage() },
            data: options,
        });
    }
    public editProforma(id: string, options: ObjectKeys): ObjectKeys {
        return fetch({
            method: 'PUT',
            url: routes.proforma.byId(id),
            headers: { 'Content-Type': 'application/json; charset=UTF-8', 'Accept-Language': getCurrentLanguage() },
            data: options,
        });
    }
    public sendProforma(id: string, options: ObjectKeys): ObjectKeys {
        return fetch({
            method: 'POST',
            url: routes.proforma.sendProforma(id),
            data: options,
            headers: {
                'Content-Type': 'multipart/form-data',
                'Accept-Language': getCurrentLanguage(),
            },
        });
    }
    public domesticProforma(options: ObjectKeys): ObjectKeys {
        return fetch({
            method: 'POST',
            url: routes.proforma.domesticProforma,
            headers: { 'Content-Type': 'application/json; charset=UTF-8', 'Accept-Language': getCurrentLanguage() },
            data: options,
        });
    }
    public foreignProforma(options: ObjectKeys): ObjectKeys {
        return fetch({
            method: 'POST',
            url: routes.proforma.foreignProforma,
            headers: { 'Content-Type': 'application/json; charset=UTF-8', 'Accept-Language': getCurrentLanguage() },
            data: options,
        });
    }

    public downloadProforma(id: string, token: string, invoiceNo: string, foreign: boolean, qr_code?: string): any {
        downloadInvoice(id, token, invoiceNo, foreign, true, qr_code);
    }
}
