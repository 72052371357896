import { FunctionComponent, useEffect, useState } from 'react';
import FiltersStatistics from './filters';
import communication from '../../../../communication';
import { ButtonContainer, Data, Heading, Row, Table } from '../newUsers';
import { ObjectKeys } from '../../../../types/objectKeys';
import Button, { ButtonVariant } from '../../../../components/button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSave } from '@fortawesome/free-solid-svg-icons';
import exportToExcel from '../../../../functions/downloadXlsx';

interface Props {
    t: Function;
}

const Payment: FunctionComponent<Props> = ({ t }) => {
    const currentYear = new Date().getFullYear();
    const startYear = 2015;
    const [year, setYear] = useState(`${currentYear}`);
    const [selectedPackage, setSelectedPackage] = useState(0);
    const [packages, setPackages] = useState([]);
    const [testPackages, setTestPackages] = useState<Array<ObjectKeys>>();

    useEffect(() => {
        async function getData(): Promise<void> {
            const res = await communication.getPayments({ year });
            setPackages(res.data);
            for (const [index, dataArray] of Object.entries(res.data)) {
                const ind = Number(index);
                const arr = [];
                if (ind === selectedPackage) {
                    for (const [indexPackage, dataPackage] of Object.entries(dataArray as Object)) {
                        dataPackage.keyIndex = indexPackage;
                        arr.push(dataPackage);
                    }
                    setTestPackages(arr as Array<ObjectKeys>);
                }
            }
        }

        getData();
    }, [year, selectedPackage]);
    const packageArray: Array<number> = [];
    Object.keys(packages).map((c) => packageArray.push(Number(c)));

    const years = Array.from({ length: currentYear - startYear + 1 }, (_, index) => (currentYear - index).toString());
    return (
        <div>
            {packageArray && (
                <>
                    <FiltersStatistics
                        selectedYear={(y: string) => setYear(y)}
                        selectedPackage={(y: number) => setSelectedPackage(y)}
                        years={years}
                        packages={packageArray}
                    />

                    <Table>
                        <tbody>
                            <Row>
                                <Heading className="data" rowSpan={2}>
                                    {t('pages.admin.payment.month').text}
                                </Heading>
                                <Heading className="data" colSpan={2}>
                                    {t('pages.admin.payment.packet').text}
                                </Heading>
                            </Row>
                            <Row>
                                <Heading className="data">{t('pages.admin.payment.total').text}</Heading>
                                <Heading className="data">{t('pages.admin.payment.transactionNo').text}</Heading>
                            </Row>
                            {testPackages &&
                                testPackages?.map((v: ObjectKeys, key: any) => (
                                    <Row key={key}>
                                        <Data>
                                            <b>{v.keyIndex}</b>
                                        </Data>
                                        <Data>{v?.total_amount}</Data>
                                        <Data>{v?.number}</Data>
                                    </Row>
                                ))}
                        </tbody>
                    </Table>

                    {/* table for xlsx */}
                    <Table style={{ display: 'none' }} id="download">
                        <tbody>
                            <Row>
                                <Heading>
                                    {t('pages.admin.payment.packet').text} {packageArray[selectedPackage]}
                                </Heading>
                            </Row>
                        </tbody>
                        <tbody>
                            <Row>
                                <Heading className="data">{t('pages.admin.payment.month').text}</Heading>
                                <Heading className="data">{t('pages.admin.payment.total').text}</Heading>
                                <Heading className="data">{t('pages.admin.payment.transactionNo').text}</Heading>
                            </Row>
                            {testPackages &&
                                testPackages?.map((v: ObjectKeys, key: any) => (
                                    <Row key={key}>
                                        <Data>
                                            <b>{v.keyIndex}</b>
                                        </Data>
                                        <Data>{v?.total_amount}</Data>
                                        <Data>{v?.number}</Data>
                                    </Row>
                                ))}
                        </tbody>
                    </Table>
                    <ButtonContainer>
                        <Button
                            variant={ButtonVariant.solid}
                            color={'var(--purple)'}
                            icon
                            onClick={() => {
                                exportToExcel('download', 'payment', 'payment.xlsx');
                            }}
                        >
                            <FontAwesomeIcon icon={faSave}></FontAwesomeIcon>
                            {t('pages.admin.statistics.saveAll').text}
                        </Button>
                    </ButtonContainer>
                </>
            )}
        </div>
    );
};
export default Payment;
