import { FunctionComponent, useState, useRef } from 'react';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashCan, faEllipsisVertical, faPenToSquare } from '@fortawesome/free-solid-svg-icons';
import colors from '../../../../global/colors';
import useTranslations from '../../../../hooks/useTranslation';
import useOutsideClick from '../../../../hooks/useOutsideClick';
import useLanguageStore from '../../../../store/language';
import { ObjectKeys } from '../../../../types/objectKeys';
import Dropdown from '../../../../components/dropdown';
import { getUnitByValue } from '../../../pausal/common/measurementUnits';
import { formatTwoDecimals } from '../../../../functions/format';
import { MQ_BREAKPOINTS } from '../../../../constants/breakpoints';
import useResponsive from '../../../../hooks/responsive/useResponsive';

interface Props {
    className?: string;
    handleChange?: Function;
    handleDelete?: Function;
    handleActivate?: Function;
    handleClick: Function;
    cardData: ObjectKeys;
    cardType: string;
}
const ArticleCard: FunctionComponent<Props> = ({
    className,
    handleChange,
    handleDelete,
    handleActivate,
    handleClick,
    cardData,
    cardType,
}) => {
    const { currentLang } = useLanguageStore();
    const t = useTranslations(currentLang);
    const ref = useRef<HTMLDivElement>(null);
    const [dropdownVisible, setDropdownVisible] = useState(false);
    const { handleClickOutside } = useOutsideClick(ref, setDropdownVisible);
    const { isTabletAndDown } = useResponsive();

    return (
        <StyledClientCard key={cardData.id} className={`card ${className ?? ''}`.trim()}>
            <CardContent className="card-content cursor-pointer">
                <InfoWrapper className="info-wrapper">
                    <div>
                        <div className="label">{t('pages.articleCard.infoWrapper.name').text}</div>
                        <div className="name">{cardData?.name || '/'}</div>
                    </div>
                    <div>
                        <div className="label">{t('pages.articleCard.infoWrapper.unitOfMeasure').text}</div>
                        <div className="name">
                            {getUnitByValue(cardData?.default_measurement_unit, false, false) || '/'}
                        </div>
                    </div>
                    <div>
                        <div className="label">{t('pages.articleCard.infoWrapper.price').text}</div>
                        <div className="name">
                            {cardData?.default_price ? formatTwoDecimals(cardData.default_price.toString()) : '/'}
                        </div>
                    </div>
                    <div>
                        <div className="label">{t('pages.articleCard.infoWrapper.currency').text}</div>
                        <div className="name">{cardData?.default_currency?.toUpperCase() || '/'}</div>
                    </div>
                    <div>
                        <div className="label">{t('pages.listOfArticles.modal.form.type').text}</div>
                        <div className="name">
                            {cardData?.type === 'service'
                                ? t('pages.editInvoice.addServiceModal.service').text
                                : t('pages.editInvoice.addServiceModal.product').text}
                        </div>
                    </div>
                </InfoWrapper>
                <ActionWrapper>
                    <div className="label"></div>
                    <div className="action">
                        {isTabletAndDown ? (
                            <Action>
                                <FontAwesomeIcon
                                    icon={faEllipsisVertical}
                                    style={{ color: colors.gray }}
                                    className="cursor-pointer dots"
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        handleClick(cardData?.id);
                                        setDropdownVisible(!dropdownVisible);
                                    }}
                                />
                                {dropdownVisible && (
                                    <DropdownContainer
                                        className={`${cardType === 'archived' ? 'for-archived' : ''}`.trim()}
                                        ref={ref}
                                        onClick={handleClickOutside}
                                    >
                                        <Dropdown
                                            arrowRight="4px"
                                            itemList={
                                                cardType !== 'archived'
                                                    ? [
                                                          {
                                                              icon: faPenToSquare,
                                                              iconColor: colors.blue,
                                                              label: t('pages.articleCard.dropdownItems.change').text,
                                                              handler: handleChange,
                                                          },
                                                          {
                                                              icon: faTrashCan,
                                                              iconColor: colors.danger,
                                                              label: t('pages.articleCard.dropdownItems.archive').text,
                                                              handler: handleDelete,
                                                          },
                                                      ]
                                                    : [
                                                          {
                                                              label: t('pages.articleCard.dropdownItems.activate').text,
                                                              handler: handleActivate,
                                                          },
                                                      ]
                                            }
                                        />
                                    </DropdownContainer>
                                )}
                            </Action>
                        ) : (
                            <ActionWithoutDropdown>
                                {handleChange !== undefined ? (
                                    <Icon>
                                        <FontAwesomeIcon
                                            icon={faPenToSquare}
                                            style={{ color: colors.blue }}
                                            onClick={(e) => handleChange && handleChange(e)}
                                        />
                                        <div className="label">{t('pages.articleCard.dropdownItems.change').text}</div>
                                    </Icon>
                                ) : (
                                    <></>
                                )}
                                {cardType === 'archived' ? (
                                    <p className="activate" onClick={(e) => handleActivate && handleActivate(e)}>
                                        {t('pages.articleCard.dropdownItems.activate').text}
                                    </p>
                                ) : (
                                    <Icon>
                                        <FontAwesomeIcon
                                            icon={faTrashCan}
                                            style={{ color: colors.danger }}
                                            onClick={(e) => handleDelete && handleDelete(e)}
                                        />
                                        <div className="label">{t('pages.articleCard.dropdownItems.archive').text}</div>
                                    </Icon>
                                )}
                            </ActionWithoutDropdown>
                        )}
                    </div>
                </ActionWrapper>
            </CardContent>
        </StyledClientCard>
    );
};
const StyledClientCard = styled.div`
    margin-bottom: 10px;
    padding: 16px 20px;
    width: 100%;

    background: var(--white);
    border: 1px solid var(--purple);
    transition: all 0.5s ease-in-out;
    &:hover {
        box-shadow: 0 0 5px 5px #eee;
        .info-wrapper {
            opacity: 0.7;
        }
    }
    @media screen and (max-width: ${MQ_BREAKPOINTS.tablet}) {
        align-items: start;
    }
`;
const CardContent = styled.div`
    display: grid;
    align-items: center;
    grid-template-columns: 8fr 1fr;

    div {
        color: var(--black);
        &.number {
            text-transform: capitalize;
        }
        &.action {
            display: flex;
            justify-content: flex-end;
        }
    }
`;
const InfoWrapper = styled.div`
    grid-template-columns: 2fr 1fr 1fr 1fr 1fr;
    display: grid;
    align-items: center;
    gap: 5px;
    @media screen and (max-width: ${MQ_BREAKPOINTS.tablet}) {
        grid-template-columns: 2fr 2fr;
    }
    .label {
        text-transform: capitalize;
        font-size: 12px;
        color: var(--gray);
        line-height: 25px;
    }
`;
const ActionWrapper = styled.div`
    div {
        &.action {
            justify-content: center;
            margin-top: 20px;
        }
    }
`;
const Action = styled.div`
    position: relative;
    .dots {
        font-size: 25px;
        padding: 10px 17px 7px;
        transition: all 0.5s ease-in-out;
        &:hover {
            background: var(--light-gray);
        }
    }
`;
const DropdownContainer = styled.div`
    position: absolute;
    z-index: 1;
    right: 0;
    top: 55px;

    p {
        font-size: 16px;
        line-height: 20px;
    }
    .dropdown-container {
        height: 120px;
        overflow-y: auto;
    }
    &.for-archived {
        .dropdown-container {
            height: 80px;
        }
    }
`;
const ActionWithoutDropdown = styled.div`
    display: flex;
    gap: 10px;
    .activate {
        text-transform: uppercase;
        color: var(--purple);
        font-size: 13px;
        line-height: 1.3;
    }
`;
const Icon = styled.div`
    position: relative;

    .label {
        opacity: 0;
        position: absolute;
        left: 50%;
        top: -20px;
        transform: translate(-50%, -50%);
        padding: 10px;
        border-radius: 4px;
        z-index: 9;
        font-size: 15px;
    }

    &:hover {
        .label {
            opacity: 1;
            transition: opacity 0.5s;
            background: var(--gray);
            color: var(--white);
            z-index: 1;
        }
    }
`;
export default ArticleCard;
