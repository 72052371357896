import { FunctionComponent, useEffect, useState } from 'react';
import styled from 'styled-components';
import Button, { ButtonVariant } from '../../../../components/button';
import colors from '../../../../global/colors';
import useLanguageStore from '../../../../store/language';
import useTranslations from '../../../../hooks/useTranslation';
import communication from '../../../../communication';
import Loader from '../../../../components/loader';
import Success from '../../../../components/successModal';
import Modal from '../../../../components/modal';
import { ObjectKeys } from '../../../../types/objectKeys';
import useUserStore from '../../../../store/user';
import { useDetectClickOutside } from 'react-detect-click-outside';
import ToggleSwitch from '../../../../components/input/toggleSwitch';
import useAgencyStore from '../../../../store/agency';
import { replaceWordsWithLinks } from '../../../../functions/replaceWordsWithLinks';
import { MQ_BREAKPOINTS } from '../../../../constants/breakpoints';
import useResponsive from '../../../../hooks/responsive/useResponsive';

interface Template {
    display_name: string;
    id: string;
    object: string;
    slug: string;
}
const InvoiceTemplate: FunctionComponent = () => {
    const { currentLang } = useLanguageStore();
    const t = useTranslations(currentLang);

    const { isMobile } = useResponsive();

    const { agency } = useAgencyStore();
    const userId = agency.user.data.id;
    const [has_logo_on_invoice, setHas_logo_on_invoice] = useState(true);
    const isTrial = agency.user.data.trial;
    const userPackage = agency.user.data.payment_package.data.name;

    const [successfullySaved, setSuccessfullySaved] = useState(false);
    const [loaderVisible, setLoaderVisible] = useState(false);
    const [selectedTemplateIndex, setSelectedTemplateIndex] = useState<number | null>(null);
    const [enlargedIndex, setEnlargedIndex] = useState<number | null>(null);
    const { userInfo } = useUserStore();
    const [selectedId, setSelectedId] = useState<string>(userInfo?.preferred_invoice_template_id);
    const [templates, setTemplates] = useState<Template[]>([]);
    const [renderKey, setRenderKey] = useState<number>(0);

    const chooseTemplate = (template: Template, index: number): void => {
        setSelectedId(template.id);
        setSelectedTemplateIndex(index);
    };

    const toggleEnlarged = (template: Template, index: number): void => {
        setEnlargedIndex(enlargedIndex === index ? null : index);
        chooseTemplate(template, index);
    };

    useEffect(() => {
        setLoaderVisible(true);
        communication.getPreferedTemplates().then((res: ObjectKeys) => {
            setTemplates(res.data.data);
            setLoaderVisible(false);
        });
    }, []);

    useEffect(() => {
        if (userInfo && userInfo.preferred_invoice_template_id) {
            const index = templates.findIndex((template) => template.id === userInfo.preferred_invoice_template_id);
            setSelectedTemplateIndex(index);
        }
    }, [templates]);

    const minimizeEnlarged = (): void => {
        setEnlargedIndex(null);
    };

    const ref = useDetectClickOutside({ onTriggered: minimizeEnlarged });

    const handleSave = async (): Promise<void> => {
        setLoaderVisible(true);
        const formData: ObjectKeys = {
            preferred_invoice_template_id: selectedId,
        };
        if (selectedId) {
            const res = await communication.updateUser(formData, userInfo?.id);
            if (res.status === 200) {
                useUserStore.setState({ userInfo: res?.data?.data });
                setLoaderVisible(false);
                setSuccessfullySaved(true);
            }
        }
    };

    const updateHasLogo = async (value: boolean): Promise<void> => {
        setLoaderVisible(true);
        const formData: ObjectKeys = {
            has_logo: value,
        };
        communication.updateUser(formData, userId).then((res: ObjectKeys) => {
            if (res.status === 200) {
                communication
                    .getAgency()
                    .then((response: ObjectKeys) => {
                        useAgencyStore.setState({
                            agency: response?.data?.data,
                        });
                        setHas_logo_on_invoice(response?.data?.data?.user?.data?.has_logo);
                    })
                    .then(() => {
                        setLoaderVisible(false);
                    });
            }
        });
    };

    useEffect(() => {
        setHas_logo_on_invoice(agency.user.data.has_logo);
        setRenderKey(renderKey + 1);
    }, [agency.user.data.has_logo]);

    return (
        <>
            {successfullySaved && (
                <Modal modalVisible={true} closeModal={() => setSuccessfullySaved(false)}>
                    <Success
                        close={() => setSuccessfullySaved(false)}
                        message={t('pages.agency.profile.saveSuccess').text}
                    />
                </Modal>
            )}
            {loaderVisible && <Loader />}
            <ToggleRow>
                <p>
                    {replaceWordsWithLinks(t('pages.agency.invoiceTemplate.remove_logo').text, {
                        Medium: '/settings/subscription/1',
                        Premium: '/settings/subscription/1',
                    })}
                </p>
                <ToggleSwitch
                    defaultChecked={has_logo_on_invoice}
                    isDisabled={isTrial || userPackage === 'Basic'}
                    onChange={(e: any) => {
                        updateHasLogo(e);
                    }}
                    key={renderKey}
                />
            </ToggleRow>
            <Description>{t('pages.agency.invoiceTemplate.default_template').text}</Description>
            <Content className={isMobile ? 'mobile-width' : 'desktop'}>
                <div className="template_container" ref={ref}>
                    {templates.map((template, index) => (
                        <div key={index} className="add-logo-preview">
                            <input
                                type="radio"
                                value={index}
                                checked={selectedTemplateIndex === index}
                                onChange={() => {
                                    chooseTemplate(template, index);
                                    toggleEnlarged(template, index);
                                }}
                            />
                            <p className="template_name">{template.display_name}</p>
                            <div className="image-container" style={{ zIndex: enlargedIndex === index ? 9999 : 1 }}>
                                <div className="image-overlay" onClick={() => toggleEnlarged(template, index)}>
                                    <p className="overlay-text">
                                        {t('pages.agency.invoiceTemplate.click_enlarge').text}
                                    </p>
                                </div>
                                <img
                                    className={`cover-img ${enlargedIndex === index ? 'enlarged' : ''}`}
                                    src={require(`../../../../assets/${template.display_name}.png`)}
                                    alt={`Template ${index + 1}`}
                                    onClick={() => {
                                        toggleEnlarged(template, index);
                                    }}
                                />
                            </div>
                        </div>
                    ))}
                </div>
                <div className={'button-container'}>
                    <Button
                        variant={ButtonVariant.solid}
                        color={colors.purple}
                        size={'200px'}
                        height={'68px'}
                        onClick={() => handleSave()}
                    >
                        {t('pages.agency.eInvoices.button').text}
                    </Button>
                </div>
            </Content>
        </>
    );
};

const Description = styled.div`
    text-align: left;
    margin-bottom: 20px;
`;
const ToggleRow = styled.div`
    margin-top: 15px;
    margin-bottom: 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 20px;

    p {
        font-size: 15px;
        margin-right: 20px;
    }
    a:hover {
        text-decoration: underline;
    }
`;
const Content = styled.div`
    vertical-align: top;
    &.desktop {
        width: 100%;
    }
    &.mobile {
        width: 100%;
    }
    .button-container {
        margin-top: 100px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .add-logo-preview {
        max-width: 43%;
        float: right;
        margin: 10px;
        text-align: center !important;
        @media screen and (max-width: ${MQ_BREAKPOINTS.tablet}) {
            max-width: 100%;
        }
    }
    input[type='radio'] {
        accent-color: #9b7fe9;
        height: 25px;
        width: 20px;
        margin-bottom: 10px;
    }
    .image-container {
        margin-top: 10px;
        position: relative;
        display: inline-block;
    }
    .template_container {
        display: flex;
        justify-content: space-evenly;
        flex-wrap: wrap;
        gap: 20px;
        @media all and (max-width: ${MQ_BREAKPOINTS.tablet}) {
            flex-direction: column;
            align-items: center;
        }
    }
    .enlarged {
        transform: scale(1.7);
        border: 1px solid black;
        transition: transform 0.3s ease-in-out;
        @media only screen and (max-width: 1670px) {
            transform: scale(1.7);
        }
        // @media only screen and (min-width: 1025px max-width: 1670px) {
        //     transform: scale(1.7);
        // }
        @media only screen and (min-width: 1670px) {
            transform: scale(1.3);
        }
        @media only screen and (min-width: 2100px) {
            transform: scale(1.4);
        }
        @media screen and (max-width: ${MQ_BREAKPOINTS.tablet}) {
            transform: scale(1);
        }
    }
    .image-overlay {
        position: absolute;
        top: 0;
        left: 50%;
        transform: translateX(-50%);
        width: 80%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.5);
        display: flex;
        justify-content: center;
        align-items: center;
        opacity: 0;
        cursor: pointer;
        transition: opacity 0.3s ease-in-out;
        @media screen and (max-width: ${MQ_BREAKPOINTS.tablet}) {
            width: 100%;
        }
    }

    .image-container:hover .image-overlay {
        opacity: 1;
        @media screen and (max-width: ${MQ_BREAKPOINTS.tablet}) {
            opacity: 0;
        }
    }

    .overlay-text {
        color: white;
        font-size: 16px;
        text-align: center;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }

    .cover-img {
        width: 80%;
        height: auto;
        cursor: pointer;
        @media screen and (max-width: ${MQ_BREAKPOINTS.tablet}) {
            width: 100%;
        }
    }

    .add-logo__content {
        display: inline-block;
        width: 56%;
        vertical-align: top;
        padding-top: 15px;
        text-align: center;

        @media all and (max-width: 750px) {
            width: 100%;
        }

        toggle-button {
            margin-bottom: 15px;
        }

        button {
            display: block;
            margin: 0 auto;
            padding: 0;
            width: 200px;
            height: 45px;
            border: 2px solid $brand-color;
            border-radius: 5px;
            background: $brand-color;
            color: $contrast-white;
            font-size: 14px;
            text-transform: uppercase;
            margin-top: 40px;

            &:focus {
                outline: none;
            }

            &:hover {
                opacity: 0.7;
            }
        }

        .cover-img {
            width: 80%;
        }
        .template_name {
            margin-bottom: 15px;
            font-size: 20px;
        }
    }
`;

export default InvoiceTemplate;
