import { FunctionComponent, useEffect, useState } from 'react';
import styled from 'styled-components';
import useLanguageStore from '../../../../store/language';
import useTranslations from '../../../../hooks/useTranslation';
import Button from '../../../../components/button';
import colors from '../../../../global/colors';
import { ButtonVariant } from '../../../../components/button';
import Input, { InputComponentType } from '../../../../components/input';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGear, faPenToSquare, faRotate, faTrashCan } from '@fortawesome/free-solid-svg-icons';
import { HealthCardsType } from '../../../../types/communication/healthCards';
import Dropdown from '../../../../components/dropdown';
import Success from '../../../../components/successModal';
import Modal from '../../../../components/modal';
import communication from '../../../../communication';
import Loader from '../../../../components/loader';
import ChangeModal from '../../../../components/changeModal';
import moment from 'moment';

interface Props {
    data: HealthCardsType;
    changed: Function;
    edit: Function;
}

const Card: FunctionComponent<Props> = ({ data, changed, edit }) => {
    const { currentLang, currentLangName } = useLanguageStore();
    const t = useTranslations(currentLang);

    const [formData, setFormData] = useState<HealthCardsType>(data);
    const [dropdownVisible, setDropdownVisible] = useState(false);
    const [successModal, setSuccessModal] = useState(false);
    const [changeModal, setChangeModal] = useState(false);
    const [cancelModal, setCancelModal] = useState(false);
    const [modalMessage, setModalMessage] = useState('');
    const [loader, setLoader] = useState(false);

    useEffect(() => {
        setFormData(data);
    }, [data, successModal]);

    const handleRenew = async (): Promise<void> => {
        setDropdownVisible(false);
        setLoader(true);
        const res = await communication.renewHealthCard(formData.id);
        if (res.status === 200) {
            setSuccessModal(true);
            setModalMessage(
                currentLangName !== 'English' ? res.data.message : t('pages.healthCards.renewSuccess').text,
            );
            setDropdownVisible(false);
        }
        setLoader(false);
    };

    const handleMform = async (): Promise<void> => {
        setDropdownVisible(false);
        setLoader(true);
        const res = await communication.mFormHealthCard(formData.id);
        if (res.status === 200) {
            setSuccessModal(true);
            setModalMessage(
                currentLangName !== 'English' ? res.data.message : t('pages.healthCards.mfromSuccess').text,
            );
            setDropdownVisible(false);
        }
        setLoader(false);
    };

    const handleCancelModal = async (): Promise<void> => {
        setCancelModal(true);
    };

    const handleCancel = async (): Promise<void> => {
        setDropdownVisible(false);
        setCancelModal(false);
        setLoader(true);
        const res = await communication.cancelHealthCard(formData.id);
        if (res.status === 200) {
            setSuccessModal(true);
            setModalMessage(t('pages.healthCards.cancelSuccess').text);
            setDropdownVisible(false);
        }
        setLoader(false);
    };

    const handleUpdate = async (): Promise<void> => {
        setDropdownVisible(false);
        setLoader(true);
        const res = await communication.updateHealthCard(formData.id, formData);
        if (res.status === 200) {
            setSuccessModal(true);
            setModalMessage(t('pages.healthCards.updateSuccess').text);
            setDropdownVisible(false);
        }
        setLoader(false);
    };

    const handleEdit = (): void => {
        edit(data);
        setDropdownVisible(false);
    };

    const dropdown = [
        { icon: faPenToSquare, label: t('pages.healthCards.dropdown.edit').text, link: '', handler: handleEdit },
        { icon: faRotate, label: t('pages.healthCards.dropdown.renew').text, link: '', handler: handleRenew },
        { icon: faTrashCan, label: t('pages.healthCards.dropdown.cancel').text, link: '', handler: handleCancelModal },
        { icon: null, label: t('pages.healthCards.dropdown.mform').text, link: '', handler: handleMform },
    ];

    return (
        <>
            {loader && <Loader />}
            {successModal && (
                <Modal modalVisible={true} closeModal={() => setSuccessModal(false)}>
                    <Success
                        close={() => {
                            changed();
                            setSuccessModal(false);
                        }}
                        message={modalMessage}
                    />
                </Modal>
            )}
            {changeModal && (
                <Modal
                    modalVisible={true}
                    closeModal={() => {
                        setChangeModal(false);
                    }}
                >
                    <ChangeModal
                        saveChanges={handleCancel}
                        close={() => {
                            setChangeModal(false);
                        }}
                        message={t('pages.healthCards.editCardQuestion').text}
                    />
                </Modal>
            )}
            {cancelModal && (
                <Modal
                    modalVisible={true}
                    closeModal={() => {
                        setCancelModal(false);
                    }}
                >
                    <ChangeModal
                        saveChanges={handleCancel}
                        close={() => {
                            setCancelModal(false);
                        }}
                        message={t('pages.healthCards.cancelCardQuestion').text}
                    />
                </Modal>
            )}
            {formData && (
                <Container>
                    <Content>
                        <div className="left">
                            <span className={formData.insurance_type}>
                                {formData.insurance_type === 'spouse'
                                    ? t('pages.healthCards.insuranceType.spouse').text
                                    : formData.insurance_type === 'child'
                                    ? t('pages.healthCards.insuranceType.child').text
                                    : t('pages.healthCards.insuranceType.carrier').text}
                            </span>
                            <Property>
                                {t('pages.healthCards.name').text}
                                <br />
                                <strong>{formData.first_name}</strong>
                            </Property>
                            <Property>
                                {t('pages.healthCards.parent').text}
                                <br />
                                <strong>{formData.parent_name}</strong>
                            </Property>
                            <Property>
                                {t('pages.healthCards.lastName').text}
                                <br />
                                <strong>{formData.last_name}</strong>
                            </Property>
                            <Property>
                                {t('pages.healthCards.id').text}
                                <br />
                                <strong>{formData.jmbg}</strong>
                            </Property>
                        </div>
                        <div className="right">
                            <div className="settings">
                                <FontAwesomeIcon
                                    onClick={() => setDropdownVisible(!dropdownVisible)}
                                    color={colors.purple}
                                    icon={faGear}
                                    style={{ cursor: 'pointer' }}
                                />
                                {dropdownVisible && (
                                    <DropdownContainer>
                                        <Dropdown itemList={dropdown} />
                                    </DropdownContainer>
                                )}
                            </div>
                            <Property>
                                {t('pages.healthCards.address').text}
                                <br />
                                <strong>{formData.street_address + ' ' + formData.street_number}</strong>
                            </Property>
                            <Property>
                                {t('pages.healthCards.municipality').text}
                                <br />
                                <strong>{formData.municipality?.data.name}</strong>
                            </Property>
                            <Property>
                                {t('pages.healthCards.city').text}
                                <br />
                                <strong>{formData.city}</strong>
                            </Property>
                        </div>
                    </Content>
                    <Footer>
                        <Input
                            type={InputComponentType.Date}
                            label={t('pages.healthCards.expiration').text}
                            date={formData.expire_date ? new Date(formData.expire_date) : null}
                            onChange={(value: string) => {
                                setFormData({
                                    ...formData,
                                    expire_date: moment(value).format('YYYY-MM-DD').toString(),
                                });
                            }}
                        />
                        <div className="button-container">
                            <Button variant={ButtonVariant.solid} color={colors.purple} onClick={() => handleUpdate()}>
                                {t('pages.healthCards.save').text}
                            </Button>
                        </div>
                    </Footer>
                </Container>
            )}
        </>
    );
};
export default Card;

const Container = styled.div`
    background: var(--white);
    border: 1px solid var(--purple);
    &:hover {
        box-shadow: 0 0 5px 5px var(--border-color);
    }
`;
const Content = styled.div`
    padding: 20px 24px 0;
    display: flex;
    .left {
        width: 50%;
        display: inline-block;
        vertical-align: top;
        margin-right: -4px;
        border-right: 1px solid #dedee2;
        span {
            &.insurance_carrier {
                background: var(--light-green);
                color: var(--green);
            }
            &.spouse {
                background: var(--light-orange);
                color: var(--orange);
            }
            &.child {
                background: var(--light-violet);
                color: var(--violet);
            }
            padding: 0 14px 2px;
            margin-left: -14px;
            margin-bottom: 14px;
            display: inline-block;
            border-radius: 20px;
            line-height: 24px;
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
        }
    }
    .right {
        width: 50%;
        margin-right: 0;
        border-right: 0;
        padding-left: 18px;
        position: relative;
    }
    .settings {
        height: 36px;
        text-align: right;
    }
`;
const Property = styled.div`
    padding: 0;
    padding-right: 10px;
    margin-bottom: 10px;
    line-height: 24px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    display: block;
    color: var(--gray);
    font-size: 12px;
    line-height: 26px;
    strong {
        font-size: 14px;
        color: var(--black);
        font-weight: unset;
    }
`;
const DropdownContainer = styled.div`
    width: 190px;
    margin-top: 15px;
    margin-left: -90px;

    font-size: 12px;
    position: absolute;
    right: 5px;

    span {
        display: none;
    }
    .dropdown-container {
        border: 1px solid var(--gray);
    }
`;
const Footer = styled.div`
    text-align: left;
    padding: 11px 24px 0;
    font-size: 13px;
    color: var(--gray);
    line-height: 26px;
    background-color: var(--body-gray);
    padding: 10px 24px;

    .date-container span.date-label {
        font-size: 13px;
    }
    input {
        color: var(--gray) !important;
    }
    .datepicker-input-wrapper {
        background: var(--white);
        border: 1px solid var(--border-color);
        svg {
            margin-right: 5px;
        }
    }

    .button-container {
        display: flex;
        justify-content: flex-end;
        margin-top: 10px;
        button {
            text-align: right;
            font-size: 12px;
            height: 44px;
        }
    }
`;
