import { FunctionComponent, useEffect, useState } from 'react';
import { nanoid } from 'nanoid';
import styled from 'styled-components';
import { ObjectKeys } from '../../../../types/objectKeys';
import moment from 'moment';
import { formatNumber, formatTwoDecimals } from '../../../../functions/format';
import logo from '../../../../assets/logo.png';
import { getUnitByValue } from '../measurementUnits';
import countTotal from '../countTotal';
import communication from '../../../../communication';
import { getAccessToken } from '../../../../functions/auth';
import { MQ_BREAKPOINTS } from '../../../../constants/breakpoints';
import useProfileStore from '../../../../store/profile';

interface Props {
    invoice: ObjectKeys;
    agency: ObjectKeys;
    title: string;
    type?: string;
    note?: string;
    qrCode?: string;
    advanceTotal?: string;
}

const PreviewInvoiceModal: FunctionComponent<Props> = ({
    invoice,
    agency,
    title,
    type,
    note,
    qrCode,
    advanceTotal,
}) => {
    const appUrl = process.env.REACT_APP_URL;

    const logoUrl = agency.logo ? `${appUrl}/${agency.logo}` : null;
    const [renderKey, setRenderKey] = useState<number>(0);

    const token = getAccessToken();

    const { profile } = useProfileStore();
    const has_logo_on_invoice = profile.has_logo;

    const [totalWithoutRabat, setTotalWithoutRabat] = useState<number | null>(null);
    const [rabat, setRabat] = useState<number | null>(null);

    const isPrepaid = invoice && invoice.is_prepaid;

    const calculateTotalAmountWithoutDiscounts = (items: ObjectKeys): void => {
        if (items && items.length > 0) {
            const totalResult = items.reduce(
                (accumulator: ObjectKeys, service: ObjectKeys) => {
                    const { pivot } = service;
                    if (pivot) {
                        const quantity = pivot.quantity ? Number(pivot.quantity.replace(',', '.')) : 0;
                        const pricePerUnit = pivot.price_per_unit ? Number(pivot.price_per_unit.replace(',', '.')) : 0;
                        const discount = pivot.discount ? Number(pivot.discount) : 0;
                        const amountWithoutDiscount = quantity * pricePerUnit;
                        const discountAmount = quantity * pricePerUnit * (discount / 100);

                        accumulator.totalWithoutDiscount += amountWithoutDiscount;
                        accumulator.totalDiscount += discountAmount;
                    }
                    return accumulator;
                },
                { totalWithoutDiscount: 0, totalDiscount: 0 },
            );

            setTotalWithoutRabat(totalResult.totalWithoutDiscount);
            setRabat(-totalResult.totalDiscount);
        } else {
            setTotalWithoutRabat(0);
            setRabat(0);
        }
    };

    const calculateTotalPrepaidNegative = (item: ObjectKeys): string => {
        let total = 0;
        if (item.prepaid_invoices?.length > 0) {
            item.prepaid_invoices.forEach((element: ObjectKeys) => {
                total += Number(element.pivot.value);
            });
        } else if (item.prepaid_invoice_ids?.length > 0) {
            item.prepaid_invoice_ids.forEach((element: ObjectKeys) => {
                total += Number(element.value);
            });
        }
        if (total !== 0) {
            total = -total; // Convert total to negative
            return formatTwoDecimals(total.toString());
        }
        return '';
    };

    useEffect(() => {
        calculateTotalAmountWithoutDiscounts(invoice.services);
    }, [invoice.value]);

    useEffect(() => {
        setRenderKey(renderKey + 1);
    }, []);

    const foreignInvoiceText =
        type === 'foreign'
            ? {
                  title:
                      title === 'invoice' ? 'Invoice / ' : title === 'proforma' ? 'Proforma / ' : 'Advance Invoice / ',
                  dated: 'Invoice date / ',
                  valueDate: 'Trading date / ',
                  tradingPlace: 'Trading place / ',
                  from: 'From / ',
                  vatNo: 'Vat No / ',
                  jbkjs: 'Public company ID / ',
                  idNo: 'ID No / ',
                  personalIdNo: 'Personal ID No / ',
                  billTo: 'Bill to / ',
                  address: 'Address / ',
                  city: 'City / ',
                  country: 'Country / ',
                  item: 'TYPE OF SERVICE',
                  unit: 'Unit',
                  unitAdvance: 'Advance / ',
                  quantity: 'Quantity',
                  price: 'Price',
                  rabat: 'DISCOUNT ',
                  total: 'Total',
                  amountDue: 'TOTAL / ',
                  prepaidTotal: 'ADVANCE / ',
                  amountDuePayment: 'TOTAL FOR PAYMENT/ ',
                  note: 'COMMENT / OPIS USLUGE',
                  identificationNum: 'Identification number / ',
                  notVatSystem: 'Not in the VAT system. / ',
                  vatCalcProforma:
                      'VAT not calculated on the proforma according to article 33 of Law on value added tax. / ',
                  vatCalcInvoice:
                      'VAT not calculated on the invoice according to article 33 of Law on value added tax. / ',
                  vatCalcAInvoice:
                      'VAT not calculated on the invoice according to article 33 of Law on value added tax. / ',
                  docValid: 'Document is valid without stamp and signature. / ',
                  place: 'Place / ',
                  putCallNoInvoice: 'When making the payment, please provide a reference number xx/xxxx / ',
                  bankAccount: 'BANK ACCOUNT / ',
                  noteTaxFree: 'NOTE ON TAX EXEMPTION / ',
                  invoiceMadeby: 'Invoice made by ',
                  proformaMadeby: 'Proforma made by ',
                  advanceMadeby: 'Advance invoice made by ',
              }
            : {};

    const handlePrint = (): void => {
        communication
            .downloadInvoice(
                invoice.id,
                token,
                invoice.invoice_number,
                invoice.currency !== 'RSD',
                false,
                qrCode ? qrCode : undefined,
            )
            .then((res: ObjectKeys) => {
                // Create a URL for the PDF blob
                const url = window.URL.createObjectURL(res.data);
                // Open the PDF in a new window or tab
                const printWindow = window.open(url, '_blank');
                if (printWindow) {
                    // Wait for the PDF to fully load, then trigger the print dialog
                    printWindow.onload = () => {
                        printWindow.focus();
                        printWindow.print();
                    };
                }
            })
            .catch((error: any) => {
                console.error('Error downloading the invoice:', error);
            });
    };

    return (
        <Container>
            <PrintButton onClick={handlePrint}>Print</PrintButton>
            {logoUrl ? (
                <AgencyLogo>
                    <img src={`${logoUrl}?renderKey=${renderKey}`} alt="Agency logo" />
                </AgencyLogo>
            ) : (
                <></>
            )}
            <Header>
                <Row>
                    <Row className="inv-no">
                        <h4 className="title">
                            {foreignInvoiceText?.title}
                            {title === 'invoice' ? (
                                'Faktura:'
                            ) : title === 'proforma' ? (
                                'Profaktura:'
                            ) : (
                                <>
                                    {'Avansna faktura:'}
                                    <br />
                                </>
                            )}{' '}
                            {invoice.invoice_number
                                ? `${invoice.prefix ? invoice.prefix + ' ' : ''}${invoice.invoice_number}`
                                : invoice.quotation_number
                                ? invoice.quotation_number
                                : 'xx/xxx'}
                        </h4>
                    </Row>
                    <Row className="date">
                        <p>
                            {foreignInvoiceText?.dated}
                            <br></br>
                            {title === 'invoice'
                                ? 'Datum fakture:'
                                : title === 'proforma'
                                ? 'Datum profakture:'
                                : 'Datum avansne fakture:'}{' '}
                            <br></br>{' '}
                            {invoice.invoice_date
                                ? moment(invoice.invoice_date).format('DD.MM.YYYY.')
                                : invoice.quotation_date
                                ? moment(invoice.quotation_date).format('DD.MM.YYYY.')
                                : moment(new Date()).format('DD.MM.YYYY.')}
                        </p>
                    </Row>
                    <Row className="date">
                        <p>
                            {foreignInvoiceText?.valueDate} <br></br>
                            {'Datum prometa:'} <br></br>
                            {invoice.trading_date
                                ? moment(invoice.trading_date).format('DD.MM.YYYY.')
                                : moment(new Date()).format('DD.MM.YYYY.')}
                            <br></br>
                            {foreignInvoiceText?.tradingPlace} <br></br>
                            {'Mesto prometa:'} <br></br> {invoice.trading_place}
                        </p>
                    </Row>
                </Row>
            </Header>
            {agency && (
                <Agency>
                    <div>
                        <div>
                            {foreignInvoiceText?.from}
                            {'Od:'}
                        </div>
                        <h4 className="agency-name">{agency.name}</h4>
                        <p>{agency.full_name}</p>
                        <p>{agency.street_address + '\xa0' + agency.street_number}</p>
                        <p>{agency.city}</p>
                        <p>
                            {foreignInvoiceText?.vatNo}
                            {'PIB:' + '\xa0' + agency.pib}
                        </p>
                        <p>
                            {foreignInvoiceText?.idNo}
                            {'MB:' + '\xa0' + agency.identification_number}
                        </p>
                        {type === 'foreign' ? (
                            <>
                                <p>
                                    {'IBAN'}: {invoice.iban ? invoice.iban : '/'}
                                </p>
                                <p>
                                    {'SWIFT'}: {invoice.swift ? invoice.swift : '/'}
                                </p>
                            </>
                        ) : (
                            <p>
                                {'ŽIRO RAČUN:'}
                                {invoice?.bank_account ? invoice?.bank_account : agency.first_bank_account}
                            </p>
                        )}
                        <p>{'E-mail:' + '\xa0' + (agency?.agency_email ? agency?.agency_email : '/')}</p>
                    </div>

                    <div>
                        <div>
                            {foreignInvoiceText?.billTo}
                            {'Komitent:'}
                        </div>
                        <h4 className="client-name">
                            {invoice?.client?.data.company_name ? invoice?.client?.data.company_name : ''}
                        </h4>
                        <p>
                            {foreignInvoiceText?.address}
                            {'Adresa:' + '\xa0' + (invoice?.client?.data.address ? invoice?.client?.data.address : '/')}
                        </p>
                        <p>
                            {foreignInvoiceText?.city}
                            {'Grad:' + '\xa0' + (invoice?.client?.data.city ? invoice?.client?.data.city : '/')}
                        </p>
                        {type === 'foreign' ? (
                            <>
                                <p>
                                    {foreignInvoiceText?.country}
                                    {'Država:'}
                                    {'\xa0'}
                                    {invoice?.client?.data.country ? invoice?.client?.data.country : '/'}
                                </p>
                                <p>
                                    {foreignInvoiceText?.vatNo}
                                    {'PIB:'}
                                    {'\xa0'}
                                    {invoice?.client?.data.pib ? invoice?.client?.data.pib : '/'}
                                </p>
                                <p>
                                    {foreignInvoiceText?.idNo}
                                    {'MB:'}
                                    {'\xa0'}
                                    {invoice?.client?.data.identification_number
                                        ? invoice?.client?.data.identification_number
                                        : '/'}
                                </p>
                            </>
                        ) : (
                            <>
                                {invoice?.client?.data.type === 'civil' && (
                                    <p>
                                        {foreignInvoiceText?.personalIdNo}
                                        {'JMBG: '}
                                        {'\xa0'}
                                        {invoice?.client?.data.jmbg ? invoice?.client?.data.jmbg : '/'}
                                    </p>
                                )}
                                {invoice?.client?.data.type === 'legal' || invoice?.client?.data.type === 'public' ? (
                                    <>
                                        <p>
                                            {foreignInvoiceText?.vatNo}
                                            {'PIB:'}
                                            {'\xa0'}
                                            {invoice?.client?.data.pib ? invoice?.client?.data.pib : '/'}
                                        </p>
                                        <p>
                                            {foreignInvoiceText?.idNo}
                                            {'MB:'}
                                            {'\xa0'}
                                            {invoice?.client?.data.identification_number
                                                ? invoice?.client?.data.identification_number
                                                : '/'}
                                        </p>
                                    </>
                                ) : null}
                                {invoice?.client?.data.type === 'public' && (
                                    <p>
                                        {foreignInvoiceText?.jbkjs}
                                        {'JBKJS: '}
                                        {'\xa0'}
                                        {invoice?.client?.data.public_company_id
                                            ? invoice?.client?.data.public_company_id
                                            : '/'}
                                    </p>
                                )}
                                {invoice?.sef_invoice_number ? (
                                    <p>
                                        {'SEF broj ugovora: '}
                                        {invoice?.sef_invoice_number}
                                    </p>
                                ) : null}
                            </>
                        )}
                    </div>
                </Agency>
            )}
            <Row className="services">
                {type === 'domestic' ? (
                    <>
                        <h4 className="type">{'Vrsta usluge'}</h4>
                        <h4 className="price">{'Jed. mere'}</h4>
                        <h4 className="price">{'Količina'}</h4>
                        <h4 className="price">{'Cena'}</h4>
                        {!isPrepaid ? <h4 className="price">{'Rabat %'}</h4> : null}
                        <h4 className="total">{'Ukupno'}</h4>
                    </>
                ) : (
                    <>
                        <div className="type">
                            <h4>{foreignInvoiceText?.item}</h4>
                            <span>( {'Vrsta usluge'} )</span>
                        </div>
                        <div className="price">
                            <h4>{foreignInvoiceText?.unit}</h4>
                            <span>( {'Jed. mere'} )</span>
                        </div>
                        <div className="price">
                            <h4>{foreignInvoiceText?.quantity}</h4>
                            <span>( {'Količina'} )</span>
                        </div>
                        <div className="price">
                            <h4>{foreignInvoiceText?.price}</h4>
                            <span>( {'Cena'} )</span>
                        </div>
                        {!isPrepaid ? (
                            <div className="price">
                                <h4>{foreignInvoiceText?.rabat}</h4>
                                <span>( {'Rabat %'} )</span>
                            </div>
                        ) : null}
                        <div className="total">
                            <h4>{foreignInvoiceText?.total}</h4>
                            <span>( {'Ukupno'} )</span>
                        </div>
                    </>
                )}
            </Row>
            {invoice?.services ? (
                <>
                    {invoice?.services?.map((service: ObjectKeys) => (
                        <div key={nanoid()}>
                            <Row className="services">
                                <p className="type">{service.name}</p>
                                <p className="price">
                                    {getUnitByValue(service.pivot.measurement_unit, type === 'foreign', true)}
                                </p>
                                <p className="price">{formatTwoDecimals(service?.pivot?.quantity)}</p>
                                <p className="price">{formatTwoDecimals(service?.pivot?.price_per_unit)}</p>
                                {!isPrepaid && <p className="price">{service?.pivot?.discount}</p>}
                                <p className="total">{formatNumber(service?.pivot?.total_price, 2, 3, '.', ',')}</p>
                            </Row>
                        </div>
                    ))}
                    {invoice?.prepaid_invoice_ids
                        ? invoice.prepaid_invoice_ids.map((item: ObjectKeys, index: number) => (
                              <Row className="services" key={index}>
                                  <p className="type">
                                      {item.prefix ? item.prefix + ' ' : ''}
                                      {item.invoice_number}
                                  </p>
                                  <p className="price">{foreignInvoiceText?.unitAdvance}Avans</p>
                                  <p className="price">1,00</p>
                                  <p className="price">-{formatTwoDecimals(item.value.toString())}</p>
                                  {!isPrepaid && <p className="price"> </p>}
                                  <p className="total">-{formatTwoDecimals(item.value.toString())}</p>
                              </Row>
                          ))
                        : null}
                    {invoice?.prepaid_invoices
                        ? invoice.prepaid_invoices.map((item: ObjectKeys, index: number) => (
                              <Row className="services" key={index}>
                                  <p className="type">
                                      {item.prefix ? item.prefix + ' ' : ''}
                                      {item.invoice_number}/{item.invoice_date.split('-')[0]}
                                  </p>
                                  <p className="price">{foreignInvoiceText?.unitAdvance}Avans</p>
                                  <p className="price">1,00</p>
                                  <p className="price">-{formatTwoDecimals(item.pivot.value.toString())}</p>
                                  {!isPrepaid && <p className="price"> </p>}
                                  <p className="total">-{formatTwoDecimals(item.pivot.value.toString())}</p>
                              </Row>
                          ))
                        : null}
                    {totalWithoutRabat !== null && !isPrepaid ? (
                        <Total>
                            <h4>
                                {foreignInvoiceText?.amountDue}
                                {'UKUPNO '} ({invoice.currency})
                            </h4>
                            <p className="right">{formatTwoDecimals(totalWithoutRabat.toString())}</p>
                        </Total>
                    ) : null}

                    {rabat !== null && !isPrepaid ? (
                        <Total>
                            <h4>
                                {foreignInvoiceText?.rabat} {foreignInvoiceText?.rabat ? ' / ' : ''}
                                {'RABAT '} ({invoice.currency})
                            </h4>
                            <p className="right">{formatTwoDecimals(rabat.toString())}</p>
                        </Total>
                    ) : null}
                    {invoice?.prepaid_invoices?.length === 0 || invoice?.prepaid_invoice_ids?.length === 0 ? (
                        <Total>
                            <h4>
                                {foreignInvoiceText?.amountDue}
                                {'UKUPNO ZA UPLATU'} ({invoice.currency})
                            </h4>
                            <p className="right">{formatNumber(countTotal(invoice.services), 2, 3, '.', ',')}</p>
                        </Total>
                    ) : null}

                    {invoice?.prepaid_invoices?.length > 0 || invoice?.prepaid_invoice_ids?.length > 0 ? (
                        <Total>
                            <h4>
                                {foreignInvoiceText?.prepaidTotal}
                                {'AVANSI'} ({invoice.currency})
                            </h4>
                            <p className="right">{calculateTotalPrepaidNegative(invoice)}</p>
                        </Total>
                    ) : null}

                    {invoice?.prepaid_invoices?.length > 0 || invoice?.prepaid_invoice_ids?.length > 0 ? (
                        <Total>
                            <h4>
                                {foreignInvoiceText?.amountDuePayment}
                                {'UKUPNO ZA UPLATU'} ({invoice.currency})
                            </h4>
                            {advanceTotal !== null && advanceTotal !== undefined ? (
                                <p className="right">{formatTwoDecimals(advanceTotal.toString())}</p>
                            ) : (
                                <p className="right">{formatTwoDecimals(invoice.total_for_payment.toString())}</p>
                            )}
                        </Total>
                    ) : null}
                    {/* only for proformas */}
                    {title === 'proforma' ? (
                        <Total>
                            <h4>
                                {foreignInvoiceText?.amountDue}
                                {'UKUPNO ZA UPLATU'} ({invoice.currency})
                            </h4>
                            <p className="right">{formatNumber(countTotal(invoice.services), 2, 3, '.', ',')}</p>
                        </Total>
                    ) : null}
                </>
            ) : (
                <></>
            )}
            <Row>
                <Comments>
                    <h4>
                        {foreignInvoiceText?.note}
                        {!foreignInvoiceText?.note && 'Komentar / opis usluge:'}
                    </h4>
                    <p
                        dangerouslySetInnerHTML={{
                            __html: note
                                ? note.replace(/<b>/g, '&lt;b&gt;').replace(/<\/b>/g, '&lt;/b&gt;')
                                : 'Rok za plaćanje je 15 dana',
                        }}
                        style={{ whiteSpace: 'pre-wrap' }}
                    ></p>
                    <p>
                        {foreignInvoiceText?.putCallNoInvoice}
                        {title === 'proforma'
                            ? 'Pri plaćanju profakture navedite poziv na broj xx/xxxx'
                            : 'Pri plaćanju fakture navedite poziv na broj xx/xxxx'}
                    </p>
                    <p>
                        {foreignInvoiceText?.identificationNum}
                        {'Identifikacioni broj:  '} <br></br>
                        {agency.signature}
                    </p>
                    <p>
                        {foreignInvoiceText?.docValid}
                        {title === 'invoice'
                            ? 'Faktura je važeća bez pečata i potpisa.'
                            : title === 'proforma'
                            ? 'Profaktura je važeća bez pečata i potpisa.'
                            : 'Avansna faktura je važeća bez pečata i potpisa.'}
                    </p>
                    {type === 'foreign' && (
                        <p>
                            {'Place of issue / Mesto izdavanja: '}
                            {agency.city}
                        </p>
                    )}
                    {type !== 'foreign' && (
                        <p>
                            {'Mesto izdavanja: '}
                            {agency.city}
                        </p>
                    )}
                    <br></br>
                    <h4>
                        {foreignInvoiceText?.noteTaxFree}
                        {'Napomena o poreskom oslobođenju:'}
                    </h4>
                    <p>
                        {foreignInvoiceText?.notVatSystem}
                        {'Poreski obveznik nije u sistemu PDV-a.'}
                    </p>
                    <p>
                        {title === 'invoice'
                            ? `${
                                  foreignInvoiceText?.vatCalcInvoice ? foreignInvoiceText?.vatCalcInvoice : ''
                              }PDV nije obračunat na fakturi u skladu sa članom 33. Zakona o porezu na dodatu vrednost.`
                            : title === 'proforma'
                            ? `${
                                  foreignInvoiceText?.vatCalcProforma ? foreignInvoiceText?.vatCalcProforma : ''
                              }PDV nije obračunat na profakturi u skladu sa članom 33. Zakona o porezu na dodatu vrednost.`
                            : `${
                                  foreignInvoiceText?.vatCalcAInvoice ? foreignInvoiceText?.vatCalcAInvoice : ''
                              }PDV nije obračunat na avansnoj fakturi u skladu sa članom 33. Zakona o porezu na dodatu vrednost.`}
                    </p>
                </Comments>
                {qrCode && (
                    <div className="qr_code">
                        <img
                            width={'150px'}
                            height={'150px'}
                            data-v-f036a56e=""
                            data-v-251216d5=""
                            src={`data:image/jpeg;base64,${qrCode}`}
                            data-v-52b191c5=""
                            key={qrCode}
                        ></img>
                    </div>
                )}
            </Row>
            <Footer>
                {type === 'foreign' ? <p>Page 1 of 1</p> : <p>Strana 1 od 1</p>}
                {has_logo_on_invoice ? (
                    <div className="logo">
                        <p>
                            {title === 'invoice'
                                ? foreignInvoiceText?.invoiceMadeby
                                    ? foreignInvoiceText?.invoiceMadeby
                                    : 'Faktura kreirana preko '
                                : title === 'proforma'
                                ? foreignInvoiceText?.proformaMadeby
                                    ? foreignInvoiceText?.proformaMadeby
                                    : 'Profaktura kreirana preko '
                                : foreignInvoiceText?.advanceMadeby
                                ? foreignInvoiceText?.advanceMadeby
                                : 'Avansna faktura kreirana preko '}
                            <a href="http://www.pausal.rs" target="_blank" rel="noreferrer">
                                pausal.rs
                            </a>
                        </p>
                        <img src={logo} height={'30px'} />
                    </div>
                ) : null}
            </Footer>
        </Container>
    );
};

export default PreviewInvoiceModal;

const Container = styled.div`
    min-width: 750px;
    padding: 40px 30px 20px 140px;
    color: black !important;
    p {
        font-size: 12px;
        // word-break: break-all;
    }
    h4 {
        font-weight: 600;
        font-size: 12px;
        &.title {
            margin-top: 15px;
            font-size: 20px;
            font-weight: 700;
        }
        &.agency-name {
            overflow-wrap: anywhere;
            font-size: 17px;
        }
        &.client-name {
            font-size: 17px;
            overflow-wrap: anywhere;
        }
    }
    @media only screen and (max-width: ${MQ_BREAKPOINTS.tablet}) {
        padding: 30px;
    }
    @media only screen and (max-width: ${MQ_BREAKPOINTS.mobileL}) {
        padding: 10px;
        margin-bottom: 100px;
    }
`;
const AgencyLogo = styled.div`
    position: absolute;
    left: 0;
    padding: 0 10px;
    img {
        width: 110px;
    }
    @media only screen and (max-width: ${MQ_BREAKPOINTS.tablet}) {
        position: static;
        padding: 0;
        margin-bottom: 10px;
    }
    @media only screen and (max-width: ${MQ_BREAKPOINTS.mobileL}) {
        text-align: center;
    }
`;

const Header = styled.div`
    width: 100%;
    padding-bottom: 20px;
    border-collapse: separate;
    border-bottom: 1px solid black;

    .inv-no {
        width: 50%;
        @media only screen and (max-width: ${MQ_BREAKPOINTS.mobileL}) {
            width: 35%;
        }
    }

    .date {
        :nth-of-type(3) {
            width: 30%;
        }
        display: flex;
        justify-content: flex-end;
        p {
            line-height: 1.3;
        }
    }
`;
const Row = styled.div`
    display: flex;
    gap: 5px;
    &.right {
        width: 100%;
        display: flex;
        justify-content: flex-end;
        margin-top: 10px;
        p {
            line-height: 1.2;
        }
    }
    &.services {
        text-align: end;
        width: 100%;
        padding-top: 10px;
        padding-bottom: 10px;
        border-bottom: 1px solid black;
        h4 {
            text-transform: uppercase;
            line-height: 1.3;
            @media only screen and (max-width: ${MQ_BREAKPOINTS.mobileL}) {
                font-size: 10px;
            }
        }
        span {
            text-transform: uppercase;
            font-size: 10px;
        }
    }
    .type {
        width: 30%;
        text-align: start;
        line-height: 0.8;
    }
    .price {
        width: 15%;
        line-height: 0.8;
    }
    .total {
        width: 25%;
        line-height: 0.8;
        font-weight: 700;
    }
    .qr_code {
        margin-top: 50px;
    }
`;
const Agency = styled.div`
    border-bottom: 1px solid black;
    font-size: 12px;
    display: flex;
    gap: 5px;
    padding: 10px 0;
    > div {
        width: 50%;
    }
    h4 {
        margin-top: 15px;
        margin-bottom: 15px;
    }
    p {
        margin-bottom: 8px;
        line-height: 1.3;
    }
`;
const Total = styled.div`
    width: 100%;
    height: 35px;
    font-weight: 700;
    display: flex;
    border-bottom: 1px solid var(--black);
    h4 {
        width: 60%;
        display: flex;
        align-items: center;
        font-weight: bold;
    }
    p {
        width: 40%;
        font-size: 20px;
        font-weight: 700;
        background-color: var(--light-gray);
        text-align: center;
        border: none;
        display: flex;
        align-items: center;
        justify-content: right; /* Optional, for horizontal centering */
        color: black !important;
    }
`;
// const Advance = styled.div`
//     width: 100%;
//     height: 30px;
//     font-weight: 700;
//     display: flex;
//     border-bottom: 1px solid var(--black);
//     h4 {
//         width: 60%;
//         display: flex;
//         align-items: center;
//     }
//     p {
//         width: 40%;
//         font-size: 20px;
//         font-weight: 500;
//         background-color: var(--light-gray);
//         text-align: center;
//         border: none;
//         display: flex;
//         align-items: center;
//         justify-content: right; /* Optional, for horizontal centering */
//         color: black !important;
//     }
// `;
const Comments = styled.div`
    padding-top: 20px;
    h4 {
        font-size: 12px;
        margin-bottom: 10px;
        text-transform: uppercase;
    }
    p {
        margin-top: 5px;
        line-height: 1.3;
        word-break: unset;
    }
`;
const Footer = styled.div`
    border-top: 1px solid var(--black);
    width: 100%;
    padding-top: 20px;
    font-size: 12px;
    margin-top: 100px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    @media only screen and (max-width: ${MQ_BREAKPOINTS.mobileL}) {
        flex-direction: column;
        img {
            margin-top: 10px;
            width: auto;
        }
    }
    .logo {
        display: contents;
    }
`;

const PrintButton = styled.button`
    position: fixed;
    top: 10px;
    right: 20px;
    padding: 5px 10px;
    background-color: #cebcff;
    color: white;
    border: none;
    cursor: pointer;
    font-size: 14px;

    display: none;

    &:hover {
        background-color: var(--purple);
    }
`;
