import fetch from '../fetch';
import routes from '../routes';
import getCurrentLanguage from '../../functions/getCurrentLanguage';
import { GetAllBanksResponse, GetBankResponse } from './types';

export default class Banks {
    public getBank(number: number): GetBankResponse {
        return fetch({
            method: 'GET',
            headers: { 'Content-Type': 'application/json; charset=UTF-8', 'Accept-Language': getCurrentLanguage() },
            url: routes.banks.get(number),
        });
    }
    public getAllBanks(): GetAllBanksResponse {
        return fetch({
            method: 'GET',
            headers: { 'Content-Type': 'application/json; charset=UTF-8', 'Accept-Language': getCurrentLanguage() },
            url: routes.banks.getAllBanks(),
        });
    }
}
