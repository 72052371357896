import { useEffect, useState } from 'react';
import styled from 'styled-components';
import { ObjectKeys } from '@/types/objectKeys';
import useTranslations from '../../../hooks/useTranslation';
import useLanguageStore from '../../../store/language';
import TextArea from '../../../components/input/textarea';
import { formatTwoDecimals } from '../../../functions/format';
import { useNavigate } from 'react-router-dom';
import { StepControlButton } from '../../../components/stepControlButton';
import { MQ_BREAKPOINTS } from '../../../constants/breakpoints';

interface StepThreeProps {
    cart: ObjectKeys;
    nextStep: Function;
    previousStep: Function;
    updatedCart: Function;
}

const StepThree: React.FC<StepThreeProps> = ({ cart, nextStep, updatedCart, previousStep }) => {
    const { currentLang } = useLanguageStore();
    const t = useTranslations(currentLang);
    const lang = localStorage.language;
    const navigate = useNavigate();
    const defaultLang = lang === 'Srpski' ? 'rs' : lang === 'English' ? 'en' : lang === 'Русский' ? 'ru' : 'rs';

    const [discount, setDiscount] = useState<number>(0);

    const discountedPrice = (price: number): number => {
        const discounted = price;
        return discounted;
    };

    useEffect(() => {
        if (cart && cart.items && cart.items.length < 1) {
            navigate('/guest/1');
        }
    }, []);

    useEffect(() => {
        // if (cart) {
        //     const discountPrev = (cart.total_price * serviceDiscount) / 100;
        //     setDiscount(discountPrev);
        // }
    }, [cart]);

    return (
        <>
            <ContentSecondStep>
                <Title>
                    <h2>{t('pages.agency.extra_services.choosen_services').text}</h2>
                </Title>
                <div className="content-wrapper">
                    <div className="leftPage">
                        <div>
                            <TableWrapper>
                                <table>
                                    <thead>
                                        <th>{t('pages.agency.extra_services.service').text}</th>
                                        <th>{t('pages.agency.extra_services.quantity').text}</th>
                                        <th>{t('pages.agency.extra_services.price').text}</th>
                                        <th>{t('pages.agency.extra_services.discount_price').text}</th>
                                    </thead>
                                    <tbody>
                                        {cart.items.map((i: ObjectKeys) => (
                                            <tr key={i.id}>
                                                <td className="service-name">{i[`name_${defaultLang}`]}</td>
                                                <td className="marginQuantity">{i.quantity}</td>
                                                <td>{formatTwoDecimals((i.price * i.quantity).toString())}</td>
                                                <td>
                                                    {formatTwoDecimals(
                                                        (discountedPrice(i.price) * i.quantity).toString(),
                                                    )}
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </TableWrapper>
                        </div>
                        <div className="bottom-note">
                            <div className="left">{t('pages.agency.extra_services.note').text}</div>
                            <div className="note">
                                <TextArea
                                    value={cart.note}
                                    onChange={(value: string) => {
                                        const newCart = { ...cart };
                                        newCart.note = value;
                                        updatedCart(newCart);
                                    }}
                                    placeholder={t('pages.agency.extra_services.placeholder_note').text}
                                    height={'75px'}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="rightMain">
                        <div className="rightPage">
                            <div className="amountPrice">
                                <div className="table">
                                    <div>
                                        <p>{t('pages.agency.subscription.price').text}</p>
                                        <p>{formatTwoDecimals(cart.total_price.toString())} RSD</p>
                                    </div>
                                    <div>
                                        <p>{t('pages.agency.subscription.discount').text}</p>
                                        <p>{formatTwoDecimals(discount.toString())} RSD</p>
                                    </div>
                                </div>
                                <div className="total">
                                    <p>{t('pages.agency.subscription.total').text}</p>
                                    <p>{formatTwoDecimals((cart.total_price - discount).toString())} RSD</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </ContentSecondStep>
            <Arrows>
                <StepControlButton
                    step={'previous'}
                    handleClick={() => {
                        previousStep();
                        navigate('/guest/1');
                    }}
                />
                <StepControlButton
                    step={'next'}
                    handleClick={() => {
                        nextStep();
                        navigate('/guest/3');
                    }}
                />
            </Arrows>
        </>
    );
};
const ContentSecondStep = styled.div`
    display: flex;
    flex-direction: column;
    padding: 20px 0;
    .content-wrapper {
        display: flex;
        flex-direction: row;
        gap: 3rem;
        @media only screen and (max-width: ${MQ_BREAKPOINTS.tablet}) {
            flex-direction: column;
        }
        .rightMain {
            flex: 1;
            display: flex;
            flex-direction: column;
            max-width: 50%;
            -webkit-box-pack: justify;
            justify-content: space-between;
            @media only screen and (max-width: ${MQ_BREAKPOINTS.tablet}) {
                padding: 0;
                max-width: 100%;
            }
        }
        .leftPage {
            flex: 1;
            max-width: 50%;
            padding: 20px 0;
            table {
                width: 100%;
            }
            @media only screen and (max-width: ${MQ_BREAKPOINTS.tablet}) {
                padding: 0;
                max-width: 100%;
            }
            .packages {
                display: flex;
                gap: 5px;
                padding: 10px;
                justify-content: space-evenly;
            }
            .chooseSubscription {
                display: flex;
                align-items: center;
                gap: 5px;
                padding: 10px;
                font-size: 15px;
                .item {
                    padding-left: 10px;
                }
                .crossed {
                    text-decoration: line-through;
                }
            }
            .bottom-note {
                display: flex;
                flex-direction: column;
                .left {
                    text-align: left;
                    font-size: 15px;
                    color: var(--gray);
                }
                textarea {
                    font-size: 15px;
                }
            }
        }
        .rightPage {
            display: flex;
            flex-direction: column;
            align-items: center;
            flex-grow: 1;
            color: white;
            height: fit-content;
            justify-content: space-between;
            @media only screen and (max-width: ${MQ_BREAKPOINTS.tablet}) {
                max-width: 100%;
                gap: 20px;
            }
            .title {
                margin-top: 20px;
                display: flex;
                flex-direction: column;
                align-items: center;
                text-align: center;
                h2 {
                    font-size: 15px;
                    color: white;
                    margin: 10px;
                }
            }
            .table {
                width: 90%;
                padding: 20px 0;
                border-top: 1px solid white;
                border-bottom: 1px solid white;
                display: flex;
                flex-direction: column;
                align-items: center;
                gap: 10px;
                margin-top: 20px;
                @media only screen and (max-width: ${MQ_BREAKPOINTS.tablet}) {
                    padding: 0;
                }
                div {
                    display: flex;
                    justify-content: space-between;
                    width: 100%;
                    padding: 10px 0;
                    p {
                        font-size: 15px;
                    }
                }
            }
            .amountPrice {
                width: 100%;
                display: flex;
                flex-direction: column;
                align-items: center;
                background-color: var(--purple);
                margin-top: 20px;
                @media only screen and (max-width: ${MQ_BREAKPOINTS.tablet}) {
                    padding: 0;
                }
            }
            .total {
                display: flex;
                justify-content: space-between;
                width: 90%;
                padding: 20px 0;
                p {
                    font-size: 15px;
                }
            }
        }
        input[type='radio'] {
            accent-color: #9b7fe9;
            height: 25px;
            width: 20px;
            margin-bottom: 10px;
        }
        .note {
            margin-top: 10px;
            margin-bottom: 20px;
            font-size: 14px;
            color: black;
            text-align: center;
            line-height: 20px;
            border: 1px solid var(--purple);
            padding: 5px;
            width: 100%;
        }
        .left {
            margin-top: 40px;
            text-align: center;
        }
    }
`;

const Title = styled.div`
    background-color: var(--purple);
    padding: 20px 10px;
    h2 {
        font-size: 20px;
        color: var(--white);
        text-align: left;
    }
`;

const Arrows = styled.div`
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
`;

const TableWrapper = styled.div`
    overflow: auto;
    ::-webkit-scrollbar {
        -webkit-appearance: none;
        height: 12px;
    }
    ::-webkit-scrollbar-thumb {
        border-radius: 10px;
        background-color: var(--purple);
    }
    table {
        margin-top: 20px;
        text-align: left;
        border-collapse: collapse;
        color: var(--gray);
        thead {
            border-bottom: 1px solid #ccc;
            th {
                padding-right: 20px;
                min-width: 120px;
                padding-bottom: 10px;
                font-size: 15px;
                @media only screen and (max-width: ${MQ_BREAKPOINTS.tablet}) {
                    min-width: auto;
                }
            }
        }
        tbody {
            tr {
                border-bottom: 1px solid #ccc;
                td {
                    padding: 10px 40px 10px 0;
                    line-height: 1.2;
                    font-size: 15px;
                    @media only screen and (max-width: ${MQ_BREAKPOINTS.tablet}) {
                        text-align: center;
                        padding-right: 0;
                    }
                }
            }
        }
    }
`;

export default StepThree;
