import { FunctionComponent } from 'react';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashCan, faPenToSquare } from '@fortawesome/free-solid-svg-icons';
import { ObjectKeys } from '../../../types/objectKeys';
import colors from '../../../global/colors';
import useLanguageStore from '../../../store/language';
import { formatNumber } from '../../../functions/format';
import getNumberLabel from './../getNumberLabel';
import getStatus from './../getStatus';
import getRestOfPayment from '../getRestOfPayment';
import { transformDateFormat } from '../../../functions/formatDateTime';
import { MQ_BREAKPOINTS } from '../../../constants/breakpoints';

interface Props {
    type: string;
    handlePayment?: Function;
    t: Function;
    cardData: ObjectKeys;
    handleUpdatePayment?: Function;
    handleDeletePayment?: Function;
}

const PaymentDropdown: FunctionComponent<Props> = ({
    cardData,
    t,
    handlePayment,
    type,
    handleUpdatePayment,
    handleDeletePayment,
}) => {
    const { currentLangName } = useLanguageStore();

    return (
        <PaymentDropdownWrapper>
            {cardData?.payments?.data?.map((payment: ObjectKeys, index: number) => (
                <PaymentCard key={payment?.id}>
                    <div>{index + 1}</div>
                    <div className="number-col">
                        <div className="label">{getNumberLabel(currentLangName, type, t)}</div>
                        <div className="number">
                            {cardData?.invoice_number
                                ? cardData?.invoice_number
                                : getStatus(cardData?.status?.toLowerCase(), t)}
                        </div>
                    </div>
                    <div className="date-col">
                        <div className="label">{t('pages.invoiceCard.paymentCard.invoiceDate').text}</div>
                        <div className="date">{transformDateFormat(cardData?.invoice_date)}</div>
                    </div>
                    <div className="date-col">
                        <div className="label">{t('pages.invoiceCard.paymentCard.paymentDate').text}</div>
                        <div className="date">{transformDateFormat(payment?.paid_date)}</div>
                    </div>
                    <div className="amount-col">
                        <div className="label">{t('pages.invoiceCard.paymentCard.paidAmount').text}</div>
                        <div className="amount">
                            {formatNumber(payment?.value, 4, 3, '.', ',') + ' ' + cardData?.currency}
                        </div>
                    </div>
                    <PaymentAction>
                        <FontAwesomeIcon
                            icon={faPenToSquare}
                            style={{ color: colors.gray, marginRight: '10px' }}
                            className="cursor-pointer"
                            onClick={() => handleUpdatePayment && handleUpdatePayment(payment?.id)}
                        />
                        <FontAwesomeIcon
                            icon={faTrashCan}
                            style={{ color: colors.danger }}
                            className="cursor-pointer"
                            onClick={() => handleDeletePayment && handleDeletePayment(payment?.id)}
                        />
                    </PaymentAction>
                </PaymentCard>
            ))}
            <NotPaidCard>
                <div>
                    <p>{t('pages.invoiceCard.paymentCard.receivables').text}</p>
                </div>
                <div className="amount-action">
                    <div className="amount-col">
                        <div className="label">{t('pages.invoiceCard.paymentCard.totalClaims').text}</div>
                        <div className="amount">{getRestOfPayment(cardData) + ' ' + cardData?.currency}</div>
                    </div>
                    <FontAwesomeIcon
                        icon={faPenToSquare}
                        style={{ color: colors.blue }}
                        className="cursor-pointer"
                        onClick={(e) => handlePayment && handlePayment(e)}
                    />
                </div>
            </NotPaidCard>
        </PaymentDropdownWrapper>
    );
};
const PaymentDropdownWrapper = styled.div`
    width: 100%;
    .label {
        text-transform: capitalize;
        font-size: 12px;
        color: var(--gray);
        line-height: 25px;
    }
`;
const PaymentCard = styled.div`
    background: #dcf1e4;
    grid-template-columns: 1fr 2fr 2fr 2fr 2fr 1fr;
    display: grid;
    align-items: center;
    border-radius: 5px;
    padding: 10px;
    margin: 10px 0;
    &:hover {
        box-shadow: 0 0 5px 5px #eee;
    }
    @media screen and (max-width: ${MQ_BREAKPOINTS.tablet}) {
        grid-template-columns: 2fr 2fr;
    }
`;
const PaymentAction = styled.div`
    display: flex;
    justify-content: flex-end;
`;
const NotPaidCard = styled.div`
    background-color: #ffdedc;
    padding: 10px;
    border-radius: 5px;
    grid-template-columns: 9fr 3fr;
    display: grid;
    align-items: center;
    .amount-action {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
    &:hover {
        box-shadow: 0 0 5px 5px #eee;
    }
    @media screen and (max-width: ${MQ_BREAKPOINTS.mobileL}) {
        grid-template-columns: 2fr 2fr;
    }
`;
export default PaymentDropdown;
