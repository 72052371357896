import useTranslations from './useTranslation';
import useLanguageStore from '../store/language';

const useNumLengthValidation = (
    number: string,
    numLength?: number,
    notRequired?: boolean,
    noNegative?: boolean,
): string => {
    const { currentLang } = useLanguageStore();
    const t = useTranslations(currentLang);

    if (notRequired) {
        if (!number || number.length === 0) {
            return '';
        }
    }
    const regex = new RegExp(`.{${numLength},}`);

    const isNumValid = regex.test(number);

    if (noNegative && Number(number) < 0) return t('navbar.nbs.numberField').text;

    if (!isNumValid) {
        if (numLength === 2) {
            return t('pages.validationMessages.twoNumbers').text;
        } else if (numLength === 3) {
            return t('pages.validationMessages.threeNumbers').text;
        } else if (numLength === 5) {
            return t('pages.validationMessages.fiveNumbers').text;
        } else if (numLength === 8) {
            return t('pages.validationMessages.eightNumbers').text;
        } else if (numLength === 13) {
            return t('pages.validationMessages.thirteenNumbers').text;
        } else if (numLength === 19) {
            return t('pages.validationMessages.nineteenNumbers').text;
        }
    }
    return '';
};
export default useNumLengthValidation;
