import fetch from './fetch';
import routes from './routes';
import { ObjectKeys } from '../types/objectKeys';
import getCurrentLanguage from '../functions/getCurrentLanguage';

export default class PaymentGateway {
    public createCart(cart: ObjectKeys): ObjectKeys {
        return fetch({
            method: 'POST',
            headers: { 'Content-Type': 'application/json; charset=UTF-8', 'Accept-Language': getCurrentLanguage() },
            url: routes.paymentGateway.createCart,
            data: cart,
        });
    }
    public getAdminCarts(params: ObjectKeys): ObjectKeys {
        return fetch({
            method: 'GET',
            headers: { 'Content-Type': 'application/json; charset=UTF-8', 'Accept-Language': getCurrentLanguage() },
            url: routes.paymentGateway.getCarts,
            params: params,
        });
    }
    public getUserCarts(params: ObjectKeys): ObjectKeys {
        return fetch({
            method: 'GET',
            headers: { 'Content-Type': 'application/json; charset=UTF-8', 'Accept-Language': getCurrentLanguage() },
            url: routes.paymentGateway.getUserCarts,
            params: params,
        });
    }
    public getUserCartById(id: string): ObjectKeys {
        return fetch({
            method: 'GET',
            headers: { 'Content-Type': 'application/json; charset=UTF-8', 'Accept-Language': getCurrentLanguage() },
            url: routes.paymentGateway.getUserCartById(id),
        });
    }
    public getUserPaymentHistory(id: string): ObjectKeys {
        return fetch({
            method: 'GET',
            headers: { 'Content-Type': 'application/json; charset=UTF-8', 'Accept-Language': getCurrentLanguage() },
            url: routes.paymentGateway.getUserPaymentHistory(id),
        });
    }
    public getActivePackagePeriod(): ObjectKeys {
        return fetch({
            method: 'GET',
            headers: { 'Content-Type': 'application/json; charset=UTF-8', 'Accept-Language': getCurrentLanguage() },
            url: routes.paymentGateway.getActivePackagePeriod,
        });
    }
    public getCartById(id: string): ObjectKeys {
        return fetch({
            method: 'GET',
            headers: { 'Content-Type': 'application/json; charset=UTF-8', 'Accept-Language': getCurrentLanguage() },
            url: routes.paymentGateway.getCartById(id),
        });
    }
    public getItems(params: ObjectKeys): ObjectKeys {
        return fetch({
            method: 'GET',
            headers: { 'Content-Type': 'application/json; charset=UTF-8', 'Accept-Language': getCurrentLanguage() },
            url: routes.paymentGateway.getItems,
            params: params,
        });
    }
    public getSignature(amount: string, cart_id: string): ObjectKeys {
        return fetch({
            method: 'POST',
            headers: { 'Content-Type': 'application/json; charset=UTF-8', 'Accept-Language': getCurrentLanguage() },
            url: routes.paymentGateway.getSignature,
            data: { amount: amount, cart_id: cart_id },
        });
    }
    public validateSignature(validation: ObjectKeys): ObjectKeys {
        return fetch({
            method: 'POST',
            headers: { 'Content-Type': 'application/json; charset=UTF-8', 'Accept-Language': getCurrentLanguage() },
            url: routes.paymentGateway.validateSignature,
            data: validation,
        });
    }
    public sendPaymentResponse(data: ObjectKeys): ObjectKeys {
        return fetch({
            method: 'POST',
            headers: { 'Content-Type': 'application/json; charset=UTF-8', 'Accept-Language': getCurrentLanguage() },
            url: routes.paymentGateway.paymentResponse,
            data: data,
        });
    }
    public payViaToken(): ObjectKeys {
        return fetch({
            method: 'POST',
            headers: { 'Content-Type': 'application/json; charset=UTF-8', 'Accept-Language': getCurrentLanguage() },
            url: routes.paymentGateway.payViaToken,
        });
    }
}
