import { FunctionComponent, useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark, faPaperclip, faCheckSquare, faSquare } from '@fortawesome/free-solid-svg-icons';
import styled from 'styled-components';
import Button, { ButtonVariant } from '../../../../../components/button';
import colors from '../../../../../global/colors';
import Input, { InputComponentType } from '../../../../../components/input';
import communication from '../../../../../communication';
import Checkbox from '../../../../../components/checkbox';
import { SendDocumentFormData } from '../../../../../types/communication/document';
import useAgencyStore from '../../../../../store/agency';
import useSendMeEmail from '../../../../../hooks/useSendMeEmail';
import useEmailValidation from '../../../../pausal/common/useEmailValidation';
import { ObjectKeys } from '@/types/objectKeys';
import getCurrentLanguage from '../../../../../functions/getCurrentLanguage';
import TextArea from '../../../../../components/input/textarea';

interface Props {
    t: Function;
    formData: SendDocumentFormData;
    setFormData: Function;
    close: Function;
    success: Function;
    loading: Function;
    modalVisible: boolean;
    id?: string;
    memorandum: ObjectKeys;
    handleSaveMemorandum?: Function;
    memoId?: string;
}

const SendMemoDocumentModal: FunctionComponent<Props> = ({
    t,
    formData,
    setFormData,
    close,
    success,
    loading,
    memorandum,
    handleSaveMemorandum,
    memoId,
    modalVisible,
}) => {
    const [showError, setShowError] = useState(false);
    const [showEmails, setShowEmails] = useState(false);
    const [emailsChanged, setEmailsChanged] = useState(false);
    const [emails, setEmails] = useState(Array<string>);
    const [sendMe, setSendMe] = useState(false);
    const [userEmail, setUserEmail] = useState('');

    const profile = useAgencyStore((props) => props.agency.user.data);

    const isEmailValid = useEmailValidation(formData.recipient, emails);
    const [fromInput, setFromInput] = useState(false);

    const currentLang = getCurrentLanguage();

    const [lang, setLang] = useState(currentLang);

    const defaultMessageSrpski = `Poštovani,\n\nU prilogu vam dostavljam ${memorandum?.heading}.\n\nUkoliko imate dodatnih pitanja, stojim na raspolaganju.\n\nLep pozdrav,\n\n${profile.first_name} ${profile.last_name}`;
    const defaultMessageEnglish = `Dear,\n\nPlease find attached ${memorandum?.heading}.\n\nIf you have any further questions, feel free to reach out.\n\nBest regards,\n\n${profile.first_name} ${profile.last_name}`;
    const defaultMessageRuski = `Уважаемый,\n\nВ приложении направляю вам ${memorandum?.heading}.\n\nЕсли у вас возникнут дополнительные вопросы, я всегда на связи.\n\nС уважением,\n\n${profile.first_name} ${profile.last_name}`;

    useEffect(() => {
        if (lang === 'rs') {
            setFormData({
                recipient: '',
                subject: memorandum?.heading ? memorandum.heading : '',
                message: defaultMessageSrpski,
            });
        } else if (lang === 'en') {
            setFormData({
                recipient: '',
                subject: memorandum?.heading ? memorandum.heading : '',
                message: defaultMessageEnglish,
            });
        } else if (lang === 'ru') {
            setFormData({
                recipient: '',
                subject: memorandum?.heading ? memorandum.heading : '',
                message: defaultMessageRuski,
            });
        }
    }, [modalVisible, memorandum, lang]);

    const send = async (): Promise<void> => {
        if (isEmailValid !== '' || emails.length < 1) {
            setShowError(true);
            return;
        }

        loading();

        try {
            const memo_id = handleSaveMemorandum ? (await handleSaveMemorandum()).id : memoId;

            if (memo_id) {
                const res = await communication.sendMemorandum(memo_id, emails, formData.message, formData.subject);
                if (res.status === 200 || res.status === 204) {
                    success();
                    closeModal();
                } else {
                    setShowError(true);
                }
            } else {
                setShowError(true);
            }
        } catch (error) {
            setShowError(true);
        }
    };

    const handleRemoveEmail = (e: string): void => {
        const reducedEmails = emails;
        const index = reducedEmails.indexOf(e);

        if (index > -1) {
            reducedEmails.splice(index, 1);
        }
        setEmails(reducedEmails);
        setEmailsChanged(!emailsChanged);
    };

    const closeModal = (): void => {
        setFormData({
            recipient: '',
            subject: '',
            message: '',
        });
        close();
    };

    const handleLangChange = (language: string): void => {
        setLang(language);
    };

    useSendMeEmail(
        setUserEmail,
        setShowEmails,
        setEmails,
        setEmailsChanged,
        handleRemoveEmail,
        profile,
        sendMe,
        emailsChanged,
        userEmail,
        emails,
        fromInput,
        setFromInput,
    );

    return (
        <>
            <Container>
                <Header>
                    <h2>{t('pages.memos.sendMailTitle').text}</h2>
                    <Icon onClick={() => closeModal()}>
                        <FontAwesomeIcon icon={faXmark} style={{ color: 'var(--gray)' }} />
                    </Icon>
                </Header>

                <label>{t('pages.agency.documents.sendModal.recipient').text}</label>
                <div className="emails">
                    {showEmails &&
                        emails.map((e) => (
                            <Email key={e}>
                                {e}
                                <span onClick={() => handleRemoveEmail(e)}>x</span>
                            </Email>
                        ))}
                </div>
                <Input
                    className="email"
                    type={InputComponentType.Text}
                    value={formData.recipient ? formData.recipient : ''}
                    onChange={(value: string) => {
                        setFormData({ ...formData, recipient: value });
                    }}
                    onEnter={() => {
                        if (formData.recipient !== '' && isEmailValid === '') {
                            setShowEmails(true);
                            setEmails([...emails, formData.recipient]);
                            setFormData({ ...formData, recipient: '' });
                        }
                    }}
                    validation={showError ? isEmailValid : ''}
                    handleBlurAction={() => {
                        setShowError(true);
                        if (emails.includes(formData.recipient)) {
                            setFormData({ ...formData, recipient: '' });
                            return;
                        }
                        if (formData.recipient !== '' && isEmailValid === '') {
                            setFromInput(true);
                            setShowEmails(true);
                            setEmails([...emails, formData.recipient]);
                            setFormData({ ...formData, recipient: '' });
                        }
                    }}
                    hideBlurOnChange
                />

                <Input
                    type={InputComponentType.Text}
                    label={t('pages.agency.documents.sendModal.subject').text}
                    value={formData.subject ? formData.subject : ''}
                    blurHandler={() => {
                        if (formData.recipient !== '') {
                            setShowEmails(true);
                            setEmails([...emails, formData.recipient]);
                            setFormData({ ...formData, recipient: '' });
                        }
                    }}
                    hideBlurOnChange
                    onChange={(value: string) => {
                        setFormData({ ...formData, subject: value });
                    }}
                />
                <TextArea
                    label={t('pages.agency.documents.sendModal.message').text}
                    value={formData.message ? formData.message : ''}
                    onChange={(value: string) => {
                        setFormData({ ...formData, message: value });
                    }}
                    blurHandler={() => {
                        if (formData.recipient !== '') {
                            setShowEmails(true);
                            setEmails([...emails, formData.recipient]);
                            setFormData({ ...formData, recipient: '' });
                        }
                    }}
                    height={'450px'}
                />
                <LangSelector>
                    <p>Izaberi jezik:</p>
                    <div
                        onClick={() => {
                            handleLangChange('rs');
                        }}
                        className="checkbox"
                    >
                        <FontAwesomeIcon icon={lang === 'rs' ? faCheckSquare : faSquare} className="check-icon" />
                        Srpski
                    </div>
                    <div
                        onClick={() => {
                            handleLangChange('en');
                        }}
                        className="checkbox"
                    >
                        <FontAwesomeIcon icon={lang === 'en' ? faCheckSquare : faSquare} className="check-icon" />
                        Engleski
                    </div>
                    <div
                        onClick={() => {
                            handleLangChange('ru');
                        }}
                        className="checkbox"
                    >
                        <FontAwesomeIcon icon={lang === 'ru' ? faCheckSquare : faSquare} className="check-icon" />
                        Ruski
                    </div>
                </LangSelector>
                <Footer>
                    <Icon className="attach">
                        <FontAwesomeIcon icon={faPaperclip} style={{ color: 'var(--gray)', marginRight: '3px' }} />
                        {t('pages.memos.pdfAttached').text}
                    </Icon>
                    <div className="send-copy">
                        <Checkbox
                            onChange={() => {
                                setSendMe(!sendMe);
                                setFromInput(false);
                            }}
                        />
                        {t('pages.agency.documents.sendModal.sendMeCopy').text}
                    </div>
                </Footer>
                <div className="button-container">
                    <Button
                        variant={ButtonVariant.solid}
                        color={colors.purple}
                        className="small"
                        size={200}
                        onClick={send}
                    >
                        {t('pages.agency.documents.labels.send').text}
                    </Button>
                </div>
            </Container>
        </>
    );
};

export default SendMemoDocumentModal;
const Container = styled.div`
    .button-container {
        margin-top: 30px;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .input-container {
        width: 100%;
    }
    label {
        color: var(--gray);
    }
    .emails {
        margin-top: 10px;
    }

    textarea {
        font-family: inherit;
        background: transparent;
        font-size: 16px;
        line-height: 24px;
        letter-spacing: -0.3px;
        border-radius: 2px;
        padding: 11px;
        border: none;
        border-bottom: 1px solid var(--border-color);
        height: 350px;
        padding: 5px;
        &:focus {
            outline: none;
            border-bottom: 1px solid var(--purple);
        }
        &::placeholder {
            color: var(--gray);
            font-weight: 400;
        }
    }
`;

export const Email = styled.div`
    display: inline-block;
    padding: 5px 10px;
    text-decoration: none;
    background: var(--purple);
    color: var(--white);
    margin-bottom: 6px;
    margin-right: 6px;
    font-size: 14px;
    span {
        margin-left: 10px;
        cursor: pointer;
    }
`;
const Header = styled.div`
    display: grid;
    grid-template-columns: 95% 5%;
    h2 {
        font-size: 24px;
        color: var(--gray);
        font-weight: 400;
        text-align: left;
    }
`;

const Footer = styled.div`
    margin-top: 20px;
    font-size: 13px;
    color: var(--gray);
    display: flow-root;
    .send-copy {
        float: right;
        display: flex;
    }
`;

const Icon = styled.div`
    float: right;
    font-size: 30px;
    margin-top: -8px;
    &.attach {
        font-size: 13px;
        color: var(--gray);
        float: left;
        margin-top: 0px;
    }
`;
const LangSelector = styled.div`
    display: flex;
    flex-direction: column;
    align-items: left;
    .flex {
        display: flex;
        margin-right: 20px;
    }
    p {
        margin-bottom: 10px;
        font-size: 14px;
        color: var(--gray);
    }
    .checkbox {
        font-size: 16px;
        display: flex;
        gap: 10px;
        align-items: center;
        cursor: pointer;
        .check-icon {
            color: var(--purple);
            border-radius: 5px;
            font-size: 18px;
        }
    }
`;
