import fetch from '../fetch';
import routes from '../routes';
import { ObjectKeys } from '../../types/objectKeys';
import getCurrentLanguage from '../../functions/getCurrentLanguage';

export default class RequestsAdmin {
    public getCartsAdmin(params: ObjectKeys): ObjectKeys {
        return fetch({
            method: 'GET',
            headers: { 'Content-Type': 'application/json; charset=UTF-8', 'Accept-Language': getCurrentLanguage() },
            url: routes.admin.requests.cartsWithoutPagination,
            params: params,
        });
    }
    public getServicesAdmin(params: ObjectKeys): ObjectKeys {
        return fetch({
            method: 'GET',
            headers: { 'Content-Type': 'application/json; charset=UTF-8', 'Accept-Language': getCurrentLanguage() },
            url: routes.admin.requests.servicesWithoutPagination,
            params: params,
        });
    }
    public updateClickUp(id: string): ObjectKeys {
        return fetch({
            method: 'PUT',
            headers: { 'Content-Type': 'application/json; charset=UTF-8', 'Accept-Language': getCurrentLanguage() },
            url: routes.admin.requests.updateClickUp(id),
            data: { read: true },
        });
    }
    public downloadPdfCarts(params: ObjectKeys): ObjectKeys {
        return fetch({
            method: 'GET',
            headers: { 'Content-Type': 'application/json; charset=UTF-8', 'Accept-Language': getCurrentLanguage() },
            responseType: 'blob',
            url: routes.admin.requests.cartsPdf,
            params: params,
        });
    }
    public downloadPdfServices(params: ObjectKeys): ObjectKeys {
        return fetch({
            method: 'GET',
            headers: { 'Content-Type': 'application/json; charset=UTF-8', 'Accept-Language': getCurrentLanguage() },
            responseType: 'blob',
            url: routes.admin.requests.servicesPdf,
            params: params,
        });
    }
    public resendFiscalBill(id: string): ObjectKeys {
        return fetch({
            method: 'POST',
            headers: { 'Content-Type': 'application/json; charset=UTF-8', 'Accept-Language': getCurrentLanguage() },
            url: routes.admin.requests.resendFiscalBill,
            data: { cart_id: id },
        });
    }
}
