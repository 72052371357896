import fetch from './fetch';
import routes from './routes';
import { ObjectKeys } from '../types/objectKeys';
// import download from './download';
import getCurrentLanguage from '../functions/getCurrentLanguage';

interface FilterProps {
    start_date: string;
    end_date: string;
    type: string;
    client_id?: string;
    currency?: string;
}

export default class Currency {
    public getAllReports(page: number, props: FilterProps): ObjectKeys {
        return fetch({
            method: 'POST',
            headers: { 'Content-Type': 'application/json; charset=UTF-8', 'Accept-Language': getCurrentLanguage() },
            url: routes.reports.getAllReports(page),
            data: props,
        });
    }

    public getReports(props: ObjectKeys): ObjectKeys {
        return fetch({
            method: 'POST',
            headers: { 'Content-Type': 'application/json; charset=UTF-8', 'Accept-Language': getCurrentLanguage() },
            url: routes.reports.getReports(),
            data: props,
        });
    }

    public getDoughnut(props: ObjectKeys): ObjectKeys {
        return fetch({
            method: 'POST',
            headers: { 'Content-Type': 'application/json; charset=UTF-8', 'Accept-Language': getCurrentLanguage() },
            url: routes.reports.getDoughnut(),
            data: props,
        });
    }

    public getTop5(props: ObjectKeys): ObjectKeys {
        return fetch({
            method: 'POST',
            headers: { 'Content-Type': 'application/json; charset=UTF-8', 'Accept-Language': getCurrentLanguage() },
            url: routes.reports.getTop5(),
            data: props,
        });
    }

    public getTopDebtors(props: ObjectKeys): ObjectKeys {
        return fetch({
            method: 'POST',
            headers: { 'Content-Type': 'application/json; charset=UTF-8', 'Accept-Language': getCurrentLanguage() },
            url: routes.reports.getTopDebtors(),
            data: props,
        });
    }

    public getAllClientsForStatistic(userId: string): ObjectKeys {
        return fetch({
            method: 'GET',
            headers: { 'Content-Type': 'application/json; charset=UTF-8', 'Accept-Language': getCurrentLanguage() },
            url: routes.reports.getAllClients(userId),
        });
    }
    public downloadPdf(token: string, filters: FilterProps): ObjectKeys | undefined {
        return fetch({
            method: 'POST',
            responseType: 'blob',
            headers: {
                'Content-Type': 'application/json, text/plain, application/pdf, */*; charset=UTF-8',
                Authorization: 'Bearer ' + token,
                'Accept-Language': getCurrentLanguage(),
            },
            url: routes.reports.downloadPdf,
            data: filters,
        });
    }
    public downloadXlsx(token: string, filters: FilterProps): ObjectKeys | undefined {
        const language =
            getCurrentLanguage() === 'en'
                ? 'en'
                : getCurrentLanguage() === 'ru'
                ? 'en'
                : getCurrentLanguage() === 'rs'
                ? 'rs'
                : 'rs';
        return fetch({
            method: 'POST',
            responseType: 'blob',
            headers: {
                'Content-Type': 'application/json; charset=UTF-8',
                Authorization: 'Bearer ' + token,
                'Accept-Language': language,
            },
            url: routes.reports.downloadXlsx,
            data: filters,
        });
    }
}
