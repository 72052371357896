import { Dispatch, FunctionComponent, SetStateAction, useState } from 'react';
import styled from 'styled-components';
import colors from '../../../../global/colors';
import Button, { ButtonVariant } from '../../../../components/button';
import communication from '../../../../communication';
import { ObjectKeys } from '../../../../types/objectKeys';
// import getAllClients from '../getAllClients';
import Loader from '../../../../components/loader';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
type Props = {
    t: Function;
    clickedService: ObjectKeys;
    closeGlobalModal: () => void;
    setClients: Dispatch<SetStateAction<ObjectKeys>>;
    tabIndex: number;
    success: Function;
};

const ArchiveModal: FunctionComponent<Props> = ({ t, clickedService, closeGlobalModal, success }) => {
    const [loaderVisible, setLoaderVisible] = useState(false);
    const handleDeleteClient = (service: ObjectKeys): void => {
        setLoaderVisible(true);
        communication
            .deleteArticle(service.id)
            .then((res: ObjectKeys) => {
                if (res) {
                    closeGlobalModal();
                    success();
                    toast.success(t('pages.listOfArticles.success').text);
                }
            })
            .catch((error: ObjectKeys) => {
                if (error) {
                    closeGlobalModal();
                    toast.error(t('pages.listOfArticles.error').text);
                }
            })
            .finally(() => {
                setLoaderVisible(false);
            });
    };
    return (
        <ArchiveModalWrapper>
            {loaderVisible && <Loader />}
            <ToastContainer />
            <ModalText>
                <h4>{t('pages.listOfArticles.areYouSure').text}</h4>
            </ModalText>
            <ButtonContainer>
                <Button
                    variant={ButtonVariant.solid}
                    color={colors.purple}
                    onClick={() => {
                        handleDeleteClient(clickedService);
                    }}
                    size={'100%'}
                >
                    {t('input.yes').text}
                </Button>
                <Button
                    variant={ButtonVariant.solid}
                    color={colors.purple}
                    onClick={() => closeGlobalModal()}
                    size={'100%'}
                >
                    {t('input.no').text}
                </Button>
            </ButtonContainer>
        </ArchiveModalWrapper>
    );
};

const ArchiveModalWrapper = styled.div`
    text-align: center;
    button {
        border-radius: 0 0 5px 5px;
    }
`;
const ButtonContainer = styled.div`
    display: flex;
    width: 50%;
    button {
        border: none;
    }
    button:first-child {
        border-radius: 0px 0px 0px 5px !important;
    }
    button:last-child {
        background-color: var(--white);
        color: var(--black);
        border-radius: 0px 0px 5px 0px !important;
        border-top: 1px solid var(--gray);
    }
`;
const ModalText = styled.div`
    padding: 35px 50px;
    text-align: center;
    h4 {
        margin-bottom: 20px;
        line-height: 1.3;
    }
    p {
        font-size: 20px;
        line-height: 1.3;
    }
`;

export default ArchiveModal;
