import { FunctionComponent, useEffect, useState } from 'react';
import styled from 'styled-components';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Package from './packageCard';
import Sidebar from '../../../components/sideModal';
import EditModal from './editModal';
import CreateModal from './createModal';
import { ObjectKeys } from '@/types/objectKeys';
import Loader from '../../../components/loader';
import useLanguageStore from '../../../store/language';
import useTranslations from '../../../hooks/useTranslation';
import Button, { ButtonVariant } from '../../../components/button';
// import colors from '../../../global/colors';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import colors from '../../../global/colors';
import communication from '../../../communication';
import Modal from '../../../components/modal';
import ConfirmModal from '../../../components/confirmModal';
import { usePasswordProtect } from '../../../hooks/passwordProtect';

const Packages: FunctionComponent = () => {
    usePasswordProtect(['/admin/packages']);
    const { currentLang } = useLanguageStore();
    const t = useTranslations(currentLang);
    const [packages, setPackages] = useState<ObjectKeys[]>([]);
    const [editModal, setEditModal] = useState(false);
    const [createModal, setCreateModal] = useState(false);
    const [selectedPackage, setSelectedPackage] = useState<ObjectKeys>({});
    const [loader, setLoader] = useState<boolean>(false);
    const [deleteModal, setDeleteModal] = useState<boolean>(false);
    const [activateModal, setActivateModal] = useState<boolean>(false);
    const [deactivateModal, setDeactivateModal] = useState<boolean>(false);

    const handleSelectedPackage = (item: ObjectKeys): void => {
        setSelectedPackage(item);
    };

    const getAllPackages = (): void => {
        setLoader(true);
        communication
            .getAllPackages()
            .then((response: ObjectKeys) => {
                setPackages(response.data.data);
                setLoader(false);
            })
            .catch((error: any) => {
                setLoader(false);
                console.error('Error:', error);
            });
    };

    const onDelete = (): void => {
        communication
            .deletePackage(selectedPackage.id)
            .then(() => {
                getAllPackages();
                toast.success('Paket uspešno obrisan');
            })
            .catch((error: any) => {
                console.error('Error:', error);
                toast.error('Greska prilikom brisanja paketa');
            });
    };

    const onActivate = (): void => {
        communication
            .activatePackage(selectedPackage.id, true)
            .then(() => {
                getAllPackages();
                toast.success('Paket uspešno aktiviran');
            })
            .catch((error: any) => {
                console.error('Error:', error);
                toast.error('Greska prilikom aktiviranja paketa');
            });
    };

    const onDeactivate = (): void => {
        communication
            .activatePackage(selectedPackage.id, false)
            .then(() => {
                getAllPackages();
                toast.success('Paket uspešno deaktiviran');
            })
            .catch((error: any) => {
                console.error('Error:', error);
                toast.error('Greska prilikom deaktiviranja paketa');
            });
    };

    useEffect(() => {
        getAllPackages();
    }, []);

    return (
        <>
            {loader && <Loader />}
            <ToastContainer />
            {deleteModal && (
                <Modal
                    width="500px"
                    className="package-modal"
                    modalVisible={true}
                    closeModal={() => {
                        setDeleteModal(false);
                    }}
                >
                    <ConfirmModal
                        action={() => {
                            setDeleteModal(false);
                            onDelete();
                            // Update services
                        }}
                        close={() => {
                            setDeleteModal(false);
                        }}
                        active={deleteModal}
                        message={'Da li ste sigurni da zelite da obrisete paket?'}
                    />
                </Modal>
            )}
            {activateModal && (
                <Modal
                    width="500px"
                    className="package-modal"
                    modalVisible={true}
                    closeModal={() => {
                        setActivateModal(false);
                    }}
                >
                    <ConfirmModal
                        action={() => {
                            setActivateModal(false);
                            onActivate();
                        }}
                        close={() => {
                            setActivateModal(false);
                        }}
                        active={activateModal}
                        message={'Da li ste sigurni da zelite da aktivirate paket?'}
                    />
                </Modal>
            )}
            {deactivateModal && (
                <Modal
                    width="500px"
                    className="package-modal"
                    modalVisible={true}
                    closeModal={() => {
                        setDeactivateModal(false);
                    }}
                >
                    <ConfirmModal
                        action={() => {
                            setDeactivateModal(false);
                            onDeactivate();
                        }}
                        close={() => {
                            setDeactivateModal(false);
                        }}
                        active={deactivateModal}
                        message={'Da li ste sigurni da zelite da deaktivirate paket?'}
                    />
                </Modal>
            )}
            {editModal && (
                <Sidebar close={() => setEditModal(false)}>
                    <EditModal
                        close={() => setEditModal(false)}
                        formData={selectedPackage}
                        t={t}
                        id={selectedPackage.id}
                        success={() => {
                            getAllPackages();
                        }}
                        loading={(e: boolean) => setLoader(e)}
                        errorMessage={(mess: string) => {
                            console.error(mess);
                        }}
                        modalVisible={editModal}
                    />
                </Sidebar>
            )}
            {createModal && (
                <Sidebar close={() => setCreateModal(false)}>
                    <CreateModal
                        close={() => setCreateModal(false)}
                        t={t}
                        success={() => {
                            getAllPackages();
                        }}
                        loading={(e: boolean) => setLoader(e)}
                        errorMessage={(mess: string) => {
                            console.error(mess);
                        }}
                        modalVisible={createModal}
                    />
                </Sidebar>
            )}
            <Header>
                <h1>Paketi plaćanja</h1>
            </Header>
            <Create>
                <ButtonContainer>
                    <Button
                        variant={ButtonVariant.solid}
                        color={colors.purple}
                        size={'20%'}
                        onClick={() => setCreateModal(true)}
                    >
                        <FontAwesomeIcon icon={faPlus} className="fa-icon-create" />
                        Dodaj paket
                    </Button>
                </ButtonContainer>
            </Create>
            <Main>
                {packages.length > 0 ? (
                    <>
                        {packages.map((packageItem) => (
                            <Package
                                key={packageItem.id}
                                name={packageItem.name}
                                monthlyPrice={packageItem.monthly}
                                half_year_discount={packageItem.half_year_discount}
                                year_discount={packageItem.year_discount}
                                services_discount={packageItem.services_discount}
                                services={packageItem.services.data}
                                six_months={packageItem.months}
                                active={packageItem.active}
                                onEdit={() => {
                                    handleSelectedPackage(packageItem);
                                    setEditModal(true);
                                }}
                                onDelete={() => {
                                    setSelectedPackage(packageItem);
                                    setDeleteModal(true);
                                }}
                                onActivate={() => {
                                    setSelectedPackage(packageItem);
                                    if (packageItem.active) {
                                        setDeactivateModal(true);
                                    } else {
                                        setActivateModal(true);
                                    }
                                }}
                            />
                        ))}
                    </>
                ) : (
                    <p>Nema paketa</p>
                )}
            </Main>
        </>
    );
};

const Header = styled.div`
    border-bottom: 1px solid var(--border-color);
    padding-bottom: 20px;
    margin-bottom: 20px;
`;

const Create = styled.div`
    margin-bottom: 20px;
    max-width: 80%;
    .fa-icon-create {
        margin-right: 10px;
    }
`;

const Main = styled.div`
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    gap: 20px;
`;
const ButtonContainer = styled.div`
    display: flex;
    justify-content: space-between;
    gap: 20px;
`;

export default Packages;
