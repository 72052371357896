import React from 'react';
import styled, { keyframes } from 'styled-components';
import { MQ_BREAKPOINTS } from '../../../../constants/breakpoints';

const SingleItemSkeleton: React.FC = () => {
    return (
        <Wrapper>
            <TopSection>
                <SkeletonTitle />
            </TopSection>
            <BottomSection>
                <SkeletonValue />
                <SkeletonRestToLimit />
            </BottomSection>
        </Wrapper>
    );
};

const loadingAnimation = keyframes`
    0% {
        background-position: -200px 0;
    }
    100% {
        background-position: calc(200px + 100%) 0;
    }
`;

const Wrapper = styled.div`
    width: 45%;
    height: 150px;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    cursor: default; // No pointer cursor for skeleton
    @media only screen and (max-width: ${MQ_BREAKPOINTS.tablet}) {
        width: 100%;
    }
`;

const TopSection = styled.span`
    width: 100%;
    height: 30%;
    background-color: var(--purple);
    display: flex;
    align-items: center;
    justify-content: center;
`;

const SkeletonTitle = styled.div`
    width: 80%;
    height: 1.2em;
    background: linear-gradient(90deg, #e6dfff 25%, #cebcff 50%, #b6a0ff 75%);
    background-size: 200% 100%;
    animation: ${loadingAnimation} 1.5s infinite;
    border-radius: 4px;
`;

const BottomSection = styled.div`
    width: 100%;
    height: 67%;
    display: flex;
    align-items: center;
    justify-content: space-around;
    border: 1px solid var(--purple);
    flex-direction: column;
`;

const SkeletonValue = styled.div`
    height: 30px;
    width: 50%;
    background: linear-gradient(90deg, #e6dfff 25%, #cebcff 50%, #b6a0ff 75%);
    background-size: 200% 100%;
    animation: ${loadingAnimation} 1.5s infinite;
    border-radius: 4px;
`;

const SkeletonRestToLimit = styled.span`
    height: 15px;
    width: 70%;
    background: linear-gradient(90deg, #e6dfff 25%, #cebcff 50%, #b6a0ff 75%);
    background-size: 200% 100%;
    animation: ${loadingAnimation} 1.5s infinite;
    border-radius: 4px;
`;

export default SingleItemSkeleton;
