const getNumberLabel = (currentLangName: string, type: string, t: Function): any => {
    if (currentLangName !== 'English') {
        switch (type) {
            case 'invoice':
                return (
                    t('pages.invoiceCard.infoWrapper.number').text +
                    ' ' +
                    t('pages.invoiceCard.infoWrapper.invoice').text +
                    ':'
                );
            case 'advanceInvoice':
                return (
                    t('pages.invoiceCard.infoWrapper.number').text +
                    ' ' +
                    t('pages.invoiceCard.infoWrapper.prepaid').text +
                    ':'
                );
            case 'proforma':
                return (
                    t('pages.invoiceCard.infoWrapper.number').text +
                    ' ' +
                    t('pages.invoiceCard.infoWrapper.proforma').text +
                    ':'
                );
            default:
                return (
                    t('pages.invoiceCard.infoWrapper.number').text +
                    ' ' +
                    t('pages.invoiceCard.infoWrapper.invoice').text +
                    ':'
                );
        }
    } else {
        switch (type) {
            case 'invoice':
                return (
                    t('pages.invoiceCard.infoWrapper.invoice').text +
                    ' ' +
                    t('pages.invoiceCard.infoWrapper.number').text +
                    ':'
                );
            case 'advanceInvoice':
                return (
                    t('pages.invoiceCard.infoWrapper.prepaid').text +
                    ' ' +
                    t('pages.invoiceCard.infoWrapper.number').text +
                    ':'
                );
            case 'proforma':
                return (
                    t('pages.invoiceCard.infoWrapper.proforma').text +
                    ' ' +
                    t('pages.invoiceCard.infoWrapper.number').text +
                    ':'
                );
            default:
                return (
                    t('pages.invoiceCard.infoWrapper.invoice').text +
                    ' ' +
                    t('pages.invoiceCard.infoWrapper.number').text +
                    ':'
                );
        }
    }
};
export default getNumberLabel;
