export function transformDateFormat(dateString: string): string {
    const date = new Date(dateString);
    const day = date.getUTCDate();
    const month = date.getUTCMonth() + 1; // Months are zero-based, so we add 1
    const year = date.getUTCFullYear();

    // Ensure leading zeros for single-digit day and month
    const formattedDay = day < 10 ? '0' + day : day;
    const formattedMonth = month < 10 ? '0' + month : month;

    // Return the transformed date in the desired format
    return `${formattedDay}.${formattedMonth}.${year}.`;
}
