import communication from '../../../communication';
import { ObjectKeys } from '../../../types/objectKeys';
const getAllClients = (setClients: any, userId: string, tabIndex: number, is_foreign?: boolean): void => {
    if (is_foreign) {
        communication
            .getClients(userId, {
                limit: '10',
                page: 1,
                searchJoin: 'and',
                search: 'is_foreign:1',
            })
            .then((res: ObjectKeys) => {
                if (res.status === 200) {
                    setClients(res?.data);
                }
            });
    } else if (tabIndex === 2) {
        communication
            .getArchivedClients({
                limit: '10',
                page: 1,
                search: '',
                searchJoin: 'and',
            })
            .then((res: ObjectKeys) => {
                if (res.status === 200) {
                    setClients(res?.data);
                }
            });
    } else if (tabIndex === 0) {
        communication
            .getClients(userId, {
                limit: '10',
                page: 1,
                searchJoin: 'and',
                search: 'is_foreign:0',
            })
            .then((res: ObjectKeys) => {
                if (res.status === 200) {
                    setClients(res?.data);
                }
            });
    } else if (tabIndex === 1) {
        communication
            .getClients(userId, {
                limit: '10',
                page: 1,
                searchJoin: 'and',
                search: 'is_foreign:1',
            })
            .then((res: ObjectKeys) => {
                if (res.status === 200) {
                    setClients(res?.data);
                }
            });
    }
};
export default getAllClients;
