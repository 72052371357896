import { create } from 'zustand';
import { ObjectKeys } from '../types/objectKeys';

interface InvoiceParamsState {
    invoices_params: ObjectKeys;
    advances_params: ObjectKeys;
    eadvances_params: ObjectKeys;
    einvoices_params: ObjectKeys;
}

const useInvoiceParamsStore = create<InvoiceParamsState>(() => ({
    invoices_params: {},
    advances_params: {},
    eadvances_params: {},
    einvoices_params: {},
}));

export default useInvoiceParamsStore;
