import { FunctionComponent, Dispatch, SetStateAction, useState } from 'react';
import styled from 'styled-components';
import { ObjectKeys } from '../../../../../types/objectKeys';
import useTranslations from '../../../../../hooks/useTranslation';
import useLanguageStore from '../../../../../store/language';
import SelectComponent, { SelectOption } from '../../../../../components/select';
import { MQ_BREAKPOINTS } from '../../../../../constants/breakpoints';

interface Props {
    setParams: Dispatch<SetStateAction<any>>;
    params: ObjectKeys;
    years: any;
    months: SelectOption[];
}

const FiltersStatistics: FunctionComponent<Props> = ({ setParams, params, years, months }) => {
    const { currentLang } = useLanguageStore();
    const currentYear = new Date().getFullYear();
    const t = useTranslations(currentLang);
    const [start, setStart] = useState({ year: `${currentYear}`, month: '01' });
    const [end, setEnd] = useState({ year: `${currentYear}`, month: '12', days: '' });

    function daysInMonth(month: number, year: number): number {
        return new Date(year, month, 0).getDate();
    }
    return (
        <>
            {years && (
                <FiltersWrapper>
                    <TopFilters>
                        <FiltersInner>
                            <SelectComponent
                                className="select-container"
                                optionList={years?.map((year: any) => {
                                    return { value: year, label: year };
                                })}
                                label={t('pages.admin.statistics.periodFrom').text}
                                handleSelectedValue={(value: string) => {
                                    setStart({ ...start, year: value });
                                    const parsedYear = parseInt(value);
                                    const date = parsedYear + `-${start.month}-01` + ' 00:00:00';
                                    setParams({
                                        ...params,
                                        start_date: date,
                                    });
                                }}
                                defaultSelectedOption={years
                                    ?.map((year: any) => {
                                        return { value: year, label: year };
                                    })
                                    .pop()}
                            />
                            <SelectComponent
                                className="select-container"
                                optionList={months}
                                handleSelectedValue={(value: string) => {
                                    setStart({ ...start, month: value });
                                    const date = start.year + `-${value}-01` + ' 00:00:00';
                                    setParams({
                                        ...params,
                                        start_date: date,
                                    });
                                }}
                                defaultSelectedOption={months[0]}
                            />
                            <SelectComponent
                                className="select-container"
                                optionList={years?.map((year: any) => {
                                    return { value: year, label: year };
                                })}
                                label={t('pages.admin.statistics.to').text}
                                handleSelectedValue={(value: string) => {
                                    setEnd({ ...end, year: value });
                                    const parsedYear = parseInt(value);
                                    const date =
                                        parsedYear +
                                        `-${end.month}-${daysInMonth(Number(end.month), Number(value))}` +
                                        ' 23:59:59';
                                    setParams({
                                        ...params,
                                        end_date: date,
                                    });
                                }}
                                defaultSelectedOption={years
                                    ?.map((year: any) => {
                                        return { value: year, label: year };
                                    })
                                    .pop()}
                            />
                            <SelectComponent
                                className="select-container"
                                optionList={months}
                                handleSelectedValue={(v: string) => {
                                    setEnd({ ...end, month: v });
                                    const date =
                                        end.year + `-${v}-${daysInMonth(Number(v), Number(end.year))}` + ' 23:59:59';
                                    setParams({
                                        ...params,
                                        end_date: date,
                                    });
                                }}
                                defaultSelectedOption={months[0]}
                            />
                        </FiltersInner>
                    </TopFilters>
                </FiltersWrapper>
            )}
        </>
    );
};

const FiltersWrapper = styled.div`
    margin-top: 10px;
    margin-bottom: 20px;
    .select-wrapper {
        flex-direction: row;
        align-items: center;
        text-transform: uppercase;
        label {
            font-size: 14px;
            line-height: 20px;
            padding: 0;
            margin-right: 6px;
        }
        .select__control {
            background: var(--white);
            color: var(--gray);
        }
        > div {
            display: flex;
            align-items: center;
        }
    }
`;
const TopFilters = styled.div`
    display: flex;
    align-item: center;
    padding: 10px 20px;
    background: var(--purple);
    color: var(--white);
    justify-content: space-between;
    .select-wrapper {
        width: 100%;
        margin-right: 20px;
        @media screen and (max-width: ${MQ_BREAKPOINTS.tablet}) {
            margin-bottom: 10px;
        }
    }
    @media screen and (max-width: ${MQ_BREAKPOINTS.tablet}) {
        flex-direction: column;
        margin-bottom: 20px;
    }
`;
const FiltersInner = styled.div`
    display: flex;
    align-items: center;
    @media screen and (max-width: ${MQ_BREAKPOINTS.tablet}) {
        margin-bottom: 20px;
        flex-direction: column;
    }

    .select-container {
        > div {
            width: 120px;
        }
        div {
            font-size: 14px;
        }
    }
`;

export default FiltersStatistics;
