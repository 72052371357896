import React from 'react';
import styled from 'styled-components';
import cancelImg from '../../../assets/cancel.png';
import { Link } from 'react-router-dom';
import useTranslations from '../../../hooks/useTranslation';
import useLanguageStore from '../../../store/language';

// Component
const PaymentSuccess: React.FC = () => {
    const { currentLang } = useLanguageStore();
    const t = useTranslations(currentLang);
    const urlParams = new URLSearchParams(window.location.search);

    const transactionDetails = {
        cart_id: urlParams.get('ShoppingCartID') || '',
        order_id: urlParams.get('WsPayOrderId') || '',
        date_time: urlParams.get('DateTime') || '',
        approval_code: urlParams.get('ApprovalCode') || null,
        amount: urlParams.get('Amount') || '',
    };

    return (
        <Container>
            <CancelText>{t('pages.payments.cancel.title').text}</CancelText>
            <CancelImage src={cancelImg} alt="Cancel" />
            <InfoText>{t('pages.payments.cancel.description').text}</InfoText>
            {transactionDetails ? (
                <div className="params">
                    <h3>{t('pages.payments.error.details').text}</h3>
                    <div className="error">
                        <p>{t('pages.payments.error.subDetails').text}</p>
                    </div>
                    <div className="param">
                        <p>{t('pages.payments.success.order').text}:</p>
                        <p>{transactionDetails.cart_id}</p>
                    </div>
                </div>
            ) : null}
            <Link to="/dashboard">
                <span className="link">{t('pages.payments.cancel.button').text}</span>
            </Link>
        </Container>
    );
};

// Styled components
const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 10%;
    background-color: var(--light-gray);
    .link {
        font-size: 1rem;
        cursor: pointer;
    }
    .params {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: 20px;
        h3 {
            font-size: 1.5rem;
            margin-bottom: 20px;
        }
        .error {
            display: flex;
            justify-content: space-between;
            width: 100%;
            margin-bottom: 10px;
            gap: 10px;
            color: var(--danger);
            p {
                font-size: 1rem;
            }
        }
        .param {
            display: flex;
            justify-content: space-between;
            width: 100%;
            margin-bottom: 10px;
            gap: 10px;
            p {
                font-size: 1rem;
            }
        }
    }
`;

const CancelText = styled.h1`
    font-size: 2rem;
    margin-bottom: 20px;
`;

const CancelImage = styled.img`
    width: 200px;
    height: 200px;
    margin-bottom: 20px;
`;

const InfoText = styled.p`
    font-size: 1rem;
    margin-bottom: 20px;
`;

// const Link = styled.a`
//     color: blue;
//     text-decoration: underline;
//     cursor: pointer;
// `;

export default PaymentSuccess;
