import { FunctionComponent, useEffect, useState } from 'react';
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';
import styled from 'styled-components';
import SelectComponent from '../../../../components/select';
import useTranslations from '../../../../hooks/useTranslation';
import useLanguageStore from '../../../../store/language';
import Button, { ButtonVariant } from '../../../../components/button';
import colors from '../../../../global/colors';
import Input, { InputComponentType } from '../../../../components/input';
import communication from '../../../../communication';
import useAgencyStore from '../../../../store/agency';
import useProfileStore from '../../../../store/profile';
import { FileUploader } from 'react-drag-drop-files';
import { dragAndDropIcon } from '../../../../assets/base64';
import comunication from '../../../../communication';
import { ObjectKeys } from '@/types/objectKeys';
import Modal from '../../../../components/modal';
import ErrorModal from '../../../../components/errorModal';
import { useNavigate } from 'react-router-dom';
import useCharLengthValidation from '../../../../hooks/useCharLengthValidation';
import useResponsive from '../../../../hooks/responsive/useResponsive';

interface Props {
    yearsOptions: Array<{ value: any; label: any }>;
    closeModal: Function;
    setIsLoader: Function;
    setOpenInfoModal: Function;
    refresh: Function;
    year: string;
    transferOrders?: ObjectKeys;
    isEdit: boolean;
}

export interface ChangeAmountTypes {
    year: string;
    user_id: string;
    model_number_recipient: number;
    call_number_recipient: string;
    quarters: Array<{ quarter: number; amount: string }>;
}

export interface HelpWithTaxTypes {
    year: string;
}

const ManageOrdersModal: FunctionComponent<Props> = ({
    yearsOptions,
    closeModal,
    setIsLoader,
    setOpenInfoModal,
    refresh,
    year,
    isEdit,
    transferOrders,
}) => {
    const { currentLang } = useLanguageStore();
    const navigate = useNavigate();
    const t = useTranslations(currentLang);
    const [tabIndex, setTabIndex] = useState(0);
    const { isMobile } = useResponsive();
    const [selectedYear, setSelectedYear] = useState<string>(year);
    const agency = useAgencyStore((state) => state.agency);
    const fileTypes = ['pdf', 'doc', 'docx', 'xls', 'xlsx', 'jpeg', 'jpg', 'png', 'gif'];
    const [wrongTypeModal, setWrongTypeModal] = useState<boolean>(false);
    // const [loader, setLoader] = useState<boolean>(false);
    const [error, setError] = useState<boolean>(false);
    const { profile } = useProfileStore();

    const [changeAmountsData, setChangeAmountsData] = useState<ChangeAmountTypes>({
        year: year,
        user_id: profile.id,
        model_number_recipient: 97,
        call_number_recipient: '',
        quarters: [
            { quarter: 1, amount: '' },
            { quarter: 2, amount: '' },
            { quarter: 3, amount: '' },
            { quarter: 4, amount: '' },
        ],
    });

    const [helpWithTaxData, setHelpWithTaxData] = useState<HelpWithTaxTypes>({
        year: year,
    });

    const callNoValidation = useCharLengthValidation(changeAmountsData.call_number_recipient, 14, true);
    const firstQuarterValidation = useCharLengthValidation(changeAmountsData.quarters[0].amount, 1, true);
    const secondQuarterValidation = useCharLengthValidation(changeAmountsData.quarters[1].amount, 1, true);
    const thirdQuarterValidation = useCharLengthValidation(changeAmountsData.quarters[2].amount, 1, true);
    const fourthQuarterValidation = useCharLengthValidation(changeAmountsData.quarters[3].amount, 1, true);

    useEffect(() => {
        setChangeAmountsData((prevState) => ({ ...prevState, year: selectedYear }));
        setHelpWithTaxData((prevState) => ({ ...prevState, year: selectedYear }));
        setChangeAmountsData((prevState) => ({ ...prevState, user_id: agency?.user?.data?.id }));
    }, [selectedYear, year]);

    useEffect(() => {
        if (isEdit && transferOrders && transferOrders.length > 0) {
            setChangeAmountsData({
                year: transferOrders[0].year,
                user_id: transferOrders[0].user_id,
                model_number_recipient: transferOrders[0].model_number_recipient,
                call_number_recipient: transferOrders[0].call_number_recipient,
                quarters: [
                    {
                        quarter: 1,
                        amount: transferOrders[0].quarterly_eko_taxes.data[0].total_amount.replace('.', ','),
                    },
                    {
                        quarter: 2,
                        amount: transferOrders[0].quarterly_eko_taxes.data[1].total_amount.replace('.', ','),
                    },
                    {
                        quarter: 3,
                        amount: transferOrders[0].quarterly_eko_taxes.data[2].total_amount.replace('.', ','),
                    },
                    {
                        quarter: 4,
                        amount: transferOrders[0].quarterly_eko_taxes.data[3].total_amount.replace('.', ','),
                    },
                ],
            });
        }
    }, [isEdit]);

    const sendNewTaxOrder = async (): Promise<void> => {
        if (
            callNoValidation !== '' ||
            firstQuarterValidation !== '' ||
            secondQuarterValidation !== '' ||
            thirdQuarterValidation !== '' ||
            fourthQuarterValidation !== ''
        ) {
            setError(true);
            return;
        }
        closeModal();
        setIsLoader(true);
        const updateData = {
            ...changeAmountsData,
            quarters: changeAmountsData.quarters.map((q) => ({
                ...q,
                amount: Number(q.amount.toString().replace(',', '.')),
            })),
        };
        communication
            .createEcotaxOrder(updateData)
            .then((res: ObjectKeys) => {
                if (res.status === 200) {
                    setIsLoader(false);
                    setOpenInfoModal(false);
                    refresh();
                } else {
                    setIsLoader(false);
                    console.error('error', res);
                }
            })
            .catch((err: any) => {
                setIsLoader(false);
                console.error(err);
            });
    };

    const updateEcotaxOrder = async (): Promise<void> => {
        if (!isEdit || !transferOrders || transferOrders.length === 0) return;
        if (
            callNoValidation !== '' ||
            firstQuarterValidation !== '' ||
            secondQuarterValidation !== '' ||
            thirdQuarterValidation !== '' ||
            fourthQuarterValidation !== ''
        ) {
            setError(true);
            return;
        }
        closeModal();
        setIsLoader(true);
        const updateData = {
            ...changeAmountsData,
            quarters: changeAmountsData.quarters.map((q) => ({
                ...q,
                amount: Number(q.amount.toString().replace(',', '.')),
            })),
        };
        communication
            .updateEcotaxOrder(transferOrders[0].id, updateData)
            .then((res: ObjectKeys) => {
                if (res.status === 200) {
                    setIsLoader(false);
                    setOpenInfoModal(false);
                    refresh();
                }
            })
            .catch((err: any) => {
                setIsLoader(false);
                console.error(err);
            });
    };

    const handleSaveTaxHelpWithTax = async (file: [Blob]): Promise<void> => {
        setIsLoader(true);
        if (file[0].size > 5242880) {
            setIsLoader(false);
            setWrongTypeModal(true);
        } else {
            const res = await comunication.uploadEcotaxSolutions(profile.id, file[0], helpWithTaxData.year);
            if (res.status === 200) {
                closeModal();
                setOpenInfoModal(true);
            }
            setIsLoader(false);
            closeModal();
        }
    };
    const tabs = [t('pages.eco_tax_transfer.change_amounts').text, t('pages.eco_tax_transfer.help_upload_tax').text];

    const handleInputChange = (value: string): void => {
        const sanitizedValue = value.replace(/[^0-9]/g, '');
        setChangeAmountsData((prevState) => ({
            ...prevState,
            call_number_recipient: sanitizedValue,
        }));
    };

    return (
        <>
            {/* {loader && <Loader />} */}
            {wrongTypeModal && (
                <Modal modalVisible={wrongTypeModal} closeModal={() => setWrongTypeModal(!wrongTypeModal)}>
                    <ErrorModal
                        t={t}
                        errorMessage={t('pages.transfers.wrongType').text}
                        setOpenModal={setWrongTypeModal}
                    />
                </Modal>
            )}
            <Content className={isMobile ? 'mobile' : 'desktop'}>
                <h1>{t('pages.transfers.manage_transfers').text}</h1>
                <Tabs className={'tabs'} selectedIndex={tabIndex} onSelect={(index) => setTabIndex(index)}>
                    <TabList>
                        {tabs.map((tab, index) => (
                            <Tab className={'custom-tab'} key={index}>
                                {tab}
                            </Tab>
                        ))}
                    </TabList>
                    <TabPanel>
                        <div className={'change-amounts first-tab'}>
                            <h4>{t('pages.eco_tax_transfer.choose_year_for_generating').text}</h4>
                            <div className="select-year-container">
                                <article>
                                    <div className={'select'}>
                                        <label className="font_small">
                                            {t('pages.eco_tax_transfer.choose_year').text}
                                        </label>

                                        <SelectComponent
                                            optionList={yearsOptions}
                                            defaultSelectedOption={yearsOptions.find((option) => option.value === year)}
                                            className={'select-year'}
                                            placeholder={selectedYear}
                                            handleSelectedValue={(value: string) => {
                                                setSelectedYear(value);
                                                setChangeAmountsData((prevState) => ({
                                                    ...prevState,
                                                    call_number_recipient: '',
                                                }));
                                                navigate(`/ecotax/${value}`);
                                            }}
                                        />
                                    </div>
                                    <p className="label-text">
                                        {t('pages.eco_tax_transfer.call_number_for_year').text}
                                        {selectedYear}
                                    </p>
                                    <Input
                                        type={InputComponentType.Text}
                                        onChange={(value: string) => {
                                            handleInputChange(value);
                                        }}
                                        value={changeAmountsData.call_number_recipient}
                                        validation={error && callNoValidation ? callNoValidation : ''}
                                        maxLength={14}
                                        className={'input-tax-orders'}
                                    />
                                    <p className="label-text">{t('pages.eco_tax_transfer.quarter').text} I</p>
                                    <Input
                                        type={InputComponentType.Amount}
                                        value={changeAmountsData.quarters[0].amount} // Set the value from state
                                        onChange={(value: string) => {
                                            setChangeAmountsData((prevState) => ({
                                                ...prevState,
                                                quarters: prevState.quarters.map((q) =>
                                                    q.quarter === 1 ? { ...q, amount: value } : q,
                                                ),
                                            }));
                                        }}
                                        className={'input'}
                                        validation={error && firstQuarterValidation ? firstQuarterValidation : ''}
                                    />

                                    <p className="label-text">{t('pages.eco_tax_transfer.quarter').text} II</p>
                                    <Input
                                        type={InputComponentType.Amount}
                                        value={changeAmountsData.quarters[1].amount} // Set the value from state
                                        onChange={(value: string) => {
                                            setChangeAmountsData((prevState) => ({
                                                ...prevState,
                                                quarters: prevState.quarters.map((q) =>
                                                    q.quarter === 2 ? { ...q, amount: value } : q,
                                                ),
                                            }));
                                        }}
                                        className={'input'}
                                        validation={error && secondQuarterValidation ? secondQuarterValidation : ''}
                                    />

                                    <p className="label-text">{t('pages.eco_tax_transfer.quarter').text} III</p>
                                    <Input
                                        type={InputComponentType.Amount}
                                        value={changeAmountsData.quarters[2].amount} // Set the value from state
                                        onChange={(value: string) => {
                                            setChangeAmountsData((prevState) => ({
                                                ...prevState,
                                                quarters: prevState.quarters.map((q) =>
                                                    q.quarter === 3 ? { ...q, amount: value } : q,
                                                ),
                                            }));
                                        }}
                                        className={'input'}
                                        validation={error && thirdQuarterValidation ? thirdQuarterValidation : ''}
                                    />

                                    <p className="label-text">{t('pages.eco_tax_transfer.quarter').text} IV</p>
                                    <Input
                                        type={InputComponentType.Amount}
                                        value={changeAmountsData.quarters[3].amount} // Set the value from state
                                        onChange={(value: string) => {
                                            setChangeAmountsData((prevState) => ({
                                                ...prevState,
                                                quarters: prevState.quarters.map((q) =>
                                                    q.quarter === 4 ? { ...q, amount: value } : q,
                                                ),
                                            }));
                                        }}
                                        className={'input'}
                                        validation={error && fourthQuarterValidation ? fourthQuarterValidation : ''}
                                    />
                                </article>
                            </div>
                        </div>
                        <div className={'buttons'}>
                            <Button
                                variant={ButtonVariant.outlined}
                                size={'50%'}
                                color={colors.purple}
                                onClick={() => closeModal()}
                            >
                                {t('pages.transfers.close').text}
                            </Button>
                            <Button
                                variant={ButtonVariant.solid}
                                size={'50%'}
                                color={colors.purple}
                                onClick={() => {
                                    if (isEdit) {
                                        updateEcotaxOrder();
                                    } else {
                                        sendNewTaxOrder();
                                    }
                                }}
                            >
                                {t('pages.transfers.send_solution').text}
                            </Button>
                        </div>
                    </TabPanel>
                    <TabPanel>
                        <div className={'change-amounts'}>
                            <h4 className="change-amounts-subtitle">
                                {t('pages.eco_tax_transfer.choose_year_for_uploading').text}
                            </h4>
                            <div className="select-year-container">
                                <article>
                                    <div className={'select'}>
                                        <label>{`${
                                            t('pages.transfers.edit_tax_transfers_modal_year').text
                                        } ${selectedYear}`}</label>

                                        <SelectComponent
                                            optionList={yearsOptions}
                                            defaultSelectedOption={yearsOptions.find((option) => option.value === year)}
                                            className={'select-year'}
                                            placeholder={selectedYear}
                                            handleSelectedValue={(value: string) =>
                                                setHelpWithTaxData((prevState) => ({ ...prevState, year: value }))
                                            }
                                        />
                                    </div>
                                </article>
                            </div>
                            <div>
                                <FileUploader
                                    multiple={true}
                                    handleChange={handleSaveTaxHelpWithTax}
                                    readFileAsBinary={true}
                                    name="files"
                                    types={fileTypes}
                                    onTypeError={() => {
                                        setWrongTypeModal(true);
                                    }}
                                >
                                    <div className={isMobile ? 'mobile dropzone' : 'dropzone'}>
                                        <img src={dragAndDropIcon} />
                                    </div>
                                </FileUploader>
                            </div>
                        </div>
                        <div className={'buttons'}>
                            <Button
                                variant={ButtonVariant.outlined}
                                size={'50%'}
                                color={colors.purple}
                                onClick={() => closeModal()}
                            >
                                {t('pages.transfers.back').text}
                            </Button>
                            <Button
                                variant={ButtonVariant.solid}
                                size={'50%'}
                                color={colors.purple}
                                onClick={() => handleSaveTaxHelpWithTax}
                            >
                                {t('pages.transfers.send_solution').text}
                            </Button>
                        </div>
                    </TabPanel>
                </Tabs>
            </Content>
        </>
    );
};
const Content = styled.div`
    text-align: center;
    &.mobile {
        width: 100%;
    }

    &.desktop {
        width: 100%;
    }
    h1 {
        text-transform: uppercase;
        margin-bottom: 20px;
    }
    display: inline-block;
    color: var(--black);
    .react-tabs__tab-list {
        border-bottom: none;
        .custom-tab {
            display: inline-block;
            position: relative;
            list-style: none;
            padding: 6px 12px;
            cursor: pointer;
            text-align: center;
            width: 50%;
            text-transform: uppercase;
        }
    }
    .react-tabs__tab--selected {
        border-right: none;
        border-left: none;
        border-top: none;
        color: var(--purple);
        border-bottom: 3px solid #9b7fe9 !important;
    }
    h1 {
        color: #4c595f;
        font-weight: 400;
        font-size: 20px;
        padding: 25px 50px 10px;
    }
    h4 {
        font-size: 16px;
        color: var(--gray);
    }
    .change-amounts {
        padding: 15px 20px 75px;
    }
    article {
        display: block;
        padding: 15px 0;
        vertical-align: middle;
        position: relative;
        text-align: left;
        .select {
            color: #92a5ac;
            margin-top: 20px;
            margin-bottom: 20px;
            text-align: left;
            width: 100%;
            display: block;
            .font_small {
                font-size: 16px;
            }
        }
        .select-wrapper {
            width: 100%;
        }
    }
    .upload-tax {
        box-shadow: none !important;
        label {
            width: 100%;
        }
    }
    .description {
        display: none;
    }
    .modal-wrapper:has(> .change-amounts) {
        max-height: 90% !important;
        overflow: auto !important;
    }
    .buttons {
        display: flex;
        align-items: center;
        justify-content: center;
        button:first-child {
            border-radius: 0 0 0 6px;
        }
        button:last-child {
            border-radius: 0 0 6px 0;
        }
    }
    .tabs {
        ul {
            border-bottom: 1px solid #e7e7e7;
        }
    }
    .first-tab {
        padding: 15px 50px 15px;
        input {
            width: 100%;
        }
    }
    .dropzone {
        max-width: 100%;
        position: relative;
        min-height: 220px;
        border: 3px dashed #e7e7e7 !important;
        color: #92a5ac;
        border-radius: 10px;
        margin: 20px 60px 10px;
        padding: 50px;
        img {
            width: 50px;
            margin-bottom: 50px;
            pointer-events: none;
        }
    }
    .dropzone.dragging {
        background-color: red; /* Style when dragging a file over the zone */
        background-color: rgba(255, 0, 0, 0.1);
    }
    .error {
        color: red;
        font-size: 12px;
        margin-top: 3px;
        display: block;
    }
    .label-text {
        font-size: 16px;
        color: var(--gray);
        margin-top: 10px;
        margin-bottom: 10px;
        :hover {
            color: var(--purple);
        }
    }
`;

export default ManageOrdersModal;
