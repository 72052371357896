import useTranslations from '../../../hooks/useTranslation';
import { FunctionComponent } from 'react';
import styled from 'styled-components';
import useLanguageStore from '../../../store/language';
import SkeletonLimits from './skeleton';
import { MQ_BREAKPOINTS } from '../../../constants/breakpoints';

interface SingleItemProps {
    limit: string;
    value: string;
    rest: string;
    isLoading: boolean;
    onClick?: () => void;
    startDate?: string;
    endDate?: string;
}

const SingleItem: FunctionComponent<SingleItemProps> = ({
    limit,
    value,
    rest,
    isLoading,
    onClick,
    startDate,
    endDate,
}) => {
    const { currentLang } = useLanguageStore();
    const t = useTranslations(currentLang);

    return (
        <>
            {isLoading ? (
                <SkeletonLimits />
            ) : (
                <Wrapper onClick={onClick}>
                    <TopSection>
                        <p>{limit}</p>
                        <span>
                            {startDate} - {endDate}
                        </span>
                    </TopSection>
                    <BottomSection>
                        <Value>{value}</Value>
                        <RestToLimit>
                            {t('pages.invoices.statistics.restToLimit').text}: {rest}
                        </RestToLimit>
                    </BottomSection>
                </Wrapper>
            )}
        </>
    );
};

export default SingleItem;

const Wrapper = styled.div`
    width: 45%;
    height: 150px;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    cursor: pointer;
    @media only screen and (max-width: ${MQ_BREAKPOINTS.tablet}) {
        width: 100%;
    }
`;

const TopSection = styled.div`
    width: 100%;
    height: 30%;
    background-color: var(--purple);
    display: flex;
    flex-direction: column;
    gap: 3px;
    align-items: center;
    color: white;
    justify-content: center;
    text-transform: uppercase;
    p {
        font-size: 18px;
    }
    span {
        font-size: 13px;
    }
`;
const BottomSection = styled.div`
    width: 100%;
    height: 67%;
    display: flex;
    align-items: center;
    justify-content: space-around;
    border: 1px solid var(--purple);
    flex-direction: column;
`;
const Value = styled.div`
    font-size: 30px;
    color: var(--purple);
`;

const RestToLimit = styled.span`
    font-size: 13px;
`;
