import ExcelJS from 'exceljs';

const exportToExcel = async (id: string, sheetName: string, fileName: string): Promise<void> => {
    const elt = document.getElementById(id);
    if (elt) {
        const workbook = new ExcelJS.Workbook();
        const sheet = workbook.addWorksheet(sheetName);

        const bodyRows = elt.querySelectorAll('tbody tr');
        bodyRows.forEach((row) => {
            const rowData: string[] = [];
            row.querySelectorAll('th, td').forEach((cell) => {
                rowData.push(cell.textContent || '');
            });
            sheet.addRow(rowData);
        });

        const blob = await workbook.xlsx.writeBuffer();
        const url = window.URL.createObjectURL(
            new Blob([blob], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' }),
        );
        const link = document.createElement('a');
        link.href = url;
        link.download = `${fileName}.xlsx`;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    } else {
        console.error(`Element with id "${id}" not found`);
    }
};

export default exportToExcel;
