import { faCaretDown, faEnvelope, faPaperPlane } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FunctionComponent, useState } from 'react';
import styled from 'styled-components';
import Button, { ButtonVariant } from '../../../../components/button';
import colors from '../../../../global/colors';
import TextArea from '../../../../components/input/textarea';
import Input, { InputComponentType } from '../../../../components/input';
import moment from 'moment';
import { ObjectKeys } from '../../../../types/objectKeys';
import communication from '../../../../communication';

interface Props {
    t: Function;
    s: ObjectKeys;
    index: number;
    expandIndex: number;
    setExpandIndex: Function;
    expand: boolean;
    setExpand: Function;
    setLoading: Function;
    setResponded: Function;
    responded: boolean;
    setErrorModal: Function;
}

const Suggestion: FunctionComponent<Props> = ({
    t,
    s,
    index,
    expandIndex,
    setExpandIndex,
    expand,
    setExpand,
    setLoading,
    setResponded,
    responded,
    setErrorModal,
}) => {
    const [response, setResponse] = useState('');
    const [mailHeading, setMailHeading] = useState(t('pages.admin.suggestions.placeholder').text);

    const sendResponse = async (): Promise<void> => {
        if (response === '') setErrorModal(true);
        else {
            setLoading(true);
            const re = await communication.replyOnSuggestion({ body: response, heading: mailHeading, id: s.id }, s.id);
            if (re.status === 200) {
                setExpandIndex(-1);
                setResponded(!responded);
            }
            setLoading(false);
        }
    };
    return (
        <SuggestionContainer className={`${s.answered === 1 ? 'answered' : ''} `}>
            <div
                className="data"
                onClick={() => {
                    if (index === expandIndex) {
                        setExpand(!expand);
                        setExpandIndex(-1);
                    } else {
                        setExpand(true);
                        setExpandIndex(index);
                    }
                }}
            >
                <div className="third">
                    <p>{t('pages.admin.suggestions.email').text}</p>
                    <p>{s.email}</p>
                </div>
                <div className="third">
                    <p>{t('pages.admin.suggestions.time').text}</p>
                    <p>{moment(s.created_at).format('DD.MM.YYYY HH:mm')}</p>
                </div>
                <div className="third right">
                    <FontAwesomeIcon color="gray" icon={faCaretDown} />
                </div>
            </div>
            {expand && index === expandIndex && (
                <div className="data expanded">
                    <p>{t('pages.admin.suggestions.suggestionTitle').text}</p>
                    <p>{s.heading}</p>

                    <p>{t('pages.admin.suggestions.suggestionData').text}</p>
                    <p>{s.body}</p>

                    {s.answered === 0 ? (
                        <>
                            <div className="reply">
                                <span>
                                    <FontAwesomeIcon color="gray" icon={faEnvelope} />
                                    {t('pages.admin.suggestions.answer').text}
                                </span>
                            </div>
                            <Input
                                className="bordered"
                                type={InputComponentType.Text}
                                value={mailHeading}
                                onChange={(value: string) => {
                                    setMailHeading(value);
                                }}
                            />
                            <TextArea
                                onChange={(value: string) => {
                                    setResponse(value);
                                }}
                                placeholder={t('pages.admin.suggestions.response').text}
                            />
                            <Button
                                icon
                                variant={ButtonVariant.solid}
                                color={colors.purple}
                                onClick={() => sendResponse()}
                            >
                                <FontAwesomeIcon color="white" icon={faPaperPlane} />
                                {t('pages.admin.suggestions.answerButton').text}
                            </Button>
                        </>
                    ) : (
                        <h4>{t('pages.admin.suggestions.alreadyAnswered').text}</h4>
                    )}
                </div>
            )}
        </SuggestionContainer>
    );
};

const SuggestionContainer = styled.div`
    background: var(--white);
    &.answered {
        background: var(--mid-gray);
    }
    padding: 15px;
    margin: 15px 0;
    border: 1px solid var(--border-color);
    overflow: hidden;
    .data {
        display: flex;
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
        -ms-flex-direction: row;
        flex-direction: row;
        /* white-space: nowrap; */
    }
    .expanded {
        display: flex;
        flex-direction: column;
    }
    .third {
        flex: 1 1 33%;
        width: 33%;
    }
    .right {
        text-align: right;
    }
    p {
        font-size: 14px;
        font-weight: 700;
        padding: 5px;
        :nth-of-type(even) {
            color: var(--gray);
            font-weight: 500;
            font-style: italic;
        }
    }
    .reply {
        width: 100%;
        margin-top: 10px;
        span {
            padding: 5px;
            font-size: 17px;
            line-height: 20px;
            font-weight: 700;
            display: block;
            margin-bottom: 10px;
        }
        svg {
            height: 17px;
            width: 17px;
            margin-right: 6px;
        }
    }
    input {
        margin-bottom: 10px;
        color: var(--gray);
        line-height: 2em;
        height: 34px;
        padding: 5px 5px 5px 10px;
        font-size: 14px;
        padding-right: 10px;
        border-bottom: unset;
        &:focus {
            outline: none;
            border-bottom: unset;
        }
        &:hover {
            outline: none;
            border-bottom: unset;
        }
    }

    textarea {
        margin-bottom: 10px;
        margin-top: 10px;

        color: var(--gray);
        background: #fff;
        border: 1px solid var(--border-color);
        transition: opacity 0.4s ease-out;
        border-radius: 0;
        line-height: 2em;
        outline: none;
        border-radius: 2px;
        padding: 5px 5px 5px 10px;
        font-size: 14px;
        padding-right: 10px;

        &:focus {
            outline: none;
            border: 1px solid var(--border-color);
        }
    }
    button {
        font-size: 12px;
        border-radius: 100px;
        width: 130px;
        height: 34px;
    }
    h4 {
        font-size: 17px;
        line-height: 20px;
        font-weight: 700;
        margin-bottom: 10px;
        margin-top: 10px;
        padding: 5px;
    }
`;

export default Suggestion;
