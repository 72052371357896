import React from 'react';
import FaAddressBookLight from '../../assets/icons/FaAddressBookLight';
import FaBuildingColumnsLight from '../../assets/icons/FaBuildingColumnsLight';
import FaChartMixedUpCircleDollarLight from '../../assets/icons/FaChartMixedUpCircleDollarLight';
import FaChartUserRegular from '../../assets/icons/FaChartUserRegular';
import FaFileInvoiceSharpLight from '../../assets/icons/FaFileInvoiceSharpLight';
import FaFileInvoiceSharpRegular from '../../assets/icons/FaFileInvoiceSharpRegular';
import FaFileInvoiceSharpSolid from '../../assets/icons/FaFileInvoiceSharpSolid';
import FaFileInvoiceSharpThin from '../../assets/icons/FaFileInvoiceSharpThin';
import FaFileLinesSharpRegular from '../../assets/icons/FaFileLinesSharpRegular';
import FaFileLinesSharpSolid from '../../assets/icons/FaFileLinesSharpSolid';
import FaFilePenRegular from '../../assets/icons/FaFilePenRegular';
import FaWrenchSharpRegular from '../../assets/icons/FaWrenchSharpRegular';
import FaAddressCardSharpLight from '../../assets/icons/FaAddressCardSharpLight';
import FaHandHoldingCircleDollarLight from '../../assets/icons/FaHandHoldingCircleDollarLight';
import FaBookUserSolid from '../../assets/icons/FaBookUserSolid';

export type CustomIconName =
    | 'faAddressBookLight'
    | 'faAddressCardSharp'
    | 'faBuildingColumnsLight'
    | 'faChartMixedUpCircleDollarLight'
    | 'faChartUserRegular'
    | 'faFileInvoiceSharpLight'
    | 'faFileInvoiceSharpRegular'
    | 'faFileInvoiceSharpSolid'
    | 'faFileInvoiceSharpThin'
    | 'faFileLinesSharpRegular'
    | 'faFileLinesSharpSolid'
    | 'faFilePenRegular'
    | 'faHandHoldingCircleDollarLight'
    | 'faWrenchSharpRegular'
    | 'faBookUserSolid';

interface IconProps {
    name: CustomIconName;
    size?: string | number;
    color?: string;
    className?: string;
}

const iconMap: Record<CustomIconName, React.FC<React.SVGProps<SVGSVGElement>>> = {
    faAddressBookLight: FaAddressBookLight,
    faAddressCardSharp: FaAddressCardSharpLight,
    faBuildingColumnsLight: FaBuildingColumnsLight,
    faChartMixedUpCircleDollarLight: FaChartMixedUpCircleDollarLight,
    faChartUserRegular: FaChartUserRegular,
    faFileInvoiceSharpLight: FaFileInvoiceSharpLight,
    faFileInvoiceSharpRegular: FaFileInvoiceSharpRegular,
    faFileInvoiceSharpSolid: FaFileInvoiceSharpSolid,
    faFileInvoiceSharpThin: FaFileInvoiceSharpThin,
    faFileLinesSharpRegular: FaFileLinesSharpRegular,
    faFileLinesSharpSolid: FaFileLinesSharpSolid,
    faFilePenRegular: FaFilePenRegular,
    faHandHoldingCircleDollarLight: FaHandHoldingCircleDollarLight,
    faWrenchSharpRegular: FaWrenchSharpRegular,
    faBookUserSolid: FaBookUserSolid,
};

const PausalIcon: React.FC<IconProps> = ({ name, size = 24, color = 'currentColor', className }) => {
    const IconComponent = iconMap[name];
    return <IconComponent width={size} height={size} fill={color} className={className} />;
};

export default PausalIcon;
