import useTranslations from '../hooks/useTranslation';
import useLanguageStore from '../store/language';

const useNotRequiredEmailValidation = (email: string): string => {
    const { currentLang } = useLanguageStore();
    const t = useTranslations(currentLang);

    const isEmailValid =
        /(^$|^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$)/.test(
            String(email).toLowerCase(),
        );
    if (!isEmailValid) {
        return t('pages.validationMessages.emailInvalid').text;
    } else {
        return '';
    }
};
export default useNotRequiredEmailValidation;
