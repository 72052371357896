import { ObjectKeys } from '../types/objectKeys';
import download from './download';
import fetchFunction from './fetch';
import routes from './routes';
import getCurrentLanguage from '../functions/getCurrentLanguage';

export default class AccountExpiration {
    public async generateAccountExpirationQR(data: string): Promise<any> {
        const response = await fetch(routes.accountExpiration.generateQr, {
            method: 'POST',
            mode: 'cors',
            cache: 'no-cache',
            headers: {
                'Content-Type': 'application/json',
                'Accept-Language': getCurrentLanguage(),
            },
            body: data,
        });
        return response.json();
    }

    public generateTemplateTransfer(options: ObjectKeys): ObjectKeys {
        return fetchFunction({
            method: 'POST',
            headers: { 'Content-Type': 'application/json; charset=UTF-8', 'Accept-Language': getCurrentLanguage() },
            url: routes.accountExpiration.generateTemplateTransfer,
            data: options,
        });
    }

    public downloadTransferOrder(transferId: string, token: string, qr_code: string): ObjectKeys {
        return download(routes.accountExpiration.downloadTransferOrder(transferId), {
            token,
            qr_code,
            hasQrCode: qr_code ? true : false,
        });
    }
}
