import { BankAccount, GetBankAccountsResponse } from '@/communication/bankAccounts/types';

import communication from '../communication';
import { isStatusSuccess } from '../communication/handlers/handleRequest';
import { queryClient } from '../App';
import { useQuery } from '@tanstack/react-query';

export const fetchBankAccounts = async (
    page: number,
    itemsPerPage: number,
    type: BankAccount['type'],
): Promise<GetBankAccountsResponse['data']> => {
    try {
        const response = await communication.getBankAccounts({ page, limit: itemsPerPage, search: `type:${type};` });
        if (isStatusSuccess(response.status)) {
            return response.data;
        } else {
            throw new Error('Failed to fetch banks');
        }
    } catch (error) {
        console.error('Failed to fetch banks:', error);
        throw new Error('Failed to fetch banks');
    }
};

const useBankAccountsQuery = (page: number, itemsPerPage: number, type: BankAccount['type']) => {
    const bankAccountsQuery = useQuery<GetBankAccountsResponse['data']>({
        queryKey: ['bank-accounts', page, type],
        queryFn: () => fetchBankAccounts(page, itemsPerPage, type),
        staleTime: 60 * 60 * 1000, // 1 hour
    });

    const invalidateBankAccounts = () => {
        queryClient.invalidateQueries({ queryKey: ['bank-accounts'] });
    };

    return { ...bankAccountsQuery, invalidateBankAccounts };
};

export default useBankAccountsQuery;
