import communication from '../../../communication';
import { Dispatch, SetStateAction } from 'react';
import { ObjectKeys } from '../../../types/objectKeys';
import useAllUsersStore from '../../../store/admin/allUsers';

const getAllUsers = async (
    setAllUsers: Dispatch<SetStateAction<Array<{ value: string; label: string }>>>,
): Promise<ObjectKeys | void> => {
    try {
        const res: ObjectKeys = await communication.getAllUsers();
        if (res.status === 200) {
            useAllUsersStore.setState({ allUsers: res.data });
            const userData = res?.data?.map((user: ObjectKeys) => {
                return { value: user.id, label: user.email + '-' + user.pib };
            });
            setAllUsers([...userData]);
            return res.data;
        }
    } catch (error) {
        console.error('Error fetching users:', error);
    }
};

export default getAllUsers;
