import { FunctionComponent, useEffect, useState } from 'react';
import Modal from '../../../../../components/modal';
import ChangeModal from '../../../../../components/changeModal';
import Input, { InputComponentType } from '../../../../../components/input';
import styled from 'styled-components';
import { formatToSerbianNumber } from '../../../../../functions/format';
import useAdvanceValueValidation from '../../../../../hooks/useAdvanceValueValidation';
import formatAdvanceValue from './formatAdvanceValue';
import { ObjectKeys } from '../../../../../types/objectKeys';

interface Props {
    t: Function;
    setCloseAdvanceModal: Function;
    advancedInvoice: ObjectKeys;
    addAdvance: Function;
}

const CloseAdvanceModal: FunctionComponent<Props> = ({ t, setCloseAdvanceModal, advancedInvoice, addAdvance }) => {
    const invoiceValue = advancedInvoice.remaining_value;
    const [enteredValue, setEnteredValue] = useState(formatToSerbianNumber(Number(invoiceValue)).toString());
    const isInputValid = useAdvanceValueValidation(enteredValue, invoiceValue, t);
    return (
        <Modal
            modalVisible={true}
            closeModal={() => {
                setCloseAdvanceModal(false);
            }}
        >
            <ChangeModal
                saveChanges={() => {
                    if (isInputValid) return;
                    {
                        setCloseAdvanceModal(false);
                        addAdvance({
                            value: enteredValue,
                            invNo: advancedInvoice.invoice_number,
                            id: advancedInvoice.id,
                            prefix: advancedInvoice.prefix,
                        });
                    }
                }}
                close={() => {
                    setCloseAdvanceModal(false);
                }}
                message={t('pages.advanceInvoices.closeModal.title').text}
                confirmText={t('pages.advanceInvoices.closeModal.confirm').text}
                closeText={t('pages.advanceInvoices.closeModal.close').text}
            >
                <Container>
                    <Input
                        type={InputComponentType.Text}
                        label={t('pages.advanceInvoices.closeModal.invoiceValue').text}
                        value={formatToSerbianNumber(Number(invoiceValue)).toString()}
                    />
                    <Input
                        type={InputComponentType.Amount}
                        label={t('pages.advanceInvoices.closeModal.enteredValue').text}
                        value={enteredValue}
                        onChange={(value: string) => {
                            setEnteredValue(value);
                        }}
                        validation={isInputValid}
                        limitDecimal={4}
                    />
                    <Input
                        type={InputComponentType.Text}
                        label={t('pages.advanceInvoices.closeModal.remainingValue').text}
                        value={formatAdvanceValue(
                            (
                                Number(Number(invoiceValue).toFixed(4)) -
                                Number(enteredValue.replace('.', '').replace(',', '.'))
                            ).toString(),
                        )}
                    />
                </Container>
            </ChangeModal>
        </Modal>
    );
};
export default CloseAdvanceModal;

const Container = styled.div`
    padding: 50px;
    margin-top: -50px;
    padding-top: 0px;
`;
