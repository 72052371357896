import { FunctionComponent, SetStateAction, Dispatch, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import useEmailValidation from '../../../hooks/useEmailValidation';
import usePasswordValidation from '../../../hooks/usePasswordValidation';
import useCharLengthValidation from '../../../hooks/useCharLengthValidation';
import useConfirmPasswordValidation from '../../../hooks/useConfirmPasswordValidation';
import Input, { InputComponentType } from '../../../components/input';
import Checkbox from '../../../components/checkbox';
import { RegisterFirstFormData } from '../../../types/register/registerFirstFormData';
import SelectComponent from '../../../components/select';
import styled from 'styled-components';
import useLanguageStore from '../../../store/language';
import { flatten } from '../../../functions/flatten';
import srJson from '../../../components/language/rs.json';
import enJson from '../../../components/language/en.json';
import ruJson from '../../../components/language/ru.json';
import useCountriesStore from '../../../store/countries';

type Props = {
    t: Function;
    showValidationMessage: boolean;
    formData: RegisterFirstFormData;
    setFormData: Dispatch<SetStateAction<RegisterFirstFormData>>;
    handleValidation: Function;
    clickedField: string;
};

const FirstStep: FunctionComponent<Props> = ({
    t,
    showValidationMessage,
    formData,
    setFormData,
    handleValidation,
    clickedField,
}) => {
    const currentLangName = localStorage.getItem('language');
    const isEmailValid = useEmailValidation(formData.email);
    const isPasswordValid = usePasswordValidation(formData.password);
    const isNameValid = useCharLengthValidation(formData.name, 3, true);
    const isLastNameValid = useCharLengthValidation(formData.lastName, 3, true);
    const isConfirmPasswordValid = useConfirmPasswordValidation(formData.password, formData.confirmPassword);
    const isCitizenshipValid = formData.country_id !== '';
    const isPromoCodValid = useCharLengthValidation(formData.promoCode, 1, formData.isPromoCodeChecked ? true : false);

    const [render, setRender] = useState(0);
    const langs = [
        { value: 'en', label: 'English' },
        { value: 'rs', label: 'Srpski' },
        { value: 'ru', label: 'Русский' },
    ];
    const { countries } = useCountriesStore();

    const citizenships = countries.map((country) => {
        return { value: country.id, label: country.name };
    });

    const handleLanguageChange = (lang: string): void => {
        let selectedLanguage: string;
        switch (lang) {
            case 'en':
                selectedLanguage = 'English';
                break;
            case 'rs':
                selectedLanguage = 'Srpski';
                break;
            case 'ru':
                selectedLanguage = 'Русский';
                break;
        }

        setFormData({ ...formData, lang: lang });
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        useLanguageStore.setState({ currentLangName: selectedLanguage! });
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        localStorage.setItem('language', selectedLanguage!);
    };

    useEffect(() => {
        setRender(render + 1);
        if (currentLangName && currentLangName === 'English') {
            const flattenData = flatten(enJson);
            useLanguageStore.setState({ currentLang: flattenData });
            localStorage.setItem('language', 'English');
        } else if (currentLangName && currentLangName === 'Русский') {
            const flattenData = flatten(ruJson);
            useLanguageStore.setState({ currentLang: flattenData });
            localStorage.setItem('language', 'Русский');
        } else if (currentLangName && currentLangName === 'Srpski') {
            const flattenData = flatten(srJson);
            useLanguageStore.setState({ currentLang: flattenData });
            localStorage.setItem('language', 'Srpski');
        }
    }, [localStorage.language]);

    return (
        <>
            <Input
                type={InputComponentType.Text}
                label={t('pages.register.rightColumn.form.firstStep.email').text}
                validation={showValidationMessage ? isEmailValid : ''}
                value={formData.email}
                blurHandler={() => handleValidation('email')}
                onChange={(value: string) => {
                    setFormData({ ...formData, email: value });
                }}
            />
            <Input
                type={InputComponentType.Text}
                label={t('pages.register.rightColumn.form.firstStep.name').text}
                validation={showValidationMessage || clickedField === 'name' ? isNameValid : ''}
                value={formData.name}
                blurHandler={() => handleValidation('name')}
                onChange={(value: string) => {
                    setFormData({ ...formData, name: value });
                }}
            />
            <Input
                type={InputComponentType.Text}
                label={t('pages.register.rightColumn.form.firstStep.lastName').text}
                validation={showValidationMessage || clickedField === 'lastName' ? isLastNameValid : ''}
                value={formData.lastName}
                blurHandler={() => handleValidation('lastName')}
                onChange={(value: string) => {
                    setFormData({ ...formData, lastName: value });
                }}
            />
            <Input
                type={InputComponentType.Password}
                label={t('pages.register.rightColumn.form.firstStep.password').text}
                validation={showValidationMessage || clickedField === 'password' ? isPasswordValid : ''}
                value={formData.password}
                blurHandler={() => handleValidation('password')}
                onChange={(value: string) => {
                    setFormData({ ...formData, password: value });
                }}
            />
            <Input
                type={InputComponentType.Password}
                label={t('pages.register.rightColumn.form.firstStep.confirmPassword').text}
                validation={showValidationMessage || clickedField === 'confirmPassword' ? isConfirmPasswordValid : ''}
                value={formData.confirmPassword}
                blurHandler={() => handleValidation('confirmPassword')}
                onChange={(value: string) => {
                    setFormData({ ...formData, confirmPassword: value });
                }}
            />
            <SelectComponent
                optionList={citizenships}
                handleSelectedValue={(country_id: string) => {
                    setFormData({ ...formData, country_id: country_id });
                }}
                placeholder={t('pages.register.rightColumn.form.firstStep.citizenship').text}
                label={t('pages.register.rightColumn.form.firstStep.citizenship').text}
                labelVariant="dark"
                width={'100%'}
                defaultSelectedOption={citizenships.find((option) => option.value === formData.country_id)}
            />
            {!isCitizenshipValid && showValidationMessage ? (
                <Error>{t('pages.register.rightColumn.form.firstStep.chooseCitizenship').text}</Error>
            ) : null}

            <SelectComponent
                optionList={langs}
                handleSelectedValue={handleLanguageChange}
                placeholder={t('pages.register.rightColumn.form.firstStep.selectLanguage').text}
                label={t('pages.register.rightColumn.form.firstStep.selectLanguage').text}
                labelVariant="dark"
                width={'100%'}
                defaultSelectedOption={langs.find((option) => option.value === formData.lang) || langs[0]}
            />
            {/* {formData.isPromoCodeChecked ? (
                <Input
                    type={InputComponentType.Text}
                    label={t('pages.register.rightColumn.form.firstStep.typePromoCode').text}
                    value={formData.promoCode}
                    validation={
                        showValidationMessage && isPromoCodValid
                            ? t('pages.register.rightColumn.form.firstStep.typePromoCode').text
                            : ''
                    }
                    onChange={(value: string) => {
                        setFormData({ ...formData, promoCode: value });
                    }}
                />
            ) : (
                <></>
            )}
            <Row>
                <Checkbox
                    defaultChecked={formData.isPromoCodeChecked}
                    onChange={() =>
                        setFormData({
                            ...formData,
                            isPromoCodeChecked: !formData.isPromoCodeChecked,
                        })
                    }
                    label={t('pages.register.rightColumn.form.firstStep.promoCode').text}
                />
            </Row> */}
            <Row>
                <Checkbox
                    defaultChecked={formData.isTermsChecked}
                    onChange={() =>
                        setFormData({
                            ...formData,
                            isTermsChecked: !formData.isTermsChecked,
                        })
                    }
                    validation={
                        showValidationMessage && !formData.isTermsChecked
                            ? t('pages.validationMessages.termsServices').text
                            : ''
                    }
                    label={
                        <Link target="_blank" to="http://www.pausal.rs/assets/PAUSAL_Uslovi_Koriscenja.pdf">
                            {t('pages.register.rightColumn.form.firstStep.iAgree').text}
                        </Link>
                    }
                />
            </Row>
        </>
    );
};
const Row = styled.div`
    margin-top: 10px;
`;
const Error = styled.div`
    color: red;
    font-size: 12px;
    margin-bottom: 5px;
`;
export default FirstStep;
