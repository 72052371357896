import React, { useEffect, useState } from 'react';
import { Chart, ArcElement, Tooltip, Legend, Title, ChartOptions } from 'chart.js';
import { Doughnut } from 'react-chartjs-2';
import communication from '../../../../communication';
import moment from 'moment';
import colors from '../../../../global/colors';
import styled from 'styled-components';
import useLanguageStore from '../../../../store/language';
import useTranslations from '../../../../hooks/useTranslation';
import { MQ_BREAKPOINTS } from '../../../../constants/breakpoints';
import useResponsive from '../../../../hooks/responsive/useResponsive';
import Skeleton from './skeleton';
import { useNavigate } from 'react-router-dom';
import { formatTwoDecimals } from '../../../../functions/format';

Chart.register(ArcElement, Tooltip, Legend, Title);
Chart.defaults.plugins.tooltip.backgroundColor = 'rgb(0, 0, 156)';
Chart.defaults.plugins.legend.position = 'top';
Chart.defaults.plugins.legend.title.font = { family: 'Helvetica Neue' };
Chart.defaults.plugins.legend.labels.boxWidth = 50;
Chart.defaults.plugins.legend.labels.boxHeight = 20;

interface PeriodFiltersProps {
    choosenDate: number;
}

const CreateDoughnutData: React.FC<PeriodFiltersProps> = ({ choosenDate }) => {
    const { currentLang } = useLanguageStore();
    const t = useTranslations(currentLang);
    const navigate = useNavigate();
    const [loader, setLoader] = useState<boolean>(false);
    const [startDate, setStartDate] = useState<string>(moment().subtract(365, 'days').format('YYYY-MM-DD'));
    const [total, setTotal] = useState<number>(0);
    const [green, setGreen] = useState<number>(0);
    const [red, setRed] = useState<number>(0);
    const { isDesktop } = useResponsive();

    const fetchTotale = (): void => {
        if (!startDate) return;
        setLoader(true);
        const propsTotal = {
            start_date: startDate,
            end_date: moment().format('YYYY-MM-DD'),
        };
        communication.getDoughnut(propsTotal).then((res: any) => {
            setGreen(res.data.paid_value_sum);
            setRed(res.data.saldo);
            setTotal(res.data.value_sum);
            setLoader(false);
        });
    };

    useEffect(() => {
        let daysBack = choosenDate;
        switch (choosenDate) {
            case 1:
                daysBack = 30;
                break;
            case 3:
                daysBack = 90;
                break;
            case 6:
                daysBack = 180;
                break;
            case 12:
                daysBack = 365;
                break;
            default:
                daysBack = 365;
        }

        const newStartDate = moment().subtract(daysBack, 'days').format('YYYY-MM-DD');
        setStartDate(newStartDate);
    }, [choosenDate]);

    useEffect(() => {
        fetchTotale();
    }, [startDate]);

    const data = {
        labels: [`${t('pages.reports.paid').text}`, `${t('pages.reports.unpaid').text}`],
        datasets: [
            {
                data: [green, red],
                backgroundColor: ['#81a969', '#ef3a5d'],
                borderWidth: 3,
                radius: isDesktop ? '80%' : '100%',
                borderColor: 'white',
            },
        ],
        layout: {
            padding: {
                top: 20,
                right: 20,
                bottom: 20,
                left: 20,
            },
        },
    };
    const options: ChartOptions<'doughnut'> = {
        plugins: {
            tooltip: {
                callbacks: {
                    label: function (tooltipItem) {
                        const label = tooltipItem.label || '';
                        const value = tooltipItem.raw as number;
                        const formattedValue = formatTwoDecimals(value.toString());
                        return `${label}: ${formattedValue}`;
                    },
                },
                backgroundColor: '#9b7fe9',
            },
            legend: {
                display: false,
            },
            datalabels: {
                formatter: (value: any, ctx: any) => {
                    const percentage = ((ctx.dataset.data[ctx.dataIndex] * 100) / total).toFixed(2) + '%';
                    return `${percentage.replace('.', ',')}`;
                },
                color: 'black',
                borderColor: 'black',
                anchor: 'end',
                align: 'start',
                offset: isDesktop ? 20 : 2,
                font: {
                    weight: 'normal',
                    size: '14',
                },
            } as any,
        },
        responsive: true,
        elements: {
            bar: {
                borderWidth: 1,
            },
        },
        layout: {
            padding: {
                top: 0,
                right: 20,
                bottom: 0,
                left: 20,
            },
        },
        onClick: (event) => {
            const endDate = moment().format('YYYY-MM-DD');
            navigate(`/statistics/reports/allclients?startDate=${startDate}&endDate=${endDate}`);
        },
    };

    return (
        <DoughnutContainer>
            <Content>
                <h3>{t('pages.reports.charts.invoiced_balans').text}</h3>
            </Content>
            {loader ? (
                <Skeleton />
            ) : (
                <>
                    {total === 0 ? (
                        <NoData>{t('pages.reports.charts.noData').text}</NoData>
                    ) : (
                        <ResponsiveDoughnut data={data} options={options} />
                    )}
                </>
            )}
        </DoughnutContainer>
    );
};
const ResponsiveDoughnut = styled(Doughnut)`
    max-height: 500px;
    max-width: 100%;
    cursor: pointer;
    margin-top: -25px;
    @media screen and (max-width: ${MQ_BREAKPOINTS.mobileXS}) {
        margin-top: -15px;
    }
    @media screen and (min-width: ${MQ_BREAKPOINTS.mobileS}) {
        margin-top: -5px;
    }
    @media screen and (min-width: ${MQ_BREAKPOINTS.laptop}) {
        margin-top: -15px;
    }
    @media screen and (min-width: ${MQ_BREAKPOINTS.laptopL}) {
        margin-top: -25px;
        padding: 10px 10px;
    }
    @media screen and (min-width: ${MQ_BREAKPOINTS.desktop}) and (max-width: ${MQ_BREAKPOINTS.desktopL}) {
        margin-top: -55px;
        padding: 0;
    }

    @media screen and (min-width: ${MQ_BREAKPOINTS.ultraWide}) {
        margin-top: -65px;
    }
`;

const DoughnutContainer = styled.div`
    max-height: 400px;
    display: flex;
    flex-direction: column;
    align-items: center;
`;

const NoData = styled.div`
    margin-top: 20px;
    padding: 20px;
    text-align: center;
    margin-bottom: 100px;

    @media screen and (min-width: ${MQ_BREAKPOINTS.laptopM}) {
        margin-top: 24px;
    }
    @media screen and (min-width: ${MQ_BREAKPOINTS.desktopL}) {
        margin-top: 26px;
    }
`;
const Content = styled.div`
    margin-top: 20px;
    width: 100%;
    h3 {
        text-align: center;
        margin-bottom: 26px;
        font-size: 14px;
        border: 1px solid #ccc;
        background-color: var(--purple);
        color: #fff;
        padding: 10px;
        text-transform: uppercase;
        @media screen and (max-width: ${MQ_BREAKPOINTS.tablet}) {
            text-align: left;
        }
    }
    p {
        text-align: left;
        margin-top: 10px;
        margin-bottom: 10px;
    }
    .period-container {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
        font-size: 16px;
        margin-top: 10px;
        gap: 1rem;
        margin-bottom: 1.5rem;
        @media screen and (max-width: ${MQ_BREAKPOINTS.tablet}) {
            justify-content: left;
        }
    }

    .period {
        padding: 5px 10px;
        border: 1px solid #ccc;
        cursor: pointer;
        text-align: center;
    }

    .period:hover {
        background-color: #f0f0f0;
    }
    .period.active {
        background-color: ${colors.purple};
        color: #fff;
    }
`;
export default CreateDoughnutData;
