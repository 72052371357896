import React, { FunctionComponent, useRef, useEffect, useState } from 'react';
import styled from 'styled-components';

interface Props {
    className?: string;
    isChecked: boolean;
    onChange: (checked: boolean) => void;
    isDisabled?: boolean;
    onLabel?: string;
    offLabel?: string;
}

const ToggleSwitch: FunctionComponent<Props> = ({
    className,
    isChecked,
    onChange,
    isDisabled = false,
    onLabel = 'ON',
    offLabel = 'OFF',
}) => {
    const onLabelRef = useRef<HTMLSpanElement>(null);
    const offLabelRef = useRef<HTMLSpanElement>(null);
    const [buttonWidth, setButtonWidth] = useState(0);

    const handleToggle = (): void => {
        if (isDisabled) return;
        onChange(!isChecked);
    };

    useEffect(() => {
        // Calculate the maximum width between the two labels
        const onLabelWidth = onLabelRef.current?.offsetWidth || 0;
        const offLabelWidth = offLabelRef.current?.offsetWidth || 0;
        setButtonWidth(Math.max(onLabelWidth, offLabelWidth) + 20); // Add padding
    }, [onLabel, offLabel]);

    return (
        <StyledWrapper
            className={`switch-wrapper ${isChecked ? 'checked' : ''} ${className ?? ''}`.trim()}
            onClick={handleToggle}
            isDisabled={isDisabled}
            isChecked={isChecked}
            buttonWidth={buttonWidth}
        >
            <div className="switch">
                <span className="label on" ref={onLabelRef}>
                    {onLabel}
                </span>
                <span className="label off" ref={offLabelRef}>
                    {offLabel}
                </span>
                <span className="switch-button"></span>
            </div>
        </StyledWrapper>
    );
};

const StyledWrapper = styled.div<{ isDisabled: boolean; isChecked: boolean; buttonWidth: number }>`
    z-index: 0;
    display: flex;
    flex-direction: column;
    cursor: ${({ isDisabled }) => (isDisabled ? 'not-allowed' : 'pointer')};

    .switch {
        position: relative;
        width: 180px;
        height: 30px;
        background-color: var(--purple);
        border-radius: 20px;
        transition: background-color 0.8s ease;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0 10px;

        .label {
            font-size: 14px;
            color: white;
            pointer-events: none;
            z-index: 1;
        }

        .on {
            color: ${({ isChecked }) => (isChecked ? 'white' : 'var(--purple)')};
        }

        .off {
            color: ${({ isChecked }) => (!isChecked ? 'white' : 'var(--purple)')};
        }
    }

    .switch-button {
        position: absolute;
        top: 3px;
        left: ${({ isChecked, buttonWidth }) => (isChecked ? `calc(100% - ${buttonWidth + 3}px)` : '3px')};
        width: ${({ buttonWidth }) => `${buttonWidth}px`};
        height: 25px;
        background-color: var(--white);
        border-radius: 17px;
        transition: left 0.5s ease;
    }
`;

export default ToggleSwitch;
