import React from 'react';

const FaFileInvoiceSharpLight: React.FC<React.SVGProps<SVGSVGElement>> = (props) => (
    <svg viewBox="0 0 384 512" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M0 255.797V511.595H384V152.506L231.494 0H0V255.797ZM32.8117 32.81H192.002V192H351.191V478.785H32.8117V32.81ZM285.207 98.6733C318.138 131.605 344.751 158.947 344.386 159.433C344.143 160.04 316.801 160.405 223.596 160.405L223.353 100.496C223.232 65.4986 223.596 40.1011 224.326 39.615C224.933 39.129 252.275 65.7416 285.207 98.6733ZM64.4054 80.2024V95.9999H160.405V64.405H64.4054V80.2024ZM64.4054 144V160.405H160.405V127.595H64.4054V144ZM64.4054 368.202V303.797V239.392H319.595V368.202H64.4054ZM223.595 431.392V447.19H319.595V415.595H223.595V431.392ZM96.0004 303.797V335.392H288V272.202H96.0004V303.797Z"
            fill="inherit"
        />
    </svg>
);

export default FaFileInvoiceSharpLight;
