import { FunctionComponent, useEffect, useState } from 'react';
import styled from 'styled-components';
import StepOne from './step1/';
import StepTwo from './step2';
import StepThree from './step3';
import { ObjectKeys } from '../../../types/objectKeys';
import { useParams } from 'react-router-dom';
import communication from '../../../communication';
import useAgencyStore from '../../../store/agency';
import Loader from '../../../components/loader';

const Services: FunctionComponent = () => {
    const [step, setStep] = useState(1);
    const [cart, setCart] = useState<ObjectKeys>({});

    const { agency } = useAgencyStore();
    const [loader, setLoader] = useState<boolean>(false);

    const params = useParams();
    const paramsCartId = useParams().cartId;

    useEffect(() => {
        if (paramsCartId && paramsCartId.includes('-')) {
            getCart(paramsCartId);
            setStep(3);
        }
    }, [paramsCartId, params]);

    const getCart = (id: string): void => {
        setLoader(true);
        communication
            .getCartById(id)
            .then((res: ObjectKeys) => {
                const discountedTotal = res.data.data.total_price;
                const items = res.data.data.items.data.map((item: any) => ({
                    id: item.package.data.id,
                    months: item.months,
                    price: item.package.data.monthly,
                    type: 'package',
                }));
                setCart({
                    user_id: agency.user.data.id,
                    status: '',
                    total_price: discountedTotal,
                    total_price_discounted: discountedTotal,
                    type: 'package',
                    note: res.data.data.note,
                    items: items,
                    discount: res.data.data.packet_service_discount,
                });
                setLoader(false);
            })
            .catch((err: ObjectKeys) => {
                setLoader(false);
                console.error('err', err);
            });
    };

    return (
        <>
            <Content>
                {loader && <Loader />}
                {step === 1 && (
                    <StepOne
                        nextStep={() => setStep(2)}
                        finallCart={(data: ObjectKeys) => {
                            setCart(data);
                        }}
                        mainCart={cart}
                    />
                )}
                {step === 2 && (
                    <StepTwo
                        cart={cart}
                        nextStep={() => setStep(3)}
                        previousStep={() => setStep(1)}
                        updatedCart={(data: ObjectKeys) => setCart(data)}
                    />
                )}
                {step === 3 && <StepThree cart={cart} previousStep={() => setStep(2)} />}
            </Content>
        </>
    );
};
const Content = styled.div`
    margin-top: 5px;
`;
export default Services;
