import { ObjectKeys } from '@/types/objectKeys';
import { AxiosError } from 'axios';
import useGeneralStore from '../store/general';

let errorCounter = 0;

export const generateQrCode = async (
    agency: ObjectKeys,
    client: ObjectKeys,
    totalInvoice: string,
    invoiceNumber: string,
    account: string,
    currency: string,
    convert = false,
    callNumber: string | null = null,
    paymentCode: string | null = null,
): Promise<void> => {
    const { setGlobalModal } = useGeneralStore.getState();
    try {
        const payload = {
            account: account ? account.replaceAll('-', '') : '',
            agency_name: agency.slice(0, 70).trimEnd(),
            total: convert
                ? parseFloat(totalInvoice).toFixed(2).replace('.', ',')
                : totalInvoice === '0'
                ? '0,00'
                : parseFloat(totalInvoice.replace('.', '').replace(',', '.')).toFixed(2).replaceAll('.', ','),
            client_name: client && client.slice(0, 70).trimEnd(),
            currency: currency.toUpperCase(),
            invoiceNumber: invoiceNumber ? invoiceNumber.slice(0, 35) : '',
            callNumber: callNumber,
        };
        const request = `K:PR|V:01|C:1|R:${payload.account}|N:${payload.agency_name}${
            payload.invoiceNumber && `|S:${payload.invoiceNumber}`
        }|I:${payload.currency}${payload.total}|SF:${paymentCode ? paymentCode : '289'}|P:${payload.client_name}${
            (payload.callNumber && `|RO:${payload.callNumber}`) || ''
        }`;
        const response = await fetch('https://nbs.rs/QRcode/api/qr/generate/', {
            method: 'POST',
            mode: 'cors',
            cache: 'no-cache',
            headers: {
                'Content-Type': 'application/json',
            },
            body: request,
        });
        errorCounter = 0;
        return response.json();
    } catch (e) {
        errorCounter += 1;

        if (errorCounter === 1) {
            const errorMessage = 'QR code generation failed';
            setGlobalModal(errorMessage, 'qr_code_error');
        } else {
            throw e as AxiosError;
        }
    }
};
