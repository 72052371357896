import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import Button, { ButtonVariant } from '../../../../components/button';
import colors from '../../../../global/colors';
import { useNavigate } from 'react-router-dom';
import getCurrentLanguage from '../../../../functions/getCurrentLanguage';
import { formatTwoDecimals } from '../../../../functions/format';
import { ObjectKeys } from '@/types/objectKeys';
import useServicesStore from '../../../../store/services';
import useTranslations from '../../../../hooks/useTranslation';
import useLanguageStore from '../../../../store/language';
import { MQ_BREAKPOINTS } from '../../../../constants/breakpoints';

interface PackageCardProps {
    price: number;
    monthlyObligatory: number;
    isClicked: boolean;
    onClicked: () => void;
    prevent: boolean;
    item: ObjectKeys;
    cart: ObjectKeys;
    zeroPrice: boolean;
}

const PackageCard: React.FC<PackageCardProps> = ({ onClicked, prevent, item, cart, zeroPrice }) => {
    const navigate = useNavigate();
    const lang = getCurrentLanguage();
    const { currentLang } = useLanguageStore();
    const t = useTranslations(currentLang);

    const [calculatedCart, setCalculatedCart] = useState<ObjectKeys>();
    const [totalPrice, setTotalPrice] = useState<number>(0);

    const buttonZeroLabel = zeroPrice && prevent && t('pages.agency.subscription.order').text;

    const buttonLabel = prevent
        ? t('pages.agency.subscription.pay').text
        : t('pages.agency.subscription.activate').text;

    const { servicesData } = useServicesStore();

    useEffect(() => {
        const updatedItems: ObjectKeys[] = cart.items.map((cartItem: ObjectKeys) => {
            // Check if the current cart item exists in the item.services.data
            const isInServiceData: boolean = item.services.data.some(
                (serviceItem: ObjectKeys) => serviceItem.id === cartItem.id,
            );

            if (isInServiceData) {
                // If there's a match in item.services.data, return cartItem with price: 0 or as needed
                return { ...cartItem, price: 0 };
            } else {
                // If no match in item.services.data, find the item in servicesData to get the price
                const serviceDataMatch = servicesData.find((service: ObjectKeys) => service.id === cartItem.id);
                if (serviceDataMatch) {
                    // If a match is found in servicesData, update the price
                    return { ...cartItem, price: serviceDataMatch.price };
                } else {
                    // If no match found in servicesData either, return the item as is or handle as needed
                    return cartItem;
                }
            }
        });

        setCalculatedCart({ items: updatedItems });
    }, [cart, item.services.data]);

    useEffect(() => {
        if (calculatedCart) {
            const calculatedPrice: number = calculatedCart.items.reduce(
                (acc: number, stuf: ObjectKeys) => acc + stuf.price * stuf.quantity,
                0,
            );
            setTotalPrice(calculatedPrice - (calculatedPrice * item.services_discount) / 100);
        }
    }, [calculatedCart]);

    return (
        <CardContainer isSelected={prevent}>
            <div>
                <Title isSelected={prevent}>{item.name}</Title>
                <h3>{formatTwoDecimals(totalPrice.toString())} RSD</h3>
                <Items>
                    <div className="item">
                        <p>{t('pages.agency.extra_services.service').text}</p>
                        <p>{t('pages.agency.extra_services.quantity').text}</p>
                    </div>
                    {calculatedCart &&
                        calculatedCart.items &&
                        calculatedCart.items.map((i: ObjectKeys) => (
                            <div className="item" key={i.id}>
                                <p>{i[`name_${lang}`]}</p>
                                <p>{i.quantity}</p>
                            </div>
                        ))}
                </Items>
            </div>
            <Activate>
                <Button
                    variant={ButtonVariant.solid}
                    color={prevent ? 'var(--white)' : colors.purple}
                    className="small"
                    size={150}
                    onClick={() => {
                        prevent ? onClicked() : navigate(`/settings/subscription/1/${item.name}`);
                    }}
                >
                    <p className={prevent ? 'purple' : 'white'}>
                        {' '}
                        {zeroPrice && prevent ? buttonZeroLabel : buttonLabel}
                    </p>
                </Button>
            </Activate>
        </CardContainer>
    );
};

const Title = styled.div<{ isSelected: boolean }>`
    font-size: 20px;
    transition: background-color 0.3s;
    background-color: 'transparent';
    color: 'var(--purple)';
    padding: 5px;
    font-size: 24px;

    color: ${(props) => (props.isSelected ? 'var(--white)' : 'var(--purple)')};
`;

const Activate = styled.div`
    margin-top: 40px;

    padding: 5px;
    display: flex;
    justify-content: space-around;
    .purple {
        color: var(--purple);
    }
    .white {
        color: var(--white);
    }
    button {
        border-radius: 0;
    }
`;

const Items = styled.div`
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    text-align: left;

    .item {
        display: grid;
        grid-template-columns: 3fr 1fr; /* Adjust the ratio according to your needs */
        align-items: center;
        padding: 10px;
        font-size: 18px;
        gap: 10px; /* Adjust the space between columns */

        > p {
            margin: 0; /* Reset margin for p elements */
        }

        > p:first-child {
            white-space: normal; /* Allow text wrapping */
            overflow: visible; /* Show wrapped text */
            text-overflow: clip; /* Default behavior, but for clarity */
            width: 100%; /* Ensure full width */
        }

        /* Updated rules for the second and third columns */
        > p:nth-child(2) {
            text-align: center; /* Align quantity to the right */
            white-space: nowrap; /* Prevent wrapping to ensure visibility */
            overflow: visible; /* Display overflow if any */
            text-overflow: clip; /* Remove ellipsis */
            width: 100%; /* Ensure full width */
        }
        > p:nth-child(3) {
            text-align: right; /* Align quantity and price to the right */
            white-space: nowrap; /* Prevent wrapping to ensure visibility */
            overflow: visible; /* Display overflow if any */
            text-overflow: clip; /* Remove ellipsis */
            width: 100%; /* Ensure full width */
            min-width: 40px;
        }
    }

    @media (max-width: ${MQ_BREAKPOINTS.tablet}) {
        .item {
            font-size: 16px;
            padding: 5px;
            grid-template-columns: 2fr 1fr 1fr; /* Adjust the ratio for smaller screens */

            > p {
                padding: 0 5px; /* Adjust padding */
            }

            > p:first-child {
                white-space: nowrap; /* Keep on one line on small screens */
                overflow: hidden;
                text-overflow: ellipsis;
            }

            /* Ensuring second and third columns are fully visible even on small screens */
            > p:nth-child(2),
            > p:nth-child(3) {
                text-align: right;
                white-space: nowrap; /* Prevent wrapping */
                overflow: visible; /* Display overflow */
                text-overflow: clip; /* Ensure full content is shown */
            }
        }
    }
`;

const CardContainer = styled.div<{ isSelected: boolean }>`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 32%;
    @media only screen and (max-width: ${MQ_BREAKPOINTS.tablet}) {
        width: 100%;
    }
    background-color: ${(props) => (props.isSelected ? 'var(--purple)' : 'var(--white)')};
    color: ${(props) => (props.isSelected ? 'var(--white)' : 'var(--gray)')};
    border: 1px solid var(--purple);
    padding: 5px;
    transition: border 0.3s, background-color 0.3s, color 0.3s;
    span {
        font-size: 18px;

        color: var(--purple);
        transition: color 0.3s;
    }
    p {
        font-size: 14px;
    }
    .oldPrice {
        text-decoration: line-through;
    }
`;

export default PackageCard;
