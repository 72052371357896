import React from 'react';

interface DangerouslySetInnerHTMLProps {
    htmlContent: string;
    className?: string;
}

const DangerouslySetInnerHTML: React.FC<DangerouslySetInnerHTMLProps> = ({ htmlContent, className }) => {
    return <div className={className} dangerouslySetInnerHTML={{ __html: htmlContent }} />;
};

export default DangerouslySetInnerHTML;
