import { FunctionComponent, useEffect, useState } from 'react';
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';

import Taxes from './taxes';
import EcoTaxes from './ecotaxes';

import styled from 'styled-components';
import useAgencyStore from '../../../store/agency';
import useLanguageStore from '../../../store/language';
import useTranslations from '../../../hooks/useTranslation';
import { useLocation, useNavigate } from 'react-router-dom';

const BankAccounts: FunctionComponent = () => {
    const { currentLang } = useLanguageStore();
    const t = useTranslations(currentLang);
    const { agency } = useAgencyStore();
    const [selectedTab, setSelectedTab] = useState<number>(0);
    const navigate = useNavigate();
    const location = useLocation();

    const handleTabSelect = (i: number) => {
        setSelectedTab(i);
        // if (i === 0) {
        //     navigate('/agency/bank-accounts?=domestic');
        // }
        // if (i === 1) {
        //     navigate('/agency/bank-accounts?=foreign');
        // }
    };

    // useEffect(() => {
    //     if (location?.search === '?=domestic') {
    //         setSelectedTab(0);
    //     } else if (location?.search === '?=foreign') {
    //         setSelectedTab(1);
    //     }
    // }, [location]);

    return (
        <>
            <Content>
                <StyledTabs selectedIndex={selectedTab} onSelect={handleTabSelect}>
                    <StyledTabList>
                        <StyledTab>Poreska rešenja</StyledTab>
                        <StyledTab>Rešenja za eko taksu</StyledTab>
                    </StyledTabList>
                    <StyledTabPanel value={0}>
                        <Taxes />
                    </StyledTabPanel>
                    <StyledTabPanel value={1}>
                        <EcoTaxes />
                    </StyledTabPanel>
                </StyledTabs>
            </Content>
        </>
    );
};
export default BankAccounts;
const StyledTabs = styled(Tabs)``;

const StyledTabList = styled(TabList)`
    display: flex;
    list-style: none;
    margin-top: 10px;
    margin-bottom: 20px;
`;

const StyledTab = styled(Tab)`
    cursor: pointer;
    padding: 6px 12px;
    border-radius: 0;
    &.react-tabs__tab--selected {
        border-bottom: 3px solid var(--purple);
        background-color: #f7f7f7;
    }
`;

const StyledTabPanel = styled(TabPanel)`
    border: 1px solid #ccc;
    border: none;
    width: 100%;
`;

const Content = styled.div`
    width: 100%;
    .center {
        text-align: center;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    display: inline-block;
    vertical-align: top;
    color: var(--black);
    .lang-selector {
        margin-top: 30px;
        margin-bottom: 30px;
    }
`;
const Header = styled.div`
    position: relative;
    border-bottom: 1px solid var(--border-color);
    padding-bottom: 20px;
    display: flex;
    .back {
        width: 30px;
        height: 30px;
        margin: 0 10px 0 0;
        vertical-align: top;
        background: var(--white);
        border: 1px solid var(--border-color);
        border-radius: 3px;
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;
        color: var(--black);
        &:hover {
            background: var(--purple);
            border: 1px solid var(--purple);
            color: var(--white);
        }
    }
`;
