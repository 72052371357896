import { FunctionComponent, useEffect, useState } from 'react';
import styled from 'styled-components';
import colors from '../../../global/colors';
import SelectComponent from '../../../components/select';
import MemorandumCard from './common/memorandumCard';
import communication from '../../../communication';
import { useNavigate } from 'react-router-dom';
import Pagination from '../../../components/pagination';
import useLanguageStore from '../../../store/language';
import useTranslations from '../../../hooks/useTranslation';
import ConfirmModal from '../../../components/confirmModal';
import Modal from './common/modal';
import Sidebar from '../../../components/sideModal';
import SendMemoModal from './common/sendModal';
import { SendDocumentFormData } from '../../../types/communication/document';
import useMemoStore from '../../../store/memos';
import { ObjectKeys } from '@/types/objectKeys';
import NoItems from '../../../components/noItems';
import Search from '../../../components/input/search';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { MQ_BREAKPOINTS } from '../../../constants/breakpoints';
import TemplateCard from './common/templateCard';
import MemoSkeleton from './common/skeleton';

export interface EmailOption {
    label: string;
    value: string;
}

const Memorandums: FunctionComponent = () => {
    const [limit, setLimit] = useState<number>(10);
    const [status, setStatus] = useState<any>('templates');
    const [find, setFind] = useState<string>('');
    const [page, setPage] = useState<string>('1');
    const [isLoader, setIsLoader] = useState<boolean>(false);
    const [sendModalVisible, setSendModalVisible] = useState<boolean>(false);
    const [selectedMemorandumId, setSelectedMemorandumId] = useState<string | undefined>('');
    const [isConfirmModalActive, setIsConfirmModalActive] = useState<boolean>(false);
    const [showSearch, setShowSearch] = useState(false);
    const navigate = useNavigate();
    const { currentLang } = useLanguageStore();
    const t = useTranslations(currentLang);
    const { memoData } = useMemoStore();

    const [clickedMemo, setClickedMemo] = useState<ObjectKeys>({});

    const [showTemplates, setShowTemplates] = useState<boolean>(false);

    const [search, setSearch] = useState('');

    const [sendData, setSendData] = useState<SendDocumentFormData>({
        recipient: '',
        subject: '',
        message: '',
    });
    const [items, setItems] = useState([
        {
            id: '1',
            label: t('pages.memos.newMemo').text,
            description: t('pages.memos.newMemoDescription').text,
            handler: () =>
                navigate('/memorandums/new', {
                    state: { name: t('pages.memos.newMemo').text, template: '1' },
                }),
            custom: false,
        },
        {
            id: '2',
            label: t('pages.memos.claim').text,
            description: t('pages.memos.claimDescription').text,
            handler: () =>
                navigate('/memorandums/new', { state: { name: t('pages.memos.claim').text, template: '2' } }),
            custom: false,
        },
        {
            id: '3',
            label: t('pages.memos.bid').text,
            description: t('pages.memos.bidDescription').text,
            handler: () => navigate('/memorandums/new', { state: { name: t('pages.memos.bid').text, template: '3' } }),
            custom: false,
        },
        {
            id: '4',
            label: t('pages.memos.statementOfDelay').text,
            description: t('pages.memos.statementOfDelayDescription').text,
            handler: () =>
                navigate('/memorandums/new', {
                    state: { name: t('pages.memos.statementOfDelay').text, template: '4' },
                }),
            custom: false,
        },
        {
            id: '5',
            label: t('pages.memos.decisionOfTravel').text,
            description: t('pages.memos.decisionOfTravelDescription').text,
            handler: () =>
                navigate('/memorandums/new', {
                    state: { name: t('pages.memos.decisionOfTravel').text, template: '5' },
                }),
            custom: false,
        },
        {
            id: '6',
            label: t('pages.memos.statementOfSigning').text,
            description: t('pages.memos.statementOfSigningDescription').text,
            handler: () =>
                navigate('/memorandums/new', {
                    state: { name: t('pages.memos.statementOfSigning').text, template: '6' },
                }),
            custom: false,
        },
        {
            id: '7',
            label: t('pages.memos.employmentIncomeConfirmation').text,
            description: t('pages.memos.employmentIncomeConfirmationDescription').text,
            handler: () =>
                navigate('/memorandums/new', {
                    state: { name: t('pages.memos.employmentIncomeConfirmation').text, template: '7' },
                }),
            custom: false,
        },
        {
            id: '8',
            label: t('pages.memos.loanAgreement').text,
            description: t('pages.memos.loanAgreementDescription').text,
            handler: () =>
                navigate('/memorandums/new', {
                    state: { name: t('pages.memos.loanAgreement').text, template: '8' },
                }),
            custom: false,
        },
    ]);
    const showSelectOptions = [
        {
            value: 10,
            label: '10',
        },
        {
            value: 20,
            label: '20',
        },
        {
            value: 30,
            label: '30',
        },
        {
            value: 40,
            label: '40',
        },
    ];
    const statusSelectOptions = [
        {
            value: 'templates',
            label: t('pages.memos.templates').text,
        },
        {
            value: 'all',
            label: t('pages.memos.all').text,
        },
        {
            value: 'preparation',
            label: t('pages.memos.preparation').text,
        },
        {
            value: 'sent',
            label: t('pages.memos.ready').text,
        },
    ];

    const filteredItems = items.filter((item) => item.label.toLowerCase().includes(search.toLowerCase()));

    const getMemorandums = async (): Promise<void> => {
        setIsLoader(true);
        let updatedStatus = status;
        let updatedLimit = limit;
        if (status === 'templates') {
            updatedStatus = 'all';
            updatedLimit = 5000;
        }

        communication.getAllMemorandums(page, updatedLimit, updatedStatus, find).then((res: ObjectKeys) => {
            useMemoStore.setState({ memoData: res.data });

            // Keep core items (those with custom: false) to ensure they are always included
            const coreItems = items.filter((item) => item.custom === false);

            // Filter and map preferred memorandums from the API response
            const preferredItems = res.data.data
                .filter((item: ObjectKeys) => item.preferred) // Filter for preferred items
                .map((item: ObjectKeys) => ({
                    id: item.id,
                    label: item.heading,
                    description: item.description,
                    handler: () => navigate(`/memorandums/copy/${item.id}`),
                    custom: true,
                }));

            // Combine core items with unique preferred items
            const updatedItems = [
                ...coreItems,
                ...preferredItems.filter(
                    (preferredItem: ObjectKeys) => !coreItems.some((item) => item.id === preferredItem.id),
                ),
            ];

            setItems(updatedItems); // Update the items state with core and preferred items
            setIsLoader(false);
        });
    };

    useEffect(() => {
        if (status !== 'templates') {
            getMemorandums();
        } else {
            getMemorandums();
            setShowTemplates(true);
        }
    }, [page, status, limit]);

    const handlePageClick = (e: any): void => {
        setPage(e.selected + 1);
    };

    const handleEdit = (id: string | undefined): void => {
        navigate(`/memorandums/edit/${id}`);
    };

    const handleSend = async (memo: ObjectKeys): Promise<void> => {
        setClickedMemo(memo);
        setSelectedMemorandumId(memo.id);
        setSendModalVisible(true);
    };

    const handleDelete = async (id: string | undefined): Promise<void> => {
        setSelectedMemorandumId(id);
        setIsConfirmModalActive(true);
    };

    const deleteMemorandum = async (): Promise<void> => {
        setIsConfirmModalActive(false);
        setIsLoader(true);
        communication
            .deleteMemorandum(selectedMemorandumId)
            .then((res: ObjectKeys) => {
                if (res.status === 204) {
                    setIsLoader(false);
                    toast.success(t('pages.memos.deleteMemoSuccess').text);
                    getMemorandums();
                }
            })
            .catch((err: any) => {
                setIsLoader(false);
                console.error(err);
                toast.error(t('pages.memos.deleteMemoError').text);
            });
    };

    return (
        <PageWrapper>
            <ToastContainer />
            <Modal modalVisible={isConfirmModalActive} closeModal={() => setIsConfirmModalActive(false)}>
                <ConfirmModal
                    close={() => setIsConfirmModalActive(false)}
                    message={t('pages.memos.deleteMemo').text}
                    action={deleteMemorandum}
                />
            </Modal>
            {sendModalVisible && (
                <Sidebar close={() => setSendModalVisible(false)}>
                    <SendMemoModal
                        close={() => setSendModalVisible(false)}
                        setFormData={setSendData}
                        formData={sendData}
                        t={t}
                        success={() => {
                            getMemorandums().then(() => setIsLoader(false));
                        }}
                        memorandum={clickedMemo}
                        loading={() => setIsLoader(true)}
                        memoId={selectedMemorandumId}
                        modalVisible={sendModalVisible}
                    />
                </Sidebar>
            )}
            <Header>
                <div className="title-and-button">
                    <h1>{t('navbar.pausal.memorandums').text}</h1>
                </div>
            </Header>
            {showTemplates ? (
                <>
                    <TemplateFilters>
                        <div className="statuses-wrapper">
                            <ul>
                                {statusSelectOptions.map((s) => {
                                    return (
                                        <li
                                            key={s.value}
                                            onClick={() => {
                                                if (s.value === 'templates') {
                                                    setShowTemplates(true);
                                                    setStatus(s.value);
                                                } else {
                                                    setPage('1');
                                                    setShowTemplates(false);
                                                    setStatus(s.value);
                                                }
                                            }}
                                            className={`status-item ${s.value === status ? 'active' : ''}`}
                                        >
                                            {s.label}
                                        </li>
                                    );
                                })}
                            </ul>
                        </div>

                        <div className="filter-bottom">
                            <div className="filter-search">
                                <Search
                                    placeholder={t('pages.memos.filters.search').text}
                                    value={search}
                                    onChange={(value: string) => setSearch(value)}
                                    noteColor={colors.white}
                                    className="select-search-filter"
                                />
                            </div>
                        </div>
                    </TemplateFilters>
                    <Content>
                        {isLoader ? (
                            Array.from({ length: limit }, (_, index) => <MemoSkeleton key={index} />)
                        ) : (
                            <>
                                {filteredItems.map((item, index) => (
                                    <TemplateCard
                                        key={index}
                                        title={item.label}
                                        description={item.description}
                                        onClick={item.handler}
                                        isCustomCard={item.custom}
                                        memo={item}
                                        handleDelete={handleDelete}
                                    />
                                ))}
                            </>
                        )}
                    </Content>
                </>
            ) : (
                <>
                    <Filters>
                        <div className="statuses-wrapper">
                            <ul>
                                {statusSelectOptions.map((s) => {
                                    return (
                                        <li
                                            key={s.value}
                                            onClick={() => {
                                                if (s.value === 'templates') {
                                                    setShowTemplates(true);
                                                    setStatus(s.value);
                                                } else {
                                                    setPage('1');
                                                    setShowTemplates(false);
                                                    setStatus(s.value);
                                                }
                                            }}
                                            className={`status-item ${s.value === status ? 'active' : ''}`}
                                        >
                                            {s.label}
                                        </li>
                                    );
                                })}
                            </ul>
                        </div>

                        <div className="filter-bottom">
                            <div className="filter">
                                <label className="show-2">{t('pages.memos.filters.show.label').text}</label>
                                <SelectComponent
                                    optionList={showSelectOptions}
                                    defaultSelectedOption={showSelectOptions.find((s) => s.value === limit)}
                                    color={colors.gray}
                                    className={'select-items'}
                                    handleSelectedValue={(value: any) => {
                                        setLimit(value);
                                    }}
                                    width={'70px'}
                                />
                                <label className="show-2">{t('pages.memos.memoPlural').text}</label>
                            </div>
                            <div className="filter-search">
                                <Search
                                    placeholder={t('pages.memos.filters.search').text}
                                    value={find}
                                    onChange={(value: string) => {
                                        setShowSearch(true);
                                        setFind(value);
                                    }}
                                    onLeaveOrEnter={(value: string) => {
                                        setFind(value);
                                        setShowSearch(false);
                                        getMemorandums();
                                    }}
                                    showSearchNote={showSearch}
                                    noteColor={colors.white}
                                    className="select-search-filter"
                                />
                            </div>
                        </div>
                    </Filters>
                    <Content>
                        {isLoader ? (
                            Array.from({ length: limit }, (_, index) => <MemoSkeleton key={index} />)
                        ) : (
                            <>
                                {memoData && memoData.data && memoData.data.length > 0 ? (
                                    memoData?.data?.map((memorandum: any, index: any) => (
                                        <MemorandumCard
                                            key={index}
                                            memorandum={memorandum}
                                            handleSend={(memo: ObjectKeys) => handleSend(memo)}
                                            handleEdit={handleEdit}
                                            handleDelete={handleDelete}
                                        />
                                    ))
                                ) : (
                                    <NoItems text={t('pages.memos.noMemos').text} />
                                )}
                            </>
                        )}
                    </Content>
                    {memoData?.data?.length > 0 && memoData?.meta?.pagination?.total_pages > 1 && (
                        <Pagination
                            pageCount={memoData?.meta?.pagination?.total_pages}
                            initialPage={0}
                            onPageChange={(e: object) => {
                                handlePageClick(e);
                            }}
                            nextLabel={(memoData?.meta?.pagination?.current_page ===
                            memoData?.meta?.pagination?.total_pages
                                ? ''
                                : '>'
                            ).toString()}
                            previousLabel={(memoData?.meta?.pagination?.current_page === 1 ? '' : '<').toString()}
                        />
                    )}
                </>
            )}
        </PageWrapper>
    );
};

const PageWrapper = styled.div`
    min-height: 100vh;
    display: inline-block;
    vertical-align: top;
    width: -webkit-fill-available;
    width: -moz-available;
`;
const Header = styled.div`
    padding-bottom: 20px;
    position: relative;
    display: inline-block;
    width: 100%;
    height: 65px;
    @media (max-width: ${MQ_BREAKPOINTS.tablet}) {
        flex-direction: column;
    }
    .title-and-button {
        display: flex;
        align-items: center;
        padding-bottom: 20px;
        border-bottom: 1px solid var(--border-color);
        gap: 35px;
        @media only screen and (max-width: ${MQ_BREAKPOINTS.tablet}) {
            flex-direction: column;
            align-items: flex-start;
            gap: 1.5rem;
        }
    }

    button {
        font-size: 12px;
    }

    .header-options-buttons {
        position: relative;
        display: inline-block;
    }
    .buttons {
        color: var(--purple);
        display: inline-block;
        &:first-child {
            margin-right: 10px;
        }
    }
    .input-container {
        margin-bottom: 0;
    }
    .find {
        background-color: var(--white);
        padding: 20px 0px 20px 30px;
    }
    .find-icon {
        position: absolute;
        align-self: flex-start;
        justify-self: center;
        top: 50%;
        transform: translateY(-50%);
        left: 9px;
    }
`;

const Content = styled.div`
    margin-top: 15px;
    display: flex;
    gap: 2rem;
    flex-wrap: wrap;
    align-items: stretch;
    @media (max-width: ${MQ_BREAKPOINTS.tablet}) {
        justify-content: space-around;
    }
`;

const TemplateFilters = styled.div`
    display: inline-block;
    width: 100%;
    padding: 20px 0;

    .statuses-wrapper {
        display: flex;
        background-color: var(--purple);
        padding: 10px 30px;
        align-items: center;
        p,
        li {
            color: var(--white);
            text-transform: uppercase;
            font-size: 14px;
        }
        ul {
            display: flex;
            .status-item {
                cursor: pointer;
                padding: 11px 1rem;
                list-style: none;
                &.active {
                    border-bottom: 3px solid var(--white);
                }
            }
            @media screen and (max-width: ${MQ_BREAKPOINTS.mobileL}) {
                display: block;
            }
        }
        @media screen and (min-width: ${MQ_BREAKPOINTS.tablet}) and (max-width: ${MQ_BREAKPOINTS.laptop}) {
            height: 65px;
        }
        @media screen and (min-width: ${MQ_BREAKPOINTS.laptopM}) and (max-width: ${MQ_BREAKPOINTS.laptopL}) {
            height: 65px;
        }
        @media screen and (min-width: ${MQ_BREAKPOINTS.desktop}) and (max-width: ${MQ_BREAKPOINTS.desktopL}) {
            height: 65px;
        }
        @media screen and (min-width: ${MQ_BREAKPOINTS.ultraWide}) {
            height: 65px;
        }
    }
    .filter-bottom {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        width: 100%;
        gap: 1.5rem;
        margin-top: 20px;
        @media (max-width: ${MQ_BREAKPOINTS.tablet}) {
            flex-direction: column;
            align-items: flex-start;
        }
        .filter {
            display: flex;
            align-items: center;
            gap: 5px;
        }
        .show-2 {
            font-size: 14px;
            color: var(--gray);
            text-transform: uppercase;
        }
    }
`;

const Filters = styled.div`
    display: inline-block;
    width: 100%;
    padding: 20px 0;

    .statuses-wrapper {
        display: flex;
        background-color: var(--purple);
        padding: 10px 30px;
        align-items: center;
        p,
        li {
            color: var(--white);
            text-transform: uppercase;
            font-size: 14px;
        }
        ul {
            display: flex;
            .status-item {
                cursor: pointer;
                padding: 11px 1rem;
                list-style: none;
                &.active {
                    border-bottom: 3px solid var(--white);
                }
            }
            @media screen and (max-width: ${MQ_BREAKPOINTS.mobileL}) {
                display: block;
            }
        }
        @media screen and (min-width: ${MQ_BREAKPOINTS.tablet}) and (max-width: ${MQ_BREAKPOINTS.laptop}) {
            height: 65px;
        }
        @media screen and (min-width: ${MQ_BREAKPOINTS.laptopM}) and (max-width: ${MQ_BREAKPOINTS.laptopL}) {
            height: 65px;
        }
        @media screen and (min-width: ${MQ_BREAKPOINTS.desktop}) and (max-width: ${MQ_BREAKPOINTS.desktopL}) {
            height: 65px;
        }
        @media screen and (min-width: ${MQ_BREAKPOINTS.ultraWide}) {
            height: 65px;
        }
    }
    .filter-bottom {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        gap: 1.5rem;
        margin-top: 20px;
        @media (max-width: ${MQ_BREAKPOINTS.tablet}) {
            flex-direction: column;
            align-items: flex-start;
        }
        .filter {
            display: flex;
            align-items: center;
            gap: 5px;
        }
        .show-2 {
            font-size: 14px;
            color: var(--gray);
            text-transform: uppercase;
        }
    }
`;

export default Memorandums;
