export const isPathActive = (link: string, otherActivePaths: string[] | undefined, currentPath: string): boolean => {
    if (link === currentPath) {
        return true;
    }

    if (otherActivePaths) {
        return otherActivePaths.some((path) => {
            if (path.includes('*')) {
                const basePath = path.split('*')[0]; // Get the part before the '*'
                return currentPath.startsWith(basePath);
            }
            return path === currentPath;
        });
    }

    return false;
};
