import communication from '../../../communication';
import useInvoicesStore from '../../../store/invoices';
import useInvoiceParamsStore from '../../../store/invoiceParams';

const getAllAInvoices = async (): Promise<boolean> => {
    const params = useInvoiceParamsStore.getState().advances_params;
    try {
        const res = await communication.getInvoices(params);

        if (res.status === 200) {
            useInvoicesStore.setState({ advanceInvoiceData: res.data });
            return true;
        } else {
            return false;
        }
    } catch (error) {
        console.error('Error:', error);
        return false;
    }
};

export default getAllAInvoices;
