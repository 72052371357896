import { FunctionComponent, useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark, faSave, faPlus, faTrash } from '@fortawesome/free-solid-svg-icons';
import styled from 'styled-components';
import colors from '../../../../global/colors';
import { ObjectKeys } from '../../../../types/objectKeys';
import Button, { ButtonVariant } from '../../../../components/button';
import Input, { InputComponentType } from '../../../../components/input';
import communication from '../../../../communication';
import Loader from '../../../../components/loader';
import Select from '../../../../components/select';

interface Props {
    t: Function;
    formData: ObjectKeys;
    close: Function;
    success: Function;
    loading: Function;
    id?: string;
    modalVisible: boolean;
    errorMessage: Function;
}

interface Service {
    id: string;
    name_rs: string;
    name_en: string;
    name_ru: string;
    description_rs: string;
    description_en: string;
    description_ru: string;
    price: number;
}

const EditModal: FunctionComponent<Props> = ({ t, formData, close, modalVisible, success }) => {
    const [editedFormData, setEditedFormData] = useState<ObjectKeys>();
    const [questionModal, setQuestionModal] = useState(false);
    const [loader, setLoader] = useState<boolean>(false);
    const [services, setServices] = useState<Service[]>();
    const [hasEffectRun, setHasEffectRun] = useState(false);
    const [errorMessage, setErrorMessage] = useState<boolean>(false);

    const monthsOptions = [
        { value: 1, label: '1' },
        { value: 2, label: '2' },
        { value: 3, label: '3' },
        { value: 4, label: '4' },
        { value: 5, label: '5' },
        { value: 6, label: '6' },
        { value: 7, label: '7' },
        { value: 8, label: '8' },
        { value: 9, label: '9' },
        { value: 10, label: '10' },
        { value: 11, label: '11' },
        { value: 12, label: '12' },
    ];

    useEffect(() => {
        if (!modalVisible) return;
        setEditedFormData(formData);
    }, [modalVisible]);

    const handleRemoveService = (serviceId: number): void => {
        if (!editedFormData || !services) return;

        const index = editedFormData.services.data.findIndex((service: ObjectKeys) => service.id === serviceId);

        if (index !== -1) {
            const updatedEditedFormData = {
                ...editedFormData,
                services: {
                    data: editedFormData.services.data.filter((service: ObjectKeys) => service.id !== serviceId),
                },
            };

            const removedService = editedFormData.services.data[index];
            setServices([...services, removedService]);
            setEditedFormData(updatedEditedFormData);
        }
    };

    const handleAddService = (service: ObjectKeys): void => {
        if (!editedFormData || !services) return;

        const updatedServicesData = [...editedFormData.services.data, service];
        const updatedServices = services.filter((s: ObjectKeys) => s.id !== service.id);

        const updatedEditedFormData = {
            ...editedFormData,
            services: { data: updatedServicesData },
        };

        setServices(updatedServices);
        setEditedFormData(updatedEditedFormData);
    };

    const updatePackage = (): void => {
        if (!editedFormData) return;
        const editedId = editedFormData.id;
        const serviceIdsOnly = editedFormData.services.data.map((service: Service) => service.id);

        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const { id, ...updatedFormDataWithoutId } = editedFormData;
        const updatedFormData = {
            ...updatedFormDataWithoutId,
            services: serviceIdsOnly,
        };
        communication
            .updatePackage(editedId, updatedFormData)
            .then(() => {
                success();
            })
            .catch((error: any) => {
                console.error('Error:', error);
                setLoader(false);
            });
    };

    // const resetForm = (): void => {
    //     setEditedFormData({});
    // };
    const getAllServices = (): void => {
        setLoader(true);
        communication
            .getAllAdminServices({ page: 1, limit: 1000, orderBy: 'name_rs', sortedBy: 'asc' })
            .then((response: ObjectKeys) => {
                // Assuming response.data.data and editedFormData.services?.data are arrays of objects with an 'id' property
                const newServices = response.data.data.filter(
                    (service: Service) =>
                        !editedFormData?.services?.data.some(
                            (existingService: Service) => existingService.id === service.id,
                        ),
                );

                setServices(newServices);
                setLoader(false);
            })
            .catch((error: any) => {
                console.error('Error:', error);
                setLoader(false);
            });
    };

    useEffect(() => {
        if (editedFormData && !hasEffectRun) {
            getAllServices();
            setHasEffectRun(true);
        }
    }, [editedFormData, hasEffectRun]);

    return (
        <>
            {loader && <Loader />}
            {questionModal ? (
                <>
                    <Question>
                        <p>Da li ste sigurni da želite da sačuvate izmene?</p>
                        <div className="button-container">
                            <Button
                                variant={ButtonVariant.solid}
                                color={colors.purple}
                                className="small"
                                size={200}
                                onClick={() => {
                                    updatePackage();
                                    close();
                                }}
                            >
                                <FontAwesomeIcon icon={faSave} style={{ color: 'var(--white)', marginRight: '10px' }} />
                                Sacuvaj izmene
                            </Button>
                            <Button
                                variant={ButtonVariant.solid}
                                color={colors.purple}
                                className="small"
                                size={200}
                                onClick={() => {
                                    setQuestionModal(false);
                                }}
                            >
                                <FontAwesomeIcon
                                    icon={faXmark}
                                    style={{ color: 'var(--white)', marginRight: '10px' }}
                                />
                                Odustani
                            </Button>
                        </div>
                    </Question>
                </>
            ) : (
                <>
                    {editedFormData && (
                        <Container>
                            <Header>
                                <h2>Izmeni {editedFormData.name} paket</h2>
                                <Icon onClick={() => close()}>
                                    <FontAwesomeIcon icon={faXmark} style={{ color: 'var(--gray)' }} />
                                </Icon>
                            </Header>
                            <br />
                            <Input
                                type={InputComponentType.Text}
                                label={'Naziv paketa'}
                                value={editedFormData.name}
                                onChange={(value: string) => {
                                    setEditedFormData({
                                        ...editedFormData,
                                        name: value,
                                    });
                                }}
                            />
                            <br />
                            <Input
                                type={InputComponentType.Amount}
                                label={'Mesecna cena (RSD)'}
                                value={editedFormData.monthly}
                                onChange={(value: any) => {
                                    setEditedFormData({
                                        ...editedFormData,
                                        monthly: value,
                                    });
                                }}
                            />
                            <p>Popusti:</p>
                            <div className="discount">
                                <label>Polugodisnja:</label>
                                <span>
                                    <Input
                                        type={InputComponentType.Number}
                                        value={editedFormData.half_year_discount}
                                        onChange={(value: any) => {
                                            setEditedFormData({
                                                ...editedFormData,
                                                half_year_discount: value,
                                            });
                                        }}
                                        className="discount-input"
                                    />
                                    %
                                </span>
                            </div>
                            <div className="discount">
                                <label>Godisnja:</label>
                                <span>
                                    <Input
                                        type={InputComponentType.Number}
                                        value={editedFormData.year_discount}
                                        onChange={(value: any) => {
                                            setEditedFormData({
                                                ...editedFormData,
                                                year_discount: Number(value),
                                            });
                                        }}
                                        className="discount-input"
                                    />
                                    %
                                </span>
                            </div>
                            <div className="discount">
                                <label>Dodatne usluge:</label>
                                <span>
                                    <Input
                                        type={InputComponentType.Number}
                                        value={editedFormData.services_discount}
                                        onChange={(value: any) => {
                                            setEditedFormData({
                                                ...editedFormData,
                                                services_discount: Number(value),
                                            });
                                        }}
                                        className="discount-input"
                                    />
                                    %
                                </span>
                            </div>
                            <div className="six-months">
                                <label>Mesecna obaveza:</label>
                                <Select
                                    isSearchable={false}
                                    optionList={monthsOptions}
                                    defaultSelectedOption={monthsOptions.find(
                                        (option) => option.value === editedFormData.months,
                                    )}
                                    handleSelectedValue={(value: number) => {
                                        setEditedFormData({
                                            ...editedFormData,
                                            months: value,
                                        });
                                    }}
                                    placeholder={'Izaberi broj meseci'}
                                />
                            </div>
                            <div>
                                <p className="services">Usluge koje su uključene u paket:</p>
                                {editedFormData && editedFormData?.services?.data?.length > 0 ? (
                                    <>
                                        {editedFormData?.services?.data?.map((service: ObjectKeys) => (
                                            <div key={service.id}>
                                                <p
                                                    className="items"
                                                    onClick={() => {
                                                        handleRemoveService(service.id);
                                                    }}
                                                >
                                                    <FontAwesomeIcon
                                                        icon={faTrash}
                                                        style={{ color: 'var(--danger)' }}
                                                    />
                                                    {service.name_rs}
                                                </p>
                                            </div>
                                        ))}
                                    </>
                                ) : (
                                    <p>Nema usluga</p>
                                )}
                            </div>
                            <br />
                            <div>
                                <p className="services">Dodaj uslugu u paket:</p>
                                {services && services.length > 0 ? (
                                    <>
                                        {services.map((service: ObjectKeys) => (
                                            <div key={service.id}>
                                                <p
                                                    className="items"
                                                    onClick={() => {
                                                        handleAddService(service);
                                                    }}
                                                >
                                                    <FontAwesomeIcon icon={faPlus} style={{ color: 'var(--purple)' }} />
                                                    {service.name_rs}
                                                </p>
                                            </div>
                                        ))}
                                    </>
                                ) : (
                                    <p>Nema usluga</p>
                                )}
                            </div>
                            {errorMessage && (
                                <p style={{ color: 'var(--danger)', marginTop: '20px', textAlign: 'center' }}>
                                    Sva polja moraju biti popunjena
                                </p>
                            )}

                            <div className="button-container">
                                <Button
                                    variant={ButtonVariant.solid}
                                    color={colors.purple}
                                    className="small"
                                    size={200}
                                    onClick={() => {
                                        if (
                                            editedFormData?.name === '' ||
                                            editedFormData?.monthly === '' ||
                                            editedFormData?.months === '' ||
                                            editedFormData?.services?.data === ''
                                        ) {
                                            setErrorMessage(true);
                                            return;
                                        } else {
                                            setErrorMessage(false);
                                            setQuestionModal(true);
                                        }
                                    }}
                                >
                                    <FontAwesomeIcon
                                        icon={faSave}
                                        style={{ color: 'var(--white)', marginRight: '10px' }}
                                    />
                                    Sacuvaj izmene
                                </Button>
                            </div>
                        </Container>
                    )}
                </>
            )}
        </>
    );
};

export default EditModal;
const Container = styled.div`
    .button-container {
        margin-top: 30px;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .input-container {
        width: 100%;
    }
    label {
        color: var(--gray);
    }
    .six-months {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: 20px;
        margin-bottom: 20px;
    }
    .services {
        font-weight: bold;
        margin-bottom: 15px;
    }
    .items {
        margin-left: 5px;
        margin-bottom: 5px;
        display: flex;
        gap: 10px;
        cursor: pointer;
    }
    .discount {
        display: flex;
        justify-content: space-between;
        align-items: center;
        span {
            display: flex;
            align-items: center;
            font-size: 18px;
        }
    }
    .discount-input {
        width: 15%;
    }
`;

const Header = styled.div`
    display: grid;
    grid-template-columns: 95% 5%;
    h2 {
        font-size: 24px;
        font-weight: 400;
        text-align: left;
    }
    p {
        width: 90%;
        font-size: 14px;
        line-height: 20px;
        margin-top: 10px;
        color: var(--gray);
        margin-bottom: 30px;
    }
`;
const Question = styled.div`
    display: flex;
    height: 50%;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 20px;
    p {
        font-size: 20px;
        font-weight: 700;
        color: var(--danger);
        text-align: center;
    }
    .button-container {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 20px;
    }
`;

const Icon = styled.div`
    float: right;
    font-size: 30px;
    margin-top: -8px;
    &.attach {
        font-size: 13px;
        color: var(--gray);
        float: left;
        margin-top: 0px;
    }
`;
