import React from 'react';
import styled from 'styled-components';
import Button, { ButtonVariant } from '../../../../components/button';
import colors from '../../../../global/colors';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPowerOff, faEdit, faTrash } from '@fortawesome/free-solid-svg-icons';

interface PackageProps {
    name: string;
    monthlyPrice: number;
    half_year_discount: number;
    year_discount: number;
    services_discount: number;
    services: {
        id: string;
        name_rs: string;
        name_en: string;
        name_ru: string;
        description_rs: string;
        description_en: string;
        description_ru: string;
        price: number;
    }[];
    six_months: number;
    active: boolean;
    onEdit: () => void;
    onDelete: () => void;
    onActivate: () => void;
}

const Package: React.FC<PackageProps> = ({
    name,
    monthlyPrice,
    half_year_discount,
    year_discount,
    services_discount,
    services,
    six_months,
    active,
    onEdit,
    onDelete,
    onActivate,
}) => {
    const handleEdit = (): void => {
        onEdit();
    };

    const handleDelete = (): void => {
        onDelete();
    };

    const handleActivate = (): void => {
        onActivate();
    };

    return (
        <StyledPackage>
            <Title active={active}>
                <h2>{name}</h2>
            </Title>
            <ActionContainer>
                {active ? (
                    <Button
                        variant={ButtonVariant.solid}
                        color={'transparent'}
                        size={'30%'}
                        onClick={() => handleActivate()}
                    >
                        <FontAwesomeIcon icon={faPowerOff} color={colors.danger} />
                        <span className="buton-title">Deaktiviraj</span>
                    </Button>
                ) : (
                    <Button
                        variant={ButtonVariant.solid}
                        color={'transparent'}
                        size={'30%'}
                        onClick={() => handleActivate()}
                    >
                        <FontAwesomeIcon icon={faPowerOff} color={colors.green} />
                        <span className="buton-title">Aktiviraj</span>
                    </Button>
                )}

                <Button variant={ButtonVariant.solid} color={'transparent'} size={'30%'} onClick={() => handleEdit()}>
                    <FontAwesomeIcon icon={faEdit} color={colors.lightBlue} />
                    <span className="buton-title">Izmeni</span>
                </Button>
                <Button variant={ButtonVariant.solid} color={'transparent'} size={'30%'} onClick={() => handleDelete()}>
                    <FontAwesomeIcon icon={faTrash} color={colors.danger} />
                    <span className="buton-title">Obrisi</span>
                </Button>
            </ActionContainer>
            <Content>
                <div>
                    <p>Cena: {monthlyPrice} RSD</p>
                </div>
                <br />
                <div>
                    <p>Popusti:</p>
                    <p>Polugodisnja: {half_year_discount} %</p>
                    <p>Godisnja: {year_discount} %</p>
                    <p>Popust na usluge: {services_discount} %</p>
                </div>
                <br />
                <div>
                    <p>Obavezno meseci: {six_months}</p>
                </div>
                <br />
                <div>
                    <p className="services">Usluge koje su uključene u paket:</p>
                    {services.map((service) => (
                        <div key={service.id}>
                            <p className="items">{service.name_rs}</p>
                        </div>
                    ))}
                </div>
            </Content>
        </StyledPackage>
    );
};

const StyledPackage = styled.div`
    margin-bottom: 20px;
    cursor: pointer;
`;

const Title = styled.div<{ active: boolean }>`
    text-align: center;
    width: 100%;
    background-color: ${(props) => (props.active ? 'var(--purple)' : '#cebcff')};
    padding: 20px;
    border-radius: 20px;
    color: var(--white);
    padding-bottom: 20px;
    margin-bottom: 20px;
`;

const Content = styled.div`
    margin-bottom: 20px;
    padding: 20px;
    .services {
        font-weight: bold;
        margin-bottom: 15px;
    }
    .items {
        margin-left: 20px;
        margin-bottom: 5px;
    }
`;
const ActionContainer = styled.div`
    display: flex;
    justify-content: space-between;
    padding: 10px;
    .buton-title {
        margin-left: 5px;
        color: black;
    }
`;

export default Package;
