import fetch from './fetch';
import routes from './routes';
import { ObjectKeys } from '../types/objectKeys';
import getCurrentLanguage from '../functions/getCurrentLanguage';

export default class Currency {
    public fetchCurrencies(): ObjectKeys {
        return fetch({
            method: 'GET',
            headers: { 'Content-Type': 'application/json; charset=UTF-8', 'Accept-Language': getCurrentLanguage() },
            url: routes.getCurrencies,
        });
    }
    public fetchPickedCurrencies(date: string): ObjectKeys {
        return fetch({
            method: 'GET',
            headers: { 'Content-Type': 'application/json; charset=UTF-8', 'Accept-Language': getCurrentLanguage() },
            url: routes.getPickedCurrencies(date),
        });
    }
}
