import { FunctionComponent, useEffect, useState } from 'react';
import { Row, StyledInput } from '../style';
import SelectSearch from '../../../../../components/searchCreateNew';
import Input, { InputComponentType } from '../../../../../components/input';
import { ObjectKeys } from '../../../../../types/objectKeys';
import colors from '../../../../../global/colors';
import { formatToSerbianNumber } from '../../../../../functions/format';
import useServiceNumberValidation from '../../../../../hooks/useServiceNumberValidation';
import styled from 'styled-components';

type SelectOption = {
    value?: string;
    label?: string;
};

interface Props {
    index: number;
    service: ObjectKeys;
    services: ObjectKeys[];
    units: ObjectKeys[];
    setFormData: Function;
    handleTotal: Function;
    invoice: ObjectKeys;
    t: Function;
    setUnits?: Function;
    handleServiceClick: Function;
}

const WebService: FunctionComponent<Props> = ({
    index,
    service,
    services,
    units,
    setFormData,
    handleTotal,
    invoice,
    t,
    setUnits,
    handleServiceClick,
}) => {
    const transformedService: ObjectKeys = {
        ...service,
        pivot: {
            ...service.pivot,
            price_per_unit: service?.pivot?.price_per_unit?.replace('.', ','),
            quantity: service?.pivot?.quantity?.replace('.', ','),
            discount: service?.pivot?.discount ? service?.pivot?.discount?.toString().replace('.', ',') : 0,
        },
    };
    const isQuantityValid = useServiceNumberValidation(transformedService.pivot.quantity);
    const isPriceValid = useServiceNumberValidation(transformedService.pivot.price_per_unit);

    const [totalServicePrice, setTotalServicePrice] = useState<number>(0);

    useEffect(() => {
        setFormData((prev: ObjectKeys) => {
            const copy = { ...prev };
            const quantity = Number(transformedService.pivot.quantity.replace(',', '.'));
            const price = Number(transformedService.pivot.price_per_unit.replace(',', '.'));
            const discount = Number(transformedService.pivot.discount.toString().replace(',', '.'));

            copy.services[index].pivot.total_price = quantity * price - (quantity * price * discount) / 100;
            setTotalServicePrice(copy.services[index].pivot.total_price);
            return copy;
        });
    }, [transformedService.pivot.price_per_unit, transformedService.pivot.discount]);

    useEffect(() => {
        handleTotal(
            invoice.services?.reduce((accumulator: number, object: ObjectKeys) => {
                return accumulator + (isNaN(object.pivot.total_price) ? 0 : Number(object.pivot.total_price));
            }, 0),
        );
    }, [totalServicePrice]);

    return (
        <Row className="service-list">
            <p className="type" onClick={() => handleServiceClick()}>
                {transformedService.name
                    ? transformedService.name
                    : services.find((s: ObjectKeys) => s.id === transformedService.id)?.name}
            </p>
            <UnitSelectWrapper>
                <SelectSearch
                    handleSelectedValue={(v: ObjectKeys) => {
                        setFormData((prev: ObjectKeys) => {
                            const copy = { ...prev };
                            copy.services[index].pivot.measurement_unit = v.value;
                            return copy;
                        });
                    }}
                    handleCreateOption={(c: SelectOption) => {
                        let newUnitAdded = false;
                        setFormData((prev: ObjectKeys) => {
                            const copy = { ...prev };
                            copy.services[index].pivot.measurement_unit = c.value;
                            return copy;
                        });
                        // Check if the unit already exists, if not, add it
                        if (!units.some((unit) => unit.value === c.value)) {
                            units.push({ label: c.label, value: c.value });
                            newUnitAdded = true;
                        }
                        // If a new unit was added, update the state to re-render the component
                        if (newUnitAdded && setUnits) {
                            setUnits([...units]);
                        }
                    }}
                    optionList={units.map((unit) => ({
                        label: unit.label,
                        value: unit.value,
                    }))}
                    key={units.length}
                    defaultValue={units.find((u) => u.value === transformedService.pivot.measurement_unit)}
                    color={colors.black}
                    noValuePlaceholderColor="red"
                    placeholder={t('pages.invoices.add_measurement_unit').text}
                    searchPlaceholder={t('input.search_and_add').text}
                />
            </UnitSelectWrapper>
            <StyledInput className="price">
                <Input
                    type={InputComponentType.Amount}
                    negative={true}
                    value={transformedService.pivot.quantity !== '' ? transformedService.pivot.quantity : ''}
                    readOnly={false}
                    onChange={(v: any) => {
                        {
                            setFormData((prev: ObjectKeys) => {
                                const copy = { ...prev };
                                copy.services[index].pivot.quantity = v;
                                const quantity = Number(v.replace(',', '.'));
                                const price = transformedService.pivot.price_per_unit.replace(',', '.');
                                const discount = transformedService.pivot.discount;
                                copy.services[index].pivot.total_price =
                                    quantity * price - (quantity * price * discount) / 100;
                                setTotalServicePrice(copy.services[index].pivot.total_price);
                                return copy;
                            });
                            handleTotal(
                                invoice.services?.reduce((accumulator: number, object: ObjectKeys) => {
                                    return (
                                        accumulator +
                                        (isNaN(object.pivot.total_price) ? 0 : Number(object.pivot.total_price))
                                    );
                                }, 0),
                            );
                        }
                    }}
                    hideBlurOnChange
                    handleBlurAction={() => {
                        setFormData((prev: ObjectKeys) => {
                            const copy = { ...prev };
                            while (
                                Array.from(copy.services[index].pivot.quantity)[0] === '0' &&
                                Array.from(copy.services[index].pivot.quantity)[1] !== ','
                            ) {
                                copy.services[index].pivot.quantity = copy.services[index].pivot.quantity.slice(1);
                            }
                            return copy;
                        });
                    }}
                    placeholder={t('pages.editInvoice.enterQuantity').text}
                    validation={isQuantityValid ? '' : 'error'}
                />
                <span>x</span>
            </StyledInput>
            <StyledInput className="price">
                <Input
                    type={InputComponentType.Amount}
                    value={
                        transformedService.pivot.price_per_unit !== '' ? transformedService.pivot.price_per_unit : ''
                    }
                    onChange={(v: any) => {
                        setFormData((prev: ObjectKeys) => {
                            const copy = { ...prev };
                            copy.services[index].pivot.price_per_unit = v;
                            const quantity = Number(transformedService.pivot.quantity.replace(',', '.'));
                            const price = v.replace(',', '.');
                            const discount = transformedService.pivot.discount;
                            copy.services[index].pivot.total_price =
                                quantity * price - (quantity * price * discount) / 100;
                            setTotalServicePrice(copy.services[index].pivot.total_price);
                            return copy;
                        });
                        handleTotal(
                            invoice.services?.reduce((accumulator: number, object: ObjectKeys) => {
                                return (
                                    accumulator +
                                    (isNaN(object.pivot.total_price) ? 0 : Number(object.pivot.total_price))
                                );
                            }, 0),
                        );
                    }}
                    hideBlurOnChange
                    handleBlurAction={() => {
                        setFormData((prev: ObjectKeys) => {
                            const copy = { ...prev };
                            while (
                                Array.from(copy.services[index].pivot.price_per_unit)[0] === '0' &&
                                Array.from(copy.services[index].pivot.price_per_unit)[1] !== ','
                            ) {
                                copy.services[index].pivot.price_per_unit =
                                    copy.services[index].pivot.price_per_unit.slice(1);
                            }

                            return copy;
                        });
                    }}
                    placeholder={t('pages.editInvoice.enterUnitPrice').text}
                    validation={isPriceValid ? '' : 'error'}
                />
                <span>x</span>
            </StyledInput>

            {/* DISCOUNT */}
            <StyledInput className="price">
                <Input
                    type={InputComponentType.Text}
                    value={transformedService.pivot.discount}
                    onChange={(v: any) => {
                        let discountValue = v.replace(',', '.').replace(/[^0-9.]/g, '');
                        if (discountValue.split('.').length > 2) return; // Prevent multiple dots

                        const parts = discountValue.split('.');
                        if (parts.length > 1 && parts[1].length > 2) {
                            discountValue = parts[0] + '.' + parts[1].slice(0, 2); // Limit to 2 decimal places
                        }
                        if (
                            parseFloat(discountValue) > 100 ||
                            (parseFloat(discountValue) >= 100 && discountValue.split('.').length > 1)
                        )
                            discountValue = '100'; // Limit discount to 100
                        if (discountValue.length > 1 && discountValue[0] === '0' && discountValue[1] !== '.') {
                            discountValue = discountValue.slice(1); // Remove leading zero unless it's 0
                        }

                        setFormData((prev: ObjectKeys) => {
                            const copy = { ...prev };
                            copy.services[index].pivot.discount = discountValue;
                            const quantity = Number(transformedService.pivot.quantity.replace(',', '.'));
                            const price = transformedService.pivot.price_per_unit.replace(',', '.');
                            const discount = parseFloat(discountValue);
                            if (!isNaN(discount) && discount <= 100) {
                                copy.services[index].pivot.total_price =
                                    quantity * price - (quantity * price * discount) / 100;
                                setTotalServicePrice(copy.services[index].pivot.total_price);
                            }
                            return copy;
                        });
                        handleTotal(
                            invoice.services?.reduce((accumulator: number, object: ObjectKeys) => {
                                return (
                                    accumulator +
                                    (isNaN(object.pivot.total_price) ? 0 : Number(object.pivot.total_price))
                                );
                            }, 0),
                        );
                    }}
                    hideBlurOnChange
                    handleBlurAction={() => {
                        setFormData((prev: ObjectKeys) => {
                            const copy = { ...prev };
                            while (
                                Array.from(copy.services[index].pivot.discount)[0] === '0' &&
                                Array.from(copy.services[index].pivot.discount)[1] !== '.'
                            ) {
                                copy.services[index].pivot.discount = copy.services[index].pivot.discount.slice(1);
                            }
                            return copy;
                        });
                    }}
                    placeholder={t('pages.editInvoice.enterUnitPrice').text}
                />
                <span>=</span>
            </StyledInput>

            <StyledInput className="price">
                <Input
                    type={InputComponentType.Text}
                    value={totalServicePrice ? formatToSerbianNumber(totalServicePrice).toString() : ''}
                />
                <span
                    style={{ cursor: 'pointer' }}
                    onClick={() =>
                        setFormData((prev: ObjectKeys) => {
                            const copy = { ...prev };
                            copy.services.splice(index, 1);
                            return copy;
                        })
                    }
                >
                    x
                </span>
            </StyledInput>
        </Row>
    );
};

const UnitSelectWrapper = styled.div`
    flex: 1;
    width: 100%;
    min-width: 180px;
    label {
        font-size: 15px;
        color: var(--gray);
        margin-bottom: 2px;
        display: flex;
        padding: 5px;
    }
    > div {
        width: 100%;
    }
    .select-search {
        > div {
            box-shadow: none;
        }
        .input-dropdown {
            background-color: transparent;
            box-shadow: none;
            border-radius: 5px;
            svg {
                height: 20px;
                width: 20px;
            }
        }
    }
`;

export default WebService;
