import { FunctionComponent, useEffect, useState } from 'react';
import { Row } from '../style';
import { ObjectKeys } from '../../../../../types/objectKeys';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faX } from '@fortawesome/free-solid-svg-icons';
import colors from '../../../../../global/colors';
import styled from 'styled-components';
import { formatTwoDecimals } from '../../../../../functions/format';

interface Props {
    t: Function;
    closedAdvances: ObjectKeys[];
    total: string;
    currency: string;
    onDelete: Function;
    onModifiedTotalChange: (modifiedTotal: string | number) => void;
}

const PayedAdvance: FunctionComponent<Props> = ({
    t,
    closedAdvances,
    total,
    currency,
    onDelete,
    onModifiedTotalChange,
}) => {
    const [totalForPay, settotalForPay] = useState<string | number>(0);
    const modifiedTotal = total.includes('.') ? total.replace(',', '').replace('.', ',') : total;
    const totalAdvances = closedAdvances.reduce((acc: number, object: ObjectKeys) => {
        return (
            acc +
            Number(object.value.includes(',') ? object.value.replace('.', '').replace(',', '.') : Number(object.value))
        );
    }, 0);

    function handleDelete(advance: ObjectKeys): any {
        onDelete(advance);
    }
    const setTotal = (totalPay: string | number): void => {
        onModifiedTotalChange(totalPay);
    };
    useEffect(() => {
        const modified = modifiedTotal.includes(',') ? Number(modifiedTotal.replace(',', '.')) : Number(modifiedTotal);
        const totalForPaying = Number(modified) - Number(totalAdvances);
        settotalForPay(formatTwoDecimals(totalForPaying.toString()));
        setTotal(totalForPaying);
    }, [modifiedTotal, totalAdvances]);

    return (
        <div>
            {closedAdvances.map((advance, index) => {
                const formattedValue = formatTwoDecimals(
                    advance.value.includes(',')
                        ? advance.value.replace('.', '').replace(',', '.')
                        : advance.value.toString(),
                );
                return (
                    <Row style={{ padding: '5px 5px 5px 0', marginBottom: '5px' }} key={index} className="right">
                        <ItemTitle>
                            <Delete onClick={() => handleDelete(advance)}>
                                <FontAwesomeIcon icon={faX} color={colors.gray} />
                            </Delete>
                            <p style={{ marginTop: '8px' }}>
                                {`${t('pages.advanceInvoices.payedByAdvance').text} ${
                                    advance.prefix ? advance.prefix + ' ' : ''
                                }${advance.invoice_number}`}
                            </p>
                        </ItemTitle>
                        <h3 style={{ fontSize: '20px' }} className="right">
                            {formattedValue}
                        </h3>
                    </Row>
                );
            })}
            {closedAdvances.length > 0 && (
                <Row className="right">
                    <p style={{ width: '70%' }}>
                        {t('pages.advanceInvoices.totalForPayment').text + '(' + currency + ')'}
                    </p>
                    <h3 className="right">{totalForPay}</h3>
                </Row>
            )}
        </div>
    );
};

export default PayedAdvance;

const Delete = styled.div`
    border-radius: 50%;
    background: var(--white);
    width: 22px;
    text-align: center;
    margin-top: 2px;
    :hover {
        background: var(--purple);
        svg {
            color: var(--white);
        }
    }
`;

const ItemTitle = styled.div`
    display: flex;
    width: 70%;
    justify-content: flex-end;
`;
