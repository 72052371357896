import { FunctionComponent, useState } from 'react';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import styled from 'styled-components';
import DragAndDrop from '../../../components/dragAndDrop';
import useResponsive from '../../../hooks/responsive/useResponsive';
import { MQ_BREAKPOINTS } from '../../../constants/breakpoints';
import useCurrencyStore from '../../../store/currency';
import { FOREIGN_BANK_ACCOUNTS_CURRENCIES } from '../../../constants';

const PaymentInstructions: FunctionComponent = () => {
    const data = useCurrencyStore();
    const tabs =
        data?.currency.map((currency) => ({
            label: currency.currency.toUpperCase(),
            value: currency.currency.toUpperCase(),
        })) ||
        FOREIGN_BANK_ACCOUNTS_CURRENCIES.map((currency) => ({
            label: currency.toUpperCase(),
            value: currency.toUpperCase(),
        }));
    const [tabIndex, setTabIndex] = useState(0);
    const { isMobile } = useResponsive();

    return (
        <Content className={isMobile ? 'mobile' : 'desktop'}>
            <Tabs selectedIndex={tabIndex} onSelect={(index) => setTabIndex(index)}>
                <TabList>
                    {tabs.map((tab, index) => (
                        <Tab className={'custom-tab'} key={index}>
                            {tab.label}
                        </Tab>
                    ))}
                </TabList>
                {tabs.map((tab, index) => (
                    <TabPanel key={index}>
                        <DragAndDrop type={tab.label} key={index} preventUpload={true} />{' '}
                    </TabPanel>
                ))}
            </Tabs>
        </Content>
    );
};

const Content = styled.div`
    width: 100%;
    display: inline-block;
    vertical-align: top;
    color: var(--black);
    .react-tabs__tab-list {
        border-bottom: none;
        .custom-tab {
            display: inline-block;
            position: relative;
            list-style: none;
            padding: 6px 12px;
            cursor: pointer;
            text-align: center;
            width: 14.27%;
            @media screen and (max-width: ${MQ_BREAKPOINTS.tablet}) {
                width: fit-content;
            }
        }
    }
    .react-tabs__tab--selected {
        border-right: none;
        border-left: none;
        border-top: none;
        border-bottom: 3px solid #9b7fe9 !important;
    }
`;

export default PaymentInstructions;
