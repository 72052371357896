import { create } from 'zustand';

interface CountryInterface {
    created_at: string;
    id: string;
    name: string;
    object: string;
    sign: string;
    updated_at: string;
}
interface CountriesState {
    countries: Array<CountryInterface>;
}

const useStore = create<CountriesState>(() => ({
    countries: [],
}));

export default useStore;
