import useTranslations from './useTranslation';
import useLanguageStore from '../store/language';

const useCharLengthValidation = (char: string, charLength: number, required?: boolean): string => {
    const { currentLang } = useLanguageStore();
    const t = useTranslations(currentLang);
    if (!required && char.length === 0) {
        return '';
    }

    if (!char || char.length === 0) {
        return t('pages.validationMessages.requiredField').text;
    }
    const regex = new RegExp(`.{${charLength},}`);

    const isCharValid = regex.test(char);

    if (!isCharValid) {
        if (charLength === 3) {
            return t('pages.validationMessages.threeCharsLong').text;
        } else if (charLength === 5) {
            return t('pages.validationMessages.fiveCharsLong').text;
        } else if (charLength === 14) {
            return t('pages.validationMessages.fourteenNumbers').text;
        } else if (charLength === 19) {
            return t('pages.validationMessages.nineteenNumbers').text;
        }
    }
    return '';
};
export default useCharLengthValidation;
