import { FunctionComponent, useState } from 'react';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { ObjectKeys } from '@/types/objectKeys';

interface Props {
    placeholder?: string;
    onChange?: Function;
    onSelect?: Function;
    className?: string;
    isDisabled?: boolean;
    value?: string;
    label?: string;
    options?: ObjectKeys;
    onLeaveOrEnter?: (value: string) => void;
    showSearchNote?: boolean;
    noteColor?: string;
}

const Search: FunctionComponent<Props> = ({
    placeholder,
    onChange = Function,
    className,
    isDisabled,
    value,
    label,
    options,
    onSelect,
    onLeaveOrEnter,
    showSearchNote,
    noteColor,
}) => {
    const [showOptions, setShowOptions] = useState<boolean>(false);
    const handleBlurOrEnter = (res: string): void => {
        if (onLeaveOrEnter) {
            onLeaveOrEnter(res);
        }
    };

    return (
        <Content>
            {label ? <label>{label}</label> : <></>}
            <StyledWrapper className={`search-wrapper ${className ?? ''}`.trim()}>
                <FontAwesomeIcon icon={faSearch} style={{ color: 'var(--gray)' }} />
                <input
                    onFocus={() => setShowOptions(true)}
                    placeholder={placeholder}
                    type="text"
                    onChange={(e) => {
                        onChange(e.target.value);
                    }}
                    disabled={isDisabled}
                    value={value}
                    onBlur={() => handleBlurOrEnter(value as any)}
                    onKeyDown={(e) => {
                        if (e.key === 'Enter') {
                            handleBlurOrEnter(value as any);
                        }
                    }}
                />
                {options && showOptions ? (
                    <div className={'options-list'}>
                        {options.map((option: ObjectKeys, index: number) => (
                            <div
                                key={index}
                                onClick={() => {
                                    onSelect && onSelect(option);
                                    setShowOptions(false);
                                }}
                            >
                                <p>
                                    {option.pib} - {option.email}
                                </p>
                            </div>
                        ))}
                    </div>
                ) : (
                    ''
                )}
            </StyledWrapper>
            {showSearchNote && (
                <span className={`on-search${noteColor ? `-${noteColor}` : ''}`}>Pritisnite enter za pretragu</span>
            )}
        </Content>
    );
};

const Content = styled.div`
    label {
        font-size: 15px;
        color: var(--gray);
        padding-left: 0;
        margin-bottom: 5px;
    }
    .options-list {
        padding: 5px;
        display: flex;
        position: absolute;
        flex-direction: column;
        top: 31.5%;
        left: 6.5%;
        overflow: auto;
        max-height: 150px;
        background-color: var(--light-gray);
        width: 87%;
        div {
            margin-bottom: 5px;
            padding: 5px 0;
            color: var(--gray);
            :hover {
                background-color: var(--purple);
                color: var(--white);
                cursor: pointer;
            }
        }
    }
    .on-search {
        font-size: 12px;
        color: var(--gray);
        margin-top: 5px;
        display: block;
    }
    .on-search-white {
        color: var(--white);
        font-size: 12px;
        margin-top: 5px;
        display: block;
    }
`;

const StyledWrapper = styled.div<Props>`
    align-items: center;
    border: 1px solid var(--border-color);
    border-radius: 2px;
    box-sizing: border-box;
    display: flex;
    height: 44px;
    padding: 5px 5px 5px 10px;
    font-size: 14px;
    padding-right: 10px;
    background: var(--white);

    input {
        border: 0;
        color: var(--gray);
        flex: 1;
        outline: none;
        padding: 4px 8px;
        font-size: 16px;
        &::placeholder {
            color: var(--gray);
            font-size: 14px;
        }
    }
    label {
        font-size: 15px;
        color: var(--gray);
        padding-left: 0;
        &:hover {
            color: var(--purple);
        }
    }
`;

export default Search;
