import { FunctionComponent, useEffect } from 'react';
import useLanguageStore from '../../../../store/language';
import { flatten } from '../../../../functions/flatten';
import srJson from '../../../../components/language/rs.json';
import enJson from '../../../../components/language/en.json';
import ruJson from '../../../../components/language/ru.json';
import Select from '../../../../components/select';
import colors from '../../../../global/colors';
import useAgencyStore from '../../../../store/agency';

interface Props {
    color?: string;
    onNameChange: (name: string) => void;
}

const SelectDefaultLang: FunctionComponent<Props> = ({ color, onNameChange }) => {
    const { agency } = useAgencyStore();
    const lang = agency.user.data.lang;

    const options = [
        { value: 'rs', label: 'Srpski' },
        { value: 'en', label: 'English' },
        { value: 'ru', label: 'Русский' },
    ];

    useEffect(() => {
        setLocalLang(lang);
    }, [agency]);

    const handleClickOption = (name: string): void => {
        setLocalLang(name);
        useLanguageStore.setState({ currentLangName: name });
        localStorage.setItem('language', name);
        onNameChange(name);
    };

    const setLocalLang = (language: string): void => {
        if (language === 'rs') {
            const flattenData = flatten(srJson);
            useLanguageStore.setState({ currentLang: flattenData });
            localStorage.setItem('language', 'Srpski');
        } else if (language === 'en') {
            const flattenData = flatten(enJson);
            useLanguageStore.setState({ currentLang: flattenData });
            localStorage.setItem('language', 'English');
        } else if (language === 'ru') {
            const flattenData = flatten(ruJson);
            useLanguageStore.setState({ currentLang: flattenData });
            localStorage.setItem('language', 'Русский');
        }
    };

    return (
        <Select
            isSearchable={false}
            optionList={options}
            handleSelectedValue={handleClickOption}
            defaultSelectedOption={options.find((option) => option.value === lang) || options[0]}
            color={color ? color : colors.white}
        />
    );
};
export default SelectDefaultLang;
