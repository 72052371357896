import { FunctionComponent, useEffect, useState } from 'react';
import { Row, StyledInput } from '../../style';
import SelectSearch from '../../../../../../components/searchCreateNew';
import Input, { InputComponentType } from '../../../../../../components/input';
import { ObjectKeys } from '../../../../../../types/objectKeys';
import colors from '../../../../../../global/colors';
import { formatToSerbianNumber } from '../../../../../../functions/format';
import useServiceNumberValidation from '../../../../../../hooks/useServiceNumberValidation';
import { useLocation } from 'react-router-dom';
import styled from 'styled-components';

type SelectOption = {
    value?: string;
    label?: string;
};

interface Props {
    index: number;
    service: ObjectKeys;
    services: ObjectKeys[];
    units: ObjectKeys[];
    setFormData: Function;
    handleTotal: Function;
    invoice: ObjectKeys;
    t: Function;
    setUnits?: Function;
    handleServiceClick: Function;
}

const WebService: FunctionComponent<Props> = ({
    index,
    service,
    services,
    units,
    setFormData,
    handleTotal,
    invoice,
    t,
    setUnits,
    handleServiceClick,
}) => {
    const { pathname } = useLocation();
    const isAdvance = pathname.includes('advance');
    const transformedService: ObjectKeys = {
        ...service,
        pivot: {
            ...service.pivot,
            price_per_unit: service?.pivot?.price_per_unit?.replace('.', ','),
            quantity: '1',
        },
    };
    const isQuantityValid = useServiceNumberValidation(transformedService.pivot.quantity);
    const isPriceValid = useServiceNumberValidation(transformedService.pivot.price_per_unit);

    const [totalServicePrice, setTotalServicePrice] = useState<number>(0);

    useEffect(() => {
        setFormData((prev: ObjectKeys) => {
            const copy = { ...prev };
            const quantity = 1;
            const price = transformedService.pivot.price_per_unit.replace(',', '.');
            copy.services[index].pivot.total_price = quantity * price;
            setTotalServicePrice(copy.services[index].pivot.total_price);
            return copy;
        });
    }, [transformedService.pivot.price_per_unit]);

    useEffect(() => {
        handleTotal(
            invoice.services?.reduce((accumulator: number, object: ObjectKeys) => {
                return accumulator + (isNaN(object.pivot.total_price) ? 0 : Number(object.pivot.total_price));
            }, 0),
        );
    }, [totalServicePrice]);

    return (
        <div>
            <Row className="service-list" key={service.type}>
                <p className="type" onClick={() => handleServiceClick()}>
                    {transformedService.name
                        ? transformedService.name
                        : services.find((s: ObjectKeys) => s.id === transformedService.id)?.name}
                </p>
                <UnitSelectWrapper>
                    <SelectSearch
                        handleSelectedValue={(v: ObjectKeys) => {
                            setFormData((prev: ObjectKeys) => {
                                const copy = { ...prev };
                                copy.services[index].pivot.measurement_unit = v.value;
                                copy.services[index].pivot.quantity = '1';
                                return copy;
                            });
                        }}
                        handleCreateOption={(c: SelectOption) => {
                            let newUnitAdded = false;
                            setFormData((prev: ObjectKeys) => {
                                const copy = { ...prev };
                                copy.services[index].pivot.measurement_unit = c.value;
                                copy.services[index].pivot.quantity = '1';
                                return copy;
                            });
                            // Check if the unit already exists, if not, add it
                            if (!units.some((unit) => unit.value === c.value)) {
                                units.push({ label: c.label, value: c.value });
                                newUnitAdded = true;
                            }
                            // If a new unit was added, update the state to re-render the component
                            if (newUnitAdded && setUnits) {
                                setUnits([...units]);
                            }
                        }}
                        optionList={units.map((unit) => ({
                            label: unit.label,
                            value: unit.value,
                        }))}
                        key={units.length}
                        defaultValue={units.find((u) => u.value === transformedService.pivot.measurement_unit)}
                        color={colors.black}
                        noValuePlaceholderColor="red"
                        placeholder={t('pages.invoices.add_measurement_unit').text}
                        searchPlaceholder={t('input.search_and_add').text}
                    />
                </UnitSelectWrapper>
                <StyledInput className="price">
                    <Input
                        type={InputComponentType.Amount}
                        value={transformedService.pivot.quantity !== '' ? transformedService.pivot.quantity : ''}
                        readOnly={true}
                        hideBlurOnChange
                        handleBlurAction={() => {
                            setFormData((prev: ObjectKeys) => {
                                const copy = { ...prev };
                                while (
                                    Array.from(copy.services[index].pivot.quantity)[0] === '0' &&
                                    Array.from(copy.services[index].pivot.quantity)[1] !== ','
                                ) {
                                    copy.services[index].pivot.quantity = copy.services[index].pivot.quantity.slice(1);
                                }
                                return copy;
                            });
                        }}
                        placeholder={t('pages.editInvoice.enterQuantity').text}
                        validation={isQuantityValid || isAdvance ? '' : 'error'}
                    />
                    <span>x</span>
                </StyledInput>
                <StyledInput className="price">
                    <Input
                        type={InputComponentType.Amount}
                        value={
                            transformedService.pivot.price_per_unit !== ''
                                ? transformedService.pivot.price_per_unit
                                : ''
                        }
                        onChange={(v: any) => {
                            setFormData((prev: ObjectKeys) => {
                                const copy = { ...prev };
                                copy.services[index].pivot.quantity = transformedService.pivot.quantity;
                                copy.services[index].pivot.price_per_unit = v;
                                const quantity = 1;
                                const price = v.replace(',', '.');
                                copy.services[index].pivot.total_price = quantity * price;
                                setTotalServicePrice(copy.services[index].pivot.total_price);
                                return copy;
                            });
                            handleTotal(
                                invoice.services?.reduce((accumulator: number, object: ObjectKeys) => {
                                    return (
                                        accumulator +
                                        (isNaN(object.pivot.total_price) ? 0 : Number(object.pivot.total_price))
                                    );
                                }, 0),
                            );
                        }}
                        hideBlurOnChange
                        handleBlurAction={() => {
                            setFormData((prev: ObjectKeys) => {
                                const copy = { ...prev };
                                while (
                                    Array.from(copy.services[index].pivot.price_per_unit)[0] === '0' &&
                                    Array.from(copy.services[index].pivot.price_per_unit)[1] !== ','
                                ) {
                                    copy.services[index].pivot.price_per_unit =
                                        copy.services[index].pivot.price_per_unit.slice(1);
                                }

                                return copy;
                            });
                        }}
                        placeholder={t('pages.editInvoice.enterUnitPrice').text}
                        validation={isPriceValid ? '' : 'error'}
                    />
                    <span>=</span>
                </StyledInput>
                <StyledInput className="price">
                    <Input
                        type={InputComponentType.Text}
                        value={totalServicePrice ? formatToSerbianNumber(totalServicePrice).toString() : ''}
                    />
                    <span
                        style={{ cursor: 'pointer' }}
                        onClick={() =>
                            setFormData((prev: ObjectKeys) => {
                                const copy = { ...prev };
                                copy.services.splice(index, 1);
                                return copy;
                            })
                        }
                    >
                        x
                    </span>
                </StyledInput>
            </Row>
        </div>
    );
};
const UnitSelectWrapper = styled.div`
    flex: 1;
    width: 100%;
    min-width: 180px;
    label {
        font-size: 15px;
        color: var(--gray);
        margin-bottom: 2px;
        display: flex;
        padding: 5px;
    }
    > div {
        width: 100%;
    }
    .select-search {
        > div {
            box-shadow: none;
        }
        .input-dropdown {
            background-color: transparent;
            box-shadow: none;
            border-radius: 5px;
            svg {
                height: 20px;
                width: 20px;
            }
        }
    }
`;
export default WebService;
