import styled from 'styled-components';
import NotificationForm from '../notificationForm';
import { useParams, useNavigate } from 'react-router-dom';
import { FunctionComponent, useEffect, useState } from 'react';
import { ObjectKeys } from '../../../../types/objectKeys';
import useTranslations from '../../../../hooks/useTranslation';
import useLanguageStore from '../../../../store/language';
import communication from '../../../../communication';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Loader from '../../../../components/loader';

interface FormData {
    title_of_alert_rs: string;
    title_of_alert_en: string;
    title_of_alert_ru: string;
    description_rs: string;
    description_en: string;
    description_ru: string;
}

export const CreateNotifications: FunctionComponent = () => {
    const { currentLang } = useLanguageStore();
    const t = useTranslations(currentLang);
    const { id } = useParams<{ id?: string }>();
    const navigate = useNavigate();

    const [loaderVisible, setLoaderVisible] = useState<boolean>(false);

    const [editFormData, setEditFormData] = useState<FormData>({
        title_of_alert_rs: '',
        title_of_alert_en: '',
        title_of_alert_ru: '',
        description_rs: '',
        description_en: '',
        description_ru: '',
    });

    const fetchNotificationForEdit = async (alert_id: string): Promise<void> => {
        setLoaderVisible(true);
        communication.getNotificationById(alert_id).then((res: ObjectKeys) => {
            if (res.status === 200) {
                setLoaderVisible(false);
                setEditFormData(res.data.data);
            } else {
                setLoaderVisible(false);
                toast.error('error in fetching notification');
            }
        });
    };

    const saveNotification = async (data: ObjectKeys): Promise<void> => {
        setLoaderVisible(true);
        communication
            .createNotification(data)
            .then((res: ObjectKeys) => {
                if (res.status === 200) {
                    setLoaderVisible(false);
                    setLoaderVisible(false);
                    navigate('/admin/notifications/history');
                    toast.success('Notifikacija je uspešno kreirana.');
                } else {
                    setLoaderVisible(false);
                    toast.error('error in creating notification');
                }
            })
            .catch((err: any) => {
                setLoaderVisible(false);
                toast.error(err.response.data.message);
            });
    };

    const updateNotification = async (data: any): Promise<void> => {
        setLoaderVisible(true);
        communication
            .updateNotification(data, id ? id : '')
            .then((res: ObjectKeys) => {
                if (res.status === 200) {
                    setLoaderVisible(false);
                    navigate('/admin/notifications/history');
                    toast.success('Notifikacija je uspešno izmenjena.');
                } else {
                    setLoaderVisible(false);
                    toast.error('error in updating notification');
                }
            })
            .catch((err: any) => {
                setLoaderVisible(false);
                toast.error(err.response.data.message);
            });
    };

    const saveAndSendNotification = async (data: ObjectKeys): Promise<void> => {
        setLoaderVisible(true);
        communication
            .createAndSendNotification(data)
            .then((res: ObjectKeys) => {
                if (res.status === 200) {
                    setLoaderVisible(false);
                    navigate('/admin/notifications/history');
                    toast.success('Notifikacija je uspešno poslata.');
                } else {
                    setLoaderVisible(false);
                    toast.error('error in creating notification');
                }
            })
            .catch((err: any) => {
                setLoaderVisible(false);
                toast.error(err.response.data.message);
            });
    };

    const updateAndSendNotification = async (data: ObjectKeys): Promise<void> => {
        setLoaderVisible(true);
        if (!id) return;
        communication
            .updateNotification(data, id)
            .then((res: ObjectKeys) => {
                if (res.status === 200) {
                    setLoaderVisible(false);
                    communication.sendNotification(id).then((response: ObjectKeys) => {
                        if (response.status === 200) {
                            setLoaderVisible(false);
                            navigate('/admin/notifications/history');
                            toast.success('Notifikacija je uspešno izmenjena i poslata.');
                        } else {
                            setLoaderVisible(false);
                            toast.error('error in sending notification');
                        }
                    });
                } else {
                    setLoaderVisible(false);
                    toast.error('error in updating notification');
                }
            })
            .catch((err: any) => {
                setLoaderVisible(false);
                toast.error(err.response.data.message);
            });
    };

    useEffect(() => {
        if (id) {
            fetchNotificationForEdit(id);
        }
    }, [id]);

    return (
        <div>
            {loaderVisible && <Loader />}
            <ToastContainer />
            {id && editFormData ? (
                <Create>
                    <NotificationForm
                        t={t}
                        type={'edit'}
                        onSend={(data: ObjectKeys) => updateAndSendNotification(data)}
                        onSave={(data: ObjectKeys) => saveNotification(data)}
                        onEdit={(data: ObjectKeys) => updateNotification(data)}
                        editData={editFormData}
                    />
                </Create>
            ) : (
                <Create>
                    <NotificationForm
                        t={t}
                        type={'create'}
                        onSend={(data: ObjectKeys) => saveAndSendNotification(data)}
                        onSave={(data: ObjectKeys) => saveNotification(data)}
                        onEdit={() => new Promise(() => void {})}
                        editData={null}
                    />
                </Create>
            )}
        </div>
    );
};
const Create = styled.div`
    margin-bottom: 20px;
    .create-title {
        font-size: 14px;
        font-weight: bold;
        color: var(--purple);
        cursor: pointer;
    }
    .fa-icon {
        margin-left: 5px;
        color: var(--purple);
    }
    .search-container {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: 20px;
    }
    .search-input {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        gap: 10px;
    }
    .show {
        margin-right: 6px;
    }
`;
