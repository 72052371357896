import React from 'react';

const FaFilePenRegular: React.FC<React.SVGProps<SVGSVGElement>> = (props) => (
    <svg viewBox="0 0 576 512" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
        <path
            d="M33.8286 8.41443C37.8514 6.40229 44.8 3.65845 49.3714 2.37799C55.4057 0.73169 81.7371 0.182922 149.029 0C213.943 0 242.651 0.548767 247.771 2.01215C251.794 3.10968 259.109 6.76813 264.229 10.0607C269.166 13.1704 296.046 38.7796 323.84 66.7667C360.96 104.449 375.223 119.997 378.149 126.399C380.343 131.338 383.086 141.582 384.183 149.265C385.28 157.862 385.829 190.971 384.914 299.81L362.057 322.675C349.44 335.114 338.651 344.809 337.92 344.077C337.189 343.345 336.274 301.822 335.543 160.606L247.589 159.874L239.909 154.752C235.703 152.009 230.766 147.07 228.937 143.777C226.377 138.838 225.646 131.155 224 48.2915H58.1486L49.3714 58.3523V453.465L58.1486 463.526L160.731 463.891C217.234 464.257 263.314 464.989 263.314 465.721C263.314 466.635 262.034 470.842 260.571 475.415C258.56 480.903 257.829 488.22 258.743 511.085L53.0286 512L39.8629 505.964C31.2686 501.939 23.9543 497 19.2 491.696C14.9943 487.305 9.87429 479.989 7.68 475.415C5.48571 470.842 2.92571 461.513 2.01143 454.379C0.731429 446.331 0 371.516 0 255.909C0 138.472 0.731429 65.4862 2.01143 57.4377C2.92571 50.3037 5.30286 41.3405 6.94857 37.3162C8.77714 33.2919 13.8971 25.975 18.4686 21.0361C22.8571 16.2801 29.8057 10.4266 33.8286 8.41443Z"
            fill="inherit"
        />
        <path
            d="M498.286 233.409C502.309 230.116 509.074 226.641 513.463 225.543C517.851 224.446 524.434 224.08 528.091 224.812C531.931 225.543 536.869 227.19 539.429 228.47C541.989 229.751 549.669 235.97 556.434 242.006C563.383 248.226 570.514 256.457 572.343 260.482C574.537 265.055 576 271.64 576 277.859C576 283.896 574.537 290.847 572.526 295.237C570.697 299.261 561.554 310.054 535.771 335.48L465.371 265.055L478.171 252.067C485.303 244.933 494.263 236.519 498.286 233.409Z"
            fill="inherit"
        />
        <path
            d="M306.469 427.307C308.114 424.197 339.2 391.637 375.406 355.053V355.235C411.794 318.651 441.966 288.835 442.514 288.835C443.063 288.835 459.154 304.383 512.914 358.345L447.086 424.38C410.88 460.599 379.246 491.33 376.686 492.793C374.126 494.074 357.12 499.012 338.651 503.586C312.503 510.171 304.274 511.634 300.251 510.171C297.509 509.256 293.851 506.329 292.023 503.768C290.377 501.208 288.914 497.732 288.914 495.903C288.914 494.257 292.206 479.44 296.229 462.977C300.251 446.697 304.823 430.6 306.469 427.307Z"
            fill="inherit"
        />
    </svg>
);

export default FaFilePenRegular;
