export function handleInputNumbers(inputValue: string): string {
    // Remove any non-digit characters except for commas
    const sanitizedValue = inputValue.replace(/[^0-9,]/g, '');

    // Ensure the comma is not at the beginning
    if (sanitizedValue.startsWith(',')) {
        return sanitizedValue.substring(1);
    }

    // Ensure there is at most one comma
    const commaCount = sanitizedValue.split(',').length - 1;
    if (commaCount > 1) {
        const parts = sanitizedValue.split(',');
        const integerPart = parts[0];
        const decimalPart = parts.slice(1).join('');
        return integerPart + ',' + decimalPart;
    }

    return sanitizedValue;
}
