import { FunctionComponent, useState, useEffect } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import moment from 'moment';
import { every, isEmpty } from 'lodash';
import useLanguageStore from '../../../../store/language';
import useTranslations from '../../../../hooks/useTranslation';
import { getAccessToken } from '../../../../functions/auth';
import { ObjectKeys } from '../../../../types/objectKeys';
import { SendDocumentFormData } from '../../../../types/communication/document';
import { generateQrCode } from '../../../../communication/qr';
import communication from '../../../../communication';
import Loader from '../../../../components/loader';
import Sidebar from '../../../../components/sideModal';
import Modal from '../../../../components/modal';
import Success from '../../../../components/successModal';
import ErrorModal from '../../../../components/errorModal';
import ChangeModal from '../../../../components/changeModal';
import InvoiceClients from '../../../../components/invoiceClients';
import AddClientModal from '../../../myBusiness/clients/addClientModal';
import PreviewInvoiceModal from '../../../pausal/common/previewInvoiceModalPlain';
import { Invoice } from '../../../pausal/common/style';
import InvoiceEdit from '../../../pausal/proforma/invoiceEdit';
import InvoiceForeignEdit from '../../../pausal/proforma/invoiceForeignEdit';
import MobileFooter from '../../../pausal/common/footer/mobileFooter';
import WebFooter from '../../../pausal/common/footer/webFooter';
import MobileHeader from '../../../pausal/common/header/mobileHeader';
import WebHeader from '../../../pausal/common/header/webHeader';
import EditClientModal from '../../../myBusiness/clients/editClientModal';
import SendModal from '../../../pausal/proforma/sendModal';
import getAllProforma from '../getAllProforma';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import useUnitsStore from '../../../../store/measurementUnits';
import useResponsive from '../../../../hooks/responsive/useResponsive';

const EditProforma: FunctionComponent = () => {
    const { currentLang } = useLanguageStore();
    const t = useTranslations(currentLang);
    const { pathname } = useLocation();
    const navigate = useNavigate();
    const proformaId = useParams().proformaId;
    const userId = useParams().userId;
    const token = getAccessToken();

    const { units } = useUnitsStore();

    const [previewModal, setPreviewModal] = useState(false);
    const [sendModal, setSendModal] = useState(false);
    const [addClientModal, setAddClientModal] = useState(false);
    const [editClientModal, setEditClientModal] = useState(false);
    const [deleteClientModal, setDeleteClientModal] = useState(false);
    const [loading, setLoading] = useState(false);
    const [success, setSuccess] = useState(false);
    const [openErrorModal, setOpenErrorModal] = useState(false);
    const [deleteInvoiceModal, setDeleteInvoiceModal] = useState(false);
    const [changeHappened, setChangeHappened] = useState(false);
    const [menuVisible, setMenuVisible] = useState(false);

    const [qrCode, setQrCode] = useState('');
    const [clickedClient, setClickedClient] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [modalMessage, setModalMessage] = useState('');
    const { isMobile } = useResponsive();
    const [newCreatedClient, setNewCreatedClient] = useState<ObjectKeys>({});
    const [clients, setClients] = useState<ObjectKeys>([]);

    const [agencyProfile, setAgencyProfile] = useState<ObjectKeys>({});

    const [sendData, setSendData] = useState<SendDocumentFormData>({
        recipient: '',
        subject: '',
        message: '',
    });
    const [proformaData, setProformaData] = useState<ObjectKeys>({
        client: { data: {} },
        comment: '',
        currency: 'RSD',
        iban: '',
        paid_date: '',
        paid_value: false,
        prepaid_invoice_ids: [],
        rate: 1,
        seal_required: false,
        swift: '',
        trading_place: '',
    });

    useEffect(() => {
        setLoading(true);
        if (proformaId && userId) {
            communication.getUsersById(userId).then((user: ObjectKeys) => {
                const agencyData = user.data.data.agency.data[0];
                setAgencyProfile({
                    ...agencyData,
                    name: agencyData.full_name,
                });
                if (agencyProfile) {
                    communication.getProformaById(proformaId).then((res: ObjectKeys) => {
                        setProformaData(res?.data?.data);
                        const newUnits = res?.data.data.services
                            .map((item: ObjectKeys) => item.pivot.measurement_unit)
                            .filter((unit: any) => !units.includes(unit));

                        useUnitsStore.setState({ units: [...units, ...newUnits] });
                        setLoading(false);
                        if (agencyProfile && agencyProfile.full_name && res.data?.data) {
                            handleGenerateQRCode(
                                agencyProfile,
                                res?.data?.data?.client?.data?.company_name,
                                res?.data?.data?.value,
                                res?.data?.data?.id,
                                res?.data?.data?.bank_account,
                                res?.data?.data?.currency,
                                true,
                            );
                        }
                    });
                }
            });
        }
    }, [proformaId]);

    useEffect(() => {
        if (!isEmpty(newCreatedClient)) {
            setProformaData((prev: ObjectKeys) => {
                const newProformaData = { ...prev };
                newProformaData.client_id = newCreatedClient && newCreatedClient?.id;
                newProformaData.client.data = newCreatedClient;
                return newProformaData;
            });
        }
    }, [newCreatedClient]);

    const handleClientDelete = async (): Promise<void> => {
        const res = await communication.deleteClient(clickedClient);
        if (res) setDeleteClientModal(false);
    };

    const handleProformaDelete = async (): Promise<void> => {
        communication.deleteProforma(proformaData.id).then((res: ObjectKeys) => {
            if (res) {
                setDeleteInvoiceModal(false);
                navigate(-1);
            }
        });
    };

    //QR code
    const handleGenerateQRCode = (
        agencyData: ObjectKeys,
        clientData: ObjectKeys,
        amount: string,
        proformaNumber: string,
        bankAccount: string,
        currency: string,
        convert: boolean,
    ): void => {
        try {
            generateQrCode(
                agencyData.name,
                clientData,
                amount,
                proformaNumber,
                bankAccount,
                currency,
                convert,
                null,
                '221',
            ).then((response: ObjectKeys | any) => {
                if (response.i) setQrCode(response.i);
            });
        } catch (error: any) {
            setQrCode('');
            setErrorMessage(error.response.data.message);
            setOpenErrorModal(true);
        }
    };
    const handleSelectedClient = (client: ObjectKeys): void => {
        setChangeHappened(true);
        setProformaData((prev: ObjectKeys) => {
            const newProformaData = { ...prev };

            // Ensure newInvoiceData.client is initialized
            if (!newProformaData.client) {
                newProformaData.client = {}; // Initialize it as an empty object if undefined
            }

            newProformaData.client_id = client?.id;
            newProformaData.client.data = client;

            return newProformaData;
        });
        proformaData &&
            agencyProfile &&
            handleGenerateQRCode(
                agencyProfile,
                client.company_name,
                proformaData.value,
                proformaData.id,
                proformaData.bank_account,
                proformaData.currency,
                true,
            );
    };

    const handleUpdateProforma = async (): Promise<void> => {
        if (proformaData?.services?.length === 0) {
            setSuccess(true);
            setModalMessage(t('pages.editInvoice.missingService').text);
        } else if (every(proformaData.services, (s) => s.pivot.quantity === '0' && s.pivot.price_per_unit === '0')) {
            setSuccess(true);
            setModalMessage(t('pages.editInvoice.invalidInvoice').text);
        } else {
            try {
                setLoading(true);
                const updateProforma = { ...proformaData };

                updateProforma.services = updateProforma.services.map((service: ObjectKeys, i: number) => ({
                    index: i,
                    id: service.id,
                    measurement_unit: service.pivot.measurement_unit,
                    quantity: service.pivot.quantity.replace(',', '.'),
                    price_per_unit: service.pivot.price_per_unit.replace(',', '.'),
                    discount: service.pivot.discount,
                }));
                updateProforma.value = Number(updateProforma.value);
                updateProforma.trading_date = moment(updateProforma.trading_date).format('YYYY-MM-DD').toString();
                updateProforma.rate = Number(updateProforma.rate);
                updateProforma.paid_date = moment(
                    updateProforma.paid_date ? updateProforma.paid_date : new Date('1970-01-01'),
                )
                    .format('YYYY-MM-DD')
                    .toString();
                updateProforma.quotation_date = moment(updateProforma.quotation_date).format('YYYY-MM-DD').toString();
                updateProforma.custom_number = false;
                updateProforma.is_custom = false;
                updateProforma.custom_text = false;
                updateProforma.invoice_per_owner = true;
                updateProforma.auto_increment = true;
                updateProforma.cancel_number = false;
                updateProforma.cancelled = false;
                delete updateProforma.quotation_number;
                const res = await communication.editProforma(proformaData.id, updateProforma);

                if (res.status === 200) {
                    setLoading(false);
                    getAllProforma(setProformaData);
                    navigate(-1);
                }
            } catch (error: any) {
                setErrorMessage(error.response.data.message);
                setOpenErrorModal(true);
                setLoading(false);
            }
        }
    };

    //Proforma Download
    const handleDownloadProforma = (): void => {
        generateQrCode(
            agencyProfile.full_name,
            proformaData.client ? proformaData.client.data : {},
            proformaData.value_in_rsd,
            proformaData.quotation_number ? proformaData.quotation_number : '',
            proformaData?.bank_account,
            proformaData.currency,
            proformaData.value ? false : true,
            null,
            '221',
        )
            .then((res: ObjectKeys | any) => {
                if (res && proformaId)
                    communication
                        .downloadProforma(
                            proformaId,
                            token,
                            proformaData.quotation_number,
                            proformaData.currency !== 'RSD',
                        )
                        .catch((error: ObjectKeys) => {
                            if (error) {
                                toast.error(t('pages.tryAgain.message').text);
                            }
                        });
            })
            .catch((error: ObjectKeys) => {
                if (error) {
                    console.error(error);
                }
            });
    };

    // Create invoice from proforma
    const handleCreateInvoiceFromProforma = (): void => {
        const proformaDataToSend = { ...proformaData };
        const proformaServices = proformaData.services.map((service: ObjectKeys, index: number) => {
            return {
                index: index,
                id: service.id,
                measurement_unit: service.pivot.measurement_unit,
                quantity: service.pivot.quantity,
                price_per_unit: service.pivot.price_per_unit,
            };
        });
        proformaDataToSend['invoice_date'] = proformaDataToSend.quotation_date;
        proformaDataToSend['services'] = proformaServices;
        proformaDataToSend['status'] = 'sent';

        if (proformaData.type === 'national') {
            communication
                .domesticProforma(proformaDataToSend)
                .then((res: ObjectKeys) => {
                    if (res) {
                        navigate(`/invoices/copy/${res?.data?.data?.id}/quotation`);
                    }
                })
                .catch((error: ObjectKeys) => {
                    setErrorMessage(error.response.data.message);
                    setOpenErrorModal(true);
                });
        } else if (proformaData.type === 'foreign') {
            communication
                .foreignProforma(proformaDataToSend)
                .then((res: ObjectKeys) => {
                    if (res) {
                        navigate(`/invoices/copy/${res?.data?.data?.id}/quotation`);
                    }
                })
                .catch((error: ObjectKeys) => {
                    setErrorMessage(error.response.data.message);
                    setOpenErrorModal(true);
                });
        }
    };

    // Create advance from proforma
    const handleCreateAdvanceFromProforma = (): void => {
        const proformaDataToSend = { ...proformaData };
        const proformaServices = proformaData.services.map((service: ObjectKeys, index: number) => {
            return {
                index: index,
                id: service.id,
                measurement_unit: service.pivot.measurement_unit,
                quantity: service.pivot.quantity,
                price_per_unit: service.pivot.price_per_unit,
            };
        });
        proformaDataToSend['invoice_date'] = proformaDataToSend.quotation_date;
        proformaDataToSend['services'] = proformaServices;
        proformaDataToSend['status'] = 'sent';

        if (proformaData.type === 'national') {
            communication
                .domesticProforma(proformaDataToSend)
                .then((res: ObjectKeys) => {
                    if (res) {
                        navigate(`/advance-invoices/copy/${res?.data?.data?.id}/quotation`);
                    }
                })
                .catch((error: ObjectKeys) => {
                    setErrorMessage(error.response.data.message);
                    setOpenErrorModal(true);
                });
        } else if (proformaData.type === 'foreign') {
            communication
                .foreignProforma(proformaDataToSend)
                .then((res: ObjectKeys) => {
                    if (res) {
                        navigate(`/advance-invoices/copy/${res?.data?.data?.id}/quotation`);
                    }
                })
                .catch((error: ObjectKeys) => {
                    setErrorMessage(error.response.data.message);
                    setOpenErrorModal(true);
                });
        }
    };

    return (
        <>
            {loading && <Loader />}
            <ToastContainer />
            {openErrorModal && (
                <Modal modalVisible={openErrorModal} closeModal={() => setOpenErrorModal(false)}>
                    <ErrorModal t={t} setOpenModal={setOpenErrorModal} errorMessage={errorMessage} />
                </Modal>
            )}
            {success && (
                <Modal modalVisible={true} closeModal={() => setSuccess(false)}>
                    <Success close={() => setSuccess(false)} message={modalMessage} />
                </Modal>
            )}
            {addClientModal && (
                <Sidebar close={() => setAddClientModal(false)}>
                    <AddClientModal
                        t={t}
                        clickedClient={proformaData.type === 'national' ? 'domestic' : 'foreign'}
                        userId={agencyProfile?.user_id}
                        setAddClientModal={setAddClientModal}
                        setClients={setClients}
                        tabIndex={0}
                        setNewCreatedClient={setNewCreatedClient}
                    />
                </Sidebar>
            )}
            {editClientModal && (
                <Sidebar close={() => setEditClientModal(false)}>
                    <EditClientModal
                        t={t}
                        clickedClient={clickedClient}
                        userId={agencyProfile?.user_id}
                        setEditClientModal={setEditClientModal}
                        setClients={setClients}
                        tabIndex={0}
                    />
                </Sidebar>
            )}
            {deleteClientModal && (
                <Modal
                    modalVisible={true}
                    closeModal={() => {
                        setDeleteClientModal(false);
                    }}
                >
                    <ChangeModal
                        saveChanges={handleClientDelete}
                        close={() => {
                            setDeleteClientModal(false);
                        }}
                        message={t('pages.editInvoice.deleteClient').text}
                    />
                </Modal>
            )}
            {deleteInvoiceModal && (
                <Modal
                    modalVisible={true}
                    closeModal={() => {
                        setDeleteInvoiceModal(false);
                    }}
                >
                    <ChangeModal
                        saveChanges={handleProformaDelete}
                        close={() => {
                            setDeleteInvoiceModal(false);
                        }}
                        message={t('pages.newProforma.deleteProforma').text}
                    />
                </Modal>
            )}
            {sendModal && (
                <Sidebar close={() => setSendModal(false)}>
                    <SendModal
                        close={() => setSendModal(false)}
                        setFormData={setSendData}
                        formData={sendData}
                        proforma={proformaData}
                        t={t}
                        id={proformaData.id}
                        success={() => {
                            setSendModal(false);
                            navigate(-1);
                        }}
                        loading={(e: boolean) => setLoading(e)}
                        clientEmail={''}
                        setProforma={setProformaData}
                        qrCode={qrCode}
                        modalVisible={sendModal}
                        handleAddNewProforma={handleUpdateProforma}
                    />
                </Sidebar>
            )}
            {previewModal && (
                <Modal
                    width="700px"
                    className="preview-modal"
                    modalVisible={true}
                    hasCloseButton={true}
                    closeModal={() => {
                        setPreviewModal(false);
                    }}
                >
                    <PreviewInvoiceModal
                        agency={agencyProfile}
                        invoice={proformaData}
                        title="proforma"
                        type={proformaData.type === 'national' ? 'domestic' : 'foreign'}
                        note={proformaData.comment}
                    />
                </Modal>
            )}
            <div className="new-proforma page">
                {isMobile ? (
                    <MobileHeader
                        t={t}
                        handleUpdateInvoice={handleUpdateProforma}
                        menuVisible={menuVisible}
                        pathname={pathname}
                        setDeleteInvoiceModal={setDeleteInvoiceModal}
                        setMenuVisible={setMenuVisible}
                        setPreviewModal={setPreviewModal}
                        setSendModal={setSendModal}
                        changeHappened={changeHappened}
                        handleInvoiceUpdate={handleUpdateProforma}
                        invoice={proformaData}
                        title={'proforma'}
                        handleDownloadInvoice={handleDownloadProforma}
                        handleCreateInvoiceFromProforma={handleCreateInvoiceFromProforma}
                        handleCreateAdvanceFromProforma={handleCreateAdvanceFromProforma}
                    />
                ) : (
                    <WebHeader
                        t={t}
                        handleUpdateInvoice={handleUpdateProforma}
                        menuVisible={menuVisible}
                        pathname={pathname}
                        setDeleteInvoiceModal={setDeleteInvoiceModal}
                        setMenuVisible={setMenuVisible}
                        setPreviewModal={setPreviewModal}
                        setSendModal={setSendModal}
                        changeHappened={changeHappened}
                        handleInvoiceUpdate={handleUpdateProforma}
                        invoice={proformaData}
                        title={'proforma'}
                        handleDownloadInvoice={handleDownloadProforma}
                        handleCreateInvoiceFromProforma={handleCreateInvoiceFromProforma}
                        handleCreateAdvanceFromProforma={handleCreateAdvanceFromProforma}
                    />
                )}
                <InvoiceClients
                    t={t}
                    setClients={setClients}
                    clients={clients}
                    setAddClientModal={setAddClientModal}
                    agency={agencyProfile}
                    handleSelectedClient={handleSelectedClient}
                    addClientModal={addClientModal}
                    deleteClientModal={deleteClientModal}
                    editClientModal={editClientModal}
                    setClickedClient={setClickedClient}
                    setEditClientModal={setEditClientModal}
                    setDeleteClientModal={setDeleteClientModal}
                    invoice={proformaData}
                    userId={userId}
                    type={
                        proformaData?.type === 'national'
                            ? 'domestic'
                            : proformaData?.type === 'foreign'
                            ? 'foreign'
                            : ''
                    }
                    loading={(isLoading: boolean) => setLoading(isLoading)}
                />
                {proformaData && agencyProfile && (
                    <Invoice>
                        {proformaData.type === 'national' ? (
                            <InvoiceEdit
                                name="proforma"
                                formData={proformaData}
                                setFormData={setProformaData}
                                agency={agencyProfile}
                                invoice={proformaData}
                                handleTotal={(sum: ObjectKeys) => {
                                    setProformaData({
                                        ...proformaData,
                                        value: sum,
                                        value_in_rsd: sum,
                                        total_for_payment: sum,
                                        total_for_payment_in_rsd: sum,
                                    });
                                }}
                                isMobile={isMobile}
                                newInvoice={true}
                                type={'domestic'}
                                setLoading={setLoading}
                                handleQrCode={(qr: string) => setQrCode(qr)}
                            />
                        ) : (
                            <InvoiceForeignEdit
                                name="proforma"
                                formData={proformaData}
                                setFormData={setProformaData}
                                agency={agencyProfile}
                                invoice={proformaData}
                                handleTotal={(sum: ObjectKeys) => {
                                    setProformaData({
                                        ...proformaData,
                                        value: sum,
                                        total_for_payment: sum,
                                    });
                                }}
                                isMobile={isMobile}
                                newInvoice={true}
                                type={'foreign'}
                                setLoading={setLoading}
                            />
                        )}
                    </Invoice>
                )}
                {isMobile ? (
                    <MobileFooter
                        handleUpdateInvoice={handleUpdateProforma}
                        setDeleteInvoiceModal={setDeleteInvoiceModal}
                        setPreviewModal={setPreviewModal}
                        setSendModal={setSendModal}
                        handleDownloadInvoice={handleDownloadProforma}
                        handleCreateInvoiceFromProforma={handleCreateInvoiceFromProforma}
                        handleCreateAdvanceFromProforma={handleCreateAdvanceFromProforma}
                        name="proforma"
                        invoice={proformaData}
                    />
                ) : (
                    <WebFooter
                        t={t}
                        handleUpdateInvoice={handleUpdateProforma}
                        setDeleteInvoiceModal={setDeleteInvoiceModal}
                        setPreviewModal={setPreviewModal}
                        setSendModal={setSendModal}
                        handleDownloadInvoice={handleDownloadProforma}
                        handleCreateInvoiceFromProforma={handleCreateInvoiceFromProforma}
                        handleCreateAdvanceFromProforma={handleCreateAdvanceFromProforma}
                        name="proforma"
                        invoice={proformaData}
                    />
                )}
            </div>
        </>
    );
};
export default EditProforma;
