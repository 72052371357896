import { Dispatch, SetStateAction } from 'react';
import communication from '../../../communication';
import { ObjectKeys } from '../../../types/objectKeys';

const getStatistics = (setStatistics: Dispatch<SetStateAction<ObjectKeys>>, selectedUserId: string): void => {
    communication.getInvoiceStatisticsByUserId(selectedUserId).then((res: ObjectKeys) => {
        if (res?.status === 200) {
            setStatistics && setStatistics(res?.data);
        }
    });
};

export default getStatistics;
