import styled, { keyframes } from 'styled-components';

const loadingAnimation = keyframes`
    0% {
        background-position: -200px 0;
    }
    100% {
        background-position: calc(200px + 100%) 0;
    }
`;

export const NotificationItemSkeleton = (): JSX.Element => {
    return (
        <SkeletonNotification>
            <div className="second-row">
                <div className="skeleton-content" />
                <div className="skeleton-content" />
                <div className="skeleton-content" />
            </div>
        </SkeletonNotification>
    );
};

const SkeletonNotification = styled.div`
    background-color: var(--white);
    color: var(--black);
    &:not(:last-child) {
        border-bottom: 1px solid var(--border-color);
    }
    .checkbox-title-wrapper {
        display: flex;
        gap: 0.5rem;
        .skeleton-icon {
            background: linear-gradient(90deg, #e6dfff 25%, #cebcff 50%, #b6a0ff 75%);
            background-size: 200% 100%;
            animation: ${loadingAnimation} 1.5s infinite;
            border-radius: 50%;
            height: 16px;
            width: 16px;
        }
        .skeleton-text {
            background: linear-gradient(90deg, #e6dfff 25%, #cebcff 50%, #b6a0ff 75%);
            background-size: 200% 100%;
            animation: ${loadingAnimation} 1.5s infinite;
            border-radius: 4px;
            height: 14px;
            width: 100%;
        }
    }
    .first-row {
        display: flex;
        justify-content: space-between;
        .skeleton-date {
            background: linear-gradient(90deg, #e6dfff 25%, #cebcff 50%, #b6a0ff 75%);
            background-size: 200% 100%;
            animation: ${loadingAnimation} 1.5s infinite;
            border-radius: 4px;
            height: 14px;
            width: 25%;
        }
    }
    .second-row {
        padding: 2rem;
        .skeleton-content {
            background: linear-gradient(90deg, #e6dfff 25%, #cebcff 50%, #b6a0ff 75%);
            background-size: 200% 100%;
            animation: ${loadingAnimation} 1.5s infinite;
            border-radius: 4px;
            height: 14px;
            width: 100%;
            margin-bottom: 8px;
        }
    }
`;

export default NotificationItemSkeleton;
