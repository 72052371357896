import { FunctionComponent, useEffect, useState } from 'react';
import { ObjectKeys } from '../../../../types/objectKeys';
import styled from 'styled-components';
import useAllUsersStore from '../../../../store/admin/allUsers';
import communication from '../../../../communication';
import Sidebar from '../../../../components/sideModal';
import Button, { ButtonVariant } from '../../../../components/button';
import colors from '../../../../global/colors';
import Input, { InputComponentType } from '../../../../components/input';
import moment from 'moment';
import useUserStore from '../../../../store/user';
import Modal from '../../../../components/modal';
import ConfirmModal from '../../../../components/confirmModal';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

interface Props {
    closeModal: Function;
    success: Function;
}

const EditTransaction: FunctionComponent<Props> = ({ closeModal, success }) => {
    const { userBOid } = useUserStore();
    const { allUsers } = useAllUsersStore();

    const [selectedTransactionId, setSelectedTransactionId] = useState('');
    const [editTransactionDate, setEditTransactionDate] = useState<string>('');

    const [confirmSendModal, setConfirmSendModal] = useState(false);

    useEffect(() => {
        const userData = allUsers?.map((user) => {
            return { value: user.id, label: user.email + '-' + user.pib, id: user.id };
        });
        if (userBOid === '' || userBOid === 'All users') useUserStore.setState({ userBOid: userData[0]?.value });
    }, [allUsers]);

    const [showError, setShowError] = useState(false);

    const editTransactionDateValid = editTransactionDate === '' ? 'Datum je obavezan' : '';

    const resetData = (): void => {
        setEditTransactionDate('');
        setSelectedTransactionId('');
        setShowError(false);
    };

    const handleUpdateTransaction = (): void => {
        if (!editTransactionDate) {
            setShowError(true);
            return;
        } else {
            setShowError(false);
            communication.updateTransaction(selectedTransactionId, editTransactionDate).then((res: ObjectKeys) => {
                if (res.status === 200) {
                    closeModal(false);
                    resetData();
                    success();
                }
            });
        }
    };

    return (
        <>
            <ToastContainer />
            {confirmSendModal ? (
                <Modal modalVisible={confirmSendModal} closeModal={() => setConfirmSendModal(false)}>
                    <ConfirmModal
                        action={async () => {
                            setConfirmSendModal(false);
                            handleUpdateTransaction();
                        }}
                        close={() => {
                            setConfirmSendModal(false);
                        }}
                        active={confirmSendModal}
                        message={'Da li ste sigurni da zelite da izmenite transakciju?'}
                    />
                </Modal>
            ) : (
                <Sidebar close={() => closeModal(false)}>
                    <SidebarContent>
                        <div className="header">
                            <h1>Uredi transakciju</h1>
                            <button className={'close_button'} onClick={() => closeModal(false)} />
                        </div>
                        <Input
                            type={InputComponentType.Date}
                            label={'Datum'}
                            validation={showError ? editTransactionDateValid : ''}
                            date={!editTransactionDate ? null : moment(editTransactionDate).toDate()}
                            onChange={(value: Date | undefined | null) =>
                                setEditTransactionDate(moment(value).format('YYYY-MM-DD').toString())
                            }
                        />
                        <div className={'button-wrapper'}>
                            <Button
                                variant={ButtonVariant.solid}
                                color={colors.purple}
                                onClick={() => {
                                    setConfirmSendModal(true);
                                }}
                            >
                                Uredi Transakciju
                            </Button>
                        </div>
                    </SidebarContent>
                </Sidebar>
            )}
        </>
    );
};
const SidebarContent = styled.div`
    .button-wrapper {
        margin-top: 20px;
        display: flex;
        justify-content: center;
        button {
            border-radius: 100px;
        }
    }
    .header {
        position: relative;
        margin-bottom: 20px;
        h1 {
            font-size: 24px;
            color: #4c595f;
            font-weight: 400;
            display: inline;
        }
        .close_button {
            position: absolute;
            top: 10px;
            right: 10px;
            display: block;
            background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAfCAYAAACGVs+MAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAs1JREFUeNq0l81LVFEYxu+cJkkTG5hFIOmmFi6iRS6EKIoGYygbK/qQiMQ25UJ3NdBfENmmJCJa9EH0JSXaB1NiJBLVIok2FbQIowIhsJJRxsqeF56By3DeM/c6txd+szjv13Mu9555T+zlmw8Jz/POg13gGzgL+r3/Yz0gC5aDB6A3jp+LYD8DVoNzoAGciLj5KTYv2iFQZfDTZgk+TiGxCBrHWCtr8WVEwCfH47pQoYgYa/Qo/s8i4CT4owQcBVfAkkU0l5zLrGEz6ZkVAUPgIJhXAg+D6yAeonmcOZ2Kf549Bw0X7oC9oKAkdIDb8tIEaF7F2A7FLz32sadnfI5h0A5mlcQ94B5Y5mguvruMtdksewwVF0xJQA7sBHmlwA4KrbH4auhrU3LzrJ3zLxpL4ChIg59KoVbwCNT61mq51qrk/GLN0VKHURLGwTYwrfg3g8dgBclxzWbTFDauva2avQIp8AQkLf4Nvh01KzW+cyMTWhNT5o2WxK1gSvE3O5pPMXfC1cAE+Kze8vF+DXEOSOwW5nqVChB7TxGTAWInGfsuSGETYlcfeSqWs07GelELWAOuBYi7ythIBTSBMdAYILaRsU1RCVgLnoH6EE+rnjnrKhWwnoVWKv7XxGaS85Q1FiWghQdNUvG/4EElPFdikqzRElbARp6ACcU/xhPuB0lzzWYJ1toUVECKZ3udUnAEbAczvrUZro0oOXWsmSonQHZyn2OzzeQfL6P8Xefpe6jk1rB2WhOQ4aBQrRQYBLvBnOO9mfMNLjarZo/2UgFyLxhwjFy3GFMI8AlKzAHmaCPbQPEuEqeaG47JV06/I47J2Wa/efEoKMf3UvYsyBM442h+CXSFbO4fu7t489LG9tMiYJUS0M+Z/m8FFxPJ7XbcNRsM38xS65OLI1iI4Gq2wFp9Ft+w4S5v8jP6QsVRX0w91uxmjzx7HvsnwADMMpd5tpG+eAAAAABJRU5ErkJggg==)
                no-repeat;
            background-size: 16px 16px;
            width: 16px;
            height: 16px;
            text-indent: -9999px;
            border: 0;
            &:hover {
                cursor: pointer;
            }
        }
    }
    .choose-user {
        margin-top: 10px;
        margin-bottom: 10px;
    }
    .user-select {
        font-size: 16px;
        color: var(--gray);
    }
    .total-amount {
        font-size: 18px;
        margin-bottom: 20px;
        margin-top: 10px;
        border: 1px solid var(--border-color);
        border-radius: 5px;
        padding: 10px;
    }
    .content {
        .select-search {
            margin-bottom: 20px;
        }
        .select-wrapper {
            width: 100%;
            label {
                font-size: 15px;
                color: var(--gray);
                padding-left: 0;
            }
            .select__control {
                margin-top: 5px;
                margin-bottom: 5px;
            }
        }
        .search-wrapper {
            margin-bottom: 5px;
            margin-top: 5px;
        }

        .options-list {
            z-index: 1;
            top: 9em;
        }
    }
    .selected-user {
        display: block;
        margin-top: 15px;
        margin-bottom: 20px;
        font-size: 15px;
        color: var(--gray);
        .delete-selected-user {
            float: right;
            cursor: pointer;
        }
    }
    .warning {
        color: red;
        font-size: 12px;
        margin-top: 10px;
    }
    .error {
        color: red;
        font-size: 14px;
        margin-top: 10px;
        text-align: center;
    }
`;

export default EditTransaction;
