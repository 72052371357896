import axios, { AxiosError, AxiosResponse } from 'axios';
import routes from './routes';
import getCurrentLanguage from '../functions/getCurrentLanguage';

const downloadInvoice = async (
    id: string | any,
    token: string,
    invoiceNo: string,
    foreign: boolean,
    proforma?: boolean,
    qr_code?: string | null,
): Promise<AxiosResponse | void> => {
    return new Promise((resolve, reject) => {
        try {
            const formData = new FormData();
            formData.append('token', token);
            if (qr_code && !foreign) {
                formData.append('qr_code', `data:image/jpeg;base64,${qr_code}`);
            }

            axios({
                url: proforma ? routes.proforma.downloadProforma(id) : routes.invoice.downloadInvoice(id),
                method: 'POST',
                responseType: 'blob',
                data: formData,
                headers: {
                    'Accept-Language': !foreign ? getCurrentLanguage() : 'en',
                    'Content-Type': 'multipart/form-data; boundary=----WebKitFormBoundaryggBjcnULJCwOMuKk',
                },
            })
                .then((response) => {
                    const fileName = response?.headers['content-disposition']?.split('filename="')[1]?.split('"')[0];
                    const href = URL.createObjectURL(response.data);
                    const link = document.createElement('a');
                    link.href = href;
                    link.setAttribute('download', fileName);
                    document.body.appendChild(link);
                    link.click();
                    document.body.removeChild(link);
                    URL.revokeObjectURL(href);
                    if (response.status === 200) resolve(response); // Resolve the promise with the response
                })
                .catch((error: AxiosError) => {
                    reject(error); // Reject the promise with the error
                });
        } catch (error) {
            reject(error as AxiosError); // Reject the promise with the error if an exception occurs
        }
    });
};

export default downloadInvoice;
