import useLanguageStore from '../../../../store/language';
import useTranslations from '../../../../hooks/useTranslation';
import { z } from 'zod';

const useDomesticAccountSchema = () => {
    const { currentLang } = useLanguageStore();
    const t = useTranslations(currentLang);

    const DomesticAccountSchema = z.array(
        z.object({
            bankAccountNumber1: z
                .string({ message: t('pages.agency.bankAccounts.domestic.bank_account_1_min').text })
                .min(3, t('pages.agency.bankAccounts.domestic.bank_account_1_min').text)
                .max(3, t('pages.agency.bankAccounts.domestic.bank_account_1_min').text),
            bankAccountNumber2: z
                .string({ message: t('pages.agency.bankAccounts.domestic.bank_account_2_min').text })
                .min(13, t('pages.agency.bankAccounts.domestic.bank_account_2_min').text)
                .max(13, t('pages.agency.bankAccounts.domestic.bank_account_2_min').text),
            bankAccountNumber3: z
                .string({ message: t('pages.agency.bankAccounts.domestic.bank_account_3_min').text })
                .min(2, t('pages.agency.bankAccounts.domestic.bank_account_3_min').text)
                .max(2, t('pages.agency.bankAccounts.domestic.bank_account_3_min').text),
        }),
    );

    return { DomesticAccountSchema };
};

export default useDomesticAccountSchema;
