import { FunctionComponent, useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark, faSave, faPlus, faTrash } from '@fortawesome/free-solid-svg-icons';
import styled from 'styled-components';
import colors from '../../../../global/colors';
import { ObjectKeys } from '../../../../types/objectKeys';
import Button, { ButtonVariant } from '../../../../components/button';
import Input, { InputComponentType } from '../../../../components/input';
import Select from '../../../../components/select';
import communication from '../../../../communication';
import Loader from '../../../../components/loader';

interface Props {
    t: Function;
    close: Function;
    success: Function;
    loading: Function;
    modalVisible: boolean;
    errorMessage: Function;
}

interface Service {
    id: string;
    name_rs: string;
    name_en: string;
    name_ru: string;
    description_rs: string;
    description_en: string;
    description_ru: string;
    price: number;
}

const SendModal: FunctionComponent<Props> = ({ close, modalVisible, success }) => {
    const [formData, setFormData] = useState<ObjectKeys>({
        name: '',
        monthly: 0,
        yearly: 0,
        half_year_discount: 0,
        yearly_discount: 0,
        services_discount: 0,
        months: 1,
        services: [],
        active: false,
    });
    const [questionModal, setQuestionModal] = useState(false);
    const [services, setServices] = useState<Service[]>();
    const [loader, setLoader] = useState<boolean>(false);
    const [error, setError] = useState<boolean>(false);

    const monthsOptions = [
        { value: 1, label: '1' },
        { value: 2, label: '2' },
        { value: 3, label: '3' },
        { value: 4, label: '4' },
        { value: 5, label: '5' },
        { value: 6, label: '6' },
        { value: 7, label: '7' },
        { value: 8, label: '8' },
        { value: 9, label: '9' },
        { value: 10, label: '10' },
        { value: 11, label: '11' },
        { value: 12, label: '12' },
    ];

    useEffect(() => {
        if (!modalVisible) return;
    }, [modalVisible]);

    const handleRemoveService = (serviceId: number): void => {
        if (!formData || !services) return;
        const index = formData.services.findIndex((service: ObjectKeys) => service.id === serviceId);

        if (index !== -1) {
            const updatedEditedFormData = {
                ...formData,
                services: formData.services.filter((service: ObjectKeys) => service.id !== serviceId),
            };
            const removedService = formData.services[index];
            setServices([...services, removedService]);
            setFormData(updatedEditedFormData);
        }
    };

    const handleAddService = (service: ObjectKeys): void => {
        if (!formData || !services) return;
        const updatedServices = services.filter((s: ObjectKeys) => s.id !== service.id);
        const updatedEditedFormData = {
            ...formData,
            services: [...formData.services, service],
        };
        setServices(updatedServices);
        setFormData(updatedEditedFormData);
    };

    const createPackage = (): void => {
        setError(false);
        const serviceIdsOnly = formData.services.map((service: Service) => service.id);
        const updatedFormData = {
            ...formData,
            services: serviceIdsOnly,
        };

        communication
            .createPackage(updatedFormData)
            .then(() => {
                success();
            })
            .catch((err: any) => {
                console.error('Error:', err);
                setLoader(false);
            });
    };

    const getAllServices = (): void => {
        setLoader(true);
        communication
            .getAllAdminServices({ page: 1, limit: 1000, orderBy: 'name_rs', sortedBy: 'asc' })
            .then((response: ObjectKeys) => {
                setServices(response.data.data);
                setLoader(false);
            })
            .catch((err: any) => {
                console.error('Error:', err);
                setLoader(false);
            });
    };

    useEffect(() => {
        getAllServices();
    }, []);

    return (
        <>
            {loader && <Loader />}
            {questionModal ? (
                <>
                    <Question>
                        <p>Da li ste sigurni da želite da dodate paket?</p>
                        <div className="button-container">
                            <Button
                                variant={ButtonVariant.solid}
                                color={colors.purple}
                                className="small"
                                size={200}
                                onClick={() => {
                                    createPackage();
                                    close();
                                }}
                            >
                                <FontAwesomeIcon icon={faSave} style={{ color: 'var(--white)', marginRight: '10px' }} />
                                Da
                            </Button>
                            <Button
                                variant={ButtonVariant.solid}
                                color={colors.purple}
                                className="small"
                                size={200}
                                onClick={() => {
                                    setQuestionModal(false);
                                }}
                            >
                                <FontAwesomeIcon
                                    icon={faXmark}
                                    style={{ color: 'var(--white)', marginRight: '10px' }}
                                />
                                Odustani
                            </Button>
                        </div>
                    </Question>
                </>
            ) : (
                <>
                    {formData && (
                        <Container>
                            <Header>
                                <h2>Dodaj paket</h2>
                                <Icon onClick={() => close()}>
                                    <FontAwesomeIcon icon={faXmark} style={{ color: 'var(--gray)' }} />
                                </Icon>
                            </Header>
                            <Input
                                type={InputComponentType.Text}
                                label={'Naziv paketa'}
                                value={formData.name}
                                onChange={(value: string) => {
                                    setFormData({
                                        ...formData,
                                        name: value,
                                    });
                                }}
                            />
                            <br />
                            <Input
                                type={InputComponentType.Amount}
                                label={'Mesecna cena (RSD)'}
                                value={formData.monthly}
                                onChange={(value: any) => {
                                    setFormData({
                                        ...formData,
                                        monthly: value,
                                    });
                                }}
                            />
                            <br />
                            {/* <Input
                                type={InputComponentType.Amount}
                                label={'Godisnja cena (RSD)'}
                                value={formData.yearly}
                                onChange={(value: any) => {
                                    setFormData({
                                        ...formData,
                                        yearly: value,
                                    });
                                }}
                            />
                            <br /> */}
                            <p>Popusti:</p>
                            <div className="discount">
                                <label>Polugodisnja:</label>
                                <span>
                                    <Input
                                        type={InputComponentType.Number}
                                        value={formData.half_year_discount}
                                        onChange={(value: any) => {
                                            setFormData({
                                                ...formData,
                                                half_year_discount: value,
                                            });
                                        }}
                                        className="discount-input"
                                    />
                                    %
                                </span>
                            </div>
                            <div className="discount">
                                <label>Godisnja:</label>
                                <span>
                                    <Input
                                        type={InputComponentType.Number}
                                        value={formData.yearly_discount}
                                        onChange={(value: any) => {
                                            setFormData({
                                                ...formData,
                                                yearly_discount: value,
                                            });
                                        }}
                                        className="discount-input"
                                    />
                                    %
                                </span>
                            </div>
                            <div className="discount">
                                <label>Dodatne usluge:</label>
                                <span>
                                    <Input
                                        type={InputComponentType.Number}
                                        value={formData.services_discount}
                                        onChange={(value: any) => {
                                            setFormData({
                                                ...formData,
                                                services_discount: value,
                                            });
                                        }}
                                        className="discount-input"
                                    />
                                    %
                                </span>
                            </div>
                            <div className="six-months">
                                <label>Mesecna obaveza:</label>
                                <Select
                                    isSearchable={false}
                                    optionList={monthsOptions}
                                    defaultSelectedOption={monthsOptions[0]}
                                    handleSelectedValue={(value: number) => {
                                        setFormData({
                                            ...formData,
                                            months: value,
                                        });
                                    }}
                                    placeholder={'Izaberi broj meseci'}
                                />
                            </div>
                            <div>
                                <p className="services">Usluge koje su uključene u paket:</p>
                                {formData.services.map((service: ObjectKeys) => (
                                    <div key={service.id}>
                                        <p className="items" onClick={() => handleRemoveService(service.id)}>
                                            <FontAwesomeIcon icon={faTrash} style={{ color: 'var(--danger)' }} />
                                            {service.name_rs}
                                        </p>
                                    </div>
                                ))}
                            </div>
                            <br />
                            <div>
                                <p className="services">Dodaj uslugu u paket:</p>
                                {services && services.length > 0 ? (
                                    <>
                                        {services.map((service: ObjectKeys) => (
                                            <div key={service.id}>
                                                <p className="items" onClick={() => handleAddService(service)}>
                                                    <FontAwesomeIcon icon={faPlus} style={{ color: 'var(--purple)' }} />
                                                    {service.name_rs}
                                                </p>
                                            </div>
                                        ))}
                                    </>
                                ) : (
                                    <p>Nema usluga</p>
                                )}
                            </div>
                            {error && (
                                <p style={{ color: 'var(--danger)', marginTop: '20px', textAlign: 'center' }}>
                                    Sva polja moraju biti popunjena
                                </p>
                            )}

                            <div className="button-container">
                                <Button
                                    variant={ButtonVariant.solid}
                                    color={colors.purple}
                                    className="small"
                                    size={200}
                                    onClick={() => {
                                        if (
                                            formData.name === '' ||
                                            formData.monthly === 0 ||
                                            formData.services.length === 0
                                        ) {
                                            setError(true);
                                            return;
                                        } else {
                                            setQuestionModal(true);
                                        }
                                    }}
                                >
                                    <FontAwesomeIcon
                                        icon={faSave}
                                        style={{ color: 'var(--white)', marginRight: '10px' }}
                                    />
                                    Sacuvaj
                                </Button>
                            </div>
                        </Container>
                    )}
                </>
            )}
        </>
    );
};

export default SendModal;
const Container = styled.div`
    .button-container {
        margin-top: 30px;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .input-container {
        width: 100%;
    }
    label {
        color: var(--gray);
    }
    .six-months {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: 20px;
        margin-bottom: 20px;
    }
    .discount {
        display: flex;
        justify-content: space-between;
        align-items: center;
        span {
            display: flex;
            align-items: center;
            font-size: 18px;
        }
    }
    .discount-input {
        width: 15%;
    }
    .services {
        font-weight: bold;
        margin-bottom: 15px;
    }
    .items {
        margin-left: 5px;
        margin-bottom: 5px;
        display: flex;
        gap: 10px;
        cursor: pointer;
    }
`;

const Header = styled.div`
    display: grid;
    grid-template-columns: 95% 5%;
    h2 {
        font-size: 24px;
        font-weight: 400;
        text-align: left;
    }
    p {
        width: 90%;
        font-size: 14px;
        line-height: 20px;
        margin-top: 10px;
        color: var(--gray);
        margin-bottom: 30px;
    }
`;
const Question = styled.div`
    display: flex;
    height: 50%;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 20px;
    p {
        font-size: 20px;
        font-weight: 700;
        color: var(--danger);
        text-align: center;
    }
    .button-container {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 20px;
    }
`;

const Icon = styled.div`
    float: right;
    font-size: 30px;
    margin-top: -8px;
    &.attach {
        font-size: 13px;
        color: var(--gray);
        float: left;
        margin-top: 0px;
    }
`;
