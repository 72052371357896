import { FunctionComponent, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faXmark,
    faPaperclip,
    faPaperPlane,
    faTrashCan,
    faSquare,
    faCheckSquare,
} from '@fortawesome/free-solid-svg-icons';
import styled from 'styled-components';
import colors from '../../../../../global/colors';
import useEmailValidation from '../../../../../pages/pausal/common/useEmailValidation';
import { ObjectKeys } from '../../../../../types/objectKeys';
import Button, { ButtonVariant } from '../../../../../components/button';
import Input, { InputComponentType } from '../../../../../components/input';
import TextArea from '../../../../../components/input/textarea';
import communication from '../../../../../communication';

interface Props {
    t: Function;
    close: Function;
    success: Function;
    loading: Function;
    clientEmail: string;
    handleAddNewInvoice?: Function;
    handleUpdateInvoice?: Function;
    errorMessage: Function;
}

const SendModal: FunctionComponent<Props> = ({ t, close, success, loading, clientEmail, errorMessage }) => {
    const [showError, setShowError] = useState(false);
    const [showEmails, setShowEmails] = useState(true);
    const [emailsChanged, setEmailsChanged] = useState(false);
    const [emails, setEmails] = useState<Array<string>>(clientEmail ? ['office@pausal.rs', clientEmail] : []);
    const [addedFiles, setAddedFiles] = useState<Array<File>>([]);

    const currentYear = new Date().getFullYear();

    const [formData, setFormData] = useState({
        recipient: '',
        subject: `Poreska rešenja i nalozi za plaćanje za ${currentYear}. godinu`,
        message: `Poštovani,\n\nObaveštavamo vaš da smo preuzeli poreska rešenja za ${currentYear}. godinu i da smo kreirali naloge za plaćanje paušalog poreza do kraja ${currentYear}. godine. Naloge za plaćanje možete naći u delu "Nalozi za prenos" dok poreska rešenja možete naći u delu “Moje obaveze” - “Poreske obaveze”.\n\nKao i prošle godine, paušalni porez plaćate do 15. u mesecu za prethodni mesec, što znači da za mesec januar treba da platite do 15. februara.\n\n Za sve dodatno, stojimo na raspolaganju 🙂\n\n Vas Pausal tim.`,
    });
    const [selectedLang, setSelectedLang] = useState('serbian');

    const handleLangChange = (lang: string): void => {
        setSelectedLang(lang);
        if (lang === 'serbian') {
            setFormData({
                ...formData,
                subject: `Poreska rešenja i nalozi za plaćanje za ${currentYear}. godinu`,
                message: `Poštovani,\n\nObaveštavamo vaš da smo preuzeli poreska rešenja za ${currentYear}. godinu i da smo kreirali naloge za plaćanje paušalog poreza do kraja ${currentYear}. godine. Naloge za plaćanje možete naći u delu "Nalozi za prenos" dok poreska rešenja možete naći u delu “Moje obaveze” - “Poreske obaveze”.\n\nKao i prošle godine, paušalni porez plaćate do 15. u mesecu za prethodni mesec, što znači da za mesec januar treba da platite do 15. februara.\n\n Za sve dodatno, stojimo na raspolaganju 🙂\n\n Vas Pausal tim.`,
            });
        } else if (lang === 'english') {
            setFormData({
                ...formData,
                subject: `Tax solutions and payment orders for ${currentYear}. / Poreska rešenja i nalozi za plaćanje za ${currentYear}. godinu`,
                message: `Dear,\n\nWe would like to inform you that we have downloaded the tax solutions for ${currentYear}. and have created payment orders for ${currentYear}.\n\nYou can find the payment orders in the "Transfer Orders" section, while the tax solutions you can find in section "My obligations" - "Tax obligations".\n\nLike last year, tax payments must be made by the 15th of the month, for the previous month. For example, January should be paid by February 15th.\n\nIf you have any additional questions, be free to ask. 🙂\n\nBest regards, Pausal team\n\n\n\nPoštovani,\n\nObaveštavamo vaš da smo preuzeli poreska rešenja za ${currentYear}. godinu i da smo kreirali naloge za plaćanje paušalog poreza do kraja ${currentYear}. godine. Naloge za plaćanje možete naći u delu "Nalozi za prenos" dok poreska rešenja možete naći u delu “Moje obaveze” - “Poreske obaveze”.\n\n Kao i prošle godine, paušalni porez plaćate do 15. u mesecu za prethodni mesec, što znači da za mesec januar treba da platite do 15. februara.\n\n Za sve dodatno, stojimo na raspolaganju 🙂\n\n Vas Pausal tim.`,
            });
        } else if (lang === 'russian') {
            setFormData({
                ...formData,
                subject: `Налоговые решения и платежные поручения за ${currentYear} год / Poreska rešenja i nalozi za plaćanje za ${currentYear}. godinu`,
                message: `Уважаемый пользователь,\n\nХотим сообщить, что мы скачали налоговые решения за ${currentYear} год и создали платежные поручения до конца ${currentYear} года.\n\nПлатежные поручения можно найти в разделе "Платежные поручения", а налоговые решения — в разделе "Мой обязательства" — "Налоговые обязательства".\n\nКак и в прошлом году, налоги оплачиваются до 15-го числа месяца за предыдущий месяц. То есть платеж за январь следует сделать до 15 февраля.\n\nЕсли у вас есть дополнительные вопросы, пишите нам 🙂\n\nВсего доброго, коллектив Pausal\n\n\n\nPoštovani,\n\nObaveštavamo vaš da smo preuzeli poreska rešenja za ${currentYear}. godinu i da smo kreirali naloge za plaćanje paušalog poreza do kraja ${currentYear}. godine. Naloge za plaćanje možete naći u delu "Nalozi za prenos" dok poreska rešenja možete naći u delu “Moje obaveze” - “Poreske obaveze”.\n\n Kao i prošle godine, paušalni porez plaćate do 15. u mesecu za prethodni mesec, što znači da za mesec januar treba da platite do 15. februara.\n\n Za sve dodatno, stojimo na raspolaganju 🙂\n\n Vas Pausal tim.`,
            });
        }
    };
    const isEmailValid = useEmailValidation(formData.recipient, emails);

    const handleDataToSend = async (): Promise<void> => {
        loading(true);
        const form = new FormData();
        for (let i = 0; i < addedFiles.length; i++) {
            const file = addedFiles[i];
            form.append('attachments[' + i + ']', file);
        }
        const requestData = {
            message: formData.message,
            subject: formData.subject,
            email: emails[0],
        };
        form.append('request_body', JSON.stringify(requestData));
        try {
            const res = await communication.sendTaxEmail(form);
            if (res.status === 204) {
                success();
                close();
                loading(false);
            }
        } catch (e: any) {
            errorMessage(e.response.data.message);
            loading(false);
        }
    };

    const sendEmail = async (): Promise<void> => {
        if (isEmailValid === '' && emails.length > 0) {
            handleDataToSend();
        } else {
            setShowError(true);
        }
    };

    const handleRemoveEmail = (e: string): void => {
        const reducedEmails = emails;
        const index = reducedEmails.indexOf(e);

        if (index > -1) {
            reducedEmails.splice(index, 1);
        }
        setEmails(reducedEmails);
        setEmailsChanged(!emailsChanged);
    };

    const handleAttachInvoice = (event: any): void => {
        if (event?.target !== null && (event?.target as any)?.files[0]) {
            setAddedFiles([...addedFiles, event?.target?.files[0]]);
        }
    };

    const handleRemoveFile = (indexToRemove: number): void => {
        const updatedFiles = addedFiles.filter((file, index) => index !== indexToRemove);
        setAddedFiles(updatedFiles);
    };

    return (
        <>
            <Container>
                <Header>
                    <h4>{'Poreska rešenja i nalozi za plaćanje'}</h4>
                    <Icon onClick={() => close()}>
                        <FontAwesomeIcon icon={faXmark} style={{ color: 'var(--gray)' }} />
                    </Icon>
                </Header>
                <label>{t('pages.invoices.sendModal.recipient').text}</label>
                <div className="emails">
                    {showEmails &&
                        emails.length > 0 &&
                        emails
                            .filter((email: string) => email !== '')
                            .map((email: string) => (
                                <Email key={email}>
                                    {email}
                                    <span onClick={() => handleRemoveEmail(email)}>x</span>
                                </Email>
                            ))}
                </div>
                <EmailFieldWrapper>
                    <Input
                        className="email"
                        type={InputComponentType.Text}
                        value={formData.recipient ? formData.recipient : ''}
                        onChange={(value: string) => {
                            setFormData({ ...formData, recipient: value });
                        }}
                        onEnter={() => {
                            if (formData.recipient !== '' && isEmailValid === '') {
                                setShowEmails(true);
                                setEmails([...emails, formData.recipient]);
                                setFormData({ ...formData, recipient: '' });
                            }
                        }}
                        validation={showError ? isEmailValid : ''}
                        handleBlurAction={() => {
                            setShowError(true);
                            if (emails.includes(formData.recipient)) {
                                setFormData({ ...formData, recipient: '' });
                                return;
                            }
                            if (formData.recipient !== '' && isEmailValid === '') {
                                setShowEmails(true);
                                setEmails([...emails, formData.recipient]);
                                setFormData({ ...formData, recipient: '' });
                            }
                        }}
                        hideBlurOnChange
                    />
                </EmailFieldWrapper>
                <Input
                    type={InputComponentType.Text}
                    label={t('pages.invoices.sendModal.subject').text}
                    value={formData.subject ? formData.subject : ''}
                    onChange={(value: string) => {
                        setFormData({ ...formData, subject: value });
                    }}
                />
                <TextArea
                    label={t('pages.invoices.sendModal.message').text}
                    value={formData.message ? formData.message : ''}
                    onChange={(value: string) => {
                        setFormData({ ...formData, message: value });
                    }}
                    height={'450px'}
                />
                <Footer>
                    <div>
                        <Icon className="attach">
                            {addedFiles.length > 0 ? (
                                <AttachedFiles>
                                    {addedFiles.map((file: ObjectKeys, index: number) => {
                                        return (
                                            <div key={index} className="attached-files">
                                                <p>
                                                    <FontAwesomeIcon
                                                        icon={faPaperclip}
                                                        style={{ color: 'var(--gray)', marginRight: '3px' }}
                                                    />
                                                    {file.name}
                                                </p>
                                                <button
                                                    onClick={() => handleRemoveFile(index)}
                                                    className="delete_button"
                                                >
                                                    <FontAwesomeIcon
                                                        icon={faTrashCan}
                                                        style={{ color: colors.danger }}
                                                    />
                                                </button>
                                            </div>
                                        );
                                    })}
                                </AttachedFiles>
                            ) : (
                                <></>
                            )}
                        </Icon>
                    </div>

                    <UploadFiles>
                        <input type="file" onChange={(e: any) => handleAttachInvoice(e)} />
                        <FontAwesomeIcon icon={faPaperclip} style={{ color: 'var(--black)', marginRight: '3px' }} />
                        <span>{t('pages.invoices.sendModal.attach').text}</span>
                    </UploadFiles>
                    <LangSelector>
                        <p>Izaberi templejt jezika mejla:</p>
                        <div
                            onClick={() => {
                                handleLangChange('serbian');
                            }}
                            className="checkbox"
                        >
                            <FontAwesomeIcon
                                icon={selectedLang === 'serbian' ? faCheckSquare : faSquare}
                                className="check-icon"
                            />
                            Srpski
                        </div>
                        <div
                            onClick={() => {
                                handleLangChange('english');
                            }}
                            className="checkbox"
                        >
                            <FontAwesomeIcon
                                icon={selectedLang === 'english' ? faCheckSquare : faSquare}
                                className="check-icon"
                            />
                            Englesko/Srpski
                        </div>
                        <div
                            onClick={() => {
                                handleLangChange('russian');
                            }}
                            className="checkbox"
                        >
                            <FontAwesomeIcon
                                icon={selectedLang === 'russian' ? faCheckSquare : faSquare}
                                className="check-icon"
                            />
                            Rusko/Srpski
                        </div>
                    </LangSelector>
                </Footer>
                <div className="button-container">
                    <Button
                        variant={ButtonVariant.solid}
                        color={colors.purple}
                        className="small"
                        size={200}
                        onClick={sendEmail}
                    >
                        <FontAwesomeIcon icon={faPaperPlane} style={{ color: 'var(--white)', marginRight: '10px' }} />
                        {t('pages.invoices.sendModal.send').text}
                    </Button>
                </div>
            </Container>
        </>
    );
};

export default SendModal;
const Container = styled.div`
    .button-container {
        margin-top: 30px;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .input-container {
        width: 100%;
    }
    label {
        color: var(--gray);
    }
    .emails {
        margin-top: 10px;
    }
`;

const EmailFieldWrapper = styled.div`
    .validation-message {
        color: var(--danger);
        font-size: 12px;
    }
`;
const LangSelector = styled.div`
    display: flex;
    align-items: left;
    .flex {
        display: flex;
        margin-right: 20px;
    }
    p {
        margin-bottom: 10px;
        font-size: 14px;
        color: var(--gray);
    }
    .checkbox {
        font-size: 16px;
        display: flex;
        gap: 10px;
        align-items: center;
        cursor: pointer;
        .check-icon {
            color: var(--purple);
            border-radius: 5px;
            font-size: 18px;
        }
    }
`;
const Email = styled.div`
    display: inline-block;
    padding: 5px 10px;
    text-decoration: none;
    background: var(--purple);
    color: var(--white);
    margin-bottom: 6px;
    margin-right: 6px;
    font-size: 14px;
    span {
        margin-left: 10px;
        cursor: pointer;
    }
`;
const Header = styled.div`
    display: grid;
    grid-template-columns: 95% 5%;
    h2 {
        font-size: 24px;
        font-weight: 400;
        text-align: left;
    }
    p {
        width: 90%;
        font-size: 14px;
        line-height: 20px;
        margin-top: 10px;
        color: var(--gray);
        margin-bottom: 30px;
    }
`;

const Footer = styled.div`
    > div {
        margin-top: 20px;
        font-size: 13px;
        color: var(--gray);
        display: flow-root;
        .send-copy {
            float: right;
            display: flex;
        }
    }
`;

const Icon = styled.div`
    float: right;
    font-size: 30px;
    margin-top: -8px;
    &.attach {
        font-size: 13px;
        color: var(--gray);
        float: left;
        margin-top: 0px;
    }
`;
const UploadFiles = styled.label`
    cursor: pointer;
    font-size: 13px;
    span {
        font-size: 13px;
        color: var(--black);
    }
    &:hover {
        span {
            color: var(--purple);
        }
    }
    input[type='file'] {
        display: none;
    }
`;
const AttachedFiles = styled.div`
    margin-top: 10px;
    p {
        font-size: 13px;
        color: var(--gray);
        margin-bottom: 5px;
    }
    .attached-files {
        display: flex;
    }
    .delete_button {
        border: none;
        background: none;
        padding-left: 10px;
        margin-top: -5px;
    }
`;
