import React from 'react';
import styled, { keyframes } from 'styled-components';

const MemoSkeleton: React.FC = () => {
    return (
        <StyledMemoCard>
            <CardContent>
                <SkeletonBox className="title-section">
                    <div className="skeleton title-skeleton"></div>
                </SkeletonBox>
                <SkeletonBox className="description-section">
                    <div className="skeleton description-skeleton"></div>
                </SkeletonBox>
                <IconFooter>
                    <SkeletonBox className="icon-skeleton"></SkeletonBox>
                    <SkeletonBox className="icon-skeleton"></SkeletonBox>
                </IconFooter>
            </CardContent>
        </StyledMemoCard>
    );
};

const loadingAnimation = keyframes`
    0% {
        background-position: -200px 0;
    }
    100% {
        background-position: calc(200px + 100%) 0;
    }
`;

const StyledMemoCard = styled.div`
    width: 300px;
    min-height: 200px;
    padding: 20px 17px;
    border: 1px solid var(--purple);
    background: var(--white);
    display: flex;
    flex-direction: column;
    gap: 10px;
    cursor: pointer;
    &:hover {
        box-shadow: 0px 0px 8px 4px rgba(0, 0, 0, 0.1);
    }
`;

const CardContent = styled.div`
    display: flex;
    flex-direction: column;
    gap: 10px;
`;

const SkeletonBox = styled.div`
    .skeleton {
        background: linear-gradient(90deg, #e6dfff 25%, #cebcff 50%, #b6a0ff 75%);
        background-size: 200% 100%;
        animation: ${loadingAnimation} 1.5s infinite;
        border-radius: 4px;
    }

    .title-skeleton {
        height: 20px;
        width: 80%;
    }

    .description-skeleton {
        height: 14px;
        width: 100%;
        margin-top: 10px;
    }

    .icon-skeleton {
        height: 25px;
        width: 25px;
        border-radius: 50%;
    }
`;

const IconFooter = styled.div`
    display: flex;
    gap: 20px;
    margin-top: auto;
`;

export default MemoSkeleton;
