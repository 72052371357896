import { FunctionComponent, useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleLeft } from '@fortawesome/free-solid-svg-icons';
import moment from 'moment';
import colors from '../../global/colors';
import communication from '../../communication';
import useTranslations from '../../hooks/useTranslation';
import useLanguageStore from '../../store/language';
import useUserStore from '../../store/user';
import { RegisterSecondFormData } from '../../types/register/registerSecondFormData';
import { RegisterFirstFormData } from '../../types/register/registerFirstFormData';
import useNotRequiredEmailValidation from '../../hooks/useNotRequiredEmailValidation';
import { ObjectKeys } from '../../types/objectKeys';
import Button, { ButtonVariant } from '../../components/button';
import Loader from '../../components/loader';
import Modal from '../../components/modal';
import ErrorModal from '../../components/errorModal';
import CityPhoto from '../../assets/city.png';
import Logo from '../../assets/logo.png';
import FirstStep from './firstStep';
import SecondStep from './secondStep';
import ThirdStep from './thirdStep';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import useCharLengthValidation from '../../hooks/useCharLengthValidation';
import useResponsive from '../../hooks/responsive/useResponsive';
import { MQ_BREAKPOINTS } from '../../constants/breakpoints';
import { usePasswordDevLock } from '../../hooks/usePasswordDevLock';
type SelectOption = {
    value?: string;
    label?: string | undefined;
};

const Register: FunctionComponent = () => {
    usePasswordDevLock(['/register']);
    const { currentLang, currentLangName } = useLanguageStore();
    const t = useTranslations(currentLang);
    const { isTabletAndDown } = useResponsive();

    const [openModal, setOpenModal] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [loaderVisible, setLoaderVisible] = useState(false);
    const [showFirstStepValidationMessage, setShowFirstStepValidationMessage] = useState(false);
    const [showSecondStepValidationMessage, setShowSecondStepValidationMessage] = useState(false);

    const [activityCodesOptions, setActivityCodesOptions] = useState<SelectOption[]>([]);
    const [municipalitiesOptions, setMunicipalitiesOptions] = useState<SelectOption[]>([]);
    const [formDataFirstStep, setFormDataFirstStep] = useState<RegisterFirstFormData>({
        email: '',
        password: '',
        confirmPassword: '',
        name: '',
        lastName: '',
        promoCode: '',
        isPromoCodeChecked: false,
        isTermsChecked: false,
        lang: 'rs',
        country_id: '',
    });

    const [formDataSecondStep, setFormDataSecondStep] = useState<RegisterSecondFormData>({
        vatNumber: '',
        agencyName: '',
        agencyFullName: '',
        agencyIdNumber: '',
        bankAccountNumber1: '',
        bankAccountNumber2: '',
        bankAccountNumber3: '',
        activityCodeId: '',
        activityCode: { label: '', value: '' },
        agencyDateOfRegistration: null,
        cityAndZip: '',
        municipalities: { label: '', value: '' },
        municipalityId: '',
        addressOfHeadQuarters: '',
        addressNumber: '',
        phoneNumber: '',
        employedInAnotherCompany: false,
        employment_type: '',
    });
    const [activeStep, setActiveStep] = useState(0);
    const [clickedField, setClickedField] = useState('');
    const [showBottomBlock, setShowBottomBlock] = useState(false);

    const isEmailValid = useNotRequiredEmailValidation(formDataFirstStep.email);
    const isPromoCodValid = useCharLengthValidation(
        formDataFirstStep.promoCode,
        1,
        formDataFirstStep.isPromoCodeChecked ? true : false,
    );

    const { activityCodes, municipalities } = useUserStore();

    const handleValidation = (inputName: string): void => {
        setClickedField(inputName);
    };

    const areAllFieldsFirstStepFilled =
        formDataFirstStep.email !== '' &&
        isEmailValid === '' &&
        formDataFirstStep.password !== '' &&
        formDataFirstStep.password.length > 4 &&
        formDataFirstStep.confirmPassword !== '' &&
        formDataFirstStep.confirmPassword.length > 4 &&
        formDataFirstStep.password === formDataFirstStep.confirmPassword &&
        formDataFirstStep.name !== '' &&
        formDataFirstStep.name.length > 2 &&
        formDataFirstStep.lastName !== '' &&
        formDataFirstStep.lastName.length > 2 &&
        formDataFirstStep.lang !== '' &&
        formDataFirstStep.country_id !== '' &&
        formDataFirstStep.isTermsChecked &&
        isPromoCodValid === '';

    const areAllFieldsSecondStepFilled =
        formDataSecondStep.vatNumber !== '' &&
        formDataSecondStep.agencyName !== '' &&
        formDataSecondStep.agencyName.length > 2 &&
        formDataSecondStep.agencyFullName !== '' &&
        formDataSecondStep.agencyFullName.length > 2 &&
        formDataSecondStep.bankAccountNumber1.length === 3 &&
        formDataSecondStep.bankAccountNumber2.length === 13 &&
        formDataSecondStep.bankAccountNumber3.length === 2 &&
        formDataSecondStep.activityCodeId !== '' &&
        formDataSecondStep.agencyDateOfRegistration !== null &&
        formDataSecondStep.cityAndZip !== '' &&
        formDataSecondStep.cityAndZip.length > 2 &&
        formDataSecondStep.municipalityId !== '' &&
        formDataSecondStep.addressOfHeadQuarters !== '' &&
        formDataSecondStep.addressOfHeadQuarters.length > 2 &&
        formDataSecondStep.addressNumber !== '' &&
        formDataSecondStep.employment_type.length > 1;

    useEffect(() => {
        //Get activity codes
        setActivityCodesOptions(
            activityCodes?.map((ac: any) => {
                return { value: ac?.id, label: ac?.code + ' ' + ac?.name };
            }),
        );

        //Get municipalities
        setMunicipalitiesOptions(
            municipalities?.map((m: any) => {
                return { value: m?.id, label: m?.name };
            }),
        );
    }, []);

    const getActiveStep: FunctionComponent = () => {
        switch (activeStep) {
            case 0:
                return (
                    <FirstStep
                        t={t}
                        showValidationMessage={showFirstStepValidationMessage}
                        formData={formDataFirstStep}
                        setFormData={setFormDataFirstStep}
                        handleValidation={handleValidation}
                        clickedField={clickedField}
                    />
                );
            case 1:
                return (
                    <SecondStep
                        t={t}
                        showValidationMessage={showSecondStepValidationMessage}
                        formData={formDataSecondStep}
                        setFormData={setFormDataSecondStep}
                        handleValidation={handleValidation}
                        clickedField={clickedField}
                        activityCodesOptions={activityCodesOptions}
                        municipalitiesOptions={municipalitiesOptions}
                    />
                );
            case 2:
                return <ThirdStep t={t} />;
            default:
                return null;
        }
    };

    const register = async (clickedStep: number): Promise<void> => {
        setShowFirstStepValidationMessage(true);
        if (areAllFieldsFirstStepFilled) {
            setActiveStep(1);
            setShowFirstStepValidationMessage(false);
        }
        if (clickedStep === 1) {
            setShowSecondStepValidationMessage(true);
        }

        if (areAllFieldsSecondStepFilled && activeStep === 1) {
            setLoaderVisible(true);
            const dateRequiredFormat = moment(formDataSecondStep.agencyDateOfRegistration)
                .format('YYYY-MM-DD')
                .toString();
            const agencyData = {
                name: formDataSecondStep.agencyName,
                full_name: formDataSecondStep.agencyFullName,
                pib: formDataSecondStep.vatNumber,
                identification_number: formDataSecondStep.agencyIdNumber,
                first_account_number:
                    formDataSecondStep.bankAccountNumber1 +
                    '-' +
                    formDataSecondStep.bankAccountNumber2 +
                    '-' +
                    formDataSecondStep.bankAccountNumber3,
                activity_code_id: formDataSecondStep.activityCodeId,
                date_of_registration: dateRequiredFormat,
                city: formDataSecondStep.cityAndZip,
                municipality_id: formDataSecondStep.municipalityId,
                street_address: formDataSecondStep.addressOfHeadQuarters,
                street_number: formDataSecondStep.addressNumber,
                phone: formDataSecondStep.phoneNumber,
                agency_email: formDataFirstStep.email,
                employment_type: formDataSecondStep.employment_type,
            };

            if (formDataFirstStep.promoCode !== '' && formDataFirstStep.isPromoCodeChecked) {
                const dataToSendWithCode = {
                    email: formDataFirstStep.email,
                    password: formDataFirstStep.password,
                    first_name: formDataFirstStep.name,
                    last_name: formDataFirstStep.lastName,
                    code: formDataFirstStep.promoCode,
                    agency: agencyData,
                    lang: formDataFirstStep.lang,
                    country_id: formDataFirstStep.country_id,
                };
                communication
                    .register(dataToSendWithCode)
                    .then((res: ObjectKeys) => {
                        setLoaderVisible(false);
                        if (res) {
                            setActiveStep(2);
                        }
                    })
                    .catch((error: ObjectKeys) => {
                        setLoaderVisible(false);
                        if (error?.response?.status === 422) {
                            const errorArray = error?.response?.data?.errors;
                            const errorMsg = errorArray.email
                                ? errorArray.email[0]
                                : errorArray.code
                                ? errorArray.code[0]
                                : '';
                            setErrorMessage(errorMsg);
                            setActiveStep(0);
                            setOpenModal(true);
                        } else if (error?.response?.status === 400) {
                            setErrorMessage(
                                currentLangName === 'English'
                                    ? t('pages.register.rightColumn.validationMessages.bankAccountInvalid').text
                                    : error.response.data.message,
                            );
                            setOpenModal(true);
                        } else {
                            toast.error(error.response.data.message);
                        }
                    });
            } else {
                const dataToSendWithoutCode = {
                    email: formDataFirstStep.email,
                    password: formDataFirstStep.password,
                    first_name: formDataFirstStep.name,
                    last_name: formDataFirstStep.lastName,
                    agency: agencyData,
                    lang: formDataFirstStep.lang,
                    country_id: formDataFirstStep.country_id,
                };
                communication
                    .register(dataToSendWithoutCode)
                    .then((res: ObjectKeys) => {
                        setLoaderVisible(false);
                        if (res) {
                            setActiveStep(2);
                        }
                    })
                    .catch((error: ObjectKeys) => {
                        let errorMessagesArray: Array<string> = [];
                        setLoaderVisible(false);
                        if (error?.response?.status === 422) {
                            Object.keys(error?.response?.data?.errors).filter((errorKey) => {
                                Object.keys(dataToSendWithoutCode).forEach((dataKey) => {
                                    if (errorKey === dataKey) {
                                        errorMessagesArray = [
                                            ...errorMessagesArray,
                                            error?.response?.data?.errors[errorKey],
                                        ];
                                        setErrorMessage(
                                            t('pages.register.rightColumn.validationMessages.emailTaken').text,
                                        );
                                        setActiveStep(0);
                                        setOpenModal(true);
                                        return errorMessagesArray;
                                    }
                                });
                            });
                            toast.error(
                                errorMessagesArray.map((message) => {
                                    return (
                                        <>
                                            {message}
                                            <br></br>
                                        </>
                                    );
                                }),
                            );
                        } else if (error?.response?.status === 400) {
                            setErrorMessage(
                                currentLangName === 'English'
                                    ? t('pages.register.rightColumn.validationMessages.bankAccountInvalid').text
                                    : error.response.data.message,
                            );
                            setOpenModal(true);
                        } else {
                            toast.error(error.response.data.message);
                        }
                    });
            }
        }
    };

    return (
        <>
            {openModal ? (
                <Modal modalVisible={openModal} closeModal={() => setOpenModal(false)}>
                    <ErrorModal t={t} setOpenModal={setOpenModal} errorMessage={errorMessage} />
                </Modal>
            ) : (
                <></>
            )}
            {loaderVisible && <Loader />}
            <ToastContainer />
            <PageWrapper className="register page">
                <RegisterInner>
                    {!isTabletAndDown ? (
                        <LeftColumn>
                            <div className="content">
                                <h1>{t('pages.register.leftColumn.title').text}</h1>
                                <h3>{t('pages.register.leftColumn.subtitle').text}</h3>
                            </div>
                        </LeftColumn>
                    ) : (
                        <></>
                    )}
                    <RightColumn>
                        <div className="content">
                            <div className="top-block">
                                <h4>{t('pages.register.rightColumn.title').text}</h4>
                                <img src={Logo} alt="logo" />
                            </div>
                            <div className="form">
                                {getActiveStep(activeStep)}
                                {activeStep !== 2 && (
                                    <div className="next-btn">
                                        <Button
                                            variant={ButtonVariant.solid}
                                            color={colors.purple}
                                            className="big"
                                            size={200}
                                            onClick={() => {
                                                register(activeStep);
                                            }}
                                        >
                                            {t('buttons.next').text}
                                        </Button>
                                    </div>
                                )}
                            </div>
                            {activeStep !== 0 && (
                                <BackBtn
                                    className="cursor-pointer"
                                    onClick={() => {
                                        activeStep === 2 ? setShowBottomBlock(true) : setActiveStep(activeStep - 1);
                                    }}
                                >
                                    <FontAwesomeIcon icon={faAngleLeft} style={{ color: 'var(--white)' }} />
                                </BackBtn>
                            )}
                            {activeStep === 0 || showBottomBlock ? (
                                <div className="bottom-block">
                                    <span>{t('pages.register.rightColumn.backTo').text}</span>
                                    <Link to="/login">{t('pages.register.rightColumn.login').text}</Link>
                                </div>
                            ) : (
                                <></>
                            )}
                        </div>
                    </RightColumn>
                </RegisterInner>
            </PageWrapper>
        </>
    );
};
export default Register;
const PageWrapper = styled.div`
    background: var(--purple);
    min-height: 100vh;
`;
const RegisterInner = styled.div`
    display: flex;
    max-width: 1800px;
    margin: 0 auto;
    padding: 100px 40px 40px 40px;

    //Media Queries
    @media only screen and (max-width: ${MQ_BREAKPOINTS.laptop}) {
        padding: 50px 40px 40px 40px;
    }
    @media only screen and (max-width: ${MQ_BREAKPOINTS.mobileL}) {
        padding: 20px 20px 20px 20px;
    }
`;
const LeftColumn = styled.div`
    background: var(--purple);
    color: var(--white);
    flex: 2 1 60%;

    .content {
        max-width: 650px;
        display: flex;
        justify-content: center;
        flex-direction: column;
        margin-top: 10%;
        margin-right: 40px;
        h1 {
            margin-bottom: 30px;
            font-size: 48px;
        }
        h3 {
            line-height: 1.2;
        }
    }
`;
const RightColumn = styled.div`
    background: var(--purple);
    flex: 2 1 40%;
    //Media Queries
    @media only screen and (max-width: ${MQ_BREAKPOINTS.laptop}) {
        width: 100%;
    }
    .content {
        background: var(--white);
        box-shadow: 11px 10px 34px -6px rgba(0, 0, 0, 0.75);
        max-width: 600px;
        //Media Queries
        @media only screen and (max-width: ${MQ_BREAKPOINTS.laptop}) {
            margin: 0 auto;
        }
        .top-block {
            background: url(${CityPhoto}) bottom no-repeat;
            background-size: 100% auto;
            border-bottom: 1px solid var(--border-color);
            display: flex;
            justify-content: space-between;
            align-items: flex-start;
            height: 160px;
            padding: 20px 45px;
            //Media Queries
            @media only screen and (max-width: ${MQ_BREAKPOINTS.mobileL}) {
                padding: 30px;
                height: 130px;
            }
            img {
                width: 110px;
                height: auto;
            }
        }
        .form {
            padding: 40px 45px 30px 45px;
            //Media Queries
            @media only screen and (max-width: ${MQ_BREAKPOINTS.mobileL}) {
                padding: 30px;
            }
            .next-btn {
                display: flex;
                justify-content: center;
                margin-top: 25px;
            }
            .input-container {
                width: 100%;
            }
            /* Chrome, Safari, Edge, Opera */
            input::-webkit-outer-spin-button,
            input::-webkit-inner-spin-button {
                appearance: none;
                display: none;
                margin: 0;
            }

            /* Firefox */
            input[type='number'] {
                appearance: textfield;
            }
        }
        .bottom-block {
            background: var(--light-gray);
            padding: 20px 45px;
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 12px;
            span {
                color: var(--gray);
                font-size: 12px;
                margin-right: 5px;
            }
        }
    }
`;
const BackBtn = styled.div`
    background: var(--purple);
    width: 21px;
    height: 28px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
`;
