import { FunctionComponent, useEffect, useState } from 'react';
import useLanguageStore from '../../store/language';
import { flatten } from '../../functions/flatten';
import srJson from '../language/rs.json';
import enJson from '../language/en.json';
import ruJson from '../language/ru.json';
import Select from '../select';
import colors from '../../global/colors';

interface Props {
    color?: string;
}

const SelectLang: FunctionComponent<Props> = ({ color }) => {
    const currentLangName = localStorage.getItem('language');
    //All options for languages
    const options = [
        { value: 'Srpski', label: 'Srpski' },
        { value: 'English', label: 'English' },
        { value: 'Русский', label: 'Русский' },
    ];
    const lang = localStorage.language;
    const defaultLang = lang === 'rs' ? 'Srpski' : lang === 'en' ? 'English' : lang === 'ru' ? 'Русский' : lang;
    const [render, setRender] = useState(0);

    const handleClickOption = (name: string): void => {
        useLanguageStore.setState({ currentLangName: name });
        localStorage.setItem('language', name);
    };

    useEffect(() => {
        setRender(render + 1);
        if (currentLangName && currentLangName === 'English') {
            const flattenData = flatten(enJson);
            useLanguageStore.setState({ currentLang: flattenData });
            localStorage.setItem('language', 'English');
        } else if (currentLangName && currentLangName === 'Русский') {
            const flattenData = flatten(ruJson);
            useLanguageStore.setState({ currentLang: flattenData });
            localStorage.setItem('language', 'Русский');
        } else if (currentLangName && currentLangName === 'Srpski') {
            const flattenData = flatten(srJson);
            useLanguageStore.setState({ currentLang: flattenData });
            localStorage.setItem('language', 'Srpski');
        }
    }, [localStorage.language]);

    return (
        <Select
            isSearchable={false}
            optionList={options}
            handleSelectedValue={handleClickOption}
            defaultSelectedOption={options.find((option) => option.value === defaultLang) || options[0]}
            color={color ? color : colors.white}
            width={'80px'}
            className={'select-lang'}
            key={render}
            backgroundColor={'var(--purple)'}
            menuTextColor={'var(--white)'}
            bottomBorder={'none'}
        />
    );
};
export default SelectLang;
