import { FunctionComponent } from 'react';
import styled from 'styled-components';
import colors from '../../../global/colors';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faDownload,
    faClone,
    faTrashCan,
    faEnvelope,
    faCheckCircle,
    faE,
    faMinusCircle,
    faPenToSquare,
    faPlus,
    faRefresh,
    faA,
    faBell,
    faEraser,
    faInfoCircle,
} from '@fortawesome/free-solid-svg-icons';
import { ObjectKeys } from '../../../types/objectKeys';
import Tooltip from '../tooltip';
interface Props {
    type: string;
    handleChange?: Function;
    handleDownload?: Function;
    handleCopy?: Function;
    handleCancel?: Function;
    handleDelete?: Function;
    handleSend?: Function;
    handleReminder?: Function;
    handlePayment?: Function;
    handleEInvoice?: Function;
    handleInvoice?: Function;
    handleAdvance?: Function;
    handleClick?: Function;
    handleRefresh?: Function;
    // handleRemovePayments?: Function;
    cardData: ObjectKeys;
    t: Function;
    eInvoice?: boolean;
    paymentInfo?: Function;
}
const InvoiceActionsWithoutDropdown: FunctionComponent<Props> = ({
    cardData,
    handleDownload,
    handleInvoice,
    handleAdvance,
    handleCopy,
    handlePayment,
    handleCancel,
    handleChange,
    handleDelete,
    handleSend,
    handleReminder,
    handleEInvoice,
    handleClick,
    handleRefresh,
    // handleRemovePayments,
    type,
    t,
    eInvoice,
    paymentInfo,
}) => {
    const role = localStorage.getItem('role');

    const isCanceledPaidInvoice = cardData && cardData.status === 'paid' && cardData.cancelled ? false : true;

    const eAdvanceWithPrepaids =
        cardData && cardData.is_einvoice && cardData.prepaid_invoices?.length > 0 ? false : true;

    return (
        <ActionWithoutDropdown>
            {role !== 'admin' ? (
                cardData?.status === 'paid' ? (
                    <>
                        {eInvoice ? (
                            <Tooltip text={t('pages.invoiceCard.dropdownItems.refresh').text}>
                                <Icon>
                                    <FontAwesomeIcon
                                        icon={faRefresh}
                                        style={{ color: colors.purple }}
                                        onClick={(e) => handleRefresh && handleRefresh(e)}
                                    />
                                </Icon>
                            </Tooltip>
                        ) : null}

                        <Tooltip text={t('pages.invoiceCard.dropdownItems.download').text}>
                            <Icon>
                                <FontAwesomeIcon
                                    icon={faDownload}
                                    style={{ color: colors.blue }}
                                    onClick={(e) => handleDownload && handleDownload(e)}
                                />
                            </Icon>
                        </Tooltip>

                        <Tooltip text={t('pages.invoiceCard.dropdownItems.copy').text}>
                            <Icon>
                                <FontAwesomeIcon
                                    icon={faClone}
                                    style={{ color: colors.purple }}
                                    onClick={(e) => handleCopy && handleCopy(e)}
                                />
                            </Icon>
                        </Tooltip>

                        {!eInvoice ? (
                            <Tooltip text={t('pages.invoiceCard.dropdownItems.delete').text}>
                                <Icon>
                                    <FontAwesomeIcon
                                        icon={faTrashCan}
                                        style={{ color: colors.danger }}
                                        onClick={(e) => handleDelete && handleDelete(e)}
                                    />
                                </Icon>
                            </Tooltip>
                        ) : null}

                        <Tooltip text={t('pages.invoiceCard.dropdownItems.send').text}>
                            <Icon>
                                <FontAwesomeIcon
                                    icon={faEnvelope}
                                    style={{ color: colors.blue }}
                                    onClick={(e) => handleSend && handleSend(e)}
                                />
                            </Icon>
                        </Tooltip>

                        {cardData?.type !== 'foreign' ? (
                            <Icon>
                                <FontAwesomeIcon
                                    icon={faE}
                                    style={{ color: cardData.is_einvoice ? colors.gray : colors.green }}
                                    onClick={(e) => handleEInvoice && handleEInvoice(e)}
                                />
                                <div className="label">
                                    {type !== 'advanceInvoice'
                                        ? t('pages.invoiceCard.dropdownItems.eInvoice').text
                                        : t('pages.invoiceCard.dropdownItems.eAdvance').text}
                                </div>
                            </Icon>
                        ) : null}
                        {!cardData?.cancelled ? (
                            <Tooltip text={t('pages.invoiceCard.dropdownItems.info').text}>
                                <Icon>
                                    <FontAwesomeIcon
                                        icon={faInfoCircle}
                                        style={{ color: colors.purple }}
                                        onClick={(e) => paymentInfo && paymentInfo(e)}
                                    />
                                </Icon>
                            </Tooltip>
                        ) : null}
                    </>
                ) : cardData?.status === 'open' && (type === 'invoice' || type === 'advanceInvoice') ? (
                    <>
                        <Tooltip text={t('pages.invoiceCard.dropdownItems.change').text}>
                            <Icon>
                                <FontAwesomeIcon
                                    icon={faPenToSquare}
                                    style={{ color: colors.blue }}
                                    onClick={(e) => handleChange && handleChange(e)}
                                />
                            </Icon>
                        </Tooltip>

                        <Tooltip text={t('pages.invoiceCard.dropdownItems.copy').text}>
                            <Icon>
                                <FontAwesomeIcon
                                    icon={faClone}
                                    style={{ color: colors.purple }}
                                    onClick={(e) => handleCopy && handleCopy(e)}
                                />
                            </Icon>
                        </Tooltip>

                        {!eInvoice ? (
                            <Tooltip text={t('pages.invoiceCard.dropdownItems.delete').text}>
                                <Icon>
                                    <FontAwesomeIcon
                                        icon={faTrashCan}
                                        style={{ color: colors.danger }}
                                        onClick={(e) => {
                                            handleClick && handleClick(cardData.id);
                                            handleDelete && handleDelete(e);
                                        }}
                                    />
                                </Icon>
                            </Tooltip>
                        ) : null}

                        <Tooltip text={t('pages.invoiceCard.dropdownItems.send').text}>
                            <Icon>
                                <FontAwesomeIcon
                                    icon={faEnvelope}
                                    style={{ color: colors.blue }}
                                    onClick={(e) => handleSend && handleSend(e)}
                                />
                            </Icon>
                        </Tooltip>
                    </>
                ) : cardData?.status === 'sent' ? (
                    <>
                        {eInvoice ? (
                            <Tooltip text={t('pages.invoiceCard.dropdownItems.refresh').text}>
                                <Icon>
                                    <FontAwesomeIcon
                                        icon={faRefresh}
                                        style={{ color: colors.purple }}
                                        onClick={(e) => handleRefresh && handleRefresh(e)}
                                    />
                                </Icon>
                            </Tooltip>
                        ) : null}

                        {!eInvoice && (
                            <Tooltip text={t('pages.invoiceCard.dropdownItems.change').text}>
                                <Icon>
                                    <FontAwesomeIcon
                                        icon={faPenToSquare}
                                        style={{ color: colors.blue }}
                                        onClick={(e) => handleChange && handleChange(e)}
                                    />
                                </Icon>
                            </Tooltip>
                        )}

                        <Tooltip text={t('pages.invoiceCard.dropdownItems.download').text}>
                            <Icon>
                                <FontAwesomeIcon
                                    icon={faDownload}
                                    style={{ color: colors.blue }}
                                    onClick={(e) => handleDownload && handleDownload(e)}
                                />
                            </Icon>
                        </Tooltip>

                        <Tooltip text={t('pages.invoiceCard.dropdownItems.copy').text}>
                            <Icon>
                                <FontAwesomeIcon
                                    icon={faClone}
                                    style={{ color: colors.purple }}
                                    onClick={(e) => handleCopy && handleCopy(e)}
                                />
                            </Icon>
                        </Tooltip>

                        {eInvoice && cardData?.eInvoice.data.status === 'Sending' ? null : (
                            <Tooltip text={t('pages.invoiceCard.dropdownItems.cancel').text}>
                                <Icon>
                                    <FontAwesomeIcon
                                        icon={faMinusCircle}
                                        style={{ color: colors.gray }}
                                        onClick={(e) => handleCancel && handleCancel(e)}
                                    />
                                </Icon>
                            </Tooltip>
                        )}

                        {!eInvoice ? (
                            <Tooltip text={t('pages.invoiceCard.dropdownItems.delete').text}>
                                <Icon>
                                    <FontAwesomeIcon
                                        icon={faTrashCan}
                                        style={{ color: colors.danger }}
                                        onClick={(e) => handleDelete && handleDelete(e)}
                                    />
                                </Icon>
                            </Tooltip>
                        ) : null}

                        <Tooltip text={t('pages.invoiceCard.dropdownItems.send').text}>
                            <Icon>
                                <FontAwesomeIcon
                                    icon={faEnvelope}
                                    style={{ color: colors.blue }}
                                    onClick={(e) => handleSend && handleSend(e)}
                                />
                            </Icon>
                        </Tooltip>

                        {type !== 'advanceInvoice' ? (
                            <Tooltip text={t('pages.invoiceCard.dropdownItems.payment').text}>
                                <Icon>
                                    <FontAwesomeIcon
                                        icon={faCheckCircle}
                                        style={{ color: colors.green }}
                                        onClick={(e) => handlePayment && handlePayment(e)}
                                    />
                                </Icon>
                            </Tooltip>
                        ) : (
                            <></>
                        )}

                        {cardData?.type !== 'foreign' ? (
                            <Tooltip
                                text={
                                    type !== 'advanceInvoice'
                                        ? t('pages.invoiceCard.dropdownItems.eInvoice').text
                                        : t('pages.invoiceCard.dropdownItems.eAdvance').text
                                }
                            >
                                <Icon>
                                    <FontAwesomeIcon
                                        icon={faE}
                                        style={{ color: cardData.is_einvoice ? colors.gray : colors.green }}
                                        onClick={(e) => handleEInvoice && handleEInvoice(e)}
                                    />
                                </Icon>
                            </Tooltip>
                        ) : null}

                        {type !== 'advanceInvoice' ? (
                            <Tooltip text={t('pages.invoices.sendModal.send_reminder').text}>
                                <Icon>
                                    <FontAwesomeIcon
                                        icon={faBell}
                                        style={{ color: colors.purple }}
                                        onClick={(e) => handleReminder && handleReminder(e)}
                                    />
                                </Icon>
                            </Tooltip>
                        ) : (
                            <></>
                        )}
                    </>
                ) : cardData?.status === 'partially' ? (
                    <>
                        {eInvoice ? (
                            <Tooltip text={t('pages.invoiceCard.dropdownItems.refresh').text}>
                                <Icon>
                                    <FontAwesomeIcon
                                        icon={faRefresh}
                                        style={{ color: colors.purple }}
                                        onClick={(e) => handleRefresh && handleRefresh(e)}
                                    />
                                </Icon>
                            </Tooltip>
                        ) : null}

                        <Tooltip text={t('pages.invoiceCard.dropdownItems.download').text}>
                            <Icon>
                                <FontAwesomeIcon
                                    icon={faDownload}
                                    style={{ color: colors.blue }}
                                    onClick={(e) => handleDownload && handleDownload(e)}
                                />
                            </Icon>
                        </Tooltip>

                        <Tooltip text={t('pages.invoiceCard.dropdownItems.copy').text}>
                            <Icon>
                                <FontAwesomeIcon
                                    icon={faClone}
                                    style={{ color: colors.purple }}
                                    onClick={(e) => handleCopy && handleCopy(e)}
                                />
                            </Icon>
                        </Tooltip>

                        {!eInvoice ? (
                            <Tooltip text={t('pages.invoiceCard.dropdownItems.delete').text}>
                                <Icon>
                                    <FontAwesomeIcon
                                        icon={faTrashCan}
                                        style={{ color: colors.danger }}
                                        onClick={(e) => handleDelete && handleDelete(e)}
                                    />
                                </Icon>
                            </Tooltip>
                        ) : null}

                        <Tooltip text={t('pages.invoiceCard.dropdownItems.send').text}>
                            <Icon>
                                <FontAwesomeIcon
                                    icon={faEnvelope}
                                    style={{ color: colors.blue }}
                                    onClick={(e) => handleSend && handleSend(e)}
                                />
                            </Icon>
                        </Tooltip>

                        {type !== 'advanceInvoice' ? (
                            <Tooltip text={t('pages.invoiceCard.dropdownItems.payment').text}>
                                <Icon>
                                    <FontAwesomeIcon
                                        icon={faCheckCircle}
                                        style={{ color: colors.green }}
                                        onClick={(e) => handlePayment && handlePayment(e)}
                                    />
                                </Icon>
                            </Tooltip>
                        ) : (
                            <></>
                        )}

                        {cardData?.type !== 'foreign' ? (
                            <Icon>
                                <FontAwesomeIcon
                                    icon={faE}
                                    style={{ color: cardData.is_einvoice ? colors.gray : colors.green }}
                                    onClick={(e) => handleEInvoice && handleEInvoice(e)}
                                />
                                <div className="label">
                                    {type !== 'advanceInvoice'
                                        ? t('pages.invoiceCard.dropdownItems.eInvoice').text
                                        : t('pages.invoiceCard.dropdownItems.eAdvance').text}
                                </div>
                            </Icon>
                        ) : null}
                        {!cardData?.cancelled ? (
                            <Tooltip text={t('pages.invoiceCard.dropdownItems.info').text}>
                                <Icon>
                                    <FontAwesomeIcon
                                        icon={faInfoCircle}
                                        style={{ color: colors.purple }}
                                        onClick={(e) => paymentInfo && paymentInfo(e)}
                                    />
                                </Icon>
                            </Tooltip>
                        ) : null}
                    </>
                ) : cardData?.status === 'cancelled' ? (
                    <>
                        <Tooltip text={t('pages.invoiceCard.dropdownItems.download').text}>
                            <Icon>
                                <FontAwesomeIcon
                                    icon={faDownload}
                                    style={{ color: colors.blue }}
                                    onClick={(e) => handleDownload && handleDownload(e)}
                                />
                            </Icon>
                        </Tooltip>

                        <Tooltip text={t('pages.invoiceCard.dropdownItems.copy').text}>
                            <Icon>
                                <FontAwesomeIcon
                                    icon={faClone}
                                    style={{ color: colors.purple }}
                                    onClick={(e) => handleCopy && handleCopy(e)}
                                />
                            </Icon>
                        </Tooltip>

                        {!eInvoice ? (
                            <Tooltip text={t('pages.invoiceCard.dropdownItems.delete').text}>
                                <Icon>
                                    <FontAwesomeIcon
                                        icon={faTrashCan}
                                        style={{ color: colors.danger }}
                                        onClick={(e) => handleDelete && handleDelete(e)}
                                    />
                                </Icon>
                            </Tooltip>
                        ) : null}

                        <Tooltip text={t('pages.invoiceCard.dropdownItems.send').text}>
                            <Icon>
                                <FontAwesomeIcon
                                    icon={faEnvelope}
                                    style={{ color: colors.blue }}
                                    onClick={(e) => handleSend && handleSend(e)}
                                />
                            </Icon>
                        </Tooltip>
                    </>
                ) : cardData?.status === 'open' && type === 'proforma' ? (
                    <>
                        <Tooltip text={t('pages.invoiceCard.dropdownItems.change').text}>
                            <Icon>
                                <FontAwesomeIcon
                                    icon={faPenToSquare}
                                    style={{ color: colors.blue }}
                                    onClick={(e) => handleChange && handleChange(e)}
                                />
                            </Icon>
                        </Tooltip>

                        <Tooltip text={t('pages.invoiceCard.dropdownItems.download').text}>
                            <Icon>
                                <FontAwesomeIcon
                                    icon={faDownload}
                                    style={{ color: colors.blue }}
                                    onClick={(e) => handleDownload && handleDownload(e)}
                                />
                            </Icon>
                        </Tooltip>

                        <Tooltip text={t('pages.invoiceCard.dropdownItems.copy').text}>
                            <Icon>
                                <FontAwesomeIcon
                                    icon={faClone}
                                    style={{ color: colors.purple }}
                                    onClick={(e) => handleCopy && handleCopy(e)}
                                />
                            </Icon>
                        </Tooltip>

                        <Tooltip text={t('pages.invoiceCard.dropdownItems.invoice').text}>
                            <Icon>
                                <FontAwesomeIcon
                                    icon={faPlus}
                                    style={{ color: colors.green }}
                                    onClick={(e) => handleInvoice && handleInvoice(e)}
                                />
                            </Icon>
                        </Tooltip>

                        <Tooltip text={t('pages.advanceInvoices.single').text}>
                            <Icon>
                                <FontAwesomeIcon
                                    icon={faA}
                                    style={{ color: colors.brown }}
                                    onClick={(e) => handleAdvance && handleAdvance(e)}
                                />
                            </Icon>
                        </Tooltip>

                        {!eInvoice ? (
                            <Tooltip text={t('pages.invoiceCard.dropdownItems.delete').text}>
                                <Icon>
                                    <FontAwesomeIcon
                                        icon={faTrashCan}
                                        style={{ color: colors.danger }}
                                        onClick={(e) => handleDelete && handleDelete(e)}
                                    />
                                </Icon>
                            </Tooltip>
                        ) : null}

                        <Tooltip text={t('pages.invoiceCard.dropdownItems.send').text}>
                            <Icon>
                                <FontAwesomeIcon
                                    icon={faEnvelope}
                                    style={{ color: colors.blue }}
                                    onClick={(e) => handleSend && handleSend(e)}
                                />
                            </Icon>
                        </Tooltip>
                    </>
                ) : cardData?.status === 'closed' ? (
                    <>
                        {eInvoice ? (
                            <Tooltip text={t('pages.invoiceCard.dropdownItems.refresh').text}>
                                <Icon>
                                    <FontAwesomeIcon
                                        icon={faRefresh}
                                        style={{ color: colors.purple }}
                                        onClick={(e) => handleRefresh && handleRefresh(e)}
                                    />
                                </Icon>
                            </Tooltip>
                        ) : null}

                        <Tooltip text={t('pages.invoiceCard.dropdownItems.download').text}>
                            <Icon>
                                <FontAwesomeIcon
                                    icon={faDownload}
                                    style={{ color: colors.blue }}
                                    onClick={(e) => handleDownload && handleDownload(e)}
                                />
                            </Icon>
                        </Tooltip>

                        <Tooltip text={t('pages.invoiceCard.dropdownItems.copy').text}>
                            <Icon>
                                <FontAwesomeIcon
                                    icon={faClone}
                                    style={{ color: colors.purple }}
                                    onClick={(e) => handleCopy && handleCopy(e)}
                                />
                            </Icon>
                        </Tooltip>

                        <Tooltip text={t('pages.invoiceCard.dropdownItems.send').text}>
                            <Icon>
                                <FontAwesomeIcon
                                    icon={faEnvelope}
                                    style={{ color: colors.blue }}
                                    onClick={(e) => handleSend && handleSend(e)}
                                />
                            </Icon>
                        </Tooltip>
                    </>
                ) : (
                    <></>
                )
            ) : (
                <>
                    <Tooltip text={t('pages.invoiceCard.dropdownItems.change').text}>
                        <Icon>
                            <FontAwesomeIcon
                                icon={faPenToSquare}
                                style={{ color: colors.blue }}
                                onClick={(e) => handleChange && handleChange(e)}
                            />
                        </Icon>
                    </Tooltip>

                    <Tooltip text={t('pages.invoiceCard.dropdownItems.delete').text}>
                        <Icon>
                            <FontAwesomeIcon
                                icon={faTrashCan}
                                style={{ color: colors.danger }}
                                onClick={(e) => handleDelete && handleDelete(e)}
                            />
                        </Icon>
                    </Tooltip>

                    <Tooltip text={t('pages.invoiceCard.dropdownItems.download').text}>
                        <Icon>
                            <FontAwesomeIcon
                                icon={faDownload}
                                style={{ color: colors.blue }}
                                onClick={(e) => handleDownload && handleDownload(e)}
                            />
                        </Icon>
                    </Tooltip>
                </>
            )}
        </ActionWithoutDropdown>
    );
};
const ActionWithoutDropdown = styled.div`
    display: flex;
    gap: 3px;
    justify-content: flex-end;
    > div {
        margin: 0 5px;
    }
`;
export const Icon = styled.div`
    position: relative;

    .label {
        opacity: 0;
        position: absolute;
        left: 50%;
        top: -20px;
        transform: translate(-50%, -50%);
        padding: 10px;
        border-radius: 4px;
        z-index: 9;
        font-size: 13px;
    }

    &:hover {
        .label {
            top: -40px;
            opacity: 1;
            transition: top 0.5s, opacity 0.5s;
            background: var(--gray);
            color: var(--white);
            z-index: 1;
        }
    }
`;
export default InvoiceActionsWithoutDropdown;
