import axios, { AxiosError, AxiosRequestConfig } from 'axios';
import { getAccessToken } from '../functions/auth';
import communication from '../communication';

const fetch: Function = async (options: AxiosRequestConfig, isPrivate = true) => {
    try {
        if (isPrivate) {
            let accessToken;
            if (getAccessToken()) {
                accessToken = `Bearer ${getAccessToken()}`;
            }

            options.headers = options.headers || {};
            options.headers.Authorization = accessToken;
            // options.withCredentials = true;
            return await axios(options);
        } else {
            options.headers = options.headers || {};
            return await axios(options);
        }
    } catch (error: AxiosError | any) {
        if (error.response?.status === 401) {
            if (!error?.request?.responseURL?.includes('login')) {
                try {
                    const refresh = communication.getRefreshToken({ refresh_token: getAccessToken() });
                    const { data } = refresh;
                    if (options.headers) options.headers.Authorization = data.access_token;

                    localStorage.setItem('access_token', data.access_token);
                    localStorage.setItem('refresh_token', data.refresh_token);

                    window.location.href = '/invoices';
                } catch (err) {
                    localStorage.setItem('access_token', '');
                    localStorage.setItem('refresh_token', '');
                    window.location.href = '/login';
                }
            }
        }
        throw error as AxiosError;
    }
};

export default fetch;
