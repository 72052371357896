import communication from '../../../communication';
import { ObjectKeys } from '../../../types/objectKeys';
import useInvoicesStore from '../../../store/invoices';
import useInvoiceParamsStore from '../../../store/invoiceParams';

const getAllInvoices = (): Promise<ObjectKeys> => {
    const params = useInvoiceParamsStore.getState().invoices_params;
    return new Promise((resolve, reject) => {
        communication
            .getInvoices(params)
            .then((res: ObjectKeys) => {
                if (res.status === 200) {
                    useInvoicesStore.setState({ invoiceData: res.data });
                    resolve(res);
                } else {
                    reject(new Error(`Failed to fetch invoices. Status: ${res.status}`));
                }
            })
            .catch((error: any) => {
                reject(error);
            });
    });
};
export default getAllInvoices;
