import React from 'react';
import styled, { keyframes } from 'styled-components';
import { MQ_BREAKPOINTS } from '../../../../../constants/breakpoints';

const SkeletonChart: React.FC = () => {
    return (
        <ChartWrapper>
            <SkeletonBar />
            <SkeletonBar />
            <SkeletonBar />
            <SkeletonBar />
            <SkeletonBar />
        </ChartWrapper>
    );
};

const loadingAnimation = keyframes`
    0% {
        background-position: -200px 0;
    }
    100% {
        background-position: calc(200px + 100%) 0;
    }
`;

const ChartWrapper = styled.div`
    width: 100%;
    max-height: 300px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    margin-top: 20px;
    @media only screen and (max-width: ${MQ_BREAKPOINTS.tablet}) {
        canvas {
            max-width: 100%;
            min-height: 300px;
        }
    }
`;

const SkeletonBar = styled.div`
    height: 30px;
    width: 90%;
    background: linear-gradient(90deg, #e6dfff 25%, #cebcff 50%, #b6a0ff 75%);
    background-size: 200% 100%;
    animation: ${loadingAnimation} 1.5s infinite;
    border-radius: 4px;
    margin-bottom: 25px;

    @media only screen and (max-width: ${MQ_BREAKPOINTS.tablet}) {
        width: 100%;
    }
`;

export default SkeletonChart;
