import { Dispatch, FunctionComponent, SetStateAction, useEffect, useState } from 'react';
import styled from 'styled-components';
import NoDataPhoto from '../../../../assets/no-transfer-order.png';
import MagicPhoto from '../../../../assets/magic.png';
import Button, { ButtonVariant } from '../../../../components/button';
import ManageOrdersModal from '../manageOrdersModal';
import { SelectOption } from '../../../../components/select';
import { useNavigate } from 'react-router-dom';

interface Props {
    t: Function;
    currentLangName: string;
    close: Function;
    yearsOptions: SelectOption[];
    setIsLoader: Function;
    setIsInfoModalActive: Dispatch<SetStateAction<boolean>>;
    handleRefresh: Function;
    transferModalData: boolean;
    year: string;
    currentMonth: string;
}

const NoTransferOrderModal: FunctionComponent<Props> = ({
    t,
    close,
    yearsOptions,
    setIsLoader,
    setIsInfoModalActive,
    handleRefresh,
    transferModalData,
    year,
    currentMonth,
}) => {
    const [info, setInfo] = useState(false);
    const [transferModal, setTransferModal] = useState(transferModalData);
    const [callNo] = useState(0);

    const navigate = useNavigate();

    const openInNewTab = (link: string): void => {
        const baseUrl = window.location.origin;
        const url = new URL(link, baseUrl);
        window.open(url.href, '_blank');
    };

    return (
        <Wrapper>
            {info ? (
                <>
                    <div className="content">
                        <img src={MagicPhoto} alt="photo" />
                        {/* <p dangerouslySetInnerHTML={{ __html: tax_administration_notice }} /> */}
                        <div>
                            <span>{t('pages.transfers.noDataModal.info1').text}</span>
                            <span
                                className="link"
                                onClick={() =>
                                    openInNewTab('https://blog.pausal.rs/kako-da-preuzmem-svoje-poresko-resenje/')
                                }
                            >
                                {t('pages.transfers.noDataModal.here').text}
                            </span>
                            <span>{t('pages.transfers.noDataModal.info2').text}</span>
                            <span className="link" onClick={() => navigate('/agency/authorization-transfer')}>
                                {t('pages.transfers.noDataModal.link').text}
                            </span>
                            <span>{t('pages.transfers.noDataModal.info3').text}</span>
                        </div>
                    </div>
                    <Footer>
                        <Button
                            textTransformNone
                            variant={ButtonVariant.solid}
                            color={'var(--purple)'}
                            onClick={() => {
                                setInfo(false);
                            }}
                            size={'50%'}
                            className="back"
                        >
                            {t('buttons.back').text}
                        </Button>

                        <Button
                            textTransformNone
                            variant={ButtonVariant.solid}
                            color={'var(--purple)'}
                            onClick={() => {
                                setInfo(false);
                                close();
                            }}
                            size={'50%'}
                        >
                            {t('buttons.ok').text}
                        </Button>
                    </Footer>
                </>
            ) : transferModal ? (
                <ManageOrdersModal
                    closeModal={() => {
                        close();
                        setTransferModal(false);
                    }}
                    yearsOptions={yearsOptions}
                    setIsLoader={setIsLoader}
                    setOpenInfoModal={setIsInfoModalActive}
                    callNumber={callNo}
                    refresh={() => handleRefresh()}
                    year={year}
                    currentMonth={currentMonth}
                />
            ) : (
                <>
                    <div className="content">
                        <img src={NoDataPhoto} alt="photo" />
                        <h3>{t('pages.transfers.noDataModal.title').text}</h3>
                        <p>{t('pages.transfers.noDataModal.data').text}</p>
                    </div>
                    <Footer>
                        <Button
                            textTransformNone
                            variant={ButtonVariant.solid}
                            color={'var(--purple)'}
                            onClick={() => {
                                setInfo(true);
                            }}
                            size={'50%'}
                        >
                            {t('input.no').text}
                        </Button>

                        <Button
                            textTransformNone
                            variant={ButtonVariant.solid}
                            color={'var(--purple)'}
                            onClick={() => {
                                setTransferModal(true);
                            }}
                            size={'50%'}
                        >
                            {t('input.yes').text}
                        </Button>
                    </Footer>
                </>
            )}
        </Wrapper>
    );
};

export default NoTransferOrderModal;

const Wrapper = styled.div`
    text-align: center;
    width: 100%;

    .content {
        padding: 35px 50px 30px;
        text-align: center;
        min-height: 130px;

        img {
            height: 200px;
            width: auto;
            margin-bottom: 30px;
        }

        h3 {
            color: var(--black);
            font-weight: 400;
            font-size: 20px;
        }

        p,
        span {
            margin-top: 25px;
            line-height: 30px;
            font-size: 16px;
            white-space: pre-wrap;
            color: var(--gray);
        }
        .link {
            color: var(--purple);
            cursor: pointer;
        }
    }
`;

const Footer = styled.div`
    border-top: 1px solid var(--border-color);
    overflow: hidden;

    display: flex;
    flex-direction: row;
    button {
        border-radius: 0px !important;
        :nth-child(2) {
            opacity: 0.7;
        }
        &.back {
            background-color: var(--white);
            color: var(--purple);
            border: none;
        }
    }
`;
