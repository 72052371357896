import React from 'react';

const FaAddressCardSharpLight: React.FC<React.SVGProps<SVGSVGElement>> = (props) => (
    <svg width="708" height="550" viewBox="0 0 708 550" xmlns="http://www.w3.org/2000/svg" {...props}>
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M0.756792 2.16003C0.084088 3.72939 -0.140147 127.708 0.084088 277.47L0.756792 549.865H707.096V0.590677C73.4088 -0.53029 1.65373 -0.0819032 0.756792 2.16003ZM39.9979 510.631H667.855V39.8245H39.9979V510.631Z"
            fill="inherit"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M203.017 127.036C209.071 124.57 217.368 121.655 221.628 120.534C225.888 119.413 234.409 118.965 240.688 119.413C246.742 119.861 257.954 122.776 265.354 125.466C274.547 129.053 282.396 134.21 290.468 142.281C296.971 148.782 304.595 158.871 307.286 164.7C311.322 173.444 312.219 179.497 312.219 196.76C312.219 214.023 311.322 220.076 307.286 228.595C304.595 234.649 296.971 244.513 290.692 251.015C284.189 257.292 273.202 265.139 266.475 268.278C256.384 272.986 250.778 274.107 236.203 274.107C221.628 274.107 216.022 272.986 205.932 268.278C199.205 265.139 188.217 257.292 181.938 251.015C175.436 244.513 167.812 234.649 165.121 228.82C160.86 219.852 159.963 214.247 159.963 196.76C160.188 179.721 161.085 173.444 165.121 164.924C167.812 158.871 174.987 149.231 181.266 142.953C187.544 136.676 197.186 129.726 203.017 127.036ZM218.265 162.682C213.331 164.924 207.053 170.081 204.362 174.565C201.671 178.824 198.756 186.671 198.083 192.276C196.962 199.226 197.635 205.279 200.102 212.005C202.568 218.283 206.829 223.663 213.107 228.147C221.404 233.976 224.767 235.097 236.203 234.873C246.742 234.873 251.451 233.752 258.178 229.268C262.663 226.129 268.269 220.3 270.287 216.489C272.305 212.453 274.099 204.158 274.099 197.881C274.323 190.707 272.754 183.533 269.614 177.703C266.475 171.65 261.99 167.166 255.039 163.579C249.657 160.889 241.136 158.647 236.203 158.647C231.27 158.647 223.198 160.441 218.265 162.682Z"
            fill="inherit"
        />
        <path
            d="M412.227 177.928C411.331 163.803 412.003 159.768 414.47 158.423C416.264 157.302 459.765 156.405 510.891 156.405C562.016 156.405 605.294 157.302 606.863 158.199C608.881 159.32 609.554 165.149 608.433 195.639H413.349L412.227 177.928Z"
            fill="inherit"
        />
        <path
            d="M414.47 236.891C412.003 238.236 411.331 242.271 412.227 256.395L413.349 274.107H608.433C609.554 243.616 608.881 237.787 606.863 236.666C605.294 235.77 562.016 234.873 510.891 234.873C459.765 234.873 416.264 235.77 414.47 236.891Z"
            fill="inherit"
        />
        <path
            d="M119.153 371.182C131.037 335.311 138.661 315.358 140.904 314.686C142.697 314.237 185.078 313.789 235.082 313.565C285.086 313.565 327.915 314.013 330.382 314.686C333.97 315.582 338.454 326.344 353.254 371.182C363.569 401.673 371.865 427.903 371.865 429.249C371.865 431.491 366.259 432.163 331.503 432.163L305.716 353.695H166.69L140.904 432.163H120.722C106.147 432.163 100.541 431.491 100.541 429.249C100.541 427.903 108.838 401.673 119.153 371.182Z"
            fill="inherit"
        />
        <path
            d="M414.47 315.358C412.003 316.703 411.331 320.739 412.227 334.863L413.349 352.574H608.433C609.554 322.084 608.881 316.255 606.863 315.134C605.294 314.237 562.016 313.341 510.891 313.341C459.765 313.341 416.264 314.237 414.47 315.358Z"
            fill="inherit"
        />
    </svg>
);

export default FaAddressCardSharpLight;
