import fetch from '../fetch';
import routes from '../routes';
import { ObjectKeys } from '../../types/objectKeys';
import getCurrentLanguage from '../../functions/getCurrentLanguage';
import download from '../download';

export default class AdminTaxSolutions {
    public getAllSolutions(page = '1'): ObjectKeys {
        return fetch({
            method: 'GET',
            headers: { 'Content-Type': 'application/json; charset=UTF-8', 'Accept-Language': getCurrentLanguage() },
            url: routes.admin.tax.getAllSolutions(page),
        });
    }
    public getSolutionsByUser(id: string, page = '1'): ObjectKeys {
        return fetch({
            method: 'GET',
            headers: { 'Content-Type': 'application/json; charset=UTF-8', 'Accept-Language': getCurrentLanguage() },
            url: routes.admin.tax.getSolutionsByUser(id, page),
        });
    }

    public downloadSolution(id: string, token: string): ObjectKeys {
        return download(routes.admin.tax.downloadSolution(id), { token }, id, 'pdf');
    }
    public uploadFile(id: string, files: Blob[], year: string): ObjectKeys {
        const form = new FormData();
        form.append('year', year);
        for (let i = 0; i < files.length; i++) {
            const file = files[i];
            form.append('solutions[' + i + ']', file);
        }
        return fetch({
            method: 'POST',
            headers: { 'Accept-Language': getCurrentLanguage() },
            url: routes.admin.tax.uploadSolution(id),
            data: form,
        });
    }
}
