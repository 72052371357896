import React from 'react';
import styled, { keyframes } from 'styled-components';
import { MQ_BREAKPOINTS } from '../../../../../constants/breakpoints';

const SkeletonDoughnut: React.FC = () => {
    return (
        <ChartWrapper>
            <SkeletonCircle />
        </ChartWrapper>
    );
};

const loadingAnimation = keyframes`
    0% {
        background-position: -200px 0;
    }
    100% {
        background-position: calc(200px + 100%) 0;
    }
`;

const ChartWrapper = styled.div`
    width: 100%;
    max-height: 500px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 10px;
    @media screen and (min-width: ${MQ_BREAKPOINTS.desktop}) {
        margin-top: 0;
    }
`;

const SkeletonCircle = styled.div`
    width: 200px;
    height: 200px;
    border-radius: 50%;
    background: linear-gradient(90deg, #e6dfff 25%, #cebcff 50%, #b6a0ff 75%);
    background-size: 200% 100%;
    animation: ${loadingAnimation} 1.5s infinite;

    @media screen and (min-width: ${MQ_BREAKPOINTS.mobileS}) {
        height: 240px;
        width: 240px;
    }
    @media screen and (min-width: ${MQ_BREAKPOINTS.mobileM}) {
        height: 290px;
        width: 290px;
    }
    @media screen and (min-width: ${MQ_BREAKPOINTS.mobileL}) {
        height: 330px;
        width: 330px;
    }
    @media screen and (min-width: ${MQ_BREAKPOINTS.tablet}) {
        height: 340px;
        width: 340px;
    }
    @media screen and (min-width: ${MQ_BREAKPOINTS.laptop}) {
        height: 250px;
        width: 250px;
    }
    @media screen and (min-width: ${MQ_BREAKPOINTS.laptopM}) {
        height: 260px;
        width: 260px;
    }
    @media screen and (min-width: ${MQ_BREAKPOINTS.laptopL}) {
        height: 280px;
        width: 280px;
    }
`;

export default SkeletonDoughnut;
