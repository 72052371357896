import { ObjectKeys } from '../types/objectKeys';
import fetch from './fetch';
import routes from './routes';
// import download from '../communication/download';
import getCurrentLanguage from '../functions/getCurrentLanguage';

export default class Memorandums {
    public getAllMemorandums(page: string, limit: any, status: any, find: string): ObjectKeys {
        return fetch({
            method: 'GET',
            headers: { 'Content-Type': 'application/json; charset=UTF-8', 'Accept-Language': getCurrentLanguage() },
            url: routes.memorandums.main(page, limit, status, find),
        });
    }

    public createMemorandum(data: Object): ObjectKeys {
        return fetch({
            method: 'POST',
            headers: { 'Content-Type': 'application/json; charset=UTF-8', 'Accept-Language': getCurrentLanguage() },
            url: routes.memorandums.create,
            data: data,
        });
    }

    public deleteMemorandum(id: string | undefined): ObjectKeys {
        return fetch({
            method: 'DELETE',
            headers: { 'Content-Type': 'application/json; charset=UTF-8', 'Accept-Language': getCurrentLanguage() },
            url: routes.memorandums.specific(id),
        });
    }

    public sendMemorandum(id: string, emails: string[], body: string, title: string): ObjectKeys {
        return fetch({
            method: 'POST',
            headers: { 'Content-Type': 'application/json; charset=UTF-8', 'Accept-Language': getCurrentLanguage() },
            url: routes.memorandums.send(id),
            data: {
                emails,
                body: body,
                title: title,
            },
        });
    }

    public updateMemorandum(data: ObjectKeys): ObjectKeys {
        return fetch({
            method: 'PUT',
            headers: { 'Content-Type': 'application/json; charset=UTF-8', 'Accept-Language': getCurrentLanguage() },
            url: routes.memorandums.specific(data.id),
            data: data,
        });
    }

    public getSpecificMemorandum(id: string | undefined): ObjectKeys {
        return fetch({
            method: 'GET',
            headers: { 'Content-Type': 'application/json; charset=UTF-8', 'Accept-Language': getCurrentLanguage() },
            url: routes.memorandums.specific(id),
        });
    }

    public downloadMemorandum(id: string | undefined, token: string | null): ObjectKeys {
        return fetch({
            method: 'POST',
            responseType: 'blob',
            headers: {
                'Content-Type': 'application/json, text/plain, application/pdf, */*; charset=UTF-8',
                Authorization: 'Bearer ' + token,
                'Accept-Language': getCurrentLanguage(),
            },
            url: routes.memorandums.download(id),
            data: { token: token },
        });
    }
    public downloadMemosWord(id: string | undefined, token: string | null): Promise<void> {
        return fetch({
            method: 'POST',
            headers: {
                'Content-Type': 'application/json; charset=UTF-8',
                'Accept-Language': getCurrentLanguage(),
            },
            url: routes.memorandums.downloadWord(id),
            data: { token: token }, // You can pass token like this for Axios
            responseType: 'blob', // This ensures Axios treats the response as a Blob
        })
            .then((response: any) => {
                if (!response || !response.data) {
                    throw new Error('Error downloading the Word document: no response data');
                }
                // Create a download link for the document
                const url = window.URL.createObjectURL(response.data); // Axios stores the blob in response.data
                const link = document.createElement('a');
                link.href = url;

                const contentDisposition = response?.headers['content-disposition'];
                const fileNameMatch = contentDisposition?.match(/filename\*?="?([^;"]+)/i);
                const fileName = fileNameMatch ? fileNameMatch[1] : 'memorandum.doc';

                link.setAttribute('download', fileName); // Set the file name
                document.body.appendChild(link);
                link.click();
                link.remove(); // Clean up
                window.URL.revokeObjectURL(url); // Revoke the blob URL
            })
            .catch((error: any) => {
                console.error('Download error:', error);
                throw error;
            });
    }
}
