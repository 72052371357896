import React from 'react';

const FaFileLinesSharpSolid: React.FC<React.SVGProps<SVGSVGElement>> = (props) => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512" fill="none" {...props}>
        <path
            d="M64 0L0 3.8147e-06L7.62939e-06 64V448L7.62939e-06 512H64H320H384V448V160H256H224V128V0H64ZM256 0V128H384L256 0ZM96 256H288C288 256 288 263.2 288 272C288 280.8 288 288 288 288H96C96 288 96 280.8 96 272C96 263.2 96 256 96 256ZM96 320H288C288 320 288 327.2 288 336C288 344.8 288 352 288 352H96C96 352 96 344.8 96 336C96 327.2 96 320 96 320ZM96 384H288C288 384 288 391.2 288 400C288 408.8 288 416 288 416H96C96 416 96 408.8 96 400C96 391.2 96 384 96 384Z"
            fill="inherit"
        />
    </svg>
);

export default FaFileLinesSharpSolid;
