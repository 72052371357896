import { FunctionComponent, useEffect, useRef, useState } from 'react';
import Select, { StylesConfig } from 'react-select';

import { Dropdown } from './Dropdown';
import styled from 'styled-components';
import useOutsideClick from '../../hooks/useOutsideClick';
import useTranslations from '../../hooks/useTranslation';
import useLanguageStore from '../../store/language';

type SelectOption = {
    value?: string;
    label?: string | undefined;
};

interface Props {
    optionList: SelectOption[];
    defaultValue?: SelectOption;
    placeholder?: string;
    searchPlaceholder?: string;
    color?: string;
    className?: string;
    handleSelectedValue?: Function;
    validation?: string;
    infoMessage?: string;
    deleteOption?: boolean;
    deleteItemHandler?: Function;
    disabled?: boolean;
}

const DropdownIndicator: FunctionComponent = () => {
    return (
        <Container>
            <svg
                style={{ marginRight: 6 }}
                width={18}
                height={18}
                viewBox="0 0 24 24"
                focusable="false"
                role="presentation"
            >
                <path
                    d="M16.436 15.085l3.94 4.01a1 1 0 0 1-1.425 1.402l-3.938-4.006a7.5 7.5 0 1 1 1.423-1.406zM10.5 16a5.5 5.5 0 1 0 0-11 5.5 5.5 0 0 0 0 11z"
                    fill="currentColor"
                    fillRule="evenodd"
                />
            </svg>
        </Container>
    );
};
const SelectSearch: FunctionComponent<Props> = ({
    optionList,
    defaultValue,
    placeholder,
    searchPlaceholder,
    color,
    handleSelectedValue,
    className,
    validation,
    infoMessage,
    deleteOption,
    deleteItemHandler,
    disabled = false,
}) => {
    const ref = useRef<HTMLDivElement>(null);
    const { currentLang } = useLanguageStore();
    const t = useTranslations(currentLang);
    const [isOpen, setIsOpen] = useState(false);
    const [value, setValue] = useState<SelectOption>();

    const handleClose = (): void => {
        setIsOpen(false);
    };

    const { handleClickOutside } = useOutsideClick(ref, handleClose);

    useEffect(() => {
        if (defaultValue) setValue(defaultValue);
    }, [defaultValue]);

    const colourStyles: StylesConfig<any> = {
        control: (styles, { menuIsOpen }) => ({
            ...styles,
            borderRadius: '5px',
            cursor: 'pointer',
            boxShadow: '0px',
            height: '42px',
            fontSize: '13px',
            border: '1px solid var(--border-color)',
            ':active': {
                borderColor: menuIsOpen ? 'var(--light-blue)' : '',
            },
            ':focus-within': {
                border: '1px solid var(--light-blue)',
            },
            ':hover': {
                border: '1px solid var(--light-blue)',
            },
        }),
        option: (styles, { isDisabled, isSelected }) => {
            return {
                ...styles,
                backgroundColor: isSelected ? 'var(--purple)' : '',
                color: isSelected ? 'var(--white)' : 'var(--black)',
                cursor: isDisabled ? 'not-allowed' : 'pointer',
                borderColor: 'var(--border-color)',
                borderWidth: '2px',
                borderRadius: '5px',
                ':active': {
                    ...styles[':active'],
                    backgroundColor: isSelected ? 'var(--purple)' : '',
                    borderColor: 'var(--border-color)',
                    borderWidth: '2px',
                    borderRadius: '5px',
                },
                ':hover': {
                    backgroundColor: 'var(--purple)',
                    color: 'var(--white)',
                    borderRadius: '0px',
                },
            };
        },
        input: (styles) => ({
            ...styles,
            borderColor: 'var(--border-color)',
            borderWidth: '2px',
            borderRadius: '5px',
            height: '34px',
            fontSize: '14px',
            color: '#555',
            lineHeight: '1.428571429',
            verticalAlign: 'middle',
            transition: 'border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s',
        }),
        placeholder: (styles) => ({
            ...styles,
            color: color,
        }),
        singleValue: (styles) => ({ ...styles }),
        menuList: (styles) => ({
            ...styles,
            height: 'auto',
            maxHeight: '240px',
            boxShadow: '0 6px 12px rgba(0,0,0,.175)',
            backgroundClip: 'padding-box',
            margin: '2px 0 0',
        }),
    };

    return optionList?.length > 0 ? (
        <StyledSelect
            ref={ref}
            onClick={handleClickOutside}
            className={`${validation !== undefined && validation !== '' ? 'validation-error' : ''}`}
        >
            <SelectContainer className={`select-search ${className ?? ''}`}>
                <Dropdown
                    isOpen={isOpen}
                    onClose={() => setIsOpen(false)}
                    target={
                        <DropdownContainer
                            className={`${isOpen ? 'opened input-dropdown' : 'input-dropdown'} ${
                                disabled ? 'disabled' : ''
                            }`}
                            onClick={() => {
                                if (!disabled) {
                                    setIsOpen((prev) => !prev);
                                }
                            }}
                        >
                            {/* <ChevronDown {...{ isOpen }} /> */}
                            <Chevron
                                className={isOpen ? 'rotate' : ''}
                                width={24}
                                height={24}
                                viewBox="0 0 24 24"
                                focusable="false"
                                role="presentation"
                            >
                                <path
                                    d="M8.292 10.293a1.009 1.009 0 0 0 0 1.419l2.939 2.965c.218.215.5.322.779.322s.556-.107.769-.322l2.93-2.955a1.01 1.01 0 0 0 0-1.419.987.987 0 0 0-1.406 0l-2.298 2.317-2.307-2.327a.99.99 0 0 0-1.406 0z"
                                    fill="currentColor"
                                    fillRule="evenodd"
                                />
                            </Chevron>
                            <div className={value ? 'selected-value' : 'placeholder'}>
                                {value ? `${value?.label}` : placeholder}
                            </div>
                        </DropdownContainer>
                    }
                >
                    {deleteItemHandler ? (
                        <Select
                            classNamePrefix="select-with-search"
                            autoFocus
                            backspaceRemovesValue={false}
                            components={{ DropdownIndicator, IndicatorSeparator: null }}
                            controlShouldRenderValue={false}
                            hideSelectedOptions={false}
                            isClearable={false}
                            menuIsOpen
                            options={optionList}
                            placeholder={searchPlaceholder ? searchPlaceholder : ''}
                            styles={colourStyles}
                            tabSelectsValue={false}
                            defaultValue={value ? value : ''}
                            noOptionsMessage={() => {
                                return t('input.no_options').text;
                            }}
                            isDisabled={disabled}
                            formatOptionLabel={(e) => (
                                <>
                                    <div
                                        onClick={() => {
                                            setValue({ label: e.label, value: e.value });
                                            setIsOpen(false);
                                            handleSelectedValue &&
                                                handleSelectedValue({ label: e.label, value: e.value });
                                        }}
                                        className="wrapLabel"
                                    >
                                        {e.label}
                                    </div>
                                    <div
                                        onClick={() => {
                                            deleteItemHandler && deleteItemHandler(e.value);
                                        }}
                                    >
                                        {deleteOption && e.icon}
                                    </div>
                                </>
                            )}
                        />
                    ) : (
                        <Select
                            classNamePrefix="select-with-search"
                            autoFocus
                            backspaceRemovesValue={false}
                            components={{ DropdownIndicator, IndicatorSeparator: null }}
                            controlShouldRenderValue={false}
                            hideSelectedOptions={false}
                            isClearable={false}
                            menuIsOpen
                            onChange={(data: SelectOption) => {
                                setValue(data);
                                setIsOpen(false);
                                handleSelectedValue && handleSelectedValue(data);
                            }}
                            options={optionList}
                            placeholder={searchPlaceholder ? searchPlaceholder : ''}
                            styles={colourStyles}
                            tabSelectsValue={false}
                            defaultValue={value ? value : ''}
                            noOptionsMessage={() => {
                                return t('input.no_options').text;
                            }}
                            isDisabled={disabled}
                        />
                    )}
                </Dropdown>
            </SelectContainer>
            {validation !== '' ? (
                <p className="validation-message">{validation}</p>
            ) : (
                (infoMessage && <p className="info-message">{infoMessage}</p>) || <></>
            )}
        </StyledSelect>
    ) : (
        <></>
    );
};
const StyledSelect = styled.div`
    width: 100%;
    .validation-message {
        color: var(--danger);
        font-size: 12px;
        margin-top: 2px;
    }
    .info-message {
        font-size: 12px;
        color: var(--gray);
    }
`;

const SelectContainer = styled.div`
    width: 100%;
    font-size: 14px;
    .wrapLabel {
        white-space: normal;
        word-wrap: break-word;
        overflow: hidden;
        margin-right: 15px;
    }
`;

const DropdownContainer = styled.div`
    &.opened {
        border: 1px solid var(--light-blue) !important;
    }
    &.disabled {
        opacity: 0.6;
    }
    position: relative;
    width: 100%;
    height: fit-content;
    padding: 6px 12px;
    font-size: 14px;
    line-height: 1.428571429;
    background-color: var(--white);
    border: 1px solid var(--border-color);
    color: var(--black);
    box-shadow: 0 0 3px #fff inset, 0 1px 1px rgba(0, 0, 0, 0.1);
`;

const Container = styled.div`
    color: 'var(--light-gray)';
    height: 24;
    width: 32;
    position: relative;
`;

const Chevron = styled.svg`
    position: absolute;
    right: 0;
    &.rotate {
        transform: rotate(-180deg);
        transition: 250ms;
    }
`;
export default SelectSearch;
