import fetch from './fetch';
import routes from './routes';
import { ObjectKeys } from '../types/objectKeys';
import getCurrentLanguage from '../functions/getCurrentLanguage';

export default class TaxCalendar {
    public getTaxCalendar(params: ObjectKeys): ObjectKeys {
        return fetch({
            method: 'get',
            headers: { 'Content-Type': 'application/json; charset=UTF-8', 'Accept-Language': getCurrentLanguage() },
            url: routes.taxCalendar.get(params.year, params.status),
        });
    }
    public getMonthlyTaxCalendar(params: ObjectKeys): ObjectKeys {
        return fetch({
            method: 'get',
            headers: { 'Content-Type': 'application/json; charset=UTF-8', 'Accept-Language': getCurrentLanguage() },
            url: routes.taxCalendar.get(params.year, params.status, params.month),
        });
    }
    public sendTaxCalendar(params: ObjectKeys): ObjectKeys {
        return fetch({
            method: 'post',
            headers: { 'Content-Type': 'application/json; charset=UTF-8', 'Accept-Language': getCurrentLanguage() },
            url: routes.taxCalendar.create,
            data: params,
        });
    }
    public updateTaxCalendar(params: ObjectKeys, id: string): ObjectKeys {
        return fetch({
            method: 'put',
            headers: { 'Content-Type': 'application/json; charset=UTF-8', 'Accept-Language': getCurrentLanguage() },
            url: routes.taxCalendar.update(id),
            data: params,
        });
    }
    public getCalendarSum(year: number): ObjectKeys {
        return fetch({
            method: 'get',
            headers: { 'Content-Type': 'application/json; charset=UTF-8', 'Accept-Language': getCurrentLanguage() },
            url: routes.taxCalendar.getSums(year),
        });
    }
}
