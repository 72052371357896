import { FunctionComponent, useEffect, useState } from 'react';
import styled from 'styled-components';
import StepOne from './1step';
import StepTwo from './2step';
import StepThree from './3step';
import { ObjectKeys } from '../../types/objectKeys';
import useTranslations from '../../hooks/useTranslation';
import useLanguageStore from '../../store/language';
import communication from '../../communication';
import { useNavigate, useParams } from 'react-router-dom';
import { MQ_BREAKPOINTS } from '../../constants/breakpoints';
import RusImg from '../../assets/rus.png';
import SadImg from '../../assets/sad.png';
import SrbImg from '../../assets/srb.png';
import SelectLang from '../../components/selectLangLogin';

const Services: FunctionComponent = () => {
    const [step, setStep] = useState(1);
    const [cart, setCart] = useState<ObjectKeys>({});
    const { currentLang } = useLanguageStore();
    const t = useTranslations(currentLang);
    const navigate = useNavigate();
    const paramStep = useParams().step;
    const params = useParams();
    const paramsCartId = useParams().cartId;

    useEffect(() => {
        setStep(paramStep ? parseInt(paramStep) : 1);
    }, [paramStep]);

    useEffect(() => {
        if (paramsCartId) {
            getCart(paramsCartId);
        }
    }, [paramsCartId, params]);

    const getCart = (id: string): void => {
        communication
            .getCartById(id)
            .then((res: ObjectKeys) => {
                if (res.data.data.length < 1) {
                    setStep(1);
                    navigate('/guest/1');
                } else {
                    const cartData = res.data.data;
                    const items = cartData.items.data.map((item: any) => ({
                        created_at: item.service.data.created_at,
                        description_en: item.service.data.description_en,
                        description_rs: item.service.data.description_rs,
                        description_ru: item.service.data.description_ru,
                        id: item.service.data.id,
                        name_en: item.service.data.name_en,
                        name_rs: item.service.data.name_rs,
                        name_ru: item.service.data.name_ru,
                        price: item.price,
                        quantity: item.quantity,
                        type: 'service',
                        updated_at: item.service.data.updated_at,
                    }));
                    setCart({
                        status: cartData.status,
                        total_price: cartData.total_price,
                        type: 'service',
                        email: cartData.detail.data.email,
                        pib: cartData.detail.data.pib,
                        first_name: cartData.detail.data.first_name,
                        last_name: cartData.detail.data.last_name,
                        language: cartData.detail.data.language,
                        note: cartData.note,
                        items: items,
                    });
                }
            })
            .catch((err: ObjectKeys) => {
                console.error('err', err);
            });
    };

    const lang = localStorage.language;
    const flagUrl = lang === 'English' ? SadImg : lang === 'Русский' ? RusImg : SrbImg;

    return (
        <Container>
            <Header>
                <div>
                    <h1>{t('navbar.pausal.services').text}</h1>
                </div>
                <div className="lang-selector">
                    <img src={flagUrl} alt="photo" width={'16px'} height={'11px'} key={localStorage.language} />
                    <SelectLang color={'black'} />
                </div>
            </Header>
            <Content>
                {step === 1 && (
                    <StepOne
                        nextStep={() => setStep(2)}
                        finallCart={(data: ObjectKeys) => setCart(data)}
                        mainCart={cart}
                    />
                )}
                {step === 2 && (
                    <StepTwo
                        cart={cart}
                        nextStep={() => setStep(3)}
                        updatedCart={(data: ObjectKeys) => setCart(data)}
                        previousStep={() => setStep(1)}
                    />
                )}
                {step === 3 && <StepThree cart={cart} previousStep={() => setStep(2)} />}
            </Content>
        </Container>
    );
};
const Container = styled.div`
    padding: 40px;
    background: var(--light-gray);
    max-width: 1400px;
    margin: auto;
    @media screen and (max-width: ${MQ_BREAKPOINTS.tablet}) {
        padding: 10px;
    }
`;
const Content = styled.div`
    text-align: center;
    margin-top: 20px;
    padding: 20px;
    p {
        font-weight: 500;
    }
`;
const Header = styled.div`
    display: flex;
    align-items: center;
    border-bottom: 1px solid var(--border-color);
    justify-content: space-between;
    padding-bottom: 20px;
    > div {
        display: flex;
        align-item: center;
        button {
            margin-left: 35px;
            height: 35px;
            font-size: 12px;
            line-height: initial;
        }
    }
    @media screen and (max-width: ${MQ_BREAKPOINTS.tablet}) {
        flex-direction: column;
        > div {
            width: 100%;
            justify-content: space-between;
            button {
                margin-left: 0;
            }
        }
    }
    .lang-selector {
        position: absolute;
        right: 100px;
        z-index: 100;
        display: flex;
        flex-direction: column-reverse;
        flex-wrap: wrap;
        align-content: stretch;
        justify-content: center;
        height: 20px;
    }

    @media only screen and (max-width: ${MQ_BREAKPOINTS.mobileL}) {
        .lang-selector {
            padding-top: 10px;
            position: inherit;
            width: 50px;
            margin-left: 110px;
        }
    }
`;
export default Services;
