export default function usePaymentValueValidation(value: string, invoiceValue: string, t: Function): string {
    // Remove thousand separators and replace decimal commas with dots
    const parsedValue = parseFloat(value.replace(/\./g, '').replace(',', '.'));

    if (isNaN(parsedValue)) return t('pages.advanceInvoices.closeModal.validation.onlyNumbers').text;
    if (parsedValue === 0) return t('pages.advanceInvoices.closeModal.validation.higherThan0').text;
    if (value === '') return t('pages.advanceInvoices.closeModal.validation.mandatory').text;
    if (value.slice(-1) === ',') return t('pages.advanceInvoices.closeModal.validation.onlyNumbers').text;

    const parsedInvoiceValue = parseFloat(invoiceValue.replace(/\./g, '').replace(',', '.'));
    if (isNaN(parsedInvoiceValue)) return t('pages.advanceInvoices.closeModal.validation.onlyNumbers').text;
    if (parsedInvoiceValue - parsedValue < 0) return '';

    return '';
}
