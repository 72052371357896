import React from 'react';
import { ObjectKeys } from '../../../../types/objectKeys';
import styled from 'styled-components';
import useTranslations from '../../../../hooks/useTranslation';
import useLanguageStore from '../../../../store/language';

interface Props {
    clients: ObjectKeys;
}

const ClientCard: React.FC<Props> = ({ clients }) => {
    const { currentLang } = useLanguageStore();
    const t = useTranslations(currentLang);
    const type = clients[0].type === 'civil' ? 'civil' : 'legal';
    return (
        <Content>
            {type === 'civil' ? (
                <h3 className="message">{t('pages.clientWarningCard.title_civil').text}</h3>
            ) : (
                <h3 className="message">{t('pages.clientWarningCard.title_legal').text}</h3>
            )}
            {type === 'civil' ? (
                <h3>{t('pages.clientWarningCard.clients_jmbg').text}</h3>
            ) : (
                <h3>{t('pages.clientWarningCard.clients_pib').text}</h3>
            )}
            {type === 'civil' ? (
                <ul>
                    {clients.map((client: any, index: number) => (
                        <p key={index}>
                            <strong>{t('pages.clients.modal.form.name').text}:</strong> {client.company_name}
                            <br />
                            <strong>{t('pages.clients.modal.form.domestic.civil.personalId').text}:</strong>
                            {client.jmbg}
                            <br />
                            <strong>{t('pages.clients.modal.form.phoneNumber').text}:</strong> {client.phone}
                            <br />
                            <strong>{t('pages.clients.modal.form.email').text}:</strong> {client.email}
                            <br />
                            <strong>{t('pages.clients.modal.form.address').text}:</strong> {client.address}
                            <br />
                            <strong>{t('pages.clients.modal.form.city').text}:</strong> {client.city}
                            <br />
                        </p>
                    ))}
                </ul>
            ) : (
                <ul>
                    {clients.map((client: any, index: number) => (
                        <p key={index}>
                            <strong>{t('pages.clients.modal.form.name').text}:</strong> {client.company_name}
                            <br />
                            <strong>{t('pages.clients.modal.form.domestic.civil.personalId').text}:</strong>{' '}
                            {client.pib}
                            <br />
                            <strong>
                                {t('pages.clients.modal.form.domestic.legal.identificationNumber').text}:
                            </strong>{' '}
                            {client.identification_number}
                            <br />
                            <strong>{t('pages.clients.modal.form.phoneNumber').text}:</strong> {client.phone}
                            <br />
                            <strong>{t('pages.clients.modal.form.email').text}:</strong> {client.email}
                            <br />
                            <strong>{t('pages.clients.modal.form.address').text}:</strong> {client.address}
                            <br />
                            <strong>{t('pages.clients.modal.form.city').text}:</strong> {client.city}
                            <br />
                        </p>
                    ))}
                </ul>
            )}
        </Content>
    );
};

const Content = styled.div`
    padding: 20px;
    h3 {
        margin-bottom: 20px;
        font-size: 18px;
        text-align: center;
    }
    p {
        margin-bottom: 20px;
        font-size: 16px;
    }
    .message {
        margin-bottom: 20px;
        font-size: 18px;
        text-align: center;
    }
`;

export default ClientCard;
