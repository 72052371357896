import { Dispatch, SetStateAction } from 'react';
import communication from '../../../communication';
import { ObjectKeys } from '../../../types/objectKeys';
import useParamsStore from '../../../store/params';

const getAllProforma = (setProforma: Dispatch<SetStateAction<ObjectKeys>>): void => {
    const page = useParamsStore.getState().p_page;
    communication
        .getProforma({
            limit: '10',
            page: page ? page : 1,
            search: {},
            searchFields: '',
            searchJoin: 'and',
            find: '',
            year: '',
        })
        .then((res: ObjectKeys) => {
            if (res.status === 200) {
                setProforma(res?.data);
            }
        });
};
export default getAllProforma;
