import React from 'react';

const FaFileInvoiceSharpRegular: React.FC<React.SVGProps<SVGSVGElement>> = (props) => (
    <svg viewBox="0 0 384 514" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M0 513.924V257.435V0.946777H256.489L384 128.458V513.924H0ZM48.0733 317.233C47.3405 235.597 46.9008 141.942 46.9008 109.111V49.3128H224.244V160.702H335.634V465.557H49.0993L48.0733 317.233ZM79.1446 112.336V128.458H192V96.2138H79.1446V112.336ZM79.1446 176.825V192.947H192V160.702H79.1446V176.825ZM79.1446 354.168V289.679V225.191H304.855V354.168H79.1446ZM192 401.069V417.191H304.855V384.947H192V401.069Z"
            fill="inherit"
        />
    </svg>
);

export default FaFileInvoiceSharpRegular;
