import { FunctionComponent, useEffect, useState } from 'react';
import { useNavigate, useParams, Link } from 'react-router-dom';
import styled from 'styled-components';
import communication from '../../../communication';
import useLanguageStore from '../../../store/language';
import useTranslations from '../../../hooks/useTranslation';
import { ObjectKeys } from '../../../types/objectKeys';
import srJson from '../../../components/language/rs.json';
import enJson from '../../../components/language/en.json';
import ruJson from '../../../components/language/ru.json';
import { flatten } from '../../../functions/flatten';

const ConfirmEmail: FunctionComponent = () => {
    const query = window.location.search;
    const urlParams = new URLSearchParams(query);
    const langValue = urlParams.get('lang');
    const { currentLang } = useLanguageStore();
    const t = useTranslations(currentLang);

    const navigate = useNavigate();

    const params = useParams();
    const token = params?.token;
    const [pleaseWait, setPleaseWait] = useState(true);

    useEffect(() => {
        if (langValue === 'en') {
            const flattenData = flatten(enJson);
            useLanguageStore.setState({ currentLang: flattenData });
            localStorage.setItem('language', 'English');
        } else if (langValue === 'ru') {
            const flattenData = flatten(ruJson);
            useLanguageStore.setState({ currentLang: flattenData });
            localStorage.setItem('language', 'Русский');
        } else if (langValue === 'rs') {
            const flattenData = flatten(srJson);
            useLanguageStore.setState({ currentLang: flattenData });
            localStorage.setItem('language', 'Srpski');
        }
    }, [langValue, localStorage.language]);

    useEffect(() => {
        async function confirmEmail(): Promise<void> {
            if (token) {
                await communication
                    .confirmEmailToken(token)
                    .then((res: ObjectKeys) => {
                        if (res.status === 200) {
                            navigate('/login/activated');
                        }
                    })
                    .catch((error: ObjectKeys) => {
                        if (error) {
                            setPleaseWait(false);
                        }
                    });
            }
        }
        confirmEmail();
    }, []);

    return (
        <PageWrapper>
            <ConfirmEmailInner>
                {pleaseWait ? (
                    <h4>{t('pages.confirmEmail.pleaseWait').text}</h4>
                ) : (
                    <>
                        <h4>{t('pages.confirmEmail.title').text}</h4>
                        <p>{t('pages.confirmEmail.subtitle').text}</p>
                        <Link to="/account-activation"> {t('pages.confirmEmail.link').text}</Link>
                    </>
                )}
            </ConfirmEmailInner>
        </PageWrapper>
    );
};

const PageWrapper = styled.div`
    background: var(--purple);
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 15px;
`;
const ConfirmEmailInner = styled.div`
    background: var(--white);
    box-shadow: 11px 10px 34px -6px rgba(0, 0, 0, 0.75);
    width: 450px;
    padding: 40px;
    text-align: center;
    p {
        margin: 15px 0;
        color: var(--gray);
        font-size: 12px;
    }
    a {
        font-size: 12px;
    }
`;
export default ConfirmEmail;
