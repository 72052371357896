import { FunctionComponent, useEffect, useState, useMemo, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import communication from '../../../communication';
import { generateQrCode } from '../../../communication/qr';
import colors from '../../../global/colors';
import useTranslations from '../../../hooks/useTranslation';
import useOutsideClick from '../../../hooks/useOutsideClick';
import useProfileStore from '../../../store/profile';
import useLanguageStore from '../../../store/language';
import useGeneralStore from '../../../store/general';
import { ObjectKeys } from '../../../types/objectKeys';
import { SendDocumentFormData } from '../../../types/communication/document';
import { formatParams } from '../../../functions/formatParams';
import { getAccessToken } from '../../../functions/auth';
import { formatNumber } from '../../../functions/format';
import Button, { ButtonVariant } from '../../../components/button';
import InvoiceCard, { InvoiceCardComponentType } from '../../../components/invoiceCard';
import NoItems from '../../../components/noItems';
import Loader from '../../../components/loader';
import Modal from '../../../components/modal';
import Sidebar from '../../../components/sideModal';
import Pagination from '../../../components/pagination';
import Dropdown from '../../../components/dropdown';
import Success from '../../../components/successModal';
import Conditions from '../../../components/conditionsModal';
import getRestOfPayment from '../../../components/invoiceCard/getRestOfPayment';
import ErrorModal from '../../../components/errorModal';
import Filters from './filters';
import PaymentModal from './paymentModal';
import PaymentUpdateModal from './paymentUpdateModal';
import CancelModal from '../common/cancelModal';
import EInvoiceModal from '../common/eInvoiceModal';
import DeleteModal from '../common/deleteModal';
import SendDocumentModal from '../common/sendModal';
import SendReminderModal from '../common/sendReminderModal';
import NoEInvoiceApiModal from '../common/noEinvoiceApiModal';
import useYearsStore from '../../../store/years';
import useInvoicesStore from '../../../store/invoices';
import useAgencyStore from '../../../store/agency';
import useParamsStore from '../../../store/params';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ConfirmModal from '../../../components/confirmModal';
import InvoiceSkeleton from '../common/skeleton/invoiceCardSkeleton';
import { MQ_BREAKPOINTS } from '../../../constants/breakpoints';
import FilterTrack from '../common/filterTrack';
import useInvoiceParamsStore from '../../../store/invoiceParams';
import getAllInvoices from './getAllInvoices';

const Invoices: FunctionComponent = () => {
    const page = useParamsStore.getState().page;
    const { currentLang } = useLanguageStore();
    const t = useTranslations(currentLang);
    const navigate = useNavigate();
    const { setGlobalModal, closeGlobalModal } = useGeneralStore();
    const ref = useRef<HTMLDivElement>(null);
    const { profile } = useProfileStore();
    const { invoiceData } = useInvoicesStore();
    const { agency } = useAgencyStore();
    const isAdmin = profile?.role === 'admin' ? true : false;

    const [hasReadPaymentConditions, setHasReadPaymentConditions] = useState(
        agency.user ? agency.user.data.has_read_payment_conditions : false,
    );

    const [removePaymentsModal, setRemovePaymentsModal] = useState(false);

    const confirmConditions = async (): Promise<void> => {
        if (isAdmin) return;
        setLoaderVisible(true);
        setHasReadPaymentConditions(true);
        const formData = {
            has_read_payment_conditions: true,
        };
        if (formData) {
            const res = await communication.updateUser(formData, agency.user.data.id);
            if (res.status === 200) {
                await communication.getAgency().then((response: ObjectKeys) => {
                    useAgencyStore.setState({
                        agency: response?.data?.data,
                    });
                });
            }
        }
        setLoaderVisible(false);
    };

    const token = getAccessToken();
    const isAccountActive = profile?.active === 1 ? true : false;

    const [statistics, setStatistics] = useState<ObjectKeys>({});
    const [searchValue, setSearchValue] = useState('');

    const [loaderVisible, setLoaderVisible] = useState(false);
    const [openCancelModal, setOpenCancelModal] = useState(false);
    const [openEInvoiceModal, setOpenEInvoiceModal] = useState(false);
    const [openPaymentModal, setOpenPaymentModal] = useState(false);
    const [openPaymentUpdateModal, setOpenPaymentUpdateModal] = useState(false);
    const [sendModal, setSendModal] = useState(false);
    const [sendReminderModal, setReminderModal] = useState(false);
    const [success, setSuccess] = useState(false);
    const [successInvoice, setSuccessInvoice] = useState(false);
    const [successReminder, setSuccessReminder] = useState(false);
    const [successEinvoice, setSuccessEinvoice] = useState(false);
    const [clientEmail, setClientEmail] = useState('');
    const [invoicePaidValue, setInvoicePaidValue] = useState<string | number>('');
    const [addInvoiceDropdown, setAddInvoiceDropdown] = useState(false);
    const [noEInvoiceApiModal, setNoEInvoiceApiModal] = useState(false);
    const [errorModal, setErrorModal] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [renderKey, setRenderKey] = useState<number>(0);

    const [invoiceToCancel, setInvoiceToCancel] = useState<ObjectKeys>({});
    const [invoiceToEinvoice, setInvoiceToEinvoice] = useState<ObjectKeys>({});
    const [invoiceToRemind, setInvoiceToRemind] = useState<ObjectKeys>({});
    const [invoiceToSend, setInvoiceToSend] = useState<ObjectKeys>({});

    const [qrCode, setQrCode] = useState('');
    const [currencyPay, setCurrencyPay] = useState('');

    const [display, setDisplay] = useState('list');
    const [clickedInvoice, setClickedInvoice] = useState('');
    const [clickedPayment, setClickedPayment] = useState('');

    const { handleClickOutside } = useOutsideClick(ref, setAddInvoiceDropdown);

    const [showSkeleton, setShowSkeleton] = useState(false);

    const [params, setParams] = useState({
        limit: 10,
        page: 1,
        search: {},
        searchFields: {},
        searchJoin: 'and',
        orderBy: '',
        sortedBy: '',
    });

    const [sendData, setSendData] = useState<SendDocumentFormData>({
        recipient: '',
        subject: '',
        message: '',
    });

    const formattedParams = useMemo(() => formatParams(params.search), [params]);
    const updateYears = async (): Promise<void> => {
        const yearsData = await communication.getYears();
        useYearsStore.setState({ years: yearsData?.data?.years });
    };

    useEffect(() => {
        useParamsStore.setState({ page: params.page });
        getInvoices();
    }, [formattedParams, params.limit, page, params.orderBy, params.sortedBy]);

    useEffect(
        () => () => {
            if (page > 1) {
                useInvoicesStore.setState({ invoiceData: [] });
                setParams({ ...params, page: 1 });
                useParamsStore.setState({ page: 1 });
            }
        },
        [],
    );

    //Invoice Change
    const handleChange = (invoiceId: string): void => {
        navigate(`/invoices/edit/${invoiceId}`);
    };

    //Invoice Copy
    const handleCopy = (invoiceId: string): void => {
        navigate(`/invoices/copy/${invoiceId}`);
    };

    //Invoice Cancel Modal
    const handleCancelModal = (invoice: ObjectKeys): void => {
        setClickedInvoice(invoice.id);
        setInvoiceToCancel(invoice);
        setOpenCancelModal(true);
    };

    //Invoice Download
    const handleDownloadInvoice = (
        invoiceId: string,
        agency_data: ObjectKeys,
        clientData: ObjectKeys,
        amount: string,
        invoiceNumber: string,
        bankAccount: string,
        currency: string,
        convert: boolean,
        invoice: ObjectKeys,
    ): void => {
        generateQrCode(
            agency_data.full_name,
            clientData.company_name,
            amount,
            invoiceNumber,
            bankAccount,
            currency,
            convert,
            invoiceNumber ? `00${invoiceNumber.replace('/', '-')}` : null,
            '221',
        )
            .then((res: ObjectKeys | any) => {
                if (res)
                    communication.downloadInvoice(
                        invoiceId,
                        token,
                        invoice.invoice_number,
                        invoice.currency !== 'RSD',
                        false,
                        res.i ? res.i : undefined,
                    );
            })
            .catch((error: ObjectKeys) => {
                communication.downloadInvoice(
                    invoiceId,
                    token,
                    invoice.invoice_number,
                    invoice.currency !== 'RSD',
                    false,
                    undefined,
                );
                console.error(error);
            });
    };

    //Refresh eInvoice
    const handleRefreshEInvoice = async (eInvoiceId: string): Promise<void> => {
        setLoaderVisible(true);
        setClickedInvoice(eInvoiceId);

        try {
            const res = await communication.refreshEInvoice({ id: eInvoiceId });

            if (res.status === 200) {
                setLoaderVisible(false);
                getInvoices().then((resp: boolean) => {
                    if (resp) {
                        setSuccess(true);
                    }
                });
            } else {
                console.error('Unexpected response status code:', res.status);
                setLoaderVisible(false);
            }
        } catch (error: any) {
            setLoaderVisible(false);
            setErrorMessage(error.response.data.message);
            setErrorModal(true);
        }
    };

    const getInvoices = (): Promise<boolean> => {
        return new Promise((resolve, reject) => {
            setShowSkeleton(true);
            const finalParams = {
                ...params,
                page: page ? page : 1,
                search: 'is_prepaid:0;' + formattedParams,
                searchFields: 'is_prepaid:=;' + formattedParams,
            };
            useInvoiceParamsStore.setState({ invoices_params: finalParams });
            communication
                .getInvoices(finalParams)
                .then((res: ObjectKeys) => {
                    if (res.status === 200) {
                        useInvoicesStore.setState({ invoiceData: res.data });
                        resolve(true);
                        setTimeout(function () {
                            setShowSkeleton(false);
                            setLoaderVisible(false);
                        }, 1000);
                    }
                })
                .catch((error: ObjectKeys) => {
                    if (error) {
                        setShowSkeleton(false);
                        useInvoicesStore.setState({ invoiceData: [] });
                        reject(false);
                    }
                });
        });
    };

    const handleOpenEInvoiceModal = (invoiceId: string): void => {
        setOpenEInvoiceModal(true);
        setClickedInvoice(invoiceId);
    };

    const handleEInvoiceModal = async (invoice: ObjectKeys): Promise<void> => {
        setInvoiceToEinvoice(invoice);
        if (agency.e_invoice_api_key) handleOpenEInvoiceModal(invoice.id);
        else setNoEInvoiceApiModal(true);
    };

    const handlePaymentModal = (invoiceId: string, paidValue: string | number, currency: string): void => {
        setCurrencyPay(currency);
        setClickedInvoice(invoiceId);
        setInvoicePaidValue(paidValue);
        setOpenPaymentModal(true);
    };

    const handleClickedInvoice = (invoice: ObjectKeys): void => {
        setClickedInvoice(invoice.id);
        setInvoiceToCancel(invoice);
    };

    //Invoice Delete Modal
    const handleDeleteModal = (invoice: ObjectKeys): void => {
        setGlobalModal(
            <DeleteModal
                t={t}
                clickedInvoice={invoice}
                closeGlobalModal={closeGlobalModal}
                onDeleteInvoice={() => {
                    getInvoices().then(() => {
                        updateYears();
                    });
                }}
            />,
        );
    };

    const handleSendModal = (
        invoiceId: string,
        invoiceClientEmail: string,
        agency_data: ObjectKeys,
        clientData: ObjectKeys,
        amount: string,
        invoiceNumber: string,
        bankAccount: string,
        currency: string,
        convert: boolean,
    ): void => {
        setClickedInvoice(invoiceId);
        setClientEmail(invoiceClientEmail);
        generateQrCode(
            agency_data.full_name,
            clientData.company_name,
            amount,
            invoiceNumber,
            bankAccount,
            currency,
            convert,
            invoiceNumber ? `00${invoiceNumber.replace('/', '-')}` : null,
            '221',
        )
            .then((res: ObjectKeys | any) => {
                if (res) {
                    setQrCode(res.i);
                    setSendModal(true);
                }
            })
            .catch((error: ObjectKeys) => {
                setQrCode('');
                console.error(error);
                setSendModal(true);
            });
    };

    const handleReminderModal = (
        invoiceId: string,
        invoiceClientEmail: string,
        agency_data: ObjectKeys,
        clientData: ObjectKeys,
        amount: string,
        invoiceNumber: string,
        bankAccount: string,
        currency: string,
        convert: boolean,
    ): void => {
        setClickedInvoice(invoiceId);
        setClientEmail(invoiceClientEmail);
        generateQrCode(
            agency_data.full_name,
            clientData.company_name,
            amount,
            invoiceNumber,
            bankAccount,
            currency,
            convert,
            invoiceNumber ? `00${invoiceNumber.replace('/', '-')}` : null,
            '221',
        )
            .then((res: ObjectKeys | any) => {
                if (res) {
                    setQrCode(res.i);
                    setReminderModal(true);
                }
            })
            .catch((error: ObjectKeys) => {
                setQrCode('');
                console.error(error);
                setReminderModal(true);
            });
    };

    const handleSendInvoice = (): void => {
        setClickedInvoice('');
        setSuccessInvoice(true);
    };

    const handleRemind = (): void => {
        setClickedInvoice('');
        setSuccessReminder(true);
    };

    const removeAllPayments = (): void => {
        setRemovePaymentsModal(false);
        setLoaderVisible(true);
        communication
            .removeAllPayments(clickedInvoice)
            .then(async (res: ObjectKeys) => {
                if (res) {
                    getInvoices().then(() => {
                        setLoaderVisible(false);
                    });
                }
            })
            .catch((error: ObjectKeys) => {
                setLoaderVisible(false);
                toast.error(error.response.data.message);
            });
    };

    const onLoad = (loading: boolean): void => {
        setLoaderVisible(loading);
    };

    //Add new invoice
    const handleAddInvoice = (invoiceType: string): void => {
        navigate(`/invoices/add/${invoiceType}`);
    };

    const handleUpdatePayment = (paymentId: string, invoiceId: string, paidValue: string | number): void => {
        setClickedPayment(paymentId);
        setOpenPaymentUpdateModal(true);
        setClickedInvoice(invoiceId);
        setInvoicePaidValue(paidValue);
    };

    // Specific Payment Delete
    const handleDeletePayment = (paymentId: string, invoiceId: string): void => {
        setLoaderVisible(true);
        communication
            .deleteInvoiceSpecificPayment(invoiceId, paymentId)
            .then((res: ObjectKeys) => {
                if (res) {
                    getAllInvoices().then(() => {
                        setLoaderVisible(false);
                    });
                }
            })
            .catch((error: ObjectKeys) => {
                setLoaderVisible(false);
                toast.error(error.response.data.message);
            });
    };

    return (
        <>
            {errorModal && (
                <Modal modalVisible={errorModal} closeModal={() => setErrorModal(false)}>
                    <ErrorModal t={t} setOpenModal={setErrorModal} errorMessage={errorMessage} />
                </Modal>
            )}
            <ToastContainer />
            {sendModal && (
                <Sidebar close={() => setSendModal(false)}>
                    <SendDocumentModal
                        close={() => setSendModal(false)}
                        setFormData={setSendData}
                        formData={sendData}
                        invoice={invoiceToSend}
                        t={t}
                        id={clickedInvoice}
                        success={() => {
                            getInvoices();
                            handleSendInvoice();
                        }}
                        loading={(e: boolean) => onLoad(e)}
                        clientEmail={clientEmail}
                        errorMessage={(mess: string) => {
                            setErrorMessage(mess);
                            setErrorModal(true);
                        }}
                        modalVisible={sendModal}
                        qrCode={qrCode}
                    />
                </Sidebar>
            )}
            {sendReminderModal && (
                <Sidebar close={() => setReminderModal(false)}>
                    <SendReminderModal
                        close={() => setReminderModal(false)}
                        setFormData={setSendData}
                        formData={sendData}
                        t={t}
                        id={clickedInvoice}
                        success={() => {
                            handleRemind();
                        }}
                        loading={(e: boolean) => onLoad(e)}
                        clientEmail={clientEmail}
                        errorMessage={(mess: string) => {
                            setErrorMessage(mess);
                            setErrorModal(true);
                        }}
                        modalVisible={sendModal}
                        invoice={invoiceToRemind}
                        qrCode={qrCode}
                    />
                </Sidebar>
            )}
            {openPaymentModal ? (
                <Modal modalVisible={openPaymentModal} closeModal={() => setOpenPaymentModal(false)}>
                    <PaymentModal
                        setOpenPaymentModal={setOpenPaymentModal}
                        t={t}
                        clickedInvoice={clickedInvoice}
                        invoicePaidValue={invoicePaidValue}
                        setLoaderVisible={setLoaderVisible}
                        setStatistics={setStatistics}
                        formattedParams={formattedParams}
                        currency={currencyPay}
                    />
                </Modal>
            ) : null}
            {openPaymentUpdateModal ? (
                <Modal modalVisible={openPaymentUpdateModal} closeModal={() => setOpenPaymentUpdateModal(false)}>
                    <PaymentUpdateModal
                        setOpenPaymentUpdateModal={setOpenPaymentUpdateModal}
                        t={t}
                        clickedInvoice={clickedInvoice}
                        invoicePaidValue={invoicePaidValue}
                        clickedPayment={clickedPayment}
                        setLoaderVisible={setLoaderVisible}
                        setStatistics={() => void 0}
                        formattedParams={formattedParams}
                        type={'invoice'}
                    />
                </Modal>
            ) : null}
            {openCancelModal ? (
                <Modal modalVisible={openCancelModal} closeModal={() => setOpenCancelModal(false)}>
                    <CancelModal
                        t={t}
                        setOpenCancelModal={setOpenCancelModal}
                        clickedInvoice={invoiceToCancel}
                        onCancelInvoice={() => {
                            getInvoices();
                        }}
                    />
                </Modal>
            ) : (
                <></>
            )}
            {openEInvoiceModal ? (
                <Modal modalVisible={openEInvoiceModal} closeModal={() => setOpenEInvoiceModal(false)}>
                    <EInvoiceModal
                        t={t}
                        setOpenEInvoiceModal={setOpenEInvoiceModal}
                        clickedInvoice={invoiceToEinvoice}
                        onEinvoiceSent={() => {
                            setSuccessEinvoice(true);
                            getInvoices().then(() => {
                                updateYears();
                            });
                        }}
                    />
                </Modal>
            ) : (
                <></>
            )}
            {noEInvoiceApiModal && (
                <Modal
                    modalVisible={true}
                    closeModal={() => {
                        setNoEInvoiceApiModal(false);
                    }}
                >
                    <NoEInvoiceApiModal
                        saveChanges={() => navigate('/settings/e-invoice')}
                        close={() => {
                            setNoEInvoiceApiModal(false);
                        }}
                        message={t('pages.invoices.noEInvoiceKeyModal.title').text}
                    />
                </Modal>
            )}
            {!hasReadPaymentConditions && !isAdmin && (
                <Modal
                    modalVisible={true}
                    closeModal={() => {
                        return;
                    }}
                >
                    <Conditions close={() => confirmConditions()} />
                </Modal>
            )}
            {removePaymentsModal && (
                <Modal
                    width="500px"
                    className="package-modal"
                    modalVisible={true}
                    closeModal={() => {
                        setRemovePaymentsModal(false);
                    }}
                >
                    <ConfirmModal
                        action={() => {
                            removeAllPayments();
                        }}
                        close={() => {
                            setRemovePaymentsModal(false);
                        }}
                        active={removePaymentsModal}
                        message={t('pages.invoices.remove_payments').text}
                    />
                </Modal>
            )}
            {success && (
                <Modal modalVisible={true} closeModal={() => setSuccess(false)}>
                    <Success close={() => setSuccess(false)} message={t('pages.eInvoices.refreshSuccess').text} />
                </Modal>
            )}
            {successInvoice && (
                <Modal modalVisible={true} closeModal={() => setSuccessInvoice(false)}>
                    <Success close={() => setSuccessInvoice(false)} message={t('pages.invoices.sentSuccess').text} />
                </Modal>
            )}
            {successReminder && (
                <Modal modalVisible={true} closeModal={() => setSuccessReminder(false)}>
                    <Success
                        close={() => setSuccessReminder(false)}
                        message={t('pages.invoices.sendModal.successReminder').text}
                    />
                </Modal>
            )}
            {successEinvoice && (
                <Modal modalVisible={true} closeModal={() => setSuccessEinvoice(false)}>
                    <Success close={() => setSuccessEinvoice(false)} message={t('pages.eInvoices.sentSuccess').text} />
                </Modal>
            )}
            {loaderVisible && <Loader />}
            <PageWrapper className="invoices page">
                <Header>
                    <div>
                        <h1>{t('pages.invoices.title').text}</h1>
                        <AddButtonContainer>
                            <Button
                                variant={ButtonVariant.solid}
                                color={colors.purple}
                                icon={true}
                                size={'auto'}
                                onClick={() => {
                                    if (isAccountActive) {
                                        setAddInvoiceDropdown(!addInvoiceDropdown);
                                    }
                                }}
                            >
                                <FontAwesomeIcon className="icon" color={colors.white} icon={faPlus} />
                                {t('pages.invoices.newInvoice').text}
                            </Button>
                            {addInvoiceDropdown && (
                                <DropdownContainer ref={ref} onClick={handleClickOutside}>
                                    <Dropdown
                                        arrowRight="4px"
                                        itemList={[
                                            {
                                                label: t('pages.invoices.addInvoiceDropdown.invoice').text,
                                                handler: () => handleAddInvoice('domestic'),
                                            },
                                            {
                                                label: t('pages.invoices.addInvoiceDropdown.foreign').text,
                                                handler: () => handleAddInvoice('foreign'),
                                            },
                                        ]}
                                    />
                                </DropdownContainer>
                            )}
                        </AddButtonContainer>
                    </div>
                </Header>
                <Filters
                    setParams={setParams}
                    params={params}
                    searchValue={searchValue}
                    setSearchValue={setSearchValue}
                    setDisplay={setDisplay}
                />
                <FilterTrack
                    onChevronClick={(name: string, order: string) => {
                        setParams({ ...params, orderBy: name, sortedBy: order });
                    }}
                    t={t}
                />
                {showSkeleton ? (
                    Array.from({ length: 10 }).map((_, index) => <InvoiceSkeleton key={index} />)
                ) : (
                    <>
                        <InvoicesList className={`display-${display}`} key={renderKey}>
                            {invoiceData?.data?.length > 0 ? (
                                invoiceData?.data?.map((invoicePrev: ObjectKeys, index: number) => {
                                    const invoice: ObjectKeys = {
                                        ...invoicePrev,
                                        services: invoicePrev.original_data.services,
                                        client: { data: invoicePrev.original_data.client },
                                    };
                                    return (
                                        <div key={invoice.id} className={`${display}`}>
                                            <InvoiceCard
                                                eInvoice={invoice?.is_einvoice}
                                                type={'invoice'}
                                                dropdownDirection={index > 1 ? 'up' : 'down'}
                                                cardData={invoice}
                                                cardDisplay={display}
                                                handleChange={() => handleChange(invoice.id)}
                                                handleCopy={() => handleCopy(invoice.id)}
                                                handleDelete={() => handleDeleteModal(invoice)}
                                                handleCancel={() => handleCancelModal(invoice)}
                                                handleDownload={() =>
                                                    handleDownloadInvoice(
                                                        invoice?.id,
                                                        invoice?.original_data?.agency,
                                                        invoice?.client?.data,
                                                        invoice.total_for_payment_in_rsd,
                                                        `${invoice.prefix ? invoice.prefix : ''}${
                                                            invoice.invoice_number
                                                        }`,
                                                        invoice.bank_account,
                                                        invoice.currency,
                                                        true,
                                                        invoice,
                                                    )
                                                }
                                                handleEInvoice={() => !invoice.eInvoice && handleEInvoiceModal(invoice)}
                                                handlePayment={() => {
                                                    handlePaymentModal(
                                                        invoice.id,
                                                        invoice.payments
                                                            ? getRestOfPayment(invoice)
                                                            : formatNumber(
                                                                  Number(invoice.value),
                                                                  4,
                                                                  3,
                                                                  '.',
                                                                  ',',
                                                              ).toString(),
                                                        invoice.currency,
                                                    );
                                                }}
                                                handleSend={() => {
                                                    setInvoiceToSend(invoice);
                                                    handleSendModal(
                                                        invoice.id,
                                                        invoice.client.data.email ? invoice.client.data.email : '',
                                                        invoice?.original_data?.agency,
                                                        invoice?.client?.data,
                                                        invoice.total_for_payment_in_rsd,
                                                        `${invoice.prefix ? invoice.prefix : ''}${
                                                            invoice.invoice_number
                                                        }`,
                                                        invoice.bank_account,
                                                        invoice.currency,
                                                        true,
                                                    );
                                                }}
                                                handleReminder={() => {
                                                    handleReminderModal(
                                                        invoice.id,
                                                        invoice.client.data.email ? invoice.client.data.email : '',
                                                        invoice?.original_data?.agency,
                                                        invoice?.client?.data,
                                                        invoice.total_for_payment_in_rsd,
                                                        `${invoice.prefix ? invoice.prefix : ''}${
                                                            invoice.invoice_number
                                                        }`,
                                                        invoice.bank_account,
                                                        invoice.currency,
                                                        true,
                                                    );
                                                    setInvoiceToRemind(invoice);
                                                }}
                                                handleClick={() => handleClickedInvoice(invoice)}
                                                handleRefresh={() => {
                                                    handleRefreshEInvoice(invoice.id);
                                                }}
                                                handleUpdatePayment={(e: string) => {
                                                    const specificPaymentValue = invoice?.payments?.data.filter(
                                                        (payment: ObjectKeys) => {
                                                            return payment.id === e;
                                                        },
                                                    );
                                                    handleUpdatePayment(
                                                        e,
                                                        invoice.id,
                                                        formatNumber(
                                                            Number(specificPaymentValue[0]?.value),
                                                            4,
                                                            3,
                                                            '.',
                                                            ',',
                                                        ).toString(),
                                                    );
                                                }}
                                                statusFilter={params?.search}
                                                handleDeletePayment={(e: string) => {
                                                    handleDeletePayment(e, invoice.id);
                                                }}
                                                paymentInfo={() => void 0}
                                            />
                                        </div>
                                    );
                                })
                            ) : (
                                <NoItems text={t('pages.invoices.noItems.description').text} />
                            )}
                        </InvoicesList>
                        {invoiceData?.data?.length > 0 && (
                            <Pagination
                                pageCount={invoiceData.meta?.pagination?.total_pages}
                                onPageChange={(e: ObjectKeys) => {
                                    setParams({ ...params, page: e.selected + 1 });
                                    useParamsStore.setState({ page: e.selected + 1 });
                                }}
                                nextLabel={`${
                                    invoiceData.meta?.pagination?.current_page ===
                                    invoiceData.meta?.pagination?.total_pages
                                        ? ''
                                        : '>'
                                }`}
                                previousLabel={`${invoiceData.meta?.pagination?.current_page === 1 ? '' : '<'}`}
                                breakLabel="..."
                                initialPage={invoiceData.meta?.pagination?.current_page - 1}
                            />
                        )}
                    </>
                )}
            </PageWrapper>
        </>
    );
};
export default Invoices;

const PageWrapper = styled.div`
    min-height: 100vh; /* Default min-height */

    @media screen and (max-width: ${MQ_BREAKPOINTS.mobileXS}) {
        min-height: 1400px;
    }

    @media screen and (min-width: ${MQ_BREAKPOINTS.mobileS}) and (max-width: ${MQ_BREAKPOINTS.mobileM}) {
        min-height: 1300px;
    }
    @media screen and (min-width: ${MQ_BREAKPOINTS.mobileM}) and (max-width: ${MQ_BREAKPOINTS.mobileL}) {
        min-height: 1300px;
    }
    @media screen and (min-width: ${MQ_BREAKPOINTS.mobileL}) and (max-width: ${MQ_BREAKPOINTS.tablet}) {
        min-height: 1200px;
    }
    @media screen and (min-width: ${MQ_BREAKPOINTS.tablet}) and (max-width: ${MQ_BREAKPOINTS.laptop}) {
        min-height: 1100px;
    }
    @media screen and (min-width: ${MQ_BREAKPOINTS.laptop}) and (max-width: ${MQ_BREAKPOINTS.laptopM}) {
        min-height: 850px;
    }
    @media screen and (min-width: ${MQ_BREAKPOINTS.laptopM}) and (max-width: ${MQ_BREAKPOINTS.laptopL}) {
        min-height: 950px;
    }
    @media screen and (min-width: ${MQ_BREAKPOINTS.laptopL}) {
        min-height: 1000px;
    }
`;

const Header = styled.div`
    display: flex;
    align-items: center;
    border-bottom: 1px solid var(--border-color);
    justify-content: space-between;
    padding-bottom: 20px;
    margin-bottom: 20px;
    > div {
        display: flex;
        align-item: center;
        button {
            margin-left: 35px;
            height: 35px;
            font-size: 12px;
            line-height: initial;
        }
    }
    @media screen and (max-width: ${MQ_BREAKPOINTS.tablet}) {
        flex-direction: column;
        margin-bottom: 0;
        > div {
            width: 100%;
            justify-content: space-between;
            button {
                margin-left: 0;
            }
        }
    }
`;
const InvoicesList = styled.div`
    &.display-grid {
        display: grid;
        gap: 10px;
        width: 100%;
        /* grid-template-columns: 1fr 1fr 1fr; */
        @media screen and (max-width: 1023px) {
            grid-template-columns: 1fr;
        }
        @media screen and (min-width: ${MQ_BREAKPOINTS.laptop}) {
            grid-template-columns: 1fr 1fr;
        }
        @media screen and (min-width: ${MQ_BREAKPOINTS.desktop}) {
            grid-template-columns: 1fr 1fr 1fr;
        }
        @media screen and (min-width: ${MQ_BREAKPOINTS.ultraWide}) {
            grid-template-columns: 1fr 1fr 1fr 1fr;
        }
    }
`;

const AddButtonContainer = styled.div`
    position: relative;
`;

export const DropdownContainer = styled.div`
    position: absolute;
    z-index: 1;
    right: 0;
    top: 55px;

    p {
        margin-left: 0;
    }
    .dropdown-container {
        height: auto;
        overflow-y: auto;
    }
`;
