import { FunctionComponent, useEffect, useState } from 'react';
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';

import Template from './template';
import Note from './defaultNote';

import styled from 'styled-components';
import useAgencyStore from '../../../store/agency';
import useLanguageStore from '../../../store/language';
import useTranslations from '../../../hooks/useTranslation';
import { useLocation, useNavigate } from 'react-router-dom';

const BankAccounts: FunctionComponent = () => {
    const { currentLang } = useLanguageStore();
    const t = useTranslations(currentLang);
    const { agency } = useAgencyStore();
    const [selectedTab, setSelectedTab] = useState<number>(0);
    const navigate = useNavigate();
    const location = useLocation();

    const handleTabSelect = (i: number): void => {
        if (i === 0) {
            navigate('/agency/invoice-template?=template');
        }
        if (i === 1) {
            navigate('/agency/invoice-template?=note');
        }
    };

    useEffect(() => {
        if (location?.search === '?=template') {
            setSelectedTab(0);
        } else if (location?.search === '?=note') {
            setSelectedTab(1);
        }
    }, [location]);

    return (
        <>
            <Content>
                <StyledTabs selectedIndex={selectedTab} onSelect={handleTabSelect}>
                    <StyledTabList>
                        <StyledTab>{t('pages.editInvoice.previewModal.invoiceHeaderLookTitle').text}</StyledTab>
                        <StyledTab>{t('pages.editInvoice.previewModal.invoiceHeaderNoteTitle').text}</StyledTab>
                    </StyledTabList>
                    <StyledTabPanel value={0}>
                        <Template />
                    </StyledTabPanel>
                    <StyledTabPanel value={1}>
                        <Note />
                    </StyledTabPanel>
                </StyledTabs>
            </Content>
        </>
    );
};
export default BankAccounts;
const StyledTabs = styled(Tabs)``;

const StyledTabList = styled(TabList)`
    display: flex;
    list-style: none;
`;

const StyledTab = styled(Tab)`
    cursor: pointer;
    padding: 6px 12px;
    border-radius: 0;
    &.react-tabs__tab--selected {
        border-bottom: 3px solid var(--purple);
    }
`;

const StyledTabPanel = styled(TabPanel)`
    margin-top: 30px;
    border: 1px solid #ccc;
    border: none;
    width: 100%;
`;

const Content = styled.div`
    width: 100%;
    .center {
        text-align: center;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    text-align: center;
    display: inline-block;
    vertical-align: top;
    color: var(--black);
    .lang-selector {
        margin-top: 30px;
        margin-bottom: 30px;
    }
`;
