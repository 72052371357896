import React from 'react';
import styled from 'styled-components';
import Button, { ButtonVariant } from '../../../../../components/button';
import colors from '../../../../../global/colors';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import useTranslations from '../../../../../hooks/useTranslation';
import useLanguageStore from '../../../../../store/language';
import getCurrentLanguage from '../../../../../functions/getCurrentLanguage';
// import useTranslations from '../../../../hooks/useTranslation';
// import useLanguageStore from '../../../../store/language';
// import { formatTwoDecimals } from '../../../../functions/format';
import { ObjectKeys } from '@/types/objectKeys';
import { MQ_BREAKPOINTS } from '../../../../../constants/breakpoints';

interface PackageCardProps {
    title: string;
    price: number;
    monthlyObligatory: number;
    isClicked: boolean;
    onClicked: () => void;
    prevent: boolean;
    item: ObjectKeys;
}

const PackageCard: React.FC<PackageCardProps> = ({ title, onClicked, prevent, item }) => {
    const { currentLang } = useLanguageStore();
    const t = useTranslations(currentLang);

    const lang = getCurrentLanguage();

    return (
        <CardContainer>
            <div>
                <Title>{title}</Title>
                <Items>
                    <div className="item">
                        <FontAwesomeIcon icon={faCheck} color={'var(--black)'} />
                        <p>{t('pages.agency.subscription.aplication').text}</p>
                    </div>
                    {item.services.data.map((i: ObjectKeys) => (
                        <div className="item" key={i.id}>
                            <FontAwesomeIcon icon={faCheck} color={'var(--black)'} />
                            <p>{i[`name_${lang}`]}</p>
                        </div>
                    ))}
                </Items>
            </div>

            <Activate prevent={prevent}>
                <Button
                    variant={ButtonVariant.solid}
                    color={prevent ? 'white' : colors.purple}
                    className="small"
                    size={150}
                    onClick={() => {
                        onClicked();
                    }}
                >
                    {t('pages.agency.subscription.activate').text}
                </Button>
            </Activate>
        </CardContainer>
    );
};

const Title = styled.div`
    font-size: 24px;
    text-transform: uppercase;
    transition: background-color 0.3s;
    background-color: 'transparent';
    color: 'var(--purple)';
    border-radius: 15px;
    padding: 5px;
`;

const Activate = styled.div<{ prevent: boolean }>`
    padding: 5px;
    display: flex;
    justify-content: space-around;
    .small {
        width: 100%;
        font-size: 16px;
        padding: 10px;
        transition: background-color 0.3s, color 0.3s;
        color: ${(props) => (props.prevent ? 'var(--purple)' : 'white')};
        border: 1px solid var(--purple);
        border-radius: 0;
    }
`;

const Items = styled.div`
    margin-top: 20px;
    display: flex;
    text-align: left;
    flex-direction: column;
    justify-content: space-between;
    .item {
        display: flex;
        align-items: center;
        gap: 5px;
        padding: 10px;
        font-size: 18px;
        p {
            padding-left: 10px;
        }
        svg {
            color: var(--purple);
        }
    }
`;

const CardContainer = styled.div`
    width: 32%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    @media only screen and (max-width: ${MQ_BREAKPOINTS.tablet}) {
        width: 100%;
    }
    color: var(--purple);
    border: 1px solid var(--purple);
    padding: 5px;
    transition: border 0.3s, background-color 0.3s, color 0.3s;
    span {
        font-size: 18px;
        font-weight: bold;
        color: var(--purple);
        transition: color 0.3s;
    }
    p {
        font-size: 14px;
        color: var(--gray);
    }
    .oldPrice {
        text-decoration: line-through;
        color: var(--purple);
    }
`;

export default PackageCard;
