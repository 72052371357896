import React from 'react';

const FaFileInvoiceSharpThin: React.FC<React.SVGProps<SVGSVGElement>> = (props) => (
    <svg viewBox="0 0 384 512" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M0 512V256V0H224L384 160V512H0ZM15.8476 15.8476H207.848V176.152H368.152V496.152H15.8476V15.8476ZM224.061 23.7714C224.366 23.7714 255.208 54.3695 360.533 159.695H224.305V121.417C224.305 100.389 224.122 69.8514 223.878 53.4552C223.634 35.5961 223.695 23.7714 224.061 23.7714ZM64 79.8476V71.9238V64H160.305V79.8476H64ZM64 143.848V135.924V128H160.305V143.848H64ZM64 288V352.305H320V223.695H64V288ZM223.695 448V440.076V432.152H320V448H223.695ZM79.8476 335.848V288V240.152H304.152V335.848H79.8476Z"
            fill="inherit"
        />
    </svg>
);

export default FaFileInvoiceSharpThin;
