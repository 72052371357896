import axios, { AxiosResponse, AxiosError } from 'axios';

interface AnalyticsResponse {
    data: any;
}

export const getAnalytics = async (token: string): Promise<AnalyticsResponse | AxiosError> => {
    const propertyId = '387052995';
    try {
        const apiUrl = `https://analyticsdata.googleapis.com/v1beta/properties/${propertyId}:runRealtimeReport`;

        const requestBody = {
            // dateRanges: [{ startDate: '2024-01-01', endDate: '2024-01-29' }],
            dimensions: [{ name: 'country' }],
            metrics: [{ name: 'activeUsers' }],
        };

        const response: AxiosResponse<AnalyticsResponse> = await axios.post(apiUrl, requestBody, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
            },
        });

        // Handle the successful response
        return response.data;
    } catch (error) {
        // Handle errors
        throw error as AxiosError;
    }
};
