import { FunctionComponent } from 'react';
import styled from 'styled-components';
import Button, { ButtonVariant } from '../../../../components/button';
import { useNavigate } from 'react-router-dom';

interface Props {
    close: Function;
    active?: boolean;
    className?: string;
    children?: string | any;
}

const Success: FunctionComponent<Props> = ({ close }) => {
    const lang = localStorage.language;
    const navigate = useNavigate();

    const text =
        lang === 'Srpski'
            ? 'Plaćanje poreza i doprinosa je potrebno izvršiti do 15. u mesecu za prethodni mesec. Ako se propusti rok, potrebno je proveriti stanje na ePorezima, jer će biti obračunata kamata. Pomoć oko analize stanja naruči kroz '
            : lang === 'English'
            ? 'Payment of taxes and contributions must be made by the 15th of the month for the previous month. If the deadline is missed, it is necessary to check the balance on eTaxes, because interest will be calculated. Help with analyzing the condition of the order through '
            : 'Уплату налогов и взносов необходимо произвести до 15 числа за предыдущий месяц. Если срок пропущен, необходимо проверить баланс на eTaxes, потому что будут начислены проценты. Помощь с анализом состояния заказа через ';

    const link = lang === 'Srpski' ? 'Pausal usluge.' : lang === 'English' ? 'Pausal services.' : 'Pausal услуге.';

    return (
        <>
            <TextContainer>
                <div>
                    <span>{text}</span>
                    <span
                        className="link"
                        onClick={() => {
                            navigate('/services/1');
                            close();
                        }}
                    >
                        {link}
                    </span>
                </div>
            </TextContainer>
            <ButtonContainer>
                <Button variant={ButtonVariant.solid} color={'var(--purple)'} onClick={() => close()} size={'100%'}>
                    ok
                </Button>
            </ButtonContainer>
        </>
    );
};

export default Success;

const TextContainer = styled.div`
    padding: 35px 50px;
    text-align: center;
    min-height: 130px;
    display: flex;
    justify-content: center;
    span {
        padding-top: 0px;
        margin-top: 0px;
        color: var(--black);
        font-size: 18px;
    }
    .link {
        color: var(--purple);
        cursor: pointer;
    }
`;

const ButtonContainer = styled.div`
    button {
        border-radius: 0px !important;
    }
`;
