import { MQ_BREAKPOINTS } from '../../constants/breakpoints';
import { faXmark } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FunctionComponent } from 'react';
import styled from 'styled-components';
interface Props {
    modalVisible: boolean;
    closeModal: Function;
    hasCloseButton?: boolean;
    children: any;
    size?: number | string;
    className?: string | '';
    width?: string;
    preventOutsideClickClose?: boolean;
}
const Modal: FunctionComponent<Props> = ({
    className,
    modalVisible,
    closeModal,
    children,
    size,
    width,
    hasCloseButton = false,
    preventOutsideClickClose = false,
}) => {
    return (
        <StyledModal
            className={`${className ? className : ''} ${modalVisible ? 'visible' : ''}`}
            closeModal={closeModal}
            size={size}
            width={width}
            modalVisible={modalVisible}
        >
            {hasCloseButton && <FontAwesomeIcon icon={faXmark} className="close-icon" onClick={() => closeModal()} />}
            <div
                className="modal-backdrop"
                onClick={() => !preventOutsideClickClose && closeModal()} // Conditional close
            />
            <div className="modal-wrapper">{children}</div>
        </StyledModal>
    );
};
const StyledModal = styled.div<Props>`
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 9999;
    opacity: 0;
    display: flex;
    pointer-events: none;
    justify-content: center;
    align-items: center;
    transition: 0.2s all ease-in-out;

    .close-icon {
        position: fixed;
        top: 1.5rem;
        right: 1.5rem;
        z-index: 999999;
        color: var(--purple);
        height: 25px;
        width: 25px;
        padding: 0.3rem;
        background-color: var(--white);
        border-radius: 50%;
        box-shadow: 1px -1px 5px 3px rgba(0, 0, 0, 0.35);
        cursor: pointer;
    }
    &.visible {
        opacity: 1;
        pointer-events: all;
    }
    div {
        &.modal-backdrop {
            position: fixed;
            width: 100%;
            height: 100%;
            top: 0;
            background-color: rgba(0, 0, 0, 0.6);

            @media only screen and (max-width: ${MQ_BREAKPOINTS.mobileL}) {
                display: block;
                position: fixed;
                top: 0;
                left: 0;
                z-index: 900;
                height: 100%;
                width: 100%;
                background: rgba(0, 0, 0, 0.6);
                overflow: auto;
            }
        }
    }

    .modal-wrapper {
        background: var(--white);
        position: absolute;
        left: 0;
        right: 0;
        margin: 0 auto;
        top: 50%;
        width: ${(props) => (props.width ? props.width : '495px')};
        max-width: 100%;
        border-radius: 6px;
        background-color: var(--white);
        -webkit-box-shadow: 0 0 10px 3px rgba(0, 0, 0, 0.5);
        box-shadow: 0 0 10px 3px rgba(0, 0, 0, 0.5);

        animation: slide-up 0.25s ease;
        transform: translateY(-50%);

        @keyframes slide-up {
            0% {
                opacity: 0;
                top: 0;
                transform: translateY(0);
            }
            100% {
                opacity: 1;
                transform: translateY(-50%);
                top: 50%;
            }
        }

        @media only screen and (max-width: ${MQ_BREAKPOINTS.mobileL}) {
            max-width: 90vw;
            margin: 0 auto;

            top: 50%;
            transform: translateY(-50%);
            position: fixed;
            z-index: 200000;
            left: 0;
            right: 0;
        }
    }
    &.preview-modal {
        .modal-wrapper {
            width: 750px;
            max-width: calc(100% - 2rem);
            height: 98%;
            overflow-y: auto;
        }
        .modal-wrapper::-webkit-scrollbar {
            width: 0;
            background: transparent;
        }
    }
    &.package-modal {
        .modal-wrapper {
            overflow-y: auto;
            @media only screen and (max-width: ${MQ_BREAKPOINTS.mobileL}) {
                margin-top: 80px;
            }
        }
        .modal-wrapper::-webkit-scrollbar {
            width: 0;
            background: transparent;
        }
    }
    &.preview-tax-modal {
        .modal-wrapper {
            width: 770px;
            overflow-y: auto;
            max-height: 80vh;
            padding: 15px;
            height: 80%;

            /* Styles for the scrollbar */
            scrollbar-width: thin; /* For Firefox */
            scrollbar-color: #888 transparent; /* For Chrome, Edge, and Safari */

            &::-webkit-scrollbar {
                width: 6px;
            }

            &::-webkit-scrollbar-thumb {
                background-color: #888;
                border-radius: 6px;
            }

            @media only screen and (max-width: ${MQ_BREAKPOINTS.mobileL}) {
                margin-top: 80px;
            }
            @media only screen and (max-width: ${MQ_BREAKPOINTS.laptop}) {
                max-height: 100vh;
            }
        }
    }
`;
export default Modal;
