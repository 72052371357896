import { FunctionComponent } from 'react';
import styled from 'styled-components';
import { ObjectKeys } from '@/types/objectKeys';
import Button, { ButtonVariant } from '../../components/button';
import colors from '../../global/colors';
import useLanguageStore from '../../store/language';
import useTranslations from '../../hooks/useTranslation';
import { formatTwoDecimals } from '../../functions/format';

type Props = {
    template: ObjectKeys;
    onClick: () => void;
    className: string;
};

const TransferOrderAdminCard: FunctionComponent<Props> = ({ template, onClick, className }) => {
    const { currentLang } = useLanguageStore();
    const t = useTranslations(currentLang);
    return (
        <Content className={className}>
            <div onClick={onClick} className="box">
                <h1 className={'title'}>{template?.payment_slip_heading}</h1>
                <div className="price-container">
                    <span>{t('pages.transfers.amount').text}</span>
                    <p>
                        {formatTwoDecimals(template?.total_amount)} {template?.currency}
                    </p>
                </div>
                <div className={'button-container'}>
                    <Button variant={ButtonVariant.outlined} color={colors.purple}>
                        {t('pages.transfers.show').text}
                    </Button>
                </div>
            </div>
        </Content>
    );
};

const Content = styled.div`
    width: 25%;
    -webkit-box-flex: 1;
    -ms-flex: 1 1 25%;
    flex: 1 1 50%;
    margin-top: 25px;
    max-width: 25%;
    padding-left: 10px;
    padding-right: 10px;

    &.mobile {
        width: 100%;
        -webkit-box-flex: 1;
        -ms-flex: 1 1 100%;
        flex: 1 1 100%;
        margin-top: 25px;
        max-width: 100%;
    }

    &:nth-child(even) {
        padding-left: 0;
    }
    &:nth-child(odd) {
        padding-left: 0;
    }
    .box {
        background: #fff;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
        border: 1px solid #9b7fe9;
        :hover {
            cursor: pointer;
            -webkit-box-shadow: 0 0 10px 2px rgba(0, 0, 0, 0.2);
            box-shadow: 0 0 10px 2px rgba(0, 0, 0, 0.2);
        }
    }
    .title {
        color: #92a5ac;
        font-size: 13px;
        line-height: 20px;
        padding: 18px 24px 0;
        min-height: 5.4em;
        margin-right: 0.83em;
        -webkit-box-flex: 1;
        -ms-flex: 1 1 100%;
        flex: 1 1 100%;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
    }

    .price-container {
        -webkit-box-flex: 1;
        -ms-flex: 1 1 100%;
        flex: 1 1 100%;
        padding: 0 24px 24px;
        span {
            display: block;
            color: #92a5ac;
            font-size: 13px;
            margin-bottom: 5px;
        }
        p {
            font-weight: 400;
            font-size: 22px;
            color: #4c595f;
        }
    }
    .button-container {
        background: var(--light-gray);
        -webkit-box-flex: 1;
        -ms-flex: 1 1 100%;
        flex: 1 1 100%;
        text-align: center;
        align-items: center;
        justify-content: center;
        padding-right: 5px;
        button {
            background: var(--white);
            color: var(--purple);
            border: 1px solid var(--purple);
            border-radius: 5px;
            text-transform: uppercase;
            font-size: 12px;
            padding: 8px 20px;
            margin: 15px auto;
            vertical-align: top;
        }
    }
`;
export default TransferOrderAdminCard;
