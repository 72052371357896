import useAllBanksQuery from '../react-query/useAllBanksQuery';

const useBankCode = (account: string): string => {
    const { data } = useAllBanksQuery();
    // Regular expression to check for IBAN
    const ibanRegex = /^[A-Z]{2}\d{2}[A-Z0-9]{1,30}$/;
    const isIban = ibanRegex.test(account);

    let bankCode = '';
    if (isIban) {
        // Assuming IBAN bank code is after the first four characters (common for many countries)
        bankCode = account.slice(4, 7); // Adjust slice indices if necessary
    } else {
        // Assuming standard bank account has bank code in the first 3 digits
        bankCode = account.slice(0, 3);
    }

    const bank = data?.find((banka) => banka.code === bankCode);

    return bank ? bank.name : 'Bank not found';
};

export default useBankCode;
