import { ObjectKeys } from '../../types/objectKeys';
import { formatNumber } from '../../functions/format';

const getRestOfPayment = (invoice: ObjectKeys): string | number => {
    if (invoice) {
        let totalPaid = 0;
        if (invoice?.payments?.data.length > 0) {
            invoice?.payments?.data.map((payment: ObjectKeys) => {
                totalPaid += parseFloat(payment.value);
            });
        }
        const debt = invoice.value - totalPaid;
        return formatNumber(debt, 4, 3, '.', ',');
    } else {
        return 0;
    }
};
export default getRestOfPayment;
