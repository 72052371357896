import fetch from '../fetch';
import routes from '../routes';
import { ObjectKeys } from '../../types/objectKeys';
import getCurrentLanguage from '../../functions/getCurrentLanguage';
export default class Transactions {
    public getAllTransactions(page = '1', type?: string): ObjectKeys {
        return fetch({
            method: 'GET',
            headers: { 'Content-Type': 'application/json; charset=UTF-8', 'Accept-Language': getCurrentLanguage() },
            url: routes.admin.transactions.getAllTransactions(page, type),
        });
    }

    public getFilteredTransaction(user_id: string, type: string, page: string): ObjectKeys {
        return fetch({
            method: 'GET',
            headers: { 'Content-Type': 'application/json; charset=UTF-8', 'Accept-Language': getCurrentLanguage() },
            url: routes.admin.transactions.getFilteredTransactions(type, page, user_id),
        });
    }

    public createNewTransactions(data: ObjectKeys): ObjectKeys {
        return fetch({
            method: 'POST',
            headers: { 'Content-Type': 'application/json; charset=UTF-8', 'Accept-Language': getCurrentLanguage() },
            url: routes.admin.transactions.createNewTransaction,
            data: data,
        });
    }
    public updateTransaction(id: string, date: string): ObjectKeys {
        return fetch({
            method: 'PUT',
            headers: { 'Content-Type': 'application/json; charset=UTF-8', 'Accept-Language': getCurrentLanguage() },
            url: routes.admin.transactions.updateTransaction(id),
            data: { transaction_date: date },
        });
    }
    public deleteTransaction(id: string): ObjectKeys {
        return fetch({
            method: 'DELETE',
            headers: { 'Content-Type': 'application/json; charset=UTF-8', 'Accept-Language': getCurrentLanguage() },
            url: routes.admin.transactions.deleteTransaction(id),
        });
    }

    public getUsersByPib(pib: string): ObjectKeys {
        return fetch({
            method: 'GET',
            headers: { 'Content-Type': 'application/json; charset=UTF-8', 'Accept-Language': getCurrentLanguage() },
            url: routes.admin.transactions.getUsersByPib(pib),
        });
    }
    public getAgencyById(id: string): ObjectKeys {
        return fetch({
            method: 'GET',
            headers: { 'Content-Type': 'application/json; charset=UTF-8', 'Accept-Language': getCurrentLanguage() },
            url: routes.admin.transactions.getAgencyById(id),
        });
    }
    public createTransactionFromCart(data: ObjectKeys): ObjectKeys {
        return fetch({
            method: 'POST',
            headers: { 'Content-Type': 'application/json; charset=UTF-8', 'Accept-Language': getCurrentLanguage() },
            url: routes.admin.transactions.createTransaction,
            data: data,
        });
    }
    public createManualTransaction(data: ObjectKeys): ObjectKeys {
        return fetch({
            method: 'POST',
            headers: { 'Content-Type': 'application/json; charset=UTF-8', 'Accept-Language': getCurrentLanguage() },
            url: routes.admin.transactions.createManualTransaction,
            data: data,
        });
    }
}
