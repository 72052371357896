import { FunctionComponent, useState } from 'react';
import styled from 'styled-components';
import Input, { InputComponentType } from '../../input';
import useCharLengthValidation from '../../../hooks/useCharLengthValidation';
import communication from '../../../communication';
import TextArea from '../../input/textarea';
import Button, { ButtonVariant } from '../../button';
import useLanguageStore from '../../../store/language';
import useProfileStore from '../../../store/profile';
import useTranslations from '../../../hooks/useTranslation';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { MQ_BREAKPOINTS } from '../../../constants/breakpoints';

interface Props {
    active?: boolean;
    className?: string;
    children?: string | any;
    close: Function;
}

const Suggestion: FunctionComponent<Props> = ({ close }) => {
    const { currentLang } = useLanguageStore();
    const t = useTranslations(currentLang);

    const [formData, setFormData] = useState({
        title: '',
        description: '',
    });

    const isTitleValid = useCharLengthValidation(formData.title, 3, true);
    const isDescriptionValid = useCharLengthValidation(formData.description, 5, true);
    const [titleTouched, setTitleTouched] = useState(false);
    const [descriptionTouched, setDescriptionTouched] = useState(false);

    const { profile } = useProfileStore();

    const handleSend = async (): Promise<void> => {
        let email;
        if (profile.email) email = profile.email;
        else {
            const res = await communication.getProfile();
            email = res.data.data.email;
        }
        if (formData.title === '' || formData.description === '' || isTitleValid || isDescriptionValid) {
            setTitleTouched(true);
            setDescriptionTouched(true);
            return;
        } else close();
        try {
            const response = await communication.sendSuggestion({
                email,
                heading: formData.title,
                body: formData.description,
            });
            if (response.status === 200) {
                toast.success(t('suggestion.success').text);
            } else {
                toast.error(t('suggestion.error').text);
            }
        } catch (error: any) {
            console.error(error);
        }
    };
    return (
        <>
            <ToastContainer />
            <SuggestionContainer>
                <h2>{t('suggestion.title').text}</h2>
                <div className="title">
                    <Input
                        type={InputComponentType.Text}
                        label={t('suggestion.subtitle').text}
                        value={formData.title}
                        validation={titleTouched ? isTitleValid : ''}
                        onChange={(value: string) => {
                            setFormData({ ...formData, title: value });
                        }}
                        blurHandler={() => setTitleTouched(true)}
                    />
                </div>
                <TextArea
                    label={t('suggestion.description').text}
                    value={formData.description}
                    validation={descriptionTouched ? isDescriptionValid : ''}
                    onChange={(value: string) => {
                        setFormData({ ...formData, description: value });
                    }}
                    blurHandler={() => setDescriptionTouched(true)}
                />
                <Buttons>
                    <Button
                        textTransformNone
                        onClick={handleSend}
                        variant={ButtonVariant.solid}
                        color={'var(--purple)'}
                        size={'50%'}
                    >
                        {t('suggestion.send').text}
                    </Button>
                    <Button
                        textTransformNone
                        variant={ButtonVariant.outlined}
                        color={'var(--purple)'}
                        onClick={() => close()}
                        size={'50%'}
                    >
                        {t('suggestion.close').text}
                    </Button>
                </Buttons>
            </SuggestionContainer>
        </>
    );
};

export default Suggestion;

const SuggestionContainer = styled.div`
    padding: 20px 60px 10px;
    resize: none;
    @keyframes slide-up {
        0% {
            opacity: 0;
            top: 0;
            transform: translateY(0);
        }
        100% {
            opacity: 1;
            transform: translateY(-50%);
            top: 50%;
        }
    }

    h2 {
        font-size: 18px;
        color: var(--purple);
        text-align: center;
        padding-top: 35px;
        margin-bottom: 10px;
    }
    > div {
        display: grid;
    }
    .title {
        > div {
            display: block;
        }
    }
    @media only screen and (max-width: ${MQ_BREAKPOINTS.mobileL}) {
        padding: 10px;
    }
`;

const Buttons = styled.div`
    display: flex !important;
    margin-top: 20px;
    button {
        border-radius: 0px;
    }
`;
