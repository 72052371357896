import { create } from 'zustand';
import { ObjectKeys } from '../types/objectKeys';

interface ServicesCartState {
    cartData: ObjectKeys;
}

const useServicesCartStore = create<ServicesCartState>(() => ({
    cartData: {},
}));

export default useServicesCartStore;
