export const getAllMeasurementUnits = (
    t: Function,
    invoiceType: string | undefined,
): Array<{ value: string; label?: Function | string }> => {
    if (invoiceType === 'domestic') {
        return [
            {
                value: 'per_piece',
                label: t('pages.editInvoice.units.piece').text,
            },
            {
                value: 'per_hour',
                label: t('pages.editInvoice.units.hour').text,
            },
            {
                value: 'meter',
                label: t('pages.editInvoice.units.m').text,
            },
            {
                value: 'square_meter',
                label: t('pages.editInvoice.units.m2').text,
            },
            {
                value: 'cubic_meter',
                label: t('pages.editInvoice.units.m3').text,
            },
            {
                value: 'kilogram',
                label: t('pages.editInvoice.units.kg').text,
            },
            {
                value: 'liter',
                label: t('pages.editInvoice.units.l').text,
            },
            {
                value: 'pack',
                label: t('pages.editInvoice.units.pack').text,
            },
            {
                value: 'word',
                label: t('pages.editInvoice.units.word').text,
            },
            {
                value: 'per_day',
                label: t('pages.editInvoice.units.day').text,
            },
        ];
    } else
        return [
            {
                value: 'per_piece',
                label: 'Piece',
            },
            {
                value: 'per_hour',
                label: 'Hour',
            },
            {
                value: 'kilogram',
                label: 'Kilo',
            },
            {
                value: 'per_day',
                label: 'Day',
            },
            {
                value: 'word',
                label: 'Word',
            },
            {
                value: 'square_meter',
                label: 'm2',
            },
        ];
};

export const getMeasurementUnit = (unit: string, foreign: boolean): any => {
    if (foreign) {
        switch (unit) {
            case 'per_piece':
                return 'Piece / Komad';
            case 'per_hour':
                return 'Hour / Sat';
            case 'meter':
                return 'm';
            case 'square_meter':
                return 'm2';
            case 'cubic_meter':
                return 'm3';
            case 'kilogram':
                return 'kg';
            case 'liter':
                return 'Liter / Litar';
            case 'pack':
                return 'Pack / Pakovanje';
            case 'word':
                return 'Word / Reč';
            case 'per_day':
                return 'Day / Dan';
        }
    } else {
        switch (unit) {
            case 'per_piece':
                return 'komad';
            case 'per_hour':
                return 'sat';
            case 'meter':
                return 'm';
            case 'square_meter':
                return 'm2';
            case 'cubic_meter':
                return 'm3';
            case 'kilogram':
                return 'kg';
            case 'liter':
                return 'l';
            case 'pack':
                return 'pak';
            case 'word':
                return 'reč';
            case 'per_day':
                return 'dan';
        }
    }
};

export const getUnitByValue = (value: string, foreign: boolean, preview: boolean): string => {
    if (foreign && !preview) {
        switch (value) {
            case 'per_piece':
                return 'Piece';
            case 'per_hour':
                return 'Hour';
            case 'meter':
                return 'm';
            case 'square_meter':
                return 'm2';
            case 'cubic_meter':
                return 'm3';
            case 'kilogram':
                return 'kg';
            case 'liter':
                return 'Liter';
            case 'pack':
                return 'Pack';
            case 'word':
                return 'Word';
            case 'per_day':
                return 'Day';
            default:
                return value;
        }
    } else if (foreign && preview) {
        switch (value) {
            case 'per_piece':
                return 'Piece / Komad';
            case 'per_hour':
                return 'Hour / Sat';
            case 'meter':
                return 'm';
            case 'square_meter':
                return 'm2';
            case 'cubic_meter':
                return 'm3';
            case 'kilogram':
                return 'kg';
            case 'liter':
                return 'Liter / Litar';
            case 'pack':
                return 'Pack / Pakovanje';
            case 'word':
                return 'Word / Reč';
            case 'per_day':
                return 'Day / Dan';
            default:
                return value;
        }
    } else {
        switch (value) {
            case 'per_piece':
                return 'komad';
            case 'per_hour':
                return 'sat';
            case 'meter':
                return 'm';
            case 'square_meter':
                return 'm2';
            case 'cubic_meter':
                return 'm3';
            case 'kilogram':
                return 'kg';
            case 'liter':
                return 'l';
            case 'pack':
                return 'pak';
            case 'word':
                return 'reč';
            case 'per_day':
                return 'dan';
            default:
                return value;
        }
    }
};
