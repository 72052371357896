import { create } from 'zustand';
import { ObjectKeys } from '../types/objectKeys';

interface MemoState {
    memoData: ObjectKeys;
}

const useMemoStore = create<MemoState>(() => ({
    memoData: {},
}));

export default useMemoStore;
